import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Store } from 'src/app/Models/store.model';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FirststepService } from 'src/app/services/firststep.service';
import { Subscription } from 'rxjs';

import { CustomValidator } from 'src/app/shared/customValidator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-createaccount',
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.css']
})
export class CreateaccountComponent implements OnInit, OnDestroy {

  createAccountForm: FormGroup;

  store: Store;
  @Output() storeCreated = new EventEmitter<boolean>();

  constructor(private authService: FirststepService, public fb: FormBuilder,
    private ngbModalService: NgbModal) { }

  private storeCreatedUpdate: Subscription;
  isError = false;
  hasAccount = false;
  isCGU = false;
  showBtnSpinner = false;

  get storeName() {
    if (!this.store || !this.store.depot) {
      return 'Non renseigné';
    }

    return `${this.store.depot.name} (${this.store.depot.id})`;
  }

  get storeNim() {
    if (!this.store || !this.store) {
      return 'Non renseigné';
    }

    return `${this.store.nim} (${this.store.code})`;
  }

  ngOnInit() {

    this.storeCreatedUpdate = this.authService.getStoreCreatedListner()
      .subscribe(status => {
        this.isError = !status;
        this.showBtnSpinner = false;
        if (status) {
          this.storeCreated.emit(status);
        }
      }, err => {
        this.showBtnSpinner = false;
        this.isError = true;
      });

    this.createAccountForm = this.fb.group({
      txtEmailInput: ['', [Validators.required, CustomValidator.emailValidator]],
      txtPasswordInput: ['', [Validators.required]],
      txtConfirmPasswordInput: ['', Validators.required]
    },
      {
        validator: CustomValidator.matchPassword // your validation method
      });


  }

  signup(form: NgForm, event) {
    if (form.invalid) {
      return;
    }
    this.authService.createAccount(event.target.txtEmail.value, event.target.password.value, this.isCGU);
    this.storeCreated.emit(true);
  }

  toggleCheck() {
    this.hasAccount = !this.hasAccount;
  }
  toggleCheckCGU() {
    this.isCGU = !this.isCGU;
  }
  signin(form: NgForm, event) {
    if (form.invalid) {
      return;
    }
    this.showBtnSpinner = true;
    this.authService.linkExistingAccount(this.store.nim, form.value.email, form.value.password);
  }

  openModal(adComapaignModal) {
    this.ngbModalService.open(adComapaignModal, { size: 'lg' });
  }

  ngOnDestroy() {
    if (this.storeCreatedUpdate) {
      this.storeCreatedUpdate.unsubscribe();
    }
  }
}
