import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { OpeningItem } from 'src/app/Models/hours/opening.model';
import { NewStatment, LineStatement } from 'src/app/Models/newStatment.model';

@Component({
  selector: 'app-timingpopup',
  templateUrl: './timingpopup.component.html',
  styleUrls: ['./timingpopup.component.css']
})
export class TimingpopupComponent implements OnInit {

  @Input() inItem: OpeningItem;
  @Input() suffix: string;
  @Output() outItem = new EventEmitter<OpeningItem>();

  @Input() changes: LineStatement[] = [];
  private initialHash: string;
  error = '';

  opening = { hour: 0, minute: 0 };
  pauseFrom = { hour: 0, minute: 0 };
  pauseTo = { hour: 0, minute: 0 };
  closing = { hour: 0, minute: 0 };
  weekDay = 0;
  onchange($event, type: string) {
    const hour = this.toString($event.hour);
    const minute = this.toString($event.minute);
    this.inItem[type] = `${hour}:${minute}:00`;
  }

  private toString(number: number) {
    return ('0' + number).slice(-2);
  }

  constructor(protected ngbModalService: NgbModal) { }

  ngOnInit() {
    if (!this.inItem) {
      return;
    }

    this.initialHash = this.hashString(this.inItem);

    this.opening = this.returnTime('opening');
    this.closing = this.returnTime('closing');
    this.pauseFrom = this.returnTime('pauseFrom');
    this.pauseTo = this.returnTime('pauseTo');
    this.weekDay = this.inItem.weekDay;
  }

  private returnTime(type: string) {
    const time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
    if (!this.inItem || !this.inItem[type] || !this.inItem[type].includes(':')) {
      return time;
    }

    time.hour = Number(this.inItem[type].split(':')[0]);
    time.minute = Number(this.inItem[type].split(':')[1]);
    return time;
  }

  closeModal() {
    if (this.ngbModalService.hasOpenModals) {
      this.ngbModalService.dismissAll();
    }
  }

  private toTime(time: NgbTimeStruct) {
    return (time.hour * 60) + time.minute;
  }

  saveData() {

    this.error = '';

    const itemHash = this.hashString(this.inItem);
    if (itemHash !== this.initialHash) {
      this.inItem.status = 'edit';
      const t1 = this.toTime(this.returnTime('opening'));
      const t2 = this.toTime(this.returnTime('pauseFrom'));
      const t3 = this.toTime(this.returnTime('pauseTo'));
      const t4 = this.toTime(this.returnTime('closing'));

      let isValid = false;
      if (t2 === 0 && t3 === 0) {
        isValid = t1 < t4;
      } else {
        isValid = (t1 < t2) && (t2 < t3) && (t3 < t4);
      }

      if (!isValid) {
        this.error = 'Les horaires d’ouverture et de fermeture sont incohérents !';
        return;
      }
    }

    this.outItem.next(this.inItem);
    this.ngbModalService.dismissAll();
  }

  private hashString(item: OpeningItem) {
    return `${item.opening || ''}${item.closing || ''}${item.pauseFrom || ''}${item.pauseTo || ''}`;
  }

  isDisabled(dayCode: number): boolean {
    return this.changes.filter(i => i.id === `${this.suffix}${dayCode}` && !i.warehouseAction).length > 0;
  }
}
