import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Notification } from '../Models/notification.model';
import { AuthService } from './auth-service';
import { NotificationMessage } from '../Models/message.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }
  private NOTIFCATIONS_LOCALSTORAGE_NAME = 'my_notifications';

  private notificationCountListner = new Subject<number>();

  getNotificationsCountListner() {
    return this.notificationCountListner.asObservable();
  }

  getNotificationsCount(userId: string, nim: string, lastSync: string = null) {
    if (!AuthService.getAuthInfo().length) {
      return;
    }

    const url = `${environment.APIURL}/api/v1/me/messages/count?userId=${userId}&nim=${nim}&lastSync=${lastSync || ''}`;
    this.http.get<{ result: number }>(url)
    .subscribe(result => {
      if (result) {
        this.notificationCountListner.next(result.result);
      }
    });
  }

  getMessages(userId: string, nim: string) {
    if (!AuthService.getAuthInfo().length) {
      return;
    }
    const url = environment.APIURL + '/api/v1/me/messages?userId=' + userId + '&nim=' + nim;
    return this.http.get<{ result: NotificationMessage[], totalRecords: number }>(url);
  }

  getSentMessages(userId: string, nim: string) {
    if (!AuthService.getAuthInfo().length) {
      return;
    }
    const url = environment.APIURL + '/api/v1/me/messages/sent?userId=' + userId + '&nim=' + nim;
    return this.http.get<{ result: NotificationMessage[], totalRecords: number }>(url);
  }

  getArchivedMessages(userId: string, nim: string) {
    if (!AuthService.getAuthInfo().length) {
      return;
    }
    const url = environment.APIURL + '/api/v1/me/messages/archive?userId=' + userId + '&nim=' + nim;
    return this.http.get<{ result: NotificationMessage[], totalRecords: number }>(url);
  }

  markAsRead(messageId: string) {
    return this.http.put(environment.APIURL + '/api/v1/me/messages/' + messageId + '/read', null);
  }

  archiveMessage(messageId: string) {
    return this.http.put(environment.APIURL + '/api/v1/me/messages/' + messageId + '/acknowledge', null);
  }

  getLocalNotififications(): Notification[] {
    return JSON.parse(localStorage.getItem(this.NOTIFCATIONS_LOCALSTORAGE_NAME));
  }

  saveLocalNotififications(notifications: Notification[]) {

    localStorage.setItem(this.NOTIFCATIONS_LOCALSTORAGE_NAME, JSON.stringify(notifications));
  }

  removeLocalNotififications() {

    localStorage.removeItem(this.NOTIFCATIONS_LOCALSTORAGE_NAME);
  }
}
