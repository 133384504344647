import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { Order, ProCustomer, StoreOrder, ProCustomerOrder } from '../Models/order.model';
import { Basket } from '../Models/basket.model';
import { StoreDeliveryConfig } from '../Models/store.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient,
    private toastrService: ToastrService) { }
  basketIssues: Basket[] = [];

  // get store orders
  getOrders(dFrom: string, dTo: string, nim: string) {
    return this.http.get<{ result: Order[] }>(environment.APIURL + '/api/v2/orders?nim=' + nim);
  }

  getStoreOrders(dFrom: string, dTo: string, nim: string) {
    const url = `${environment.APIURL}/api/v4/orders/store?nim=${nim}&from=${dFrom}&to=${dTo}`;
    return this.http.get<{ result: StoreOrder[] }>(url);
  }

  // get pro orders
  getProOrders(dFrom: string, dTo: string, refernce: string, nim: string) {
    const url = `${environment.APIURL}/api/v4/orders?nim=${nim}&from=${dFrom}&to=${dTo}&reference=${refernce}`;
    return this.http.get<{ result: ProCustomerOrder[] }>(url);
  }

  getCustomers() {
    return this.http.get<{ result: ProCustomer[] }>(environment.APIURL + '/api/v2/pro/accounts');
  }

  addCustomer(data: ProCustomer) {
    return this.http.post<{ result: boolean }>(environment.APIURL + '/api/v2/pro/account', data);
  }

  updateCustomer(data: ProCustomer) {
    return this.http.put<{ result: boolean }>(environment.APIURL + '/api/v2/pro/account', data);
  }

  initCatalog(proId: string) {
    return this.http.post<{ result: boolean }>(environment.APIURL + '/api/v2/pro/' + proId + '/catalog', {});
  }

  createOrder(data: any, nim: string) {
    return this.http.post<{ result: boolean }>(environment.APIURL + '/api/v4/orders?nim=' + nim, data);
  }

  acceptOrder(data: any, nim: string) {
    return this.http.put<{ result: boolean }>(environment.APIURL + '/api/v4/orders/store/feedback?nim=' + nim, data);
  }

  storePaidOrder(data: any, nim: string) {
    return this.http.put<{ result: boolean }>(environment.APIURL + '/api/v4/orders/store/payfeedback?nim=' + nim, data);
  }

  storeDeliveryConfig(data: any, nim: string) {
    return this.http.put<{ result: boolean }>(environment.APIURL + '/api/v4/orders/store/delivery?nim=' + nim, data);
  }

  getStoreDeliveryConfig(data: any, nim: string) {
    return this.http.get<{ result: StoreDeliveryConfig }>(environment.APIURL + '/api/v4/orders/store/delivery?nim=' + nim, data);
  }

  acceptDelivery(data: any) {
    return this.http.put(environment.APIURL + '/api/v4/orders/feedback', data);
  }

  deliverOrder(orderId: string, data: any) {
    return this.http.put(environment.APIURL + '/api/v4/orders/' + orderId + '/retrieve', data);
  }

  cancelOrder(dateId: string, reference: string) {
    const url = `${environment.APIURL}/api/v4/orders/cancel?dateId=${dateId}&reference=${reference}`;
    return this.http.delete<{ result: boolean }>(url);
  }

  refuseOrder(orderId: string) {
    return this.http.put(environment.APIURL + '/api/v2/orders/' + orderId + '/refuse', null);
  }

  addIssueToLocalStorage(issue: Basket[]) {
    localStorage.setItem('basket', JSON.stringify(issue));
  }

  getUserBasket() {
    return localStorage.getItem('basket');
  }

  clearUserBasket() {
    localStorage.removeItem('basket');
    this.basketIssues = [];
  }

  addIssueToUserBasket(issueBasket) {
    const userBasket = this.getUserBasket();
    this.basketIssues = [];
    if (userBasket != null) {
      this.basketIssues = JSON.parse(userBasket);
    }
    const isExist = this.basketIssues.filter(e => e.issue.id === issueBasket.issue.id);
    if (!isExist.length && issueBasket) {
      this.basketIssues.push(issueBasket);
    } else if (this.basketIssues.findIndex(i => i.issue.id === issueBasket.issue.id) >= 0) {

      this.basketIssues[this.basketIssues.findIndex(i => i.issue.id === issueBasket.issue.id)] = issueBasket;
    }
    this.addIssueToLocalStorage(this.basketIssues);
  }
  getBasketByIssue(issue) {
    const userBasket = this.getUserBasket();
    if (userBasket != null) {
      this.basketIssues = JSON.parse(userBasket);
    }
    return this.basketIssues.filter(e => e.issue.id === issue.id);
  }
  removeItemFromUserBasket(issueBasket) {
    const userBasket = this.getUserBasket();
    if (userBasket != null) {
      this.basketIssues = JSON.parse(userBasket);
      const newitems = this.basketIssues.filter(i => i.issue.id !== issueBasket.issue.id);
      if (newitems) {
        this.addIssueToLocalStorage(newitems);
        this.basketIssues = newitems;
        this.toastrService.success('Titre a été retité de votre panier');
      }
    }

  }
}
