import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';
import { PathList } from '../Models/pathList';
import { OrderService } from '../services/order.service';
import { ElementSchemaRegistry } from '@angular/compiler';
import { Issue } from '../Models/issue.model';
import { Basket } from '../Models/basket.model';

@Injectable({
    providedIn: 'root'
})

export class Commons {

    constructor(private router: Router, private orderService: OrderService) { }

    goNext(e, inputs) {
        e.preventDefault();
        // Get all input elements on the page
        const elements = inputs;
        let index = parseInt(e.currentTarget.id, 0) + 1;
        if (index >= elements.length) {
            index = 0;
        }
        elements._results[index].nativeElement.focus();
    }

    getRoutesRestrictionEligible() {
        return this.router.config.filter(route => {
            return route.data && route.data.isOnRestriction === true;
        }).map((item, index) => {
            const name = item.data.breadcrumb || '';
            return {
                'path': item.path, 'name': name
            };
        });
    }

    getRoutesForOldOwner() {
        return this.router.config.filter(route => {
            return route.data && route.data.accessToOldOwner === true;
        }).map((item, index) => {
            return item.path;
        });
    }

    getRoutesAdsEligible() {
        return this.router.config.filter(route => {
            return route.data && route.data.isAccessForAds === true;
        }).map((item, index) => {
            const name = item.data.breadcrumb || '';
            return {
                'path': item.path, 'name': name
            };
        });
    }

    getRoutesBasicPack() {
        return this.router.config.filter(route => {
            return route.data && route.data.isBasicPack === true && route.data.isOnRestriction === true;
        }).map((item, index) => {
            const name = item.data.breadcrumb || '';
            return {
                'path': item.path, 'name': name
            };
        });
    }

    getDefaultRouteByRole(role: string): string {
        const config = this.router.config.find(route => {
            if (!route.data || !route.data.roles || !route.data.roles.length) {
                return false;
            }
            return route.data.roles.filter(r => r === role.toLowerCase()).length > 0;
        });
        if (!config) {
            return '/dashboard';
        }

        return config.path;
    }

    getRoutesMediumPack() {
        return this.router.config.filter(route => {
            return route.data && route.data.isMediumPack === true && route.data.isOnRestriction === true;
        }).map((item, index) => {
            const name = item.data.breadcrumb || '';
            return {
                'path': item.path, 'name': name
            };
        });
    }

    getRoutesFullPack() {
        return this.router.config.filter(route => {
            return route.data && route.data.isFullPack === true && route.data.isOnRestriction === true;
        }).map((item, index) => {
            const name = item.data.breadcrumb || '';
            return {
                'path': item.path, 'name': name
            };
        });
    }


    getUserBasket(): Basket[] {
        const userBasket = this.orderService.getUserBasket();
        if (userBasket) {
            return JSON.parse(userBasket);
        }
    }
}
