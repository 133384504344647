import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { AuthService } from '../services/auth-service';
import { CustomerCode } from '../Models/customerCode.model';


@Injectable({
  providedIn: 'root'
})
export class CustomerCodeService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  private customerCodes: CustomerCode[] = [];
  private customerCodesUpdated = new Subject<CustomerCode[]>();
  private currentNIM = this.authService.currentNim;

  getCustomerCodes() {
    this.http.get<{ result: CustomerCode[] }>(environment.APIURL + '/api/v2/pro/codes?nim=' + this.currentNIM)
      .subscribe(response => {
        if (response) {
          this.customerCodes = response.result;
          this.customerCodesUpdated.next([...this.customerCodes]);
        } else {
          this.customerCodesUpdated.next([]);
        }
      }, err => {
        this.customerCodesUpdated.next([]);
      });
  }

  getCustomerCodesUpdateListner() {
    return this.customerCodesUpdated.asObservable();
  }

  addNewCustomerCode(data: any) {
    const url = environment.APIURL + '/api/v2/pro/codes?nim=' + this.currentNIM;
    return this.http.post<{ result: string }>(url, data);
  }

  cancelCustomerCode(codeId: string) {
    const url = environment.APIURL + '/api/v2/pro/codes/' + codeId;
    return this.http.delete<{ result: boolean }>(url);
  }

  createAccountPublic(data: any, hasAccount: boolean = false) {
    const url = `${environment.APIURL}/api/v2/pro/confirmaccount?nim=${this.currentNIM}&hasAccount=${hasAccount}`;
    return this.http.post<{ result: boolean }>(url, data);
  }
}
