import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import { SearchService } from '../../services/search.service';
import { Issue } from '../../Models/issue.model';
import { AuthService } from '../../services/auth-service';
import { HistorySale } from 'src/app/Models/history-sale.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.css']
})
export class SearchAutocompleteComponent implements OnInit, OnDestroy {

  constructor(private searchService: SearchService, private authService: AuthService) { }

  @Input() typeAction = '';
  @Input() readonlySearch = false;
  @Input() titleCodif = '';
  @Output() publicationSelected = new EventEmitter<Issue[]>();
  @Output() searchSelectedItem = new EventEmitter<Issue>();
  @Output() history = new EventEmitter<HistorySale[]>();

  @Input() allowTest = false;

  @Input() changeCodif: Subject<string> = new Subject<string>();

  @ViewChild('searchInput') searchInput: HTMLElement;

  private publications: Issue[] = [];
  private issuesSub: Subscription;
  private changeCodifSub: Subscription;
  private publicationSub: Subscription;
  private publicationHistorySub: Subscription;
  private searchSub: Subscription;
  private quantitySub: Subscription;
  isSearching: Boolean = false;
  isLoading: Boolean = false;
  issues: Issue[] = [];
  private selectedItem: Issue;

  keyUp = new Subject<string>();

  txtSearch = '';
  isAllowed = true;
  txtError = '';
  private autoSelect = false;
  ngOnInit() {

    this.autoSelect = (this.titleCodif && this.titleCodif.length > 0);
    this.searchSub = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.doSearchFromString(value));

    if (this.titleCodif && this.titleCodif !== '') {
      this.keyUp.next(this.titleCodif);
    }

    this.changeCodifSub = this.changeCodif.subscribe(codif => {
      this.titleCodif = codif;
        if (this.titleCodif && this.titleCodif !== '') {
          this.keyUp.next(this.titleCodif);
        }
    });

    this.publicationSub = this.searchService.getPublicationsUpdateListener()
        .subscribe(issuesResult => {
          if (!this.selectedItem) {
            return;
          }

          if (this.selectedItem.titleOnly && this.typeAction === 'service') {
            this.selectedItem.titleOnly = !issuesResult || !issuesResult.length;
          }

          this.publications = issuesResult;

          // check if any all issues are not reorder allowed
          if (this.typeAction === 'reorder'
            && this.publications.findIndex(i => i.title.reorderAllowed) < 0
          ) {
            this.isAllowed = false;
            this.txtError = 'Vous ne pouvez pas passer de commande pour ce titre.';
          }

          // emit event to parent
          this.publicationSelected.emit(this.publications);
        });

    this.publicationHistorySub = this.searchService.getHistoryUpdateListener()
        .subscribe(issuesResult => {
          // emit event to parent
          this.history.emit(issuesResult);
        });

    this.issuesSub = this.searchService.getIssuesUpdateListner()
        .subscribe(issuesResult => {
          this.issues = issuesResult;
          this.isLoading = false;
          if (this.autoSelect) {
            this.onselect(this.issues[0]);
          }
        });

  }


  onselect(selectedIssue: Issue) {

    this.selectedItem = selectedIssue;

    if (!this.selectedItem) {
      return;
    }

    if (this.selectedItem.title) {
      if (this.typeAction === 'service') {

        this.isAllowed = this.selectedItem.title.allowServiceUpdate;
        this.txtError = 'Cette fonctionnalité n’est pas disponible pour ce titre.';
      }
    }
    this.isSearching = false;
    this.txtSearch = this.selectedItem.titleName;
    const codif = this.selectedItem.title == null ? '' : this.selectedItem.title.codif;

    this.searchSelectedItem.emit(this.selectedItem);
    if (this.typeAction !== 'service') {

      if (this.typeAction === 'reorder'
        && !this.selectedItem.title.allowReorder
      ) {
        this.isAllowed = this.selectedItem.title.allowReorder;
        this.txtError = this.selectedItem.title.reorderErrorMessage || 'Vous ne pouvez pas passer de commande pour ce titre.';
        this.publicationSelected.emit([]);
        return;
      }
      this.searchService.getPublications(codif,
            this.authService.currentNim,
            false, false, this.typeAction === 'forgotten');
    } else if (!this.selectedItem.titleOnly) {
      this.selectedItem.titleOnly = true;
      this.searchService.getNextPublications(codif, this.authService.currentNim);
    } else {
      this.publicationSelected.emit([]);
    }

    if (this.typeAction !== 'forgotten'
      || !this.selectedItem.title) {

      // get history
      this.searchService.getHistorys(this.selectedItem.title.id, this.authService.currentNim);
    }
  }

  doSearch(form: NgForm) {

    const searchInput = form ? form.value.searchInput : this.titleCodif;

    this.autoSelect = false;
    this.keyUp.next(searchInput);
  }

  private doSearchFromString(text: string) {

    if (!text || !text.length || text.length === 1) {
      this.isSearching = false;
      this.isLoading = false;
      return;
    }
    this.isAllowed = true;
    this.issues = [];
    this.isLoading = true;
    const searchInput = text || this.titleCodif;

    if (searchInput === '') {
      this.isSearching = false;
      this.isLoading = false;
      return;
    }

    this.isSearching = true;
    this.searchService.searchTitles(searchInput, this.authService.currentNim, this.allowTest, this.typeAction);
  }

  ngOnDestroy() {
    if (this.issuesSub) {
      this.issuesSub.unsubscribe();
    }
    if (this.publicationSub) {
      this.publicationSub.unsubscribe();
    }
    if (this.publicationHistorySub) {
      this.publicationHistorySub.unsubscribe();
    }
    if (this.quantitySub) {
      this.quantitySub.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    if (this.changeCodifSub) {
      this.changeCodifSub.unsubscribe();
    }
  }
}
