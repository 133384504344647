import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth-service';
import { NgForm } from '@angular/forms';
import { Sweetalert } from '../shared/sweetAlert';
import { ContactService } from '../services/contact.service';
import { GA } from '../shared/gAnalytics';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  options: any[] = [];
  selectedValue = 0;
  attachements = [];
  fileCount: string;
  showBtnSpinner = false;

  constructor(private contactService: ContactService, private authService: AuthService, private sweet: Sweetalert,
    private ga: GA) { }

  ngOnInit() {
    this.bindOptions();
  }
  get isStore(): boolean {
    return this.authService.isStore;
  }

  get isWarehouse(): boolean {
    return this.authService.isWarehouse;
  }

  get userFullName(): string {
    return this.authService.userFullName;
  }

  get userEmail(): string {
    return this.authService.userEmail;
  }
  // bind options for diffusers and warehouse
  bindOptions() {

    if (this.isWarehouse) {
      this.options.push('Obtenir de l\u2019aide');
      this.options.push('Alertes');
      this.options.push('Diffuseurs');
      this.options.push('Centre de notification');
      this.options.push('Contact');
    }
    if (this.isStore) {

      this.options.push('Obtenir de l\u2019aide');
      this.options.push('Recherche');
      this.options.push('Performances');
      this.options.push('Incident');
      this.options.push('R\xE9clamation sur BL');
      this.options.push('D\xE9claration d\u2019invendus');
      this.options.push('T\xE9l\xE9chargement du BL/BI en PDF');
      this.options.push('D\xE9claration des oubli\xE9s');
      this.options.push('Demande de r\xE9assort');
      this.options.push('Modification de quantit\xE9 de service');
      this.options.push('Installation d\u2019un titre');
      this.options.push('Centre de notification');
      this.options.push('Point de vente');
      this.options.push('Contact');
    }

    this.selectedValue = 0;
  }

  // on post new message
  onSaveContact(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const name = this.userFullName;
    const email = this.userEmail;
    // tslint:disable-next-line:max-line-length
    const body = (form.value.txtDescriptionRequest ? form.value.txtDescriptionRequest : '') + '\n\n' + 'NIM : ' + this.authService.currentNim + '\nUserAgent utilisé : ' + this.getUserAgent() + '\n' + this.getBrowserInfo();
    const subject = 'PDIF — ' + this.options[this.selectedValue] + ' ' + form.value.object;
    const attachements = this.attachements;

    const reorderObj = {
      'name': name,
      'email': email,
      'body': body,
      'subject': subject,
      'attachements': attachements,
    };

    this.showBtnSpinner = true;

    this.contactService.sendTicket(reorderObj)
      .subscribe(response => {
        this.sweet.success('Merci !', 'Votre demande a été envoyée et sera traitée dans les meilleurs délais.');
        this.ga.createEvent('click', 'Contact', 'envoyer un nouveau message contact');
        this.showBtnSpinner = false;
      },
        error => {

          this.showBtnSpinner = false;
          this.sweet.warning('Erreur !', 'Une erreur s’est produite lors de la création d’un ticket.');
        });

  }
  // return userAgent
  getUserAgent() {
    let userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Macintosh') > -1) {
      userAgent = 'mac';
    }
    if (userAgent.indexOf('Windows') > -1) {
      userAgent = 'Windows';
    }
    if (userAgent.indexOf('iPhone') > -1) {
      userAgent = 'iPhone';
    }
    if (userAgent.indexOf('iPad') > -1) {
      userAgent = 'iPad';
    }
    if (userAgent.indexOf('Android') > -1) {
      userAgent = 'Android';
    }
    if (userAgent.indexOf('Windows Phone') > -1) {
      userAgent = 'Windows Phone';
    }
    return userAgent;
  }
  // return browser name and version
  getBrowserInfo(): string {
    const userInfo = window.navigator.userAgent.split(' ');
    if (userInfo) {
      const b = userInfo[userInfo.length - 1].split('/');
      if (b) { return b[0] + ', Version : ' + b[1]; }
    }
    return '';
  }


  // read attachement files
  handleFileInput(input) {

    const files = input.target.files;
    const isFileExist = false;
    const self = this;
    let count = files.length - 1;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const picReader = new FileReader();
      picReader.addEventListener('load', function (event) {
        const picFile = <FileReader>event.target;
        const picnames = files[count].name;
        const fileExtension = picnames.split('.').pop();
        count--;
        const attachToPush = {
          'content': picFile.result,
          'extension': fileExtension,
          'picnames': picnames
        };

        if (self.attachements.length > 0) {
          $.each(self.attachements, function (index, item) {
            if (item.content === picFile.result) {
              self.sweet.warning('Attention !', 'Contenu déjà sélectionné.');
              this.isFileExist = true;
              return false;
            }
          });
          if (isFileExist === false) {
            self.attachements.push(attachToPush);
          }
        } else {
          self.attachements.push(attachToPush);
        }

        const nbrFile = self.attachements.length;
        nbrFile > 1 ? self.fileCount = nbrFile + ' Fichiers sélectionnés' : self.fileCount = nbrFile + ' Fichier sélectionné';

      });
      picReader.readAsDataURL(file);

    }
  }

  removeAttachement(index: number) {
    this.attachements.splice(index, 1);
  }

  // reset contact form
  cancelForm() {
    $('#name, #email, #object, #txtDescriptionRequest').val('');
    this.selectedValue = 0;
    this.attachements = [];
    this.fileCount = '';
  }
}
