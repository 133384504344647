import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WarehouseHoliday, WarehouseStoreHoliday, WarehouseStoreHolidayLatestAnswer } from '../Models/warehouse-holiday.model';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseHolidaysService {

  private STORAGE_PREFIX = 'HOLIDAY';
  private holidaysUpdated = new Subject<WarehouseHoliday[]>();
  private holidaysStoreUpdated = new Subject<WarehouseStoreHoliday>();
  private holidaysLatestAnswerStoreUpdated = new Subject<WarehouseStoreHolidayLatestAnswer[]>();
  private statusUpdated = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  setToStorage(nim: string, date: string) {
    localStorage.setItem(`${this.STORAGE_PREFIX}_${nim}`, date);
  }

  removeFromStorage(nim: string) {
    localStorage.removeItem(`${this.STORAGE_PREFIX}_${nim}`);
  }

  getHolidaysUpdateListener() {
    return this.holidaysUpdated.asObservable();
  }

  getStoreHolidayUpdateListener() {
    return this.holidaysStoreUpdated.asObservable();
  }

  getStatusUpdateListener() {
    return this.statusUpdated.asObservable();
  }

  getLatestAnswerUpdateListener() {
    return this.holidaysLatestAnswerStoreUpdated.asObservable();
  }

  // get list of claims by date and nim
  getHolidays(nim: string, year: number) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/${nim}?year=${year}`;
    this.http.get<{ result: WarehouseHoliday[] }>(url)
      .subscribe(response => {
        if (response) {
          this.holidaysUpdated.next(response.result);
        } else {
          this.holidaysUpdated.next([]);
        }
      }
        ,
        error => {
          this.holidaysUpdated.next([]);
        });
  }

  getStoreHolidayQuestion(nim: string) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/stores/${nim}`;
    this.http.get<{ result: WarehouseStoreHoliday }>(url)
      .subscribe(response => {
        if (response) {
          this.holidaysStoreUpdated.next(response.result);
        } else {
          this.holidaysUpdated.next(null);
        }
      }
        ,
        error => {
          this.holidaysUpdated.next(null);
        });
  }

  getStoreLatestAnswer(nim: string) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/stores/${nim}/reminder`;
    this.http.get<{ result: WarehouseStoreHolidayLatestAnswer[] }>(url)
      .subscribe(response => {
        if (response) {
          this.holidaysLatestAnswerStoreUpdated.next(response.result);
        } else {
          this.holidaysLatestAnswerStoreUpdated.next([]);
        }
      }
        ,
        error => {
          this.holidaysLatestAnswerStoreUpdated.next([]);
        });
  }

  addHolidays(nim: string, name: string, date: string) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/${nim}`;
    this.http.post<{ result: boolean }>(url, {name, date})
      .subscribe(response => {
        if (response) {
          this.statusUpdated.next(response.result);
        } else {
          this.statusUpdated.next(false);
        }
      }
        ,
        error => {
          this.statusUpdated.next(false);
        });
  }

  changeStatus(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/${nim}/status?holidayId=${id}`;
    this.http.put<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.statusUpdated.next(response.result);
        } else {
          this.statusUpdated.next(false);
        }
      }
        ,
        error => {
          this.statusUpdated.next(false);
        });
  }

  setOpen(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/stores/${nim}/open?holidayId=${id}`;
    this.http.put<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.statusUpdated.next(response.result);
        } else {
          this.statusUpdated.next(false);
        }
      }
        ,
        error => {
          this.statusUpdated.next(false);
        });
  }

  setClosed(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/warehousesholidays/stores/${nim}/closed?holidayId=${id}`;
    this.http.put<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.statusUpdated.next(response.result);
        } else {
          this.statusUpdated.next(false);
        }
      }
        ,
        error => {
          this.statusUpdated.next(false);
        });
  }
}
