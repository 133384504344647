export class ItemToPush {
    code: string;
    codif: string;
    date: string;
    nim: string;
    numeroParution: string;
    quantity?: number;
    supplied: number;
    isNew: boolean;
    dayCode: string;

}
