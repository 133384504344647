import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/Models/issue.model';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categories-navbar',
  templateUrl: './categories-navbar.component.html',
  styleUrls: ['./categories-navbar.component.css']
})
export class CategoriesNavbarComponent implements OnInit {

  constructor(private categoriyService: CategoryService,
    private route: ActivatedRoute) { }

  shortcode = '';
  categories: Category[];
  categoriesSeeMore: Category[];
  isLoading = true;

  ngOnInit() {
    this.route.firstChild.params.subscribe(param => {
      this.shortcode = param['shortCode'] || '';
    });
    this.getAllCategories();
  }

  getCategoryUrl(cat: Category) {
    if (!cat) {
      return '';
    }

    if (this.shortcode.length) {
      return `/category/${cat.id}/${this.shortcode}`;
    }
    return `/category/${cat.id}`;
  }

  getAllCategories() {
    this.isLoading = true;
    this.categoriyService.getCategories().pipe(take(7))
      .subscribe(res => {
        if (res.result) {
          localStorage.setItem('categories', JSON.stringify(res.result));
          this.categories = res.result.slice(0, 7);
          this.categoriesSeeMore = res.result.slice(7, res.result.length);
          this.isLoading = false;
        }
      },
        err => {
          this.isLoading = false;
        });
  }

}
