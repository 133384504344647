import { Component, OnInit, ViewChild } from '@angular/core';
import { PdvbaseComponent } from '../pdvbase/pdvbase.component';
import { NgForm, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../shared/customValidator';

@Component({
  selector: 'app-social-reason',
  templateUrl: './social-reason.component.html',
  styleUrls: ['./social-reason.component.css']
})
export class SocialReasonComponent extends PdvbaseComponent implements OnInit {
  sRForm: FormGroup;
  isFormChange = false;

  ngOnInit() {
    super.ngOnInit();
    this.ga.createEventScreenView('Pdv > Raison sociale');

    this.sRForm = this.fb.group({
      txtLastNameInput: '',
      txtFirstNameInput: '',
      txtTradeNameInput: '',
      txtLegalFormInput: '',
      txtLegalNameInput: '',
      txtAddressInput: '',
      txtZipCodeInput: '',
      txtCityInput: '',
      txtPhoneInput: ['', [Validators.required, CustomValidator.phoneNumberValidator]],
      txtCellularInput: ['', [Validators.required, CustomValidator.phoneNumberValidator]],
      txtEmailInput: ''
    });
  }

  reBindForm() {
    this.sRForm = this.fb.group({
      txtLastNameInput: '',
      txtFirstNameInput: '',
      txtTradeNameInput: '',
      txtLegalFormInput: '',
      txtLegalNameInput: '',
      txtAddressInput: '',
      txtZipCodeInput: '',
      txtCityInput: '',
      txtPhoneInput: '',
      txtCellularInput: '',
      txtEmailInput: ''
    });

    if (this.store) {
      this.sRForm.setValue({
        txtLastNameInput: this.store.contact.lastName,
        txtFirstNameInput: this.store.contact.firstName,
        txtTradeNameInput: this.store.name,
        txtLegalFormInput: this.store.headQuarter.legalForm,
        txtLegalNameInput: this.store.headQuarter.legalName,
        txtAddressInput: this.store.headQuarter.address.firstAddress,
        txtZipCodeInput: this.store.headQuarter.address.postalCode,
        txtCityInput: this.store.headQuarter.address.locality,
        txtPhoneInput: this.store.contact.phone || '',
        txtCellularInput: this.store.contact.cellular || '',
        txtEmailInput: this.store.email || ''
      });
    }
    this.isFormChange = false;
  }

  get warehouseContactName() {
    if (!this.store
      || !this.store.depot
      || !this.store.depot.contact) {
      return '';
    }

    return `${this.store.depot.contact.civility} ${this.store.depot.contact.firstName} ${this.store.depot.contact.lastName}`;
  }

  onSaveSocialReason(event: any, form: NgForm) {
    // if (form.invalid) {
    //   return;
    // }

    if (this.checkForValue( this.store.contact.firstName, event.target.txtFirstName.value)) {
      const fContact = this.getStatementOrDefault('social_contact.firstName', 'Contact');
      fContact.newValue = event.target.txtFirstName.value;
      fContact.oldValue = this.store.contact.firstName || '';
      fContact.label = 'Prénom';
      this.addToChanges(fContact);
    }

    if (this.checkForValue( this.store.contact.lastName, event.target.txtLastName.value )) {
      const lContact = this.getStatementOrDefault('social_contact.lastName', 'Contact');
      lContact.newValue = event.target.txtLastName.value;
      lContact.oldValue = this.store.contact.lastName || '';
      lContact.label = 'Nom';
      this.addToChanges(lContact);
    }

    if (this.checkForValue( this.store.name, event.target.txtTradeName.value )) {
      const nContact = this.getStatementOrDefault('social_name', 'Raison sociale');
      nContact.newValue = event.target.txtTradeName.value;
      nContact.oldValue = this.store.name || '';
      nContact.label = 'Nom commercial';
      this.addToChanges(nContact);
    }

    if (this.checkForValue( this.store.headQuarter.legalForm, event.target.txtLegalForm.value)) {
      const hfContact = this.getStatementOrDefault('social_headQuarter.legalForm', 'Raison sociale');
      hfContact.newValue = event.target.txtLegalForm.value;
      hfContact.oldValue = this.store.headQuarter.legalForm || '';
      hfContact.label = 'Forme d’exploitation';
      this.addToChanges(hfContact);
    }

    if (this.checkForValue( this.store.headQuarter.legalName, event.target.txtLegalName.value )) {
      const hnContact = this.getStatementOrDefault('social_headQuarter.legalName', 'Raison sociale');
      hnContact.newValue = event.target.txtLegalName.value;
      hnContact.oldValue = this.store.headQuarter.legalName || '';
      hnContact.label = 'Raison sociale';
      this.addToChanges(hnContact);
    }

    if (this.checkForValue( this.store.contact.phone, event.target.txtPhone.value )) {
      const pContact = this.getStatementOrDefault('social_contact.phone', 'Contact');
      pContact.newValue = event.target.txtPhone.value;
      pContact.oldValue = this.store.contact.phone || '';
      pContact.label = 'Téléphone';
      this.addToChanges(pContact);
    }

    if (this.checkForValue( this.store.contact.cellular, event.target.txtCellular.value )) {
      const mContact = this.getStatementOrDefault('social_contact.cellular', 'Contact');
      mContact.newValue = event.target.txtCellular.value;
      mContact.oldValue = this.store.contact.cellular || '';
      mContact.label = 'Téléphone mobile';
      this.addToChanges(mContact);
    }

    this.save(this.localStatements);
    this.ga.createEvent('click', 'Timing', 'Modification des informations du  Raison sociale / commercial');
  }
  checkForValue(storeValue, inputValue): boolean {
    if ( storeValue !== undefined && storeValue !== inputValue ) {
        return true;
    } else if ( storeValue === undefined && inputValue !== '' ) {
      return true;
    } else {
      return false;
    }
  }
}
