import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { Issue } from '../Models/issue.model';

import { DtLanguages } from '../shared/dtLanguages';
import { AuthService } from '../services/auth-service';
import { TitlesService } from '../services/titles.service';
import { Subscription } from 'rxjs';

import { ActivatedRoute, Router, NavigationEnd, ActivationEnd } from '@angular/router';

import { HistorySale } from 'src/app/Models/history-sale.model';
import { SearchService } from '../services/search.service';
import { DatePipe } from '../shared/pipes/date-formatter.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit, OnDestroy {

  private datePipe = new DatePipe();
  constructor(private searchService: SearchService,
    private ngbModalHistoryService: NgbModal,
    private titleRequest: TitlesService,
    private authService: AuthService,
    private titleService: TitlesService,
    private ngbModalService: NgbModal,
    private ngbModalConfig: NgbModalConfig,
    private dtlanguages: DtLanguages,
    private route: ActivatedRoute,
    private router: Router) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;

        this.routerSub = this.router.events.subscribe((e: any) => {
          this.issue = null;
          this.isIssueLoading = true;
          this.historyDisabled = true;
          this.getIssueFailed = false;
          if (e instanceof NavigationEnd) {
            this.loadData();
          } else if (e instanceof ActivationEnd) {
            this.readParameters();
          }
        });
  }


  issue: Issue;
  titleCodif: string;
  issueNumber: string;

  archiveIssues: [Issue];
  gammesIssues: [Issue];
  recoIssues: [Issue];
  isArchivesLoading: Boolean = true;
  isGammesLoading: Boolean = true;
  isRecoLoading: Boolean = true;

  historys: HistorySale[] = [];

  private issuesSubs: Subscription;
  private historySub: Subscription;
  private routerSub: Subscription;

  isIssueLoading: Boolean = true;
  getIssueFailed: Boolean = false;

  historyDisabled: Boolean = true;
  loadingText = 'Chargement en cours';

  getIPG_CPPAPHtml(): string {

    const cppapStartDate = new Date(environment.disableIGP_CPPAP.startDate);
    const disableIPG_CPPAP = !this.authService.isAdmin && (new Date() < cppapStartDate);

    if (disableIPG_CPPAP
      || !this.issue
      || !(this.issue.titleCodif || '').startsWith('0')) {
      return '';
    }

    const category = this.issue.category || '';
    if (category.length) {
      return `<span class="btn btn-sm btn-danger">${category}</span>`;
    }

    if (this.issue.isPolitic === true) {
      return '<span class="btn btn-sm btn-danger">IPG</span>';
    }

    if (this.issue.isCPPAP === true) {
      return '<span class="btn btn-sm btn-danger">CPPAP</span>';
    }

    return '<span class="btn btn-sm btn-danger">NON CPPAP</span>';
  }

  showPlafond(): boolean {
    return !environment.disablePlafond;
  }

  get returnDate(): string {
    if (!this.issue) {
      return null;
    }

    return this.issue.predictedReturnDate || this.issue.returnDate;
  }

  get categoryName(): string {
    if (!this.issue
      || !this.issue.title
      || !this.issue.title.category) {
      return '';
    }

    let text = this.issue.title.category.name;
    if (!text || !text.length) {
      return '';
    }

    if (this.issue.title.category.initials
      && this.issue.title.category.initials.length) {
        text = `${this.issue.title.category.name} (${this.issue.title.category.initials})`;
    }

    return text;
  }

  get isPredicted(): boolean {
    if (!this.issue) {
      return false;
    }

    return this.issue.predictedReturnDate && !this.issue.returnDate;
  }

  get oldDate(): string {
    if (!this.issue || !this.issue.pdifInfo) {
      return '–';
    }
    if (this.issue.pdifInfo.neverTooOld) {
      return 'jamais';
    }
    if (!this.issue.pdifInfo.tooOldDate) {
      return '–';
    }

    return this.datePipe.transform(this.issue.pdifInfo.tooOldDate, 'DD MMMM YYYY');
  }

  ngOnInit() {

    this.historySub = this.searchService.getHistoryUpdateListener()
      .subscribe(issuesResult => {
        this.historys = issuesResult;
      }, err => {
        this.historys = [];
      });
    this.issuesSubs = this.titleService.getIssuesUpdateListner()
      .subscribe((issueResult: Issue) => {
        this.issue = issueResult;
        this.isIssueLoading = false;
        this.historyDisabled = false;
        this.getIssueFailed = this.issue == null;
        this.isGammesLoading = this.isRecoLoading = this.isArchivesLoading = false;
        this.updateHistory();
      });
  }

  private readParameters() {
    this.titleCodif = this.route.snapshot.paramMap.get('titleCodif');
    this.issueNumber = this.route.snapshot.paramMap.get('issueNumber');
  }

  unsoldRate(history: HistorySale): string {
    if (!history || history.unsold === undefined) {
      return '';
    }
    return `${((history.unsold / history.supplied) * 100).toFixed(0)}&nbsp;%`;
  }

  loadData() {
    if (this.titleCodif) {
      // get Issue for WS
      this.titleService.searchIssue(this.titleCodif, this.issueNumber, this.authService.currentNim);
    } else {
        this.isIssueLoading = false;
        this.getIssueFailed = true;
    }
  }


  updateHistory() {
    if (!this.issue || !this.issue.title) {
      return;
    }

    this.searchService.getHistorys(this.issue.title.id, this.authService.currentNim);
  }

   // on open history modal and logic
   openHistoryModal(content) {
    this.ngbModalHistoryService.open(content, { size: 'lg' });
  }

  closeHistoryModal() {
    this.ngbModalHistoryService.dismissAll();
  }


  ngOnDestroy() {
    if (this.historySub) {
      this.historySub.unsubscribe();
    }
    if (this.issuesSubs) {
      this.issuesSubs.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
