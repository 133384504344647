import { Component, OnInit } from '@angular/core';
import { ExploreService } from 'src/app/services/explore.service';
import { Explore } from 'src/app/Models/explore.model';
import { Issue } from 'src/app/Models/issue.model';
import { AuthService } from 'src/app/services/auth-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {

  constructor(private exploreService: ExploreService,
    private route: ActivatedRoute,
    private authService: AuthService) { }
  explore: Explore[] = [];
  isLoading = true;
  isSearch = false;
  isSearching = false;
  searchText = '';
  shortcode = '';

  ngOnInit() {
    // retrive shortcode from url
    this.shortcode = this.route.snapshot.paramMap.get('shortCode') || '';
    this.getExplorer(10);
  }

  resetExplore() {
    this.searchText = '';
    this.getExplorer(10);
  }

  getExplorer(count: number) {

    this.isSearch = false;
    this.isLoading = true;
    this.explore = [];
    this.exploreService.getExplorer(count, this.authService.currentNim, this.shortcode)
      .subscribe(res => {
        this.isLoading = false;
        if (res) {
          this.explore = res.result || [];
        }
      },
        err => {
          this.isLoading = false;
        });
  }

  searchInExplore($event) {

    if ($event.keyCode !== 13
      || !$event.srcElement) {
      return;
    }

    this.isSearching = true;
    this.isLoading = true;
    this.explore = [];
    const query = $event.srcElement.value;
    this.exploreService.searchExplorer(query, this.authService.currentNim, this.shortcode)
      .subscribe(res => {
        this.isSearching = false;
        this.isLoading = false;
        if (res.result) {
          this.isSearch = true;
          this.explore = res.result;
        }
      },
        err => {
          this.isLoading = false;
          this.isSearching = false;
          this.isSearch = false;
        });
  }
}
