import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { Issue, IssueSnap } from 'src/app/Models/issue.model';
import { OrderService } from 'src/app/services/order.service';
import * as moment from 'moment';
import { resolveReflectiveProviders } from '@angular/core/src/di/reflective_provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BL } from 'src/app/Models/bl.model';
import { DatePipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { CurrencyFormatter } from 'src/app/shared/pipes/currency-formatter.pipe';
import { environment } from 'src/environments/environment';
import { Rupture } from 'src/app/Models/rupture.model';
import { StoreAssortimentTitre } from 'src/app/Models/storeAssortiment.model';

@Component({
  selector: 'app-issue-ctrl',
  templateUrl: './issue-ctrl.component.html',
  styleUrls: ['./issue-ctrl.component.css']
})
export class IssueCtrlComponent implements OnInit {
  @Input() issue: Issue;
  @Input() issueTop10: Rupture;
  @Input() snap: IssueSnap;
  @Input() assortProposal: StoreAssortimentTitre;
  @Input() bl: BL;
  private datePipe = new DatePipe();
  private pricePipe = new CurrencyFormatter();
  public env = environment;
  displayName = true;
  isNextRouter = false;
  imgNotFound = 'https://zeensassets.blob.core.windows.net/web/imageNotFound.png';

  badgeCss = ['badge-primary', 'badge-success', 'badge-info', 'badge-secondary', 'badge-zeens', 'badge-dark', 'badge-warning'];

  private _innerBadgeCss = '';

  constructor(private orderService: OrderService,
    private sanitizer: DomSanitizer,
    private ngbModalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.isNextRoute();
  }

  getBadgeCss() {

    if (!this._innerBadgeCss.length) {
      this._innerBadgeCss = this.badgeCss[Math.floor(Math.random() * this.badgeCss.length)];
    }

    return this._innerBadgeCss;
  }
  getCoverStyle() {

    let imageUrl = '';
    if (!environment.isPdif && this.issue && moment(this.issue.releaseDate).isAfter(moment())) {
      imageUrl = this.issue.cover;
    }
    if (this.issueTop10) {
      imageUrl = this.issueTop10.coverUrl;
    } else if (this.issue) {
      imageUrl = this.issue.cover;
    } else if (this.snap) {
      imageUrl = this.snap.cover;
    } else if (this.bl) {
      imageUrl = this.bl.cover;
    } else if (this.assortProposal) {
      imageUrl = this.assortProposal.cover;
    }
    if (imageUrl.length && imageUrl !== this.imgNotFound) {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }
    return imageUrl;
  }

  get getName(): string {
    if (this.issueTop10) {
      return this.issueTop10.name;
    }

    if (this.issue) {
      return this.issue.name || this.issue.titleName;
    }

    if (this.bl) {
      return this.bl.title;
    }

    if (this.assortProposal) {
      return this.assortProposal.name;
    }

    return '';
  }
  get getPrice(): string {
    if (this.issue) {
      return this.pricePipe.transform(this.issue.price, true);
    }
    if (this.snap) {
      return this.pricePipe.transform(this.snap.price, true);
    }
    return '';
  }
  get getPotential(): string {
    if (!this.assortProposal) {
      return '';
    }
    return `<span><span class="text-white font-w-100">Potentiel</span> : ${this.pricePipe.transform(this.assortProposal.ca, true)} / an</span>`;
  }

  get getDate(): string {
    if (this.issue) {
      return this.datePipe.transform(this.issue.releaseDate, 'DD MMM YYYY');
    }
    if (this.issueTop10) {
      if (!this.issueTop10.rate) {
        return `${this.issueTop10.codif}-${this.issueTop10.issueNumber}`;
      } else if (this.issueTop10.dayCode > 0 && this.issueTop10.dayCode <= 7) {
        return `${moment().locale('fr').weekday(this.issueTop10.dayCode - 1).format('dddd')}`;
      }
      return '';
    }
    if (this.bl) {
      return `${this.bl.codif}-${this.bl.number}`;
    }
    if (this.snap) {
      return `${this.snap.label}`;
    }
    
    return '';
  }

  isNext(): boolean {

    if (!this.issue || environment.isPdif) {
      return false;
    }
    return moment(this.issue.releaseDate).isAfter(moment());
  }

  isNextRoute() {
    this.activeRoute.params.subscribe(params => {
      this.isNextRouter = (params['id'] || '').indexOf('next') >= 0;
    });
  }

  goToDetail() {
    if (this.bl) {
      this.router.navigate([`/titles/${this.bl.codif}/issues/${this.bl.number}`]);
    } else if (this.issue && this.env.isPdif) {
      this.router.navigate([this.issue.webLink]);
    } else if (this.snap) {
      this.router.navigate([this.snap.webUrl]);
    }
  }

  getDateDiff(): number {

    if (!this.issue) {
      return 0;
    }
    const duration = moment.duration(moment().diff(moment(this.issue.releaseDate)));
    return Math.floor(duration.asDays());
  }

  onAddIssueToUserBasket(issue) {
    this.orderService.addIssueToUserBasket(issue);
  }
  openModal(content) {
    if (!environment.isPdif && this.issue) {
      this.ngbModalService.open(content, { size: 'lg' });
    }
  }
  closeModal() {
    this.ngbModalService.dismissAll();
  }
  loaded() {
    this.displayName = false;
  }
}
