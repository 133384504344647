import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import { SalesTurnoverV2 } from '../Models/salesTurnover.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private http: HttpClient) { }
  private sales: SalesTurnoverV2[] = [];
  private salesUpdated = new Subject<SalesTurnoverV2[]>();

  getSales(nim: string) {
    const url = `${environment.APIURL}/api/v3/metrics/stores/${nim}/salesTurnover`;
    this.http.get<{ result: SalesTurnoverV2[] }>(url)
      .subscribe(response => {
        if (response) {
          this.sales = response.result.sort((a, b) => {
            return a.period > b.period ? 1 : -1;
          });
          this.salesUpdated.next([...this.sales]);
        } else {
          this.salesUpdated.next([]);
        }
      }, err => {
        this.salesUpdated.next([]);
      });
  }

  getSalesListener() {
    return this.salesUpdated.asObservable();
  }

}
