import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

import { BL } from '../Models/bl.model';
import { Article } from '../Models/article.model';
import { Rupture } from '../Models/rupture.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }
  private bls: BL[] = [];
  private blsUpdated = new Subject<BL[]>();
  private rupturesUpdated = new Subject<Rupture[]>();
  private rupturesRecUpdated = new Subject<Rupture[]>();
  private top10Updated = new Subject<Rupture[]>();

  private subscribeAutoUpdated = new Subject<boolean>();

  getUnsoldsService(nim: string) {
    return this.http.get<{ result: BL[] }>(environment.APIURL + '/api/v1/stores/bls/current/unsold?nim=' + nim)
      .subscribe(res => {
        if (res) {
          this.bls = res.result;
          this.blsUpdated.next([...this.bls]);
        }
      }, err => {
        this.blsUpdated.next([]);
      });
  }

  getUnsoldsListener() {
    return this.blsUpdated.asObservable();
  }

  getRuptures(nim: string) {

    let url = `${environment.APIURL}/api/v2/breakings?nim=${nim}`;
    const ts = localStorage.getItem('LR_TS');
    if (ts) {
      url += `&ts=${ts}`;
    }
    this.http.get<{ result: Rupture[] }>(url)
      .subscribe(res => {
        if (res) {
          this.rupturesUpdated.next(res.result);
        }
      }, err => {
        this.rupturesUpdated.next([]);
      });
  }

  getRupturesListener() {
    return this.rupturesUpdated.asObservable();
  }

  getRecurrentRuptures(nim: string) {

    let url = `${environment.APIURL}/api/v2/breakings/repeated?nim=${nim}`;
    const ts = localStorage.getItem('LR_TS');
    if (ts) {
      url += `&ts=${ts}`;
    }
    this.http.get<{ result: Rupture[] }>(url)
      .subscribe(res => {
        if (res) {
          this.rupturesRecUpdated.next(res.result);
        }
      }, err => {
        this.rupturesRecUpdated.next([]);
      });
  }

  getRupturesRecListener() {
    return this.rupturesRecUpdated.asObservable();
  }

  getTop10(nim: string) {

    let url = `${environment.APIURL}/api/v1/top10titles/stores/${nim}`;
    const ts = localStorage.getItem('LR_TS');
    if (ts) {
      url += `?ts=${ts}`;
    }
    this.http.get<{ result: Rupture[] }>(url)
      .subscribe(res => {
        if (res) {
          this.top10Updated.next(res.result);
        }
      }, err => {
        this.top10Updated.next([]);
      });
  }

  getTop10Listener() {
    return this.top10Updated.asObservable();
  }

  subscribeAutoReorder(nim: string) {

    this.http.put<{ result: boolean }>(environment.APIURL + '/api/v2/breakings/auto/' + nim, {})
      .subscribe(res => {
        if (res) {
          this.subscribeAutoUpdated.next(res.result);
        }
      }, err => {
        this.subscribeAutoUpdated.next(false);
      });
  }

  getSubscribeAutoListener() {
    return this.subscribeAutoUpdated.asObservable();
  }
}
