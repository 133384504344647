import Swal, { SweetAlertResult, SweetAlertType } from 'sweetalert2';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class Sweetalert {

    warning(titleDialog: string, textDialog: string) {

        Swal(titleDialog, textDialog, 'warning');
    }

    error(titleDialog: string, textDialog: string) {

        Swal(titleDialog, textDialog, 'error');
    }


    success(titleDialog: string, textDialog: string) {

        Swal(titleDialog, textDialog, 'success');
    }
    // tslint:disable-next-line:max-line-length
    confirmDialog(titleDialog: string, textDialog: string, callback: ICallback, confirmButtonText: string = null, messageType: SweetAlertType = 'warning'): void {
        Swal({
            title: titleDialog,
            // text: textDialog,
            html: textDialog,
            type: messageType,
            showCancelButton: true,
            confirmButtonColor: 'rgb(112, 191, 65)',
            cancelButtonColor: '#aaa',
            confirmButtonText: confirmButtonText || 'OK'
        }).then((result) => {
            callback( null, result.value );

        });
    }
}
type ICallback = ( error: Error, result?: any )  => void;
