import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { AuthService } from '../services/auth-service';
import { Incident, IncidentResult } from '../Models/incident.model';


@Injectable({
  providedIn: 'root'
})
export class IncidentsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  private incidentsUpdated = new Subject<IncidentResult>();
  private currentNIM = this.authService.currentNim;

  getIncidents() {
    this.http.get<{ result: IncidentResult }>(environment.APIURL + '/api/v1/stores/requests/incidents?nim=' + this.currentNIM)
      .subscribe(response => {
        if (response && response.result) {
          this.incidentsUpdated.next(response.result);
        } else {
          this.incidentsUpdated.next(null);
        }
      }, err => {
        this.incidentsUpdated.next(null);
      });
  }

  getIncidentsUpdateListner() {
    return this.incidentsUpdated.asObservable();
  }

  addNewIncident(data: any) {
    const url = environment.APIURL + '/api/v1/stores/requests/incidents?nim=' + this.currentNIM;
    return this.http.put<{ result: boolean }>(url, data);
  }
}
