import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArchiveWarehouseDocumentByDate } from '../Models/archiveDocument.model';

@Injectable({
  providedIn: 'root'
})
export class WarehouseArchivesService {

  private archiveDatesUpdated = new Subject<ArchiveWarehouseDocumentByDate>();
  private pdfUpdated = new Subject<string>();

  constructor(private http: HttpClient) { }

  getDocs(nim: string, dateFrom: string, dateTo: string, frequency: string) {
    const url = `${environment.APIURL}/api/v1/warehouses/docs?nim=${nim}&from=${dateFrom}&to=${dateTo}&frequency=${frequency}`;
    // const url = `${environment.APIURL}/api/v1/warehouses/docs?nim=${nim}&from=${dateFrom}&to=${dateTo}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: ArchiveWarehouseDocumentByDate }>(url)
            .subscribe(response => {
                if (response) {
                    this.archiveDatesUpdated.next(response.result);
                } else {
                    this.archiveDatesUpdated.next(null);
                }
            }, err => {
                this.archiveDatesUpdated.next(null);
            });
  }

  getArchiveDatesRequestsUpdateListner() {
    return this.archiveDatesUpdated.asObservable();
  }

  getDocPDF(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/warehouses/docs/${id}?nim=${nim}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: string }>(url)
            .subscribe(response => {
                if (response) {
                    this.pdfUpdated.next(response.result);
                } else {
                    this.pdfUpdated.next(null);
                }
            }, err => {
                this.pdfUpdated.next(null);
            });
  }

  getPdfUpdateListner() {
    return this.pdfUpdated.asObservable();
  }
}
