import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Campaign } from 'src/app/Models/campaign.model';


@Injectable({
  providedIn: 'root'
})
export class AdCampaignService {

  campaigns: Campaign[];
  constructor(private http: HttpClient) { }

  getAllCampaigns() {
    return this.http.get<{ result: Campaign[] }>(environment.APIURL + '/api/v2/campaigns');
  }
  getACTIVECampaigns(nim: string) {
    return this.http.get<{ result: Campaign[] }>(environment.APIURL + '/api/v2/campaigns/active?nim=' + nim);
  }

  addCampaign(data: Campaign) {
    return this.http.post(environment.APIURL + '/api/v2/campaigns', data);
  }

  updateCampaign(data: Campaign) {
    return this.http.put(environment.APIURL + '/api/v2/campaigns/' + data.id, data);
  }

}
