import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';
import { ProCustomer } from 'src/app/Models/order.model';
import { Address } from 'src/app/Models/address.model';
import { NgForm } from '@angular/forms';
import { GmsService } from 'src/app/services/gms.service';
import { GmsUser, GmsStore } from 'src/app/Models/gms.model';
import { Subscription, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DtLanguages } from 'src/app/shared/dtLanguages';

@Component({
  selector: 'app-gms',
  templateUrl: './gms.component.html',
  styleUrls: ['./gms.component.css']
})
export class GmsComponent implements OnInit, OnDestroy, AfterViewInit {

  // @ViewChild('content') modalNotification: ElementRef;
  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  gmsUsers: GmsUser[];
  importedStores: GmsStore[] = [];
  private gmsUsersSubs: Subscription;
  private gmsUpdateSubs: Subscription;
  selectedGMS: GmsUser;
  isLoading = true;
  showSpinnerModal = false;
  // Update Campaigne
  gmsName = '';
  gmsMail = '';
  gmsPassword = '';

  errorMessage = '';

  constructor(private gmsService: GmsService,
    private ngbModalService: NgbModal,
    private dtlanguages: DtLanguages,
    private authService: AuthService) { }

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      autoWidth: false,
      order: [0, 'asc'],
      language: this.dtlanguages.frLanguage()
    };

    this.gmsUpdateSubs = this.gmsService.addOrUpdateGmsAccountUpdateListner()
      .subscribe(res => {
        this.showSpinnerModal = false;
        if (res) {
          this.closeModal();
          this.getGmsUsers();
        } else {
          this.errorMessage = 'Une erreur est survenue au moment du traitement de votre demande !';
        }
      },
        err => {
          this.showSpinnerModal = false;
          this.errorMessage = 'Une erreur est survenue au moment du traitement de votre demande !';
        });

    this.gmsUsersSubs = this.gmsService.getGmsUsersUpdateListner()
      .subscribe((result: GmsUser[]) => {
        this.gmsUsers = result;
        this.isLoading = false;
        this.rerender();
      });

    this.getGmsUsers();
  }

  sortNims(stores: GmsStore[]): GmsStore[] {
    if (!stores || !stores.length) {
      return [];
    }

    return stores.sort((a, b) => {
      return (a.nim || '').localeCompare(b.nim);
    });
  }

  getGmsUsers() {
    this.isLoading = true;
    this.gmsService.getGmsUsers();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  addGMSUser(form: NgForm) {

    if (!form.valid) {
      return;
    }

    this.showSpinnerModal = true;
    const gmsUser: GmsUser = {
      id: null,
      name: form.value.gmsName,
      email: form.value.gmsEmail,
      password: form.value.gmsPassword,
      storesCount: 0,
      stores: this.importedStores
    };

    this.gmsService.addOrUpdateGmsAccount(gmsUser);
  }

  updateGMSUser(form: NgForm) {

    this.errorMessage = '';

    if (!this.selectedGMS) {
      this.addGMSUser(form);
      return;
    }

    this.selectedGMS.name = this.gmsName;
    this.selectedGMS.email = this.gmsMail;
    this.selectedGMS.password = '';
    if (this.importedStores.length > 0) {
      this.selectedGMS.stores = this.importedStores;
    }
    this.showSpinnerModal = true;
    this.gmsService.addOrUpdateGmsAccount(this.selectedGMS);
  }

  deleteGMSUser(item: GmsUser) {

    this.errorMessage = '';

    if (!item) {
      return;
    }

    this.gmsService.deleteGms(item);
  }

  openModal(content, indexOrder: number) {

    if (indexOrder !== undefined) {
      this.selectedGMS = this.gmsUsers[indexOrder];
      this.gmsName = this.selectedGMS.name;
      this.gmsMail = this.selectedGMS.email;
      this.gmsPassword = this.selectedGMS.password;
    } else {
      this.selectedGMS = null;
    }

    this.ngbModalService.open(content, { size: 'lg' });
  }

  closeModal() {
    this.importedStores = [];
    this.selectedGMS = null;
    this.gmsName = '';
    this.gmsMail = '';
    this.gmsPassword = '';
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    this.closeModal();
    if (this.gmsUsersSubs) {
      this.gmsUsersSubs.unsubscribe();
    }
    if (this.gmsUpdateSubs) {
      this.gmsUpdateSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }
  onFileSelect(input: HTMLInputElement) {

    const self = this;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = function (e) {
        // import form CSV
        const textFromFileLoaded = fileReader.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.split(/,|;/ig).map(t => {
            const item: GmsStore = {
              nim: t.trim(),
              nil: '',
              name: ''
            };
            return item;
          });
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t.nim));
        if (nims.length === 0) {
          alert('Le fichier ne contient pas de NIM sur 7 caractères.');
          input.value = '';
          self.importedStores = [];
        } else {
          self.importedStores = nims;
        }
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }
}
