import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Explore } from '../Models/explore.model';

@Injectable({
  providedIn: 'root'
})
export class ExploreService {

  constructor(private http: HttpClient) { }

  getExplorer(count: number, nim: string, shortCode: string = '') {
    const url = `${environment.APIURL}/api/v2/content/explore?take=${count}&nim=${nim}&shortcode=${shortCode}`;
    return this.http.get<{ result: Explore[] }>(url);
  }

  searchExplorer(q: string, nim: string, shortCode: string = '') {
    const url = `${environment.APIURL}/api/v2/content/explore?q=${q}&nim=${nim}&shortcode=${shortCode}`;
    return this.http.get<{ result: Explore[] }>(url);
  }
}
