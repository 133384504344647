import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Tour } from 'src/app/Models/tour.model';
import { Store } from 'src/app/Models/store.model';
import { NgbModal, NgbModalRef, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';
import { Subscription } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';
import { NgForm } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Warehouse } from 'src/app/Models/warehouse.model';

@Component({
  selector: 'app-logisticalerts',
  templateUrl: './logisticalerts.component.html',
  styleUrls: ['./logisticalerts.component.css']
})
export class LogisticalertsComponent implements OnInit, OnDestroy {

  @Input() meModal: NgbModalRef;

  warehouses: Warehouse[] = [];
  selectedWarehouses: string[] = [];

  private openedModal: NgbModalRef;
  private storeChangeSubs: Subscription;
  htmlContent = '';
  htmlSubject = '';
  emailsList = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Message...',
    translate: 'no'
  };

  isSending = false;
  showBtnSpinner = false;

  constructor(private alertService: AlertService,
    private ngbModalService: NgbModal,
    private sweet: Sweetalert,
    private storeService: StoreService,
    private authService: AuthService) { }

  private bindData(): void {
    if (!this.warehouses || !this.warehouses.length) {
      return;
    }
    this.selectedWarehouses = this.warehouses.map(t => t.nim);
  }
  ngOnInit() {

    this.alertService.getSendUpdateListener()
      .subscribe(status => {
        this.isSending = false;
        if (status) {
          this.sweet.success('Succès !', 'l’alerte est envoyée avec succès.');
        } else {
          this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
        }
      }, error => {
        this.isSending = false;
        this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
      });

    this.storeService.getWarehousesUpdateListner()
    .subscribe(warehouses => {
      this.warehouses = warehouses || [];
      this.bindData();
    }, error => {
    });
    this.storeService.getWarehouses();
  }

  onWarehouseChecked(event, value: string) {

    if (!event) {
      return;
    }

    const indexOfItem = this.selectedWarehouses.indexOf(value);
    if (event.target.checked) {
      if (indexOfItem < 0) {
        this.selectedWarehouses.push(value);
      }
    } else if (indexOfItem >= 0) {
      this.selectedWarehouses.splice(indexOfItem, 1);
    }
  }

  isWarehouseChecked(value: string) {
    return this.selectedWarehouses.indexOf(value) >= 0;
  }

  importFromCSV(fileLoadedEvent) {
    // import form CSV
    const textFromFileLoaded = fileLoadedEvent.target.result as string;
    const regexp = /^\d{7}$/i;
    const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
      return line.split(/,|;/ig).map(t => t.trim());
    }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
    if (nims.length === 0) {
      alert('Le fichier ne contient pas de NIM sur 7 caractères.');
    } else {
      this.selectedWarehouses = nims;
    }
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.split(/,|;/ig).map(t => t.trim());
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
        if (nims.length === 0) {
          alert('Le fichier ne contient pas de NIM sur 7 caractères.');
          input.value = '';
        } else {
          this.selectedWarehouses = nims;
        }
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  sendAlert() {
    if (this.htmlContent === '' || this.selectedWarehouses.length === 0) {
      return;
    }
   const data = {
      warehouseNims: this.selectedWarehouses,
      emails: this.emailsList,
      content: this.htmlContent,
      subject: this.htmlSubject
    };

    this.isSending = true;

    this.alertService.sendLogisticAlert(data);
  }

  openModal(content) {
    this.openedModal = this.ngbModalService.open(content, { size: 'lg' });
  }

  closeModal() {
    if (!this.openedModal) {
      return;
    }
    this.openedModal.close();
  }

  closeModalRoot() {
    this.ngbModalService.dismissAll();
  }

  ngOnDestroy() {
    if (this.storeChangeSubs) {
      this.storeChangeSubs.unsubscribe();
    }
  }
}
