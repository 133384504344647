import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { IssueDiffered, IssueDifferedItems } from 'src/app/Models/issue.model';
import { ArchivesService } from 'src/app/services/archives.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth-service';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-differedreturn',
  templateUrl: './differedreturn.component.html',
  styleUrls: ['./differedreturn.component.css']
})
export class DifferedreturnComponent implements OnInit, OnDestroy {

  documentDate = moment();
  isLoading = true;
  noDataToDisplay = false;
  print = false;
  fileName = `differe-`;
  private differedSubscription: Subscription;
  private differedDatesSubscription: Subscription;

  item: IssueDiffered;
  dates: string[] = [];

  @ViewChild('content') modalLoading: ElementRef;

  constructor(private archiveService: ArchivesService,
    private ngbModalService: NgbModal,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.differedSubscription = this.archiveService.getDifferedDocumentListner()
      .subscribe(doc => {
        this.isLoading = false;
        if (doc != null) {
          this.item = doc;
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF(true);
            }, 1000);
          }
        } else {
          this.closeModal();
        }
      }, err => {
        this.closeModal();
        this.isLoading = false;
      });
    this.differedDatesSubscription = this.archiveService.getDifferedDatesListner()
    .subscribe(dates => {
      this.dates = dates || [];
      if (!this.dates.length) {
        this.noDataToDisplay = true;
        this.isLoading = false;
        return;
      }

      this.performCall(moment(this.dates[0]).format('YYYY-MM-DD'));
    });

    let date = '2019-10-05';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.documentDate = moment(date).locale('fr');
      this.archiveService.getDifferedDocument(this.documentDate.format('YYYY-MM-DD'), this.authService.currentNim);
    } else {
      this.archiveService.getDifferedDates(this.authService.currentNim);
    }

    this.fileName = `${this.fileName}${date}.pdf`;
  }

  onDatesChange(e): void {
    this.isLoading = true;

    this.performCall(e.target.value);
  }

  performCall(date) {
    this.archiveService.getDifferedDocument(date, this.authService.currentNim);
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  printWindow() {
    window.print();
  }

  getIssueNumber(issue: IssueDifferedItems): string {
    if (!issue) {
      return '';
    }

    return `${issue.issueNumber} ${issue.dayCode || ''}`.trim();
  }

  downloadToPDF(closeAfterPrint = false) {
    this.print = true;
    this.archiveService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  goBack() {
    const date = this.documentDate.format('MM/YYYY');
    this.router.navigate([`/archives/${date}`]);
  }

  ngOnDestroy() {
    if (this.differedSubscription) {
      this.differedSubscription.unsubscribe();
    }
    if (this.differedDatesSubscription) {
      this.differedDatesSubscription.unsubscribe();
    }
  }
}
