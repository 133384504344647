import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DtLanguages } from '../shared/dtLanguages';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth-service';
import { Sweetalert } from '../shared/sweetAlert';
import * as moment from 'moment';
import { StoreService } from '../services/store.service';
import { Store } from '../Models/store.model';
import { PendingChange } from '../Models/pendingchanges/pendingchange.model';
import { HolidayItem } from '../Models/hours/holiday.model';
import { PendingChangeItem } from '../Models/pendingchanges/pendingchangeitem.model';
import { GA } from '../shared/gAnalytics';
import { LineStatement, NewStatment } from '../Models/newStatment.model';
import { DatePipe } from '../shared/pipes/date-formatter.pipe';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit, AfterViewInit, OnDestroy {

  private datePipe = new DatePipe();
  // declarations
  store: Store;
  pendingStore: PendingChange;
  private storeSubs: Subscription;
  changes: NewStatment[];
  holidayschanges: HolidayItem[];
  get holidays(): HolidayItem[] {
    if (this.store == null || this.store.hours == null) {
      return [];
    }

    return this.store.hours.holidays || [];
  }
  get pendingHolidays(): PendingChangeItem<HolidayItem>[] {
    if (this.pendingStore == null || this.pendingStore.hours == null) {
      return [];
    }
    return this.pendingStore.hours.holidays || [];
  }
  showSpinner: Boolean = false;
  fromDate;
  minDateTo;
  maxDateFrom;
  deliveryDate = '';

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  // constructor
  constructor(private ngbModalService: NgbModal,
    private authService: AuthService,
    private config: NgbDatepickerConfig,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private storeService: StoreService,
    private dtlanguages: DtLanguages, private sweet: Sweetalert,
    private ga: GA) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      paging: false,
      autoWidth: false,
      columnDefs: [{
        'targets': [2],
        'orderable': false
      }
      ],
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };

    const date = new Date();
    this.fromDate = { year: date.getFullYear(), month: date.getUTCMonth() + 1, day: date.getDate() };
    this.minDateTo = { year: date.getFullYear(), month: date.getUTCMonth() + 1, day: date.getDate() };

    this.storeSubs = this.storeService.getstoreAndPendingUpdateListner().subscribe(data => {
      if (!data) {
        return;
      }

      this.changes = data.changes;
      this.store = data.store;
      this.holidaysChanges();
      this.rerender();
      this.showSpinner = false;
      this.dtTrigger.next();
    });
    this.storeService.getStoreWithPendingChanges();
  }

  /**
   *  rerender the datatable
   */
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  /**
   * on open modal and logic
   * param content
   */
  openModal(content) {
    this.ga.createEvent('view', 'Demandes', 'Création Nouvelle Demande de congé');
    this.ngbModalService.open(content);
  }

  /**
   *  on close modal and logic
   */
  closeModal(sendrRacking = true) {
    if (sendrRacking) {
      this.ga.createEvent('cancel', 'Demandes', 'Création Nouvelle Demande de congé');
    }
    this.ngbModalService.dismissAll();
  }

  /**
     * action on select date start holiday
     * param event
     */
  onSelectDebut(event: NgbDate) {
    this.minDateTo = { year: event.year, month: event.month, day: event.day };
    const maxDate = new Date(event.year, event.month - 1, event.day, 0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate() + (7 * 8));
    this.maxDateFrom = { year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate() };
    this.deliveryDate = '';
  }

  onSelectFin(event: NgbDate) {

    if (!event) {
      return;
    }

    if (!this.store
      || !this.store.hours
      || !this.store.hours.opening
      || !this.store.hours.opening.length) {
      return;
    }

    const date = new Date(event.year, event.month - 1, event.day, 0, 0, 0, 0);
    const day = date.getDay() === 0 ? 7 : date.getDay();
    let nextDay = this.store.hours.opening[0].weekDay - 1;
    const days = this.store.hours.opening.filter(element => {
      return (element.weekDay > day && element.weekDay !== 8);
    });

    if (days.length) {
      nextDay = days[0].weekDay - 1;
    }

    const searchDate = moment(date).add(1, 'd').locale('fr'); // now or change to any date

    for (let i = 0; i < 7; i++) {

      if (searchDate.weekday() === nextDay) {
        break;
      }

      searchDate.add(1, 'd');
    }

    this.deliveryDate = searchDate.format('ddd DD MMM YYYY');
  }

  /**
   *  save and post new request
   * param form
   */
  saveHolidays(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const getHolidaysToSave = [];
    let isToComplete = true;

    const self = this;
    const nim = this.authService.currentNim;
    // check if already exists
    const existingHolidays = [];

    if (this.pendingHolidays) {
      $.each(this.pendingHolidays, function fn(index, value) {
        existingHolidays.push({ start: moment(value.value.from), end: moment(value.value.to) });
      });
    }
    if (this.holidays) {

      $.each(this.holidays, function fn(index, value) {
        existingHolidays.push({ start: moment(value.from), end: moment(value.to) });
      });
    }

    const holidayList: any[] = [];
    let newHoliday: any;
    for (let i = 0; i < this.holidays.length; i++) {
      holidayList.push({
        'from': this.holidays[i].from,
        'to': this.holidays[i].to,
        'type': this.holidays[i].type,
      });
    }
    for (let i = 0; i < this.pendingHolidays.length; i++) {
      if (this.pendingHolidays[i].action === 'delete') {
        continue;
      }
      holidayList.push({
        'from': this.pendingHolidays[i].value.from,
        'to': this.pendingHolidays[i].value.to,
        'type': this.pendingHolidays[i].value.type,
      });
    }
    newHoliday = {
      'from': this.ngbDateParserFormatter.format(form.value.datePickerDebut),
      'to': this.ngbDateParserFormatter.format(form.value.datePickerFin),
      'type': form.value.txtRaison,
    };

    if (newHoliday) {

      let obj = {};
      const mmntFrom = moment(newHoliday.from);
      const mmntTo = moment(newHoliday.to);
      obj = {
        'from': mmntFrom.format('YYYY-MM-DDTHH:mm:ss'),
        'to': mmntTo.format('YYYY-MM-DDTHH:mm:ss'),
        'type': newHoliday.type,
      };

      const diffDays = moment.duration(mmntTo.diff(mmntFrom)).asDays();
      const weeks = Math.round(diffDays / 7);
      if (weeks > 8) {
        self.sweet.warning('Attention !', 'Vous ne pouvez pas saisir une période de congés suppérieur à 8 semaines.');
        isToComplete = false;
        return false;
      }

      if (existingHolidays.filter(function (e) {

        return mmntFrom.isBetween(e.start, e.end, null, '[]') || mmntTo.isBetween(e.start, e.end, null, '[]');
      }).length) {
        self.sweet.warning('Attention !', 'Vous avez déjà saisie une demande de congés sur cette période.');
        isToComplete = false;
        return false;
      }
      /*$.each(holidayList, function fn(index, value) {
        getHolidaysToSave.push(value);
      });*/
      getHolidaysToSave.push(obj);
    }

    const objToSave = {
      'nim': nim,
      'hours': {
        'holidays': getHolidaysToSave
      }
    };

    const ctrlopen = this.getStatementOrDefault(`holiday_${this.getTodayDateFormat(getHolidaysToSave[0].from, 'YYYY-MM-DD')}`, 'add');
    ctrlopen.label = `Congé du ${this.getTodayDateFormat(getHolidaysToSave[0].from, 'DD-MM-YYYY')}`;
    ctrlopen.newValue = JSON.stringify(objToSave);
    if (isToComplete) {
      this.globalSave(ctrlopen);
    }
  }

  // on post new Holiday
  globalSave(objToSave: any) {

    const localStatements: LineStatement[] = [];
    localStatements.push(objToSave);
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de valider les modifications?',
      (error: Error, result: any): void => {
        if (result) {
              this.storeService.saveChanges(localStatements)
              .subscribe(response => {
                if (response.result) {
                  this.sweet.success('Succès !', 'Vos modifications ont bien été enregistrées.');
                  this.storeService.getStoreWithPendingChanges();
                  this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande de congé');
                  this.closeModal(false);
                }
              },
                err => {
                  this.sweet.warning('Attention !', 'Vos modifications n’ont pas pu être enregistrer, veuillez réessayer plus tard.');
                });
        }
      });
  }

  /**
   * remove Holiday
   */
  removeHoliday(index: number) {

    this.rerender();
    this.dtTrigger.next();
    const nim = this.authService.currentNim;
    this.holidays.splice(index, index + 1);
    const objToSave = {
      'nim': nim,
      'hours': {
        'holidays': this.holidays
      }
    };

    const ctrlopen = this.getStatementOrDefault(`holiday_${this.getTodayDateFormat(this.holidays[0].from , 'YYYY-MM-DD')}`, 'remove');
    ctrlopen.label = `Congé du ${this.getTodayDateFormat(this.holidays[0].from, 'DD-MM-YYYY')}`;
    ctrlopen.newValue = JSON.stringify(objToSave);

    this.globalSave(ctrlopen);
  }

  ngOnDestroy() {
    if (this.storeSubs) {
      this.storeSubs.unsubscribe();
    }
    this.closeModal(false);
  }
  getStatementOrDefault(id: string, action: string = 'update') {
    const ctrl =  {
      'id': id,
      'newValue': '',
      'group': 'Congés',
      'action': action,
      'oldValue': '',
      'label': '',
      'changedOn': this.getTodayDateFormat(),
      'warehouseAction': null
    };

    ctrl.changedOn = this.getTodayDateFormat();

    return ctrl;
  }
  getTodayDateFormat(date: string = null, dateFormat: string = 'YYYY-MM-DDTHH:mm:ss'): string {
    return this.datePipe.transform(date, dateFormat, true);
  }
  holidaysChanges() {
    let hschanges: HolidayItem[] = [];
    if (this.changes !== undefined && this.changes.length) {
      const hChanges = this.changes[0].changes.filter(i => i.id.startsWith('holiday_'));
      hChanges.forEach(item => {
        const str = item.newValue.split('\n').join('<br>');
        const holiday = JSON.parse(str) as {hours: {holidays: HolidayItem[]}};
        if (item.warehouseAction && item.warehouseAction.status) {
          holiday.hours.holidays.forEach(h => h.validated = item.warehouseAction && item.warehouseAction.status);
        }
        hschanges = hschanges.concat(holiday.hours.holidays);
      });
    }

    this.holidayschanges =  hschanges;
  }
}
