import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Category, Issue } from '../Models/issue.model';
import { Explore } from '../Models/explore.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  getCategories() {
    return this.http.get<{ result: Category[] }>(environment.APIURL + '/api/v2/content/categories');
  }

  getCategoryById(id: string, count: number, nim: string, q: string = '', shortcode: string = '') {
    let url = `${environment.APIURL}/api/v2/content/categories/${id}/issues?nim=${nim}&q=${q}&shortCode=${shortcode}`;
    if (count > 0) {
      url += `?take=${count}`;
    }
    return this.http.get<{ result: Explore }>(url);
  }
}
