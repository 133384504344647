import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth-service';
import { Commons } from '../shared/commons';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService,
        private router: Router,
        private commons: Commons) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const isAuth = AuthService.getAuthInfo().length > 0;

        const allowUrlAuth = route.data['allowUrlAuth'] as boolean;
        if (allowUrlAuth) {
            return true;
        }

        if (!isAuth) {
            const allowAnonymous = route.data['allowAnonymous'] as boolean;
            if (allowAnonymous) {
                return true;
            }
            this.router.navigate(['/login']);
            return;
        }

        if (isAuth && !this.authService.isPdifCGU) {
            this.router.navigate(['/cgu'], {});
            return;
        }

        const roles = route.data['roles'] as string[];

        if (roles && roles.length > 0) {
            if (!this.authService.checkUserRole(roles)) {
                this.router.navigate(['/unauthorized'], {});
                return;
            }
        }

        if (!this.authService.isActualOwner
            && !this.authService.isCommercial
            && !this.authService.isSecurityAdmin) {
            const allowedPaths = this.commons.getRoutesForOldOwner();
            if (!allowedPaths || !allowedPaths.length) {
                return false;
            }
            if (allowedPaths.indexOf(route.routeConfig.path) >= 0) {
                return true;
            }
            this.router.navigate([allowedPaths[0]], {});
            return;
        }

        if (isAuth && route.routeConfig && route.routeConfig.path) {

            if (!this.authService.hasAccessToPath(route.routeConfig.path)) {
                // manage message display (warehouse or company or owner restriction)
                this.router.navigate(['/unauthorized'], {});
                return;
            }
        }

        return isAuth;

    }

}
