import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { DataItem } from '../Models/store.model';
import { ActivityType } from '../Models/store.model';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ActivityService {

  constructor(private http: HttpClient) { }

  private activities: DataItem[];
  private activitiesUpdate = new Subject<DataItem[]>();


  // get all activities & proximities
  getAllActivities(activityType: ActivityType) {
    this.http.get<{ result: DataItem[] }>(environment.APIURL + this.getAPIURL(activityType))
      .subscribe(response => {
        if (response && response.result) {
          this.activities = response.result.sort((a, b) => (a.name || '').localeCompare(b.name));
          this.activitiesUpdate.next(this.activities);
        } else {
          this.activitiesUpdate.next([]);
        }
      }, err => {
        this.activitiesUpdate.next([]);
      });
  }

  getAPIURL(activityType: ActivityType) {
    switch (activityType) {
      case ActivityType.Activity: {
        return '/api/v1/activities/all';
      }
      case ActivityType.Proximity:
      default: {
        return '/api/v1/proximities/all';
      }
    }

  }

  getActivitiesUpdateListner() {
    return this.activitiesUpdate.asObservable();
  }
}
