import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendTicket(data: any) {
    return this.http.post<{ result: boolean }>('https://pdif-emailing-ws.francemessagerie.fr/api/v1/tickets' , data);
  }
}
