import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import * as moment from 'moment';
import { IncidentsService } from '../services/incidents.service';
import { Incident, IncidentResult } from '../Models/incident.model';
import { DtLanguages } from '../shared/dtLanguages';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth-service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Sweetalert } from '../shared/sweetAlert';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})


export class IncidentsComponent implements OnInit, OnDestroy, AfterViewInit {

  // declarations
  private incidentsSubs: Subscription;
  incidents: Incident[];
  showSpinner = false;
  blLastThreeDays: string[] = [];
  dateIncident: string;
  isReadOnly: boolean;

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  // constructor
  constructor(private incidentsService: IncidentsService,
    private authService: AuthService,
    private ngbModalService: NgbModal,
    private dtlanguages: DtLanguages,
    private sweet: Sweetalert) { }

  ngOnInit() {

    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      autoWidth: false,
      columnDefs: [{
        'targets': [0],
        'type': 'date-DD-MMM-YYYY'
      }
      ],
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };
    this.getDates();
    this.onBindIncidents();

  }
  // bind incidents
  onBindIncidents() {

    this.showSpinner = true;
    this.incidentsService.getIncidents();
    this.incidentsSubs = this.incidentsService.getIncidentsUpdateListner()
      .subscribe((result: IncidentResult) => {
        if (result) {
          this.isReadOnly = result.isReadOnly || false;
          this.incidents = result.data || [];
        } else {
          this.incidents = [];
        }
        this.showSpinner = false;
        this.rerender();
        this.dtTrigger.next();
      });

  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  // on post new incident
  onSaveRequest(form: NgForm) {
    if (this.authService.isReadOnly()) {
      return;
    }
    if (!form.valid) {
      return;
    }
    this.showSpinner = true;
    const nim = this.authService.currentNim;

    const incidentObj = {
      'comment': form.value.txtComment,
      'date': this.dateIncident,
      'nim': nim
    };
    this.incidentsService.addNewIncident(incidentObj)
      .subscribe(response => {
        if (response.result) {
          this.sweet.success('Succès !', 'Votre déclaration d’incident a bien été enregistrée.');
          this.onBindIncidents();
          this.closeModal();
        } else {
          this.sweet.warning('Attention !', 'Impossible d’enregistrer cet incident.');
        }
        this.showSpinner = false;
      },
        error => {
          this.sweet.warning('Attention !', 'Impossible d’enregistrer cet incident.');
          this.showSpinner = false;
        });
  }

  // on open modal and logic
  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });

  }

  getDates() {
    let currentDate = moment().locale('fr');
    const stopDate = moment().subtract(3, 'days').locale('fr');

    while (currentDate >= stopDate) {
      this.blLastThreeDays.push(currentDate.format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add(-1, 'days');
    }
    this.dateIncident = this.blLastThreeDays[0];
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    this.closeModal();
    if (this.incidentsSubs) {
      this.incidentsSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }
}
