import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth-service';
import { StoreService } from '../services/store.service';
import { Store } from '../Models/store.model';
import { TurnoverV2 } from '../Models/turnover.model';
import * as moment from 'moment';
import { ChartService } from '../services/chart.service';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { SalesTurnoverV2 } from '../Models/salesTurnover.model';
import { CurrencyFormatter } from '../shared/pipes/currency-formatter.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit, OnDestroy {

  constructor(private authService: AuthService, private storeService: StoreService) { }

  static STABLE = 'stable';
  private globalMirrorRank: number;
  private soldoutEvolution: number;
  private soldoutMirrorEvolution: number;
  private globalEvolution: number;
  private globalRank: number;
  private currencyPipe = new CurrencyFormatter();
  sales: SalesTurnoverV2[] = [];
  storeSub: Subscription;
  turnoverSub: Subscription;
  store: Store;
  storeTurnover: TurnoverV2;
  depositRange: number;

  get disableData(): boolean {
    return environment.disablePerformance;
  }

  get getPeriod() {
    if (!this.storeTurnover) {
      return '';
    }

    return `de ${this.parseDateToFormat(this.storeTurnover.start)} à ${this.parseDateToFormat(this.storeTurnover.end)}`;
  }
  get contactName(): string {
    if (!this.store || !this.store.contact) {
      return '';
    }

    return this.store.contact.civility + ' ' + this.store.contact.firstName + ' ' + this.store.contact.lastName;
  }

  get getVolumeTTC() {
    if (!this.storeTurnover || !this.storeTurnover.turnover) {
      return '';
    }

    return this.storeTurnover.turnover.value;
  }

  getArrow(evolution, compareTo: number = 0) {

    if (evolution > compareTo) {
      return 'fa fa-arrow-up text-card-green';
    } else if (evolution < compareTo) {
      return 'fa fa-arrow-down text-card-red';
    }

    return 'fa fa-arrow-right text-orange';
  }

  get getWarehouseRank() {
    if (!this.storeTurnover || !this.storeTurnover.warehouseRank) {
      return '';
    }

    return this.storeTurnover.warehouseRank.value;
  }

  get getWarehouseStoresCount() {
    if (!this.storeTurnover || !this.storeTurnover.warehouseRank) {
      return '';
    }

    return this.storeTurnover.warehouseRank.total;
  }

  get getMirrorRank() {
    if (!this.storeTurnover || !this.storeTurnover.segmentRank) {
      return '';
    }

    return this.storeTurnover.segmentRank.value;
  }

  get getMirrorStoresCount() {
    if (!this.storeTurnover || !this.storeTurnover.segmentRank) {
      return 0;
    }

    return this.storeTurnover.segmentRank.total;
  }

  get getAveragePrice() {
    if (!this.storeTurnover || !this.storeTurnover.averagePrice) {
      return '';
    }

    return this.currencyPipe.transform(this.storeTurnover.averagePrice.value);
  }

  get getMirrorAveragePrice() {
    if (!this.storeTurnover || !this.storeTurnover.averagePrice) {
      return '';
    }

    return this.currencyPipe.transform(this.storeTurnover.averagePrice.compareValue);
  }

  get getSaleRate() {
    if (!this.storeTurnover || !this.storeTurnover.salesRate) {
      return 0;
    }

    return this.storeTurnover.salesRate.value;
  }

  get getSaleRateMirror() {
    if (!this.storeTurnover || !this.storeTurnover.salesRate) {
      return 0;
    }

    return this.storeTurnover.salesRate.compareValue;
  }

  get getSoldoutRate() {
    if (!this.storeTurnover || !this.storeTurnover.soldoutRate) {
      return 0;
    }

    return this.storeTurnover.soldoutRate.value;
  }
  get getSoldoutRateMirror() {
    if (!this.storeTurnover || !this.storeTurnover.soldoutRate) {
      return 0;
    }

    return this.storeTurnover.soldoutRate.compareValue;
  }

  get getSoldoutEvolution() {
    if (this.soldoutEvolution == null) {
      if (!this.storeTurnover || !this.storeTurnover.soldoutRate) {
        return ' 0 %';
      }

      const turnover1 = this.storeTurnover.soldoutRate.compareValue;
      const turnover2 = this.storeTurnover.soldoutRate.value;
      this.soldoutEvolution = (((turnover1 / turnover2) - 1) * 100);
    }

    const val = (this.soldoutEvolution || 0);
    if (val >= 0) {
      return `+ ${val.toFixed(2)} %`;
    }
    return `${val.toFixed(2)} %`;
  }

  get getSoldoutMirrorEvolution() {
    if (this.soldoutMirrorEvolution == null) {
      if (!this.storeTurnover || !this.storeTurnover.soldoutRate) {
        return ' 0 %';
      }

      const turnover1 = this.storeTurnover.soldoutRate.compareValue;
      const turnover2 = this.storeTurnover.soldoutRate.value;
      this.soldoutMirrorEvolution = (((turnover1 / turnover2) - 1) * 100);
    }

    const val = (this.soldoutMirrorEvolution || 0);
    if (val >= 0) {
      return `+ ${val.toFixed(2)} %`;
    }
    return `${val.toFixed(2)} %`;
  }

  get getGlobalEvolution() {
    if (this.globalEvolution == null) {
      if (!this.storeTurnover || !this.storeTurnover.turnover) {
        return 0;
      }

      const turnover1 = this.storeTurnover.turnover.value;
      const turnover2 = this.storeTurnover.turnover.compareValue;
      this.globalEvolution = (((turnover1 / turnover2) - 1) * 100);
    }
    return (this.globalEvolution || 0);
  }

  get getGlobalRank() {
    if (this.globalRank == null) {
      if (!this.storeTurnover || !this.storeTurnover.warehouseRank) {
        return 0;
      }

      const turnover1 = this.storeTurnover.warehouseRank.compareValue;
      const turnover2 = this.storeTurnover.warehouseRank.value;
      this.globalRank = turnover1 - turnover2;
    }
    return (this.globalRank || 0);
  }

  get getGlobalRankToString() {
    const rank = this.getGlobalRank;
    if (rank === 0) {
      return PerformanceComponent.STABLE;
    }

    return rank.toFixed(0);
  }

  get getGlobalMirrorRank() {
    if (this.globalMirrorRank == null) {
      if (!this.storeTurnover || !this.storeTurnover.segmentRank) {
        return 0;
      }

      const turnover1 = this.storeTurnover.segmentRank.compareValue;
      const turnover2 = this.storeTurnover.segmentRank.value;
      this.globalMirrorRank = turnover1 - turnover2;
    }
    return (this.globalMirrorRank || 0);
  }

  get getGlobalMirrorRankToString() {
    const rank = this.getGlobalMirrorRank;
    if (rank === 0) {
      return PerformanceComponent.STABLE;
    }

    return rank.toFixed(0);
  }

  private parseDateToFormat(date: Date) {

    if (!date) {
      return '';
    }
    return moment(date).locale('fr').format('MMMM YYYY');
  }

  ngOnInit() {
    this.storeSub = this.storeService.getStoreUpdateListner()
      .subscribe(store => {
        this.store = store;
      });
    this.turnoverSub = this.storeService.getTurnoverUpdateListner()
      .subscribe(store => {
        this.storeTurnover = store;
      });
    if (this.disableData) {
      return;
    }
    this.storeService.getStore();
    this.storeService.getTurnover();
    // this.getDepositRange();
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.turnoverSub) {
      this.turnoverSub.unsubscribe();
    }
  }
}
