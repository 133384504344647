import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Alert } from '../Models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  currentNIM = this.authService.currentNim;
  private alertsUpdated = new Subject<Alert[]>();
  private sendUpdated = new Subject<boolean>();

  getAlerts() {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.APIURL}/api/v1/alerts/warehouse/${this.currentNIM}/history?pager.ItemCount=2147483647&pager.IgnoreCount=true`;
    this.http.get<{ result: Alert[] }>(url)
      .subscribe(response => {
        if (response) {
          this.alertsUpdated.next(response.result);
        } else {
          this.alertsUpdated.next([]);
        }
      }, err => {
        this.alertsUpdated.next([]);
      });
  }

  getAlertsUpdateListener() {
    return this.alertsUpdated.asObservable();
  }

  sendAlert(data) {
    if (!data) {
      return;
    }
    const url = environment.APIURL + '/api/v1/alerts/warehouse?warehousenim=' + this.currentNIM;
    this.http.post<{ result: boolean }>(url, data)
      .subscribe(response => {
        if (response) {
          this.sendUpdated.next(response.result);
        } else {
          this.sendUpdated.next(false);
        }
      }, error => {
        this.sendUpdated.next(false);
      });
  }
  sendLogisticAlert(data) {
    if (!data) {
      return;
    }
    // todo : change url
    const url = environment.APIURL + '/api/v1/alerts/logistic';
    this.http.post<{ result: boolean }>(url, data)
      .subscribe(response => {
        if (response) {
          this.sendUpdated.next(response.result);
        } else {
          this.sendUpdated.next(false);
        }
      }, error => {
        this.sendUpdated.next(false);
      });
  }

  sendAdminAlert(data) {
    if (!data) {
      return;
    }
    // todo : change url
    const url = environment.APIURL + '/api/v1/alerts/admin';
    this.http.post<{ result: boolean }>(url, data)
      .subscribe(response => {
        if (response) {
          this.sendUpdated.next(response.result);
        } else {
          this.sendUpdated.next(false);
        }
      }, error => {
        this.sendUpdated.next(false);
      });
  }

  getSendUpdateListener() {
    return this.sendUpdated.asObservable();
  }
}
