import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AdCampaignService } from 'src/app/services/ad-campaign.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { Campaign } from 'src/app/Models/campaign.model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { PathList } from 'src/app/Models/pathList';
import { Commons } from 'src/app/shared/commons';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-ad-campaign',
  templateUrl: './ad-campaign.component.html',
  styleUrls: ['./ad-campaign.component.css']
})
export class AdCampaignComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private ngbModalService: NgbModal, private sweet: Sweetalert,
    private adCampaignService: AdCampaignService,
    private dtlanguages: DtLanguages,
    private commons: Commons,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private ngbModalConfig: NgbModalConfig) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }
  @ViewChild('bannerFileInput') bannerImg: ElementRef;

  isExternalURL = true;
  isCodifURL = false;
  isPublicationURL = false;
  bannerBase64 = '';
  fileExtension = '';
  isValidBanner = true;
  selectedLocation = [];


  campaign: Campaign;
  selectedNims: string[] = null;
  campaigns: Campaign[];
  showSpinner = true;
  showSpinnerModal = false;
  showNoDataPanel = false;
  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  pathsList: PathList[] = [];

  // Update Campaigne
  campaignName  = '';
  campaignbanner = '';
  campaigncodif = '';
  campaignexternalUrl = '';
  campaignstartDate: NgbDateStruct ;
  campaignendDate: NgbDateStruct ;
  today: NgbDateStruct  = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear()};
  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      columnDefs: [{
        'targets': [1, 3, 4, 6],
        'orderable': false
      },
      {
        'targets': [3],
        'width': '30%'
      }
      ],
      autoWidth: false,
      order: [5, 'desc'],
      language: this.dtlanguages.frLanguage()
    };
    this.getCampaigns();

    this.pathsList = this.commons.getRoutesAdsEligible();
  }

  setPath() {
    if (!this.selectedLocation || !this.selectedLocation.length) {
      this.selectedLocation = this.pathsList.map(s => s.name);
    } else {
      this.selectedLocation = [];
    }
  }

  getCampaigns() {

    this.showSpinner = true;
    this.adCampaignService.getAllCampaigns()
    .subscribe(res => {
      if (res && res.result) {
        this.setCampings(res.result);
        this.showSpinner = false;

        this.rerender();
        this.dtTrigger.next();
      }
    },
      err => {
        this.showSpinner = false;
        this.showNoDataPanel = true;
      });
  }
  private setCampings(result: Campaign[]) {
    if (result.length) {
       this.campaigns = result || [];
    } else {
      this.showNoDataPanel = true;
    }
  }
  addCampaign(form: NgForm) {
    if (!form.valid && !this.campaign ) {
      return;
    }

    const name = form.value.txtName;
    const localtions = this.selectedLocation;
    const bannerImg = this.bannerBase64;

    if ( !this.campaign && !bannerImg) {
      return;
    }
    let codif = null;
    let externalUrl = null;

    if (form.value.txtCodif) {
      codif = form.value.txtCodif;
    }
    if (form.value.txtExternalURL) {
      externalUrl = form.value.txtExternalURL;
    }
    const startDate = `${this.campaignstartDate.year}-${this.campaignstartDate.month}-${this.campaignstartDate.day}T00:00:00`;
    const endDate = `${this.campaignendDate.year}-${this.campaignendDate.month}-${this.campaignendDate.day}T00:00:00`;

    const status = 1; // by default active
    const idCamping = this.campaign ? this.campaign.id : '';  // to update
    const bannerUrl = this.campaign ? this.campaign.bannerUrl : '';  // to update
    const nims = this.selectedNims || (this.campaign ? this.campaign.nims : null);  // to update

    const campaign = {
      'id': idCamping,
      'name': name,
      'routePaths': localtions,
      'banner': {'data': bannerImg, 'extension': this.fileExtension},
      'deepLink': {
        'codif': codif,
        'url': externalUrl
      },
      'startDate': startDate,
      'endDate': endDate,
      'status': status,
      'bannerUrl': bannerUrl,
      'nims': nims
    };
    this.showSpinnerModal = true;

   if ( idCamping === '') {
     this.adCampaignService.addCampaign(campaign)
      .subscribe(res => {

        this.showSpinnerModal = false;
        this.selectedNims = null;
        this.getCampaigns();
        this.closeModal();
      },
        err => {
          this.selectedNims = null;
          this.showSpinnerModal = false;
        });
    } else {
      if (campaign.banner.data === '') {
        campaign.banner = null;
      }
        this.adCampaignService.updateCampaign(campaign)
      .subscribe(res => {

        this.showSpinnerModal = false;
        this.selectedNims = null;
        this.getCampaigns();
        this.closeModal();
      },
        err => {
          this.selectedNims = null;
          this.showSpinnerModal = false;
        });
      }
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  updateCampaign(campaign: Campaign, adComapaignModal) {

    this.campaign = campaign;
    this.campaignName = campaign.name;
    this.selectedLocation = campaign.routePaths;

    this.campaignbanner = campaign.bannerUrl;

    this.campaigncodif = (campaign.deepLink && campaign.deepLink.codif) ? campaign.deepLink.codif : '';

    this.campaignexternalUrl = (campaign.deepLink && campaign.deepLink.url) ? campaign.deepLink.url : '';
    this.isExternalURL = (this.campaignexternalUrl !== '') ? true : false;
    this.isCodifURL = (this.campaigncodif !== '') ? true : false;
    if (campaign.startDate && campaign.startDate) {
      const d1 = new Date(campaign.startDate);
      this.campaignstartDate = { day: d1.getDate(), month: d1.getMonth() + 1, year: d1.getFullYear()};
    }
    if (campaign.endDate) {
      const d1 = moment(campaign.endDate);
      if (d1.isAfter(moment())) {
        this.campaignendDate = { day: d1.date(), month: d1.month() + 1, year: d1.year() };
      }
    }

    this.openModal(adComapaignModal);
  }

  pauseCampaign(campaign: Campaign) {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir mettre en pause cette campagne ?',
      (error: Error, result: any): void => {
        if (result) {
          campaign.status = 2;
          this.adCampaignService.updateCampaign(campaign)
          .subscribe(res => {

            this.getCampaigns();
            },
            err => {

            });
        }
      });
  }

  restartCampaign(campaign: Campaign) {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir relancer cette campagne ?',
      (error: Error, result: any): void => {
        if (result) {
          campaign.status = 1;
          this.adCampaignService.updateCampaign(campaign)
          .subscribe(res => {

            this.getCampaigns();
            },
            err => {

            });
        }
      });
  }

  getClassStatus(campaign: Campaign) {
    if (!campaign) {
      return '';
    }

    if (campaign.endDate && moment(campaign.endDate).isBefore(moment())) {
      return 'text-danger';
    }

    return campaign.status === 1 ? 'text-success' : 'text-warning';
  }

  onFileSelect(input: HTMLInputElement) {
    const self = this;
    this.selectedNims = [];
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.split(/,|;/ig).map(t => t.trim());
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
        if (nims.length === 0) {
          alert('Le fichier ne contient pas de NIM sur 7 caractères.');
          input.value = '';
          self.selectedNims = null;
        } else {
          self.selectedNims = nims;
        }
        };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
    return false;
  }

  getImageBase64String(event) {
    this.clearFileInput();

    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const name = event.target.files[0].name;

      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      reader.onloadend = (ev) => {

        const width = img.naturalWidth;
        const height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);

        if (width !== 728 && height !== 90) {
          this.isValidBanner = false;
          this.clearFileInput();
          return;
        }
        this.fileExtension = name.split('.').pop();
        this.bannerBase64 = reader.result.toString();
        this.isValidBanner = true;
        this.clearFileInput();
      };

      reader.readAsDataURL(file);
    }
  }

  isCampaignExpired(campaign: Campaign, status: number): boolean {
    if (!campaign.endDate || campaign.endDate === null) {
      return false;
    }

    if (moment(campaign.endDate).isBefore(moment())) {
      return true;
    }

    return campaign.status !== status;
  }
  getUrlCampaign(campaign: Campaign) {
    if (campaign && campaign.deepLink && campaign.deepLink.url) {
      if (campaign.deepLink.url.includes('//')) {
        return campaign.deepLink.url;
      } else {
      return '//' + campaign.deepLink.url;
      }
    } else if (campaign && campaign.deepLink && campaign.deepLink.codif) {
      return '/titles/' + campaign.deepLink.codif ; // + '/issues/{{issue.issueNumber == null ? issue.referenceId : issue.issueNumber}}';
    } else {
      return 'javascript:void(0)';
    }

  }
  openModal(adComapaignModal) {
    this.ngbModalService.open(adComapaignModal, { size: 'lg' });
  }

  clearFileInput() {
    if ( this.bannerImg && this.bannerImg.nativeElement) {

    this.bannerImg.nativeElement.value = '';
    }
  }

  closeModal(sendrRacking = true) {
    this.isValidBanner = true;
    this.bannerBase64 = '';
    this.clearFileInput();
    this.selectedLocation = [];
    // --
    this.campaign = null;
    this.campaignName = '';
    this.campaignbanner = '';
    this.campaigncodif = '';
    this.campaignexternalUrl = '';
    this.isExternalURL =  false;
    this.isCodifURL =  false;
    this.campaignstartDate = null;
    this.campaignendDate = null;
    this.ngbModalService.dismissAll();

  }
  ngOnDestroy() {
    this.closeModal(false);
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }
}
