import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbModalConfig, NgbDate, NgbDateStruct, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { OrderService } from 'src/app/services/order.service';
import { StoreOrder, StoreOrderItem, Order, PointInfo } from 'src/app/Models/order.model';
import { AuthService } from 'src/app/services/auth-service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { Issue } from 'src/app/Models/issue.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import * as moment from 'moment';
import { CurrencyFormatter } from 'src/app/shared/pipes/currency-formatter.pipe';
import { Address } from 'src/app/Models/address.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy, AfterViewInit {

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  orders: StoreOrder[];
  currentCustomer: PointInfo;
  itemsToValidate: any[] = [];
  isLoading = true;
  orderSelected: StoreOrder;
  orderToPay: string[] = [];
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  showBtnSpinner = false;
  selectedClient = '';

  deliveryHourLimit = 14;
  deliveryPeriod = 0;

  private currencyPipe = new CurrencyFormatter();

  private providers: string[] = [];
  get providerReferences(): string[] {

    if (!this.providers.length
      && this.orders && this.orders.length) {

      this.providers = this.orders.map((v, i) => v.customer.name)
                  .filter(this.onlyUnique)
                  .sort((a, b) => 0 - (a < b ? 1 : -1));
    }

    return this.providers;
  }

  constructor(private orderService: OrderService,
    private authService: AuthService, private ngbModalService: NgbModal,
    private config: NgbDatepickerConfig,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private dtlanguages: DtLanguages,
    private sweet: Sweetalert) { }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: false,
      columnDefs: [{
        'targets': [0, 5],
        'orderable': false
      }
      ],
      autoWidth: false,
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };
  this.getOrders('', '');
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  displayTomorrowFlag(index: number): boolean {
    if (this.isPro || index < 0) {
      return false;
    }

    const createdOn = moment(this.orderSelected.date);
    const issueDate = moment(this.orderSelected.items[index].releaseDate);

    return createdOn.isBefore(issueDate);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
       dtInstance.destroy();
       this.dtTrigger.next();
   });
  }
  get isPro(): boolean {
    return this.authService.isPro(true);
  }
  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  addressToString(address: Address) {
    if (!address) {
      return '';
    }

    let str = address.firstAddress;
    if (address.secondAddress) {
      str += ', ' + address.secondAddress;
    }
    str += ', ' + address.postalCode;
    str += ', ' + address.locality;

    return str;
  }

  getOrdersByOptions() {
    const from = this.fromDate ? this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day : '';
    const to = this.toDate ? this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day : '';
    this.getOrders(from, to);
  }

  getOrders(dFrom: string, dTo: string) {

    this.isLoading = true;
    this.orderService.getStoreOrders(dFrom, dTo, this.authService.currentNim)
      .subscribe(res => {
        this.isLoading = false;
        if (res.result) {
          if (this.selectedClient.length) {
            res.result = res.result.filter(c => c.customer.name === this.selectedClient);
            this.currentCustomer = res.result[0].customer;
          } else {
            this.currentCustomer = null;
          }

          this.orders = res.result;
          if (res.result.length) {
            this.deliveryHourLimit = res.result[0].provider.config.hour;
            this.deliveryPeriod = res.result[0].provider.config.period;
          }
          this.rerender();
        }
      },
        err => {
          this.isLoading = false;
        });
  }

  onChangeQantityStore(itemId: string) {
    const item = this.orderSelected.items.find(i => i.id === itemId);

    if (!item) {
      return;
    }

    const availableQtt = parseInt(((document.getElementById('txt-store-' + item.id) as HTMLInputElement).value), 0);
    if (availableQtt === undefined) {
      return;
    }
    item.availableQuantity = availableQtt;
  }

  onChangeQantityPro(itemId: string) {
    const item = this.orderSelected.items.find(i => i.id === itemId);

    if (!item) {
      return;
    }

    const receivedQtt = parseInt(((document.getElementById('txt-pro-' + item.id) as HTMLInputElement).value), 0);
    if (receivedQtt === undefined) {
      return;
    }
    item.receivedQuantity = receivedQtt;
  }

  getOrderStatus(order: StoreOrder): string {
    if (!order || order.status === undefined) {
      return 'En attente';
    }

    let status = order.status ? 'Traité' : 'En attente';
    if (!this.isPro && order.received) {
        status = order.paid ? 'Soldé' : 'À payer';
    } else if (order.received) {
      status = 'Confirmé';
    }

    return status;
  }

  onAcceptOrder() {
    if (!this.orderSelected) {
      return;
    }

    this.orderSelected.items.forEach(i => {
      if (i.availableQuantity === undefined) {
        i.availableQuantity = i.quantity;
      }
    });

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showBtnSpinner = true;
          this.orderService.acceptOrder(this.orderSelected, this.authService.currentNim)
            .subscribe(res => {
              this.showBtnSpinner = false;
              if (res.result) {
                localStorage.removeItem(this.orderSelected.id);
                this.sweet.success('Succès !', 'Commande acceptée');
                this.getOrders('', '');
                this.closeModal();
              }
            },
              err => {
                this.showBtnSpinner = false;
              });
        }
      });
  }

  onStorePaidOrder() {

    if (!this.orderToPay.length) {
      return;
    }

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showBtnSpinner = true;
          this.orderService.storePaidOrder(this.orderToPay, this.authService.currentNim)
            .subscribe(res => {
              this.showBtnSpinner = false;
              if (res.result) {
                this.orderToPay = [];
                this.sweet.success('Succès !', 'Commande soldée avec succès !');
                this.getOrders('', '');
                this.closeModal();
              }
            },
              err => {
                this.showBtnSpinner = false;
              });
        }
      });
  }

  saveToLocal() {
    if (!this.orderSelected) {
      return;
    }
    localStorage.setItem(this.orderSelected.id, JSON.stringify(this.orderSelected));
  }

  saveDeliveryConfiguration() {

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir sauvegarder cette configuration ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showBtnSpinner = true;
          this.orderService.storeDeliveryConfig(
            {hour: this.deliveryHourLimit, period: this.deliveryPeriod},
            this.authService.currentNim)
            .subscribe(res => {
              this.showBtnSpinner = false;
              if (res.result) {
                this.sweet.success('Succès !', 'Configuration enregistrée avec succès !');
                this.getOrders('', '');
                this.closeModal();
              }
            },
              err => {
                this.showBtnSpinner = false;
              });
        }
      });
  }

  printDocument() {
    if (!this.orderSelected) {
      return;
    }

    this.printAll([this.orderSelected]);
  }

  getQuantity(master: number, child: number, quantity: number) {

    if (master === undefined) {

      if (child === undefined) {
        return quantity;
      }

      return child;
    }

    return master;
  }

  printAll(orders: StoreOrder[] = null) {

    orders = orders || this.orders;

    if (!orders || !orders.length) {
      return;
    }

    let html = `<html>
    <head>
    ${document.head.innerHTML}
    </head><body><div class="container">`;

    let minDate = moment();
    let maxDate = moment();

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    const customers = orders.map((v, i) => v.customerId).filter(onlyUnique);

    let table = `<table class="table">
    <thead>
    <tr>
      <th>Référence</th>
      <th>Titre</th>
      <th class="text-center">Quantité</th>
      <th class="text-center">Prix</th>
    </tr>
    </thead><tbody>`;

    let allTotalQty = 0;
    let allTotal = 0;

    orders.forEach(order => {

      const dt = moment(order.date);
      if (minDate.diff(dt, 'days') > 0) {
        minDate = dt;
      }
      if (maxDate.diff(dt, 'days') < 0) {
        maxDate = dt;
      }

      let totalQty = 0;
      let total = 0;

      table += `<tr><td colspan="4">`;
      if (customers.length > 1) {
        const name = this.isPro ? '' : `${order.customer.name}`;
        table += `${name}<br>`;
      }
      table += `Commande : ${order.id} du ${dt.locale('fr').format('DD MMM YYYY')} (${order.items.length} exemplaires)</td></tr>`;
      order.items.forEach(item => {
        const qty = item.availableQuantity === undefined ? item.quantity : item.availableQuantity;
        const price = qty * item.unitPrice;
        totalQty += qty;
        total += price;
        table += `<tr>
          <td>${item.id}</td>
          <td class="bold">${item.name}
            <br/>
            <span class="font-weight-lighter">${moment(item.releaseDate).locale('fr').format('DD MMMM YYYY')}<span>
          </td>
          <td class="text-center">${qty}</td>
          <td class="text-center">${this.currencyPipe.transform(price, false)}</td>
        </tr>`;
      });

      if (orders.length > 1) {
        table += `<tr class="bold">
          <td colspan="2" class="text-right p-r-10"></td>
          <td class="text-center">${totalQty}</td>
          <td class="text-center">${this.currencyPipe.transform(total, false)}</td>
        </tr>`;
      }

      allTotalQty += totalQty;
      allTotal += total;
    });

    table += `<tr class="bold">
        <td colspan="2" class="text-right p-r-10">Total</td>
        <td class="text-center">${allTotalQty}</td>
        <td class="text-center">${this.currencyPipe.transform(allTotal, false)}</td>
      </tr>`;

    table += '</tbody></table>';

    if (customers.length === 1) {
      if (this.isPro) {
        const provider = this.orders[0].provider;
        html += `<h6>Fournisseur : ${provider.name}</h6>`;
        html += `${this.addressToString(provider.address)}`;
      } else {
        const customer = this.orders[0].customer;
        html += `<h6>Client : ${customer.name}</h6>`;
        html += `${this.addressToString(customer.address)}`;
      }
    } else if (customers.length > 1) {
      html += `<h6>Synthèse des commandes<h6>`;
    }

    if (minDate.isSame(maxDate)) {
      html += `<div>Date : ${minDate.locale('fr').format('DD MMMM YYYY')}</div>`;
    } else {
      html += `<div>Du : ${minDate.locale('fr').format('DD MMMM YYYY')}</div>`;
      html += `<div>Au : ${maxDate.locale('fr').format('DD MMMM YYYY')}</div>`;
    }

    html += `${table}</div></body></html>`;

    const w = window.open();
    w.document.write(html);
    setTimeout(() => {
      w.print();
      w.close();
    }, 200);
  }

  onAcceptDelivery() {
    if (!this.orderSelected) {
      return;
    }

    this.orderSelected.items.forEach(i => {
      if (i.receivedQuantity === undefined) {
        i.receivedQuantity = i.availableQuantity;
      }
    });

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider cette la réception ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showBtnSpinner = true;
          this.orderService.acceptDelivery(this.orderSelected)
            .subscribe(res => {
              this.showBtnSpinner = false;
              if (res) {
                this.sweet.success('Succès !', 'Commande acceptée');
                this.getOrders('', '');
                this.closeModal();
              }
            },
              err => {
                this.showBtnSpinner = false;
              });
        }
      });
  }

  onRefuseOrder(orderId: string) {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir refuser cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          this.orderService.refuseOrder(orderId)
            .subscribe(res => {
              if (res) {
                this.sweet.success('Succès !', 'Commande accepté');
                this.getOrders('', '');
              }
            },
              err => {
              });
        }
      });
  }
  getTotalExemplaire(items: Issue[]) {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total += items[i].quantity;
    }
    return total + ' exemplaire' + (total > 1 ? 's' : '');
  }
  getTotalPrice() {
    return this.orderSelected.items.map(i => {
        const qty = i.availableQuantity === undefined ? i.quantity : i.availableQuantity;
        return i.unitPrice * qty;
      })
      .reduce((a, b) => a + b);
  }
  openModal(content, indexOrder: number) {

    if (indexOrder !== undefined) {
      this.orderSelected = this.orders[indexOrder];
      this.orderToPay.push(this.getOrderInternalId(this.orderSelected));

      const savedItem = JSON.parse(localStorage.getItem(this.orderSelected.id)) as StoreOrder;
      if (savedItem) {
        this.orderSelected = savedItem;
      }
    }

    this.ngbModalService.open(content, { size: 'lg' });
  }

  selectToPay(order: StoreOrder, checked: boolean) {
    const id = this.getOrderInternalId(order);

    if (!id || !id.length) {
      return;
    }

    const itemIndex = this.orderToPay.indexOf(id);
    if (checked) {
      if (itemIndex < 0) {
        this.orderToPay.push(id);
      }
    } else {
      if (this.orderToPay.indexOf(id) >= 0) {
        this.orderToPay.splice(itemIndex, 1);
      }
    }
  }

  private getOrderInternalId(order: StoreOrder) {
    if (!order) {
      return null;
    }

    return `${this.authService.currentNim}-${order.customerId}-${order.id}`;
  }

  closeModal() {
    this.orderSelected = null;
    this.ngbModalService.dismissAll();
    this.orderToPay = [];
  }
  ngOnDestroy() {
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }
}
