import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { AuthService } from 'src/app/services/auth-service';
import { Sweetalert } from 'src/app/shared/sweetAlert';

@Component({
  selector: 'app-lostpassword',
  templateUrl: './lostpassword.component.html',
  styleUrls: ['./lostpassword.component.css']
})
export class LostpasswordComponent implements OnInit {

  constructor(private authService: AuthService,
    public sweet: Sweetalert) { }
  isProcessing = false;
  isSuccess = false;
  userEmail = '';
  processType = 0;

  ngOnInit() {
  }

  onResetPassword(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.processType = 1;
    this.isProcessing = true;
    this.userEmail = '';
    this.authService.resetPassword(form.value.email).subscribe(response => {
      this.isProcessing = false;
      this.isSuccess = true;
    }, error => {
      this.isProcessing = false;
      this.isSuccess = false;
    });
  }

  onResetLogin(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.processType = 2;
    this.isProcessing = true;
    this.userEmail = '';
    this.authService.resetPassword2(form.value.txtNim, form.value.txtName).subscribe(response => {
      this.isProcessing = false;
      if (response && response.result && response.result.length) {
        this.isSuccess = true;
        this.userEmail = response.result;
      } else {
        this.sweet.warning('Attention !', 'Votre NIM et nom ne correspondent pas.');
      }
    }, error => {
      this.isProcessing = false;
      this.isSuccess = false;
      if (error.status && error.status === 409) {
        this.sweet.warning('Attention !', 'Aucun compte n’est associé à ce NIM, vous devez créer un compte.');
      } else {
        this.sweet.warning('Attention !', 'Une erreur est survenue au moment du traitement de la demande, veuillez rééssayer plus tard !');
      }
    });
  }
}
