import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }

    return items.filter(singleItem =>
      (singleItem[field] || '').toLowerCase().includes(value.toLowerCase())
    );
  }
}

@Pipe({
  name: 'filterMulti'
})
export class FilterMultiPipe implements PipeTransform {
  transform(items: any, filter: any, isAnd: boolean): any {
    if (filter && Array.isArray(items)) {
      const filterKeys = Object.keys(filter).filter(s => (filter[s] || '').length > 0);
      if (!filterKeys.length) {
        return items;
      }
      if (isAnd) {
        return items.filter(item => {
          return filterKeys.every(v => (filter[v] || '').toLowerCase() === (item[v] || '').toLowerCase());
        });
      } else {
        return items.filter(item => {
          return filterKeys.some(v => (filter[v] || '').toLowerCase() === (item[v] || '').toLowerCase());
        });
      }
    } else {
      return items;
    }
  }
}
