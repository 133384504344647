import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ArchivesService } from 'src/app/services/archives.service';
import { Subscription } from 'rxjs';
import { Bill } from 'src/app/Models/bill.model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-avoir',
  templateUrl: './avoir.component.html',
  styleUrls: ['./avoir.component.css']
})
export class AvoirComponent implements OnInit, OnDestroy {
  billDate = moment();
  private billSubscription: Subscription;
  bill: Bill;
  isLoading = true;
  print = false;
  fileName = `avoir-`;
  selectedNim: string = null;
  selectedCode = '';

  @ViewChild('content') modalLoading: ElementRef;
  constructor(private ngbModalService: NgbModal,
    private archiveService: ArchivesService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.billSubscription = this.archiveService.getBillListner()
      .subscribe(doc => {
        if (doc != null) {
          this.bill = doc;
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF(true);
            }, 1000);
          }
        }
        this.isLoading = false;
      }, err => {
        this.closeModal();
        this.isLoading = false;
      });

    if (this.route.snapshot.paramMap.get('printOnly') != null) {
      this.print = this.route.snapshot.paramMap.get('printOnly') === 'true';
    }

    this.selectedCode = this.route.snapshot.queryParams['selectedCode'];
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    const nim = this.selectedNim || this.authService.currentNim;

    let date = '';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.billDate = moment(date).locale('fr');
      this.archiveService.getBillOrCredit(date, nim, 'credit', this.selectedCode);
    }

    this.fileName = `${this.fileName}${date}.pdf`;
  }
  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  goBack() {
    const date = this.billDate.format('MM/YYYY');
    const queryParam = { };

    if (this.selectedNim && this.selectedNim.length) {
      queryParam['selectedNim'] = this.selectedNim || '';
    }

    if (this.selectedCode && this.selectedCode.length) {
      queryParam['selectedCode'] = this.selectedCode || '';
    }

    this.router.navigate([`/archives/${date}`], { queryParams: queryParam });
  }

  printPressStatment() {
    window.print();
  }
  downloadToPDF(closeAfterPrint = false) {
    this.print = true;
    this.archiveService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  ngOnDestroy() {
    if (this.billSubscription) {
      this.billSubscription.unsubscribe();
    }
  }
}

