import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Article } from '../Models/article.model';
import { Subject } from 'rxjs';
import { TitreInfo } from '../Models/titreingo.model';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private blobAllUpdated = new Subject<TitreInfo[]>();
  private blobAddUpdated = new Subject<boolean>();
  private latestUpdated = new Subject<TitreInfo>();

  constructor(private http: HttpClient) { }

  // get latest articles
  // getLastArticles(nim: string, count: number) {
  //   return this.http.get<{ result: Article[] }>(environment.APIURL + '/api/v4/articles/latest?nim=' + nim + '&count=' + count);
  // }


  // // get all articles
  // getArticles(nim: string, fromDate: string, toDate: string, tag: string, itemCount: number, pageIndex = 0) {
  //   let apiUrl = '/api/v4/articles?nim=' + nim;
  //   if (tag !== '') {
  //     apiUrl += '&tag=' + tag;
  //   }
  //   if (fromDate !== '') {
  //     apiUrl += '&from=' + fromDate;
  //   }
  //   if (toDate !== '') {
  //     apiUrl += '&to=' + toDate;
  //   }
  //   apiUrl += `&pager.index=${pageIndex}&pager.itemCount=${itemCount}`;
  //   return this.http.get<({ result: Article[], totalRecords: number })>(environment.APIURL + apiUrl);
  // }

  // // get tags list
  // getTags() {
  //   return this.http.get<({ result: string[] })>(environment.APIURL + '/api/v4/articles/tags');
  // }

  getAllBlobs() {
    let url = environment.APIURL + '/api/v4/titresinfos';
    const ts = localStorage.getItem('TI_TS');
    if (ts) {
      url += `?ts=${ts}`;
    }
    // tslint:disable-next-line:max-line-length
    this.http.get<{ result: TitreInfo[] }>(url)
      .subscribe(response => {
        this.blobAllUpdated.next(response.result);
      }, err => {
        this.blobAllUpdated.next([]);
      });
  }

  getAllBlobsListner() {
    return this.blobAllUpdated.asObservable();
  }

  getAddBlob(data: TitreInfo) {
    const url = environment.APIURL + '/api/v4/titresinfos';
    // tslint:disable-next-line:max-line-length
    this.http.post<{ result: boolean }>(url, data)
      .subscribe(response => {
        localStorage.setItem('TI_TS', (new Date()).getTime().toString());
        this.blobAddUpdated.next(response.result);
      }, err => {
        this.blobAddUpdated.next(false);
      });
  }

  getAddBlobListner() {
    return this.blobAddUpdated.asObservable();
  }

  getLatestBlob() {
    let url = environment.APIURL + '/api/v4/titresinfos/latest';
    const ts = localStorage.getItem('TI_TS');
    if (ts) {
      url += `?ts=${ts}`;
    }
    // tslint:disable-next-line:max-line-length
    this.http.get<{ result: TitreInfo }>(url)
      .subscribe(response => {
        this.latestUpdated.next(response.result);
      }, err => {
        this.latestUpdated.next(null);
      });
  }

  getLatestBlobListner() {
    return this.latestUpdated.asObservable();
  }
}
