import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth-service';
import { ArchivesService } from 'src/app/services/archives.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountStatement } from 'src/app/Models/statements/account.model';
import { saveAs } from 'file-saver';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-accountstatmentforms',
  templateUrl: './accountstatmentforms.component.html',
  styleUrls: ['./accountstatmentforms.component.css']
})
export class AccountstatmentformsComponent implements OnInit, OnDestroy {

  private accountDocumentSubscription: Subscription;
  fileName = `releve-compte-`;
  print = false;
  accountStatementDate = moment();
  statement: AccountStatement;
  isLoading = true;
  selectedNim: string = null;
  selectedCode = '';

  get totalOperations(): number {
    if (!this.statement) {
      return 0;
    }

    const notExpired = this.statement.notExpired ? this.statement.notExpired.total : 0;
    const sampling = this.statement.due ? this.statement.due.amount : 0;
    const previous = this.statement.previous ? this.statement.previous.amount : 0;
    return notExpired + sampling + previous;
  }

  get warehouse() {
    if (!this.statement) {
      return null;
    }

    return this.statement.warehouse;
  }

  get pdv() {
    if (!this.statement) {
      return null;
    }

    return this.statement.pdv;
  }

  @ViewChild('content') modalLoading: ElementRef;

  constructor(private archiveService: ArchivesService,
    private route: ActivatedRoute,
    private router: Router,
    private ngbModalService: NgbModal,
    private authService: AuthService,
    private ngbModalConfig: NgbModalConfig) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('printOnly') != null) {
      this.print = this.route.snapshot.paramMap.get('printOnly') === 'true';
    }

    this.selectedCode = this.route.snapshot.queryParams['selectedCode'];
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    const nim = this.selectedNim || this.authService.currentNim;

    let date = '';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.accountStatementDate = moment(date).locale('fr');
      this.archiveService.getAccountDocument(date, nim, this.selectedCode);
    }

    this.accountDocumentSubscription = this.archiveService.getAccountDocumentListner()
      .subscribe(doc => {
        if (doc != null) {
          this.statement = doc;
          this.fileName += `${date}-R${doc.week}.pdf`;
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF(true);
            }, 1000);
          }
        }
        this.isLoading = false;
      }, err => {
        this.closeModal();
        this.isLoading = false;
      });
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  downloadToXML() {
    this.archiveService.downloadToXML(this.accountStatementDate.format('YYYY-MM-DD'),
            this.authService.currentNim, 'account')
      .subscribe(result => {
        const name = this.fileName.replace('.pdf', '.xml');
        saveAs(result, name);
      }, err => {
      });
  }

  goBack() {
    const date = this.accountStatementDate.format('MM/YYYY');
    const queryParam = { };

    if (this.selectedNim && this.selectedNim.length) {
      queryParam['selectedNim'] = this.selectedNim || '';
    }

    if (this.selectedCode && this.selectedCode.length) {
      queryParam['selectedCode'] = this.selectedCode || '';
    }

    this.router.navigate([`/archives/${date}`], { queryParams: queryParam });
  }

  printAccountStatment() {
    window.print();
  }

  downloadToPDF(closeAfterPrint = false) {
    this.print = true;
    this.archiveService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  printDoc() {
    window.print();
  }

  ngOnDestroy() {
    if (this.accountDocumentSubscription) {
      this.accountDocumentSubscription.unsubscribe();
    }
  }
}
