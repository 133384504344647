import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Sweetalert } from 'src/app/shared/sweetAlert';
import { Issue } from 'src/app/Models/issue.model';
import * as moment from 'moment';
import { Commons } from 'src/app/shared/commons';
import { OrderService } from 'src/app/services/order.service';
import { Basket } from 'src/app/Models/basket.model';
import { AuthService } from 'src/app/services/auth-service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-basket',
  templateUrl: './user-basket.component.html',
  styleUrls: ['./user-basket.component.css']
})
export class UserBasketComponent implements OnInit {

  constructor(private sweet: Sweetalert,
    private commons: Commons,
    private orderService: OrderService,
    private authService: AuthService,
    private toastrService: ToastrService) { }
  baskets: Basket[] = [];
  isUserBasketEmpty = true;
  referenceClient = '';
  showBtnSpinner = false;
  paid = false;
  get isCustomer(): boolean {
    return this.authService.isCustomer();
  }

  get maxHour(): number {
    if (!this.baskets || !this.baskets.length) {
      return environment.lipregoMaxCommandHour;
    }

    const item = this.baskets[0];
    return item.issue.maxHour || environment.lipregoMaxCommandHour;
  }
  get isAfterMaxHour(): boolean {
    if (!this.baskets || !this.baskets.length) {
      return false;
    }

    const item = this.baskets[0];

    return moment().hour() >= (item.issue.maxHour || this.maxHour);
  }

  ngOnInit() {
    this.getItemsfromLocalStorage();
  }

  getItemsfromLocalStorage() {
    this.baskets = this.commons.getUserBasket();
    if (this.baskets && this.baskets.length) {
      this.isUserBasketEmpty = false;
    }
  }

  onPaidChange(val: boolean) {
    this.paid = val;
  }

  onValidatOrder() {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showBtnSpinner = true;
          const currentOrder = [];
          if (this.baskets && this.baskets.length) {
            const refInput = document.getElementById('refClient') as HTMLInputElement;
            this.baskets.forEach(elem => {
              const qtt = parseInt(((document.getElementById(elem.issue.id) as HTMLInputElement).value), 0);
              if (qtt > 0) {
                let ref = elem.reference;
                if (refInput) {
                  ref = refInput.value;
                }
                const objToPush = { 'id': elem.issue.id, 'quantity': qtt, 'reference': ref, paid: this.paid };
                currentOrder.push(objToPush);
              }
            });

            this.orderService.createOrder(currentOrder, this.authService.currentNim)
              .subscribe(res => {
                this.showBtnSpinner = false;
                this.sweet.success('Succès !', 'Votre commande a été envoyée au diffuseur.');
                // clear user basket if order created
                this.clearBasket();
                (document.getElementById('refClient') as HTMLInputElement).value = '';
              },
                err => {
                  this.showBtnSpinner = false;
                });
          }
        }
      });
  }

  onRemoveItemFromUserBasket(basket) {
    this.orderService.removeItemFromUserBasket(basket);
    this.getItemsfromLocalStorage();
  }

  OnInCreaseQuantity(id) {
    let qtt = parseInt(((document.getElementById(id) as HTMLInputElement).value), 0);
    if (qtt >= 1) {
      qtt--;
      (document.getElementById(id) as HTMLInputElement).value = qtt.toString();
    }
  }

  OnUpCreaseQuantity(id) {
    let qtt = parseInt(((document.getElementById(id) as HTMLInputElement).value), 0);
    qtt++;
    (document.getElementById(id) as HTMLInputElement).value = qtt.toString();
  }

  getTotal(price: number, inputId: string) {
    const qtt = parseInt(((document.getElementById(inputId) as HTMLInputElement).value), 0);
    if (qtt === 0) {
      return 0;
    }
    return qtt * price;
  }
  getBasketTotal(): number {
    if (!this.baskets
      || !this.baskets.length) {
      return 0;
    }
    return this.baskets.map(s => s.quantity * s.issue.price).reduce((a, b) => a + b);
  }
  onClearBasket() {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir vider votre panier ?',
      (error: Error, result: any): void => {
        if (result) {
          this.orderService.clearUserBasket();
          this.baskets = [];
          this.isUserBasketEmpty = true;
        }
      });
  }
  clearBasket() {
    this.orderService.clearUserBasket();
    this.baskets = [];
    this.isUserBasketEmpty = true;
  }
 isFormValide(): boolean {
    return this.referenceClient.length > 0 || !this.showClientReference;
  }

  get showClientReference(): boolean {

    if (!this.baskets || !this.baskets.length) {
      return false;
    }

    if (this.isCustomer) {
      return false;
    }

    if (this.authService.isPublicPro()) {
      return true;
    }

    const uniqueValues = this.baskets.map(b => b.reference).filter((v, i, a) => v && a.indexOf(v) === i);
    return uniqueValues.length <= 1;
  }
}
