import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({ name: 'DateToNowPipe' })
export class DateToNowPipe implements PipeTransform {
  transform(value: string): string {

    let newStr = (moment(value).locale('fr').fromNow()); // dddd d MMMM YYYY
    if (!value || newStr === 'Invalid date') {
      newStr = '–';
    } else if (newStr.includes('seconde')) { // max 45 seconde
      newStr = 'à l’instant';
    }
    return newStr;
  }
}
