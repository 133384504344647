import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Subscription } from 'rxjs';
import { SignupStat, EditorActionStat, CodeActionStat, WarehouseDetailStat } from 'src/app/Models/signupStat.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { DatePipe } from 'src/app/shared/pipes/date-formatter.pipe';

@Component({
  selector: 'app-activity-report',
  templateUrl: './activity-report.component.html',
  styleUrls: ['./activity-report.component.css']
})
export class ActivityReportComponent implements OnInit, OnDestroy {

  constructor(private storeService: StoreService) { }
  private signupStatsSubscription: Subscription;
  private actionStatsSubscription: Subscription;
  private warehouseStatsSubscription: Subscription;
  private storageFromKEY = 'SSFROM';
  private storageToKEY = 'SSTO';
  signupStats: SignupStat[] = [];
  warehouseStats: WarehouseDetailStat[] = [];
  // storeStats: StoreActionStat[] = [];
  editorStats: EditorActionStat[] = [];
  extendedEditorStats: EditorActionStat[] = null;
  codeStats: CodeActionStat[] = [];
  extendedCodeStats: CodeActionStat[] = null;
  lastExtendedIndex: number = null;
  modelFrom: NgbDateStruct;
  modelTo: NgbDateStruct;
  showNoDataPanel = false;
  loadingsignups = false;
  loadingwarehouses = false;
  loadingActions = false;
  displayedTab = 0;


  ngOnInit() {
    this.signupStatsSubscription = this.storeService.getSignupStatsListner().subscribe(s => {
      this.signupStats = s || [];
      this.showNoDataPanel = false;
      this.loadingsignups = false;
    }, err => {
      this.showNoDataPanel = true;
      this.loadingsignups = false;
      this.signupStats = [];
    });

    this.warehouseStatsSubscription = this.storeService.getWarehouseStatsListner().subscribe(s => {
      this.warehouseStats = s || [];
      this.showNoDataPanel = false;
      this.loadingwarehouses = false;
    }, err => {
      this.showNoDataPanel = true;
      this.loadingwarehouses = false;
      this.warehouseStats = [];
    });

    this.actionStatsSubscription = this.storeService.getActionStatsListner().subscribe(actionStats => {
      this.showNoDataPanel = false;
      this.loadingActions = false;
      if (!actionStats) {
        this.editorStats = [];
        this.codeStats = [];
        return;
      }

      this.editorStats = actionStats.editors || [];
      // this.storeStats = actionStats.stores || [];
      this.codeStats = actionStats.codes || [];
    }, err => {
      this.showNoDataPanel = true;
      this.showNoDataPanel = false;
      this.loadingActions = false;
      this.editorStats = [];
      this.codeStats = [];
    });

    const fromDate = localStorage.getItem(this.storageFromKEY) || '';
    if (fromDate.length) {
      const d1 = new Date(fromDate);
      this.modelFrom = { day: d1.getDate(), month: d1.getMonth() + 1, year: d1.getFullYear() };
    }
    const toDate = localStorage.getItem(this.storageToKEY) || '';
    if (toDate.length) {
      const d2 = new Date(toDate);
      this.modelTo = { day: d2.getDate(), month: d2.getMonth() + 1, year: d2.getFullYear() };
    }
    // this.performCall(fromDate, toDate);
  }

  getDateCount(date: Date) {
    if (!this.signupStats.length) {
      return 0;
    }

    return this.signupStats.filter(s => s.date === date).length;
  }

  ngOnDestroy() {
    if (this.signupStatsSubscription) {
      this.signupStatsSubscription.unsubscribe();
    }
    if (this.actionStatsSubscription) {
      this.actionStatsSubscription.unsubscribe();
    }
    if (this.warehouseStatsSubscription) {
      this.warehouseStatsSubscription.unsubscribe();
    }
  }

  changeDisplay(tabToDisplay: number) {
    this.displayedTab = tabToDisplay;
  }

  getStats() {
    this.getSignupStats();
    this.getActionStats();
    this.getWarehouseStats();
  }

  getSignupStats() {
    const fromDate = this.modelFrom ? this.modelFrom.year + '-' + this.modelFrom.month + '-' + this.modelFrom.day : '';
    const toDate = this.modelTo ? this.modelTo.year + '-' + this.modelTo.month + '-' + this.modelTo.day : '';

    this.performSignupCall(fromDate, toDate);
  }

  getWarehouseStats() {
    const fromDate = this.modelFrom ? this.modelFrom.year + '-' + this.modelFrom.month + '-' + this.modelFrom.day : '';
    const toDate = this.modelTo ? this.modelTo.year + '-' + this.modelTo.month + '-' + this.modelTo.day : '';

    this.performWarehouseCall(fromDate, toDate);
  }

  getActionStats() {
    const fromDate = this.modelFrom ? this.modelFrom.year + '-' + this.modelFrom.month + '-' + this.modelFrom.day : '';
    const toDate = this.modelTo ? this.modelTo.year + '-' + this.modelTo.month + '-' + this.modelTo.day : '';

    this.performActionStatsCall(fromDate, toDate);
  }

  toggleRow(date: Date, nim: string) {
    if (nim) {
      return;
    }
    const rowId = this.rowUnixId(date);
    const array = document.getElementsByClassName(rowId);
    for (let index = 0; index < array.length; index++) {
      array[index].classList.toggle('showRow');
      array[index].classList.toggle('hideRow');
    }
  }

  toggleCodeActionRow(itemIndex: number) {

    if (this.lastExtendedIndex === itemIndex) {
      this.extendedCodeStats = null;
      this.lastExtendedIndex = null;
      return;
    }

    this.lastExtendedIndex = itemIndex;
    this.extendedCodeStats = null;
    const item = this.codeStats[itemIndex];

    if (!item.stores
      || !Object.keys(item.stores).length) {
      return;
    }

    this.extendedCodeStats = Array.from(this.codeStats);
    Object.keys(item.stores).forEach((key, index) => {
      const j = item.stores[key];
      const k = { date: key, rea: j.rea, reaSamples: j.reaSamples, ser: j.ser, stores: {} } as CodeActionStat;
      this.extendedCodeStats.splice(index + 1 + itemIndex, 0, k);
    });
  }

  toggleEditorActionRow(itemIndex: number) {

    if (this.lastExtendedIndex === itemIndex) {
      this.extendedEditorStats = null;
      this.lastExtendedIndex = null;
      return;
    }

    this.lastExtendedIndex = itemIndex;
    this.extendedEditorStats = null;
    const item = this.editorStats[itemIndex];

    if (!item.titles
      || !Object.keys(item.titles).length) {
      return;
    }

    this.extendedEditorStats = Array.from(this.editorStats);
    Object.keys(item.titles).forEach((key, index) => {
      const j = item.titles[key];
      const k = { editor: key, rea: j.rea, reaSamples: j.reaSamples, ser: j.ser, titles: {} } as EditorActionStat;
      this.extendedEditorStats.splice(index + 1 + itemIndex, 0, k);
    });
  }

  getActionColVal(val: string) {

    const dt = moment(val);
    if (val.length > 7 && dt.isValid()) {
      return dt.locale('fr').format('DD MMMM YYYY');
    }

    return val;
  }

  codeActionRowDisplay(item: CodeActionStat) {
    if (!item || !item.stores) {
      return '';
    }
    return Object.keys(item.stores || {}).length ? '+' : '';
  }

  editorActionRowDisplay(item: EditorActionStat) {
    if (!item || !item.titles) {
      return '';
    }
    return Object.keys(item.titles || {}).length ? '+' : '';
  }

  exportDataToCSV() {
    if (!this.signupStats.length) {
      return;
    }
    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `signup-metrics-${date.format('YYYY-MM-DD')}`,
      filename: `signup-metrics-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.signupStats.map((v, i) => {
      return { date: v.date, nim: v.nim || 'tous', count: v.count || 1 };
    });
    csvExporter.generateCsv(data);
  }

  exportWarehouseDataToCSV() {
    if (!this.warehouseStats.length) {
      return;
    }
    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `warehouse-metrics-${date.format('YYYY-MM-DD')}`,
      filename: `warehouse-metrics-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.warehouseStats.map((v, i) => {
      return { nim: v.nim, notif: v.notif || 0, holidays: v.holidays || 0, diffs: v.diffs || 0 };
    });
    csvExporter.generateCsv(data);
  }

  exportActionDataToCSV() {
    if (!this.signupStats.length) {
      return;
    }
    const date = moment();
    const fileSuffix = this.displayedTab === 0 ? 'jour' : 'editeur';
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `actions-${fileSuffix}-metrics-${date.format('YYYY-MM-DD')}`,
      filename: `actions-${fileSuffix}-metrics-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = [];
    if (this.displayedTab === 0) {
      this.codeStats.forEach((v) => {
        if (!v.stores) {
          return;
        }
        data.push({
          date: moment(v.date).locale('fr').format('DD MMM YYYY'),
          nim: 'Total',
          rea: v.rea,
          reaSamples: v.reaSamples,
          ser: v.ser
        });
        Object.keys(v.stores).map(function (key, index) {
          data.push({
            date: '',
            nim: `nim : ${key}`,
            rea: v.stores[key].rea,
            reaSamples: v.stores[key].reaSamples,
            ser: v.stores[key].ser
          });
        });
      });
    } else {
      this.editorStats.forEach((v) => {
        if (!v.titles) {
          return;
        }
        data.push({ editeur: v.editor, titre: 'Total', rea: v.rea, reaSamples: v.reaSamples, ser: v.ser });
        Object.keys(v.titles).map(function (key, index) {
          data.push({
            editeur: '', titre: `codif : ${key}`,
            rea: v.titles[key].rea,
            reaSamples: v.titles[key].reaSamples,
            ser: v.titles[key].ser
          });
        });
      });
    }
    csvExporter.generateCsv(data);
  }

  rowUnixId(date: Date): string {
    return 'row-' + moment(date).unix();
  }

  private performSignupCall(from: string, to: string) {
    from = from || '';
    to = to || '';
    if (!from.length || !to.length) {
      return;
    }

    this.loadingsignups = true;
    this.signupStats = [];
    localStorage.setItem(this.storageFromKEY, from);
    localStorage.setItem(this.storageToKEY, to);
    this.storeService.getSignupStats(from, to);
  }

  private performWarehouseCall(from: string, to: string) {
    from = from || '';
    to = to || '';
    if (!from.length || !to.length) {
      return;
    }

    this.loadingwarehouses = true;
    this.warehouseStats = [];
    localStorage.setItem(this.storageFromKEY, from);
    localStorage.setItem(this.storageToKEY, to);
    this.storeService.getWarehouseStats(from, to);
  }

  private performActionStatsCall(from: string, to: string) {
    from = from || '';
    to = to || '';
    if (!from.length || !to.length) {
      return;
    }

    this.loadingActions = true;
    this.editorStats = [];
    this.codeStats = [];
    localStorage.setItem(this.storageFromKEY, from);
    localStorage.setItem(this.storageToKEY, to);
    this.storeService.getActionStats(from, to);
  }

}
