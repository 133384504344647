import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Sweetalert } from '../shared/sweetAlert';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {

    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient,
        private sweet: Sweetalert) { }

    /**
     * Checks in every set frequency the version of frontend application
     * param url
     * param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        if (!environment.production) {
            return;
        }
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * param url
     */
    private checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime(), { responseType: 'json' })
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(hash);

                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;

                    // If new version, do something
                    if (hashChanged) {
                        // location.reload();
                        this.sweet.confirmDialog('Nouveauté !',
                        // tslint:disable-next-line:max-line-length
                        'Nous avons détecté une nouvelle version de PDIF, nous devons recharger l’application pour vous garantir le bon fonctionnement du portail.',
                            (error: Error, result: any): void => {
                                if (result) {
                                    location.reload();
                                }
                            });
                    }
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * param currentHash
     * param newHash
     * returns {boolean}
     */
    private hasHashChanged(newHash: string): boolean {
        if (!this.currentHash || !this.currentHash.length) {
            return false;
        }

        return this.currentHash !== newHash;
    }
}
