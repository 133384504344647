import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from 'src/app/Models/store.model';
import { FirststepService } from 'src/app/services/firststep.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checknim',
  templateUrl: './checknim.component.html',
  styleUrls: ['./checknim.component.css']
})
export class ChecknimComponent implements OnInit, OnDestroy {

  @Output() storeLoaded = new EventEmitter<Store>();

  constructor(private authService: FirststepService) { }

  private storeLoadedUpdate: Subscription;
  isError = false;
  isExisting = false;
  showBtnSpinner = false;

  ngOnInit() {

    this.storeLoadedUpdate = this.authService.getStoreLoadedListner()
      .subscribe(store => {
        this.showBtnSpinner = false;
        if (store == null) {
          this.isError = true;
        } else if (!store.nim) {
          this.isExisting = true;
        } else {
          this.storeLoaded.emit(store);
        }
      }, err => {
        this.isError = true;
        this.showBtnSpinner = false;
      });
      this.authService.authCheck();
  }

  checkNim(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.isError = false;
    this.isExisting = false;
    this.showBtnSpinner = true;
    this.authService.checkNim(form.value.nim, form.value.password);
  }

  ngOnDestroy() {
    if (this.storeLoadedUpdate) {
      this.storeLoadedUpdate.unsubscribe();
    }
  }
}
