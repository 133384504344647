import { Contact } from './contact.model';
import { Address } from './address.model';
import { Warehouse } from './warehouse.model';
import { Hour } from './hours/hours.model';
import { Specification } from './specifications/specification.model';
import { HeadQuarter } from './headquarter.model';
import { NewStatment } from './newStatment.model';
import { Location } from './location.model';

export interface Store {
    nim: string;
    nil: string;
    code: string;
    name: string;
    email: string;
    address: Address;
    location: Location;
    contact: Contact;
    depot: Warehouse;
    isRelay: boolean;
    hasAccount: boolean;
    hours: Hour;
    activities: DataItem[];
    proximities: DataItem[];
    cashDeskSoftwares: DataItem[];
    specification: Specification;
    headQuarter: HeadQuarter;
    tourNumber: string;
    accountCreated?: boolean;
    hasRole?: boolean;
    pending: PendingEmail;
    proDelivery: StoreDeliveryConfig;
    isReadOnly: boolean;
    ibanInfo: StoreIbanInfo;
}

export interface StoreIbanInfo {
    status: number;
    rib: string;
}

export interface StoreDeliveryConfig {
    hour: number;
    period: number;
}

export interface StoreContact {
    nim: string;
    commercialRecipients: string[];
    frontDeskRecipients: string[];
}

export interface PendingEmail {
    date: Date;
    email: string;
}

export interface DataItem {
    id: string;
    name: string;
    rank?: number;
}

export class StoreAndPendingChange {
    store: Store;
    changes: NewStatment[];
}

export enum ActivityType {
    Activity = 1,
    Proximity
}
