export class MonthPeriode {
    from: string;
    to: string;
    month: string;
    year: string;

    constructor(month: string, year: string, from: string, to: string) {
        this.month = month;
        this.year = year;
        this.from = from;
        this.to = to;
    }

    displayText(onlyYear: boolean = false): string {
        if (onlyYear) {
            return `${this.year}`;
            return;
        }
        return `${this.month} ${this.year}`;
    }
}
