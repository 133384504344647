import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { AuthService } from 'src/app/services/auth-service';
import { Issue } from 'src/app/Models/issue.model';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { BL } from 'src/app/Models/bl.model';
import * as moment from 'moment';

@Component({
  selector: 'app-new-bl',
  templateUrl: './new-bl.component.html',
  styleUrls: ['./new-bl.component.css']
})
export class NewBLComponent implements OnInit, OnDestroy {

  constructor(private searchService: SearchService, private authService: AuthService) { }

  @Input() bls: BL[] = [];
  @Output() blSelected = new EventEmitter<BL[]>();
  @Output() closeModalEvent = new EventEmitter();

  @ViewChild('searchInput') searchInput: HTMLElement;

  private publicationSub: Subscription;
  private searchSub: Subscription;
  isSearching: Boolean = false;
  isLoading: Boolean = false;
  toAdd: BL[] = [];
  issues: Issue[] = [];

  notAllowed = false;

  keyUp = new Subject<string>();
  txtSearch = '';
  isAllowed = true;
  txtError = '';

  ngOnInit() {
    this.searchSub = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.doSearchFromString(value));

    this.publicationSub = this.searchService.getPublicationsUpdateListener()
      .subscribe(issuesResult => {
        this.isSearching = false;
        this.isLoading = false;

        if (issuesResult && issuesResult.length) {
          if (this.bls.filter(b => b.codif === issuesResult[0].title.titleCodif).length > 0) {

            this.isAllowed = false;
            this.txtError = 'Codif déjà existé';
            return;
          }

          this.txtSearch = issuesResult[0].title.name;
          if (issuesResult[0].pdifInfo
            && issuesResult[0].pdifInfo.blockAddToBl) {
            this.notAllowed = true;
          } else {
            this.issues = issuesResult;
          }
        }
      });
  }

  getIssueStatus(item: Issue): string {
    if (!item) {
      return '';
    }
    const now = moment();
    if (moment(item.releaseDate) <= now
      && (!item.returnDate || moment(item.returnDate) > now)) {
      return '<span class="badge badge-warning font-w-400">EN VENTE</span>';
    }
    if (moment(item.releaseDate) > now) {
      return '<span class="badge badge-primary font-w-400">PROCHAINE</span>';
    }
    return '<span class="badge badge-secondary font-w-400">RELEVÉ</span>';
  }

  issueAllowed(item: Issue) {
    if (!this.notAllowed || !item) {
      return false;
    }

    const now = moment();
    if (moment(item.releaseDate) > now) {
      return true;
    }

    if (!item.pdifInfo) {
      return true;
    }

    return item.supplied;
  }

  onselect(selectedIssue: Issue) {

    if (selectedIssue) {
      return;
    }
    this.isSearching = false;
    this.txtSearch = selectedIssue.titleName;
    const codif = selectedIssue.title == null ? '' : selectedIssue.title.codif;

    this.searchService.getPublicationsWithNext(codif, this.authService.currentNim);
  }

  doSearch(form: NgForm) {

    const searchInput = form ? form.value.searchInput : '';

    this.keyUp.next(searchInput);
  }

  private doSearchFromString(text: string) {

    if (!text || !text.length || text.length === 1) {
      this.isSearching = false;
      this.isLoading = false;
      return;
    }
    this.isAllowed = true;
    this.notAllowed = false;
    this.issues = [];
    this.isLoading = true;
    const searchInput = text;

    if (searchInput === '') {
      this.isSearching = false;
      this.isLoading = false;
      return;
    }

    if (this.bls.filter(b => b.codif === searchInput).length > 0) {

      this.isAllowed = false;
      this.txtError = 'Ce titre existe dans votre BL.';
      return;
    }
    this.isSearching = true;
    this.searchService.getPublicationsWithNext(searchInput, this.authService.currentNim);
  }

  ngOnDestroy() {
    if (this.publicationSub) {
      this.publicationSub.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  onChange(event, item: Issue) {
    if (!event || !event.target) {
      return;
    }

    const quantity = parseInt(event.target.value, 0);
    if (isNaN(quantity) || quantity < 0) {
      return;
    }
    const index = this.toAdd.findIndex(i => i.id === item.id);
    if (index >= 0) {
      const existing = this.toAdd[index];
      if (quantity > 0) {
        existing.received = quantity;
      } else {
        this.toAdd.splice(index, 1);
      }
    } else if (quantity > 0) {
      this.toAdd.push({
        quantity: 0,
        received: event.target.value,
        gc: '',
        cr: '',
        cs: '',
        id: item.id,
        cover: item.cover,
        price: item.price.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        codif: item.title.titleCodif,
        family: item.title.category.initials,
        number: item.referenceId,
        title: item.title.name,
        webLink: item.webLink,
        isNew: true,
        dayCode: item.title.isDaily ? '0' : '',
        // --- unsold ---
        priority: null,
        unsolt: null,
      });
    }
  }

  setBL() {

    if (!this.toAdd || !this.toAdd.length) {
      return;
    }
    this.blSelected.emit(this.toAdd);
  }
}
