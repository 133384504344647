import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { PressStatement } from 'src/app/Models/statements/press.model';
import { Subscription } from 'rxjs';
import { ArchivesService } from 'src/app/services/archives.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-pressstatmentforms',
  templateUrl: './pressstatmentforms.component.html',
  styleUrls: ['./pressstatmentforms.component.css']
})
export class PressstatmentformsComponent implements OnInit, OnDestroy {

  private pressDocumentSubscription: Subscription;
  fileName = `releve-presse-`;
  print = false;
  pressStatementDate = moment();
  statement: PressStatement;
  isLoading = true;
  selectedNim: string = null;
  selectedCode = '';

  @ViewChild('content') modalLoading: ElementRef;

  constructor(private archiveService: ArchivesService,
    private route: ActivatedRoute,
    private ngbModalService: NgbModal,
    private authService: AuthService,
    private ngbModalConfig: NgbModalConfig,
    private router: Router) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('printOnly') != null) {
      this.print = this.route.snapshot.paramMap.get('printOnly') === 'true';
    }

    this.selectedCode = this.route.snapshot.queryParams['selectedCode'];
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    const nim = this.selectedNim || this.authService.currentNim;

    let date = '';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.pressStatementDate = moment(date).locale('fr');
    }

    this.pressDocumentSubscription = this.archiveService.getPressDocumentListner()
      .subscribe(doc => {
        if (doc != null) {
          this.statement = doc;
          if (doc.providers && doc.providers.length) {
            this.fileName += `${date}-R${doc.providers[0].week}.pdf`;
          } else {
            this.fileName += `${date}.pdf`;
          }
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF(true);
            }, 1000);
          }
          this.isLoading = false;
        }
      }, err => {
        this.closeModal();
        this.isLoading = false;
      });

      if (date && date.length) {
        this.archiveService.getPressDocument(date, nim, this.selectedCode);
      }
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  goBack() {
    const date = this.pressStatementDate.format('MM/YYYY');
    const queryParam = { };

    if (this.selectedNim && this.selectedNim.length) {
      queryParam['selectedNim'] = this.selectedNim || '';
    }

    if (this.selectedCode && this.selectedCode.length) {
      queryParam['selectedCode'] = this.selectedCode || '';
    }

    this.router.navigate([`/archives/${date}`], { queryParams: queryParam });
  }

  printPressStatment() {
    window.print();
  }

  downloadToPDF(closeAfterPrint = false) {
    this.print = true;
    this.archiveService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  downloadToXML() {
    this.archiveService.downloadToXML(this.pressStatementDate.format('YYYY-MM-DD'),
            this.authService.currentNim, 'press')
      .subscribe(result => {
        const name = this.fileName.replace('.pdf', '.xml');
        saveAs(result, name);
      }, err => {
      });
  }

  ngOnDestroy() {
    if (this.pressDocumentSubscription) {
      this.pressDocumentSubscription.unsubscribe();
    }
  }

}
