import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WarehouseHolidaysService } from '../services/warehouse-holidays.service';
import { WarehouseHoliday, WarehouseHolidayDetail } from '../Models/warehouse-holiday.model';
import { AuthService } from '../services/auth-service';
import * as moment from 'moment';
import { Sweetalert } from '../shared/sweetAlert';
import { Subscription } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-warehouse-holidays',
  templateUrl: './warehouse-holidays.component.html',
  styleUrls: ['./warehouse-holidays.component.css']
})
export class WarehouseHolidaysComponent implements OnInit, OnDestroy {

  holidaysSubs: Subscription;
  statusSubs: Subscription;

  holidays: WarehouseHoliday[];
  selectedHoliday: WarehouseHoliday;
  showSpinner = true;

  holidayName: string;
  holidayDate: NgbDateStruct;
  minDay: NgbDateStruct;

  selectedYear: number = new Date().getFullYear();

  constructor(private ngbModalService: NgbModal,
    private holidayService: WarehouseHolidaysService,
    private sweet: Sweetalert,
    private authService: AuthService) { }

  holidayStatus(holiday: WarehouseHoliday): number {
    if (!holiday) {
      return -1;
    }
    const mnt = moment(holiday.date);
    const minDate = moment().subtract(-7, 'day');
    if (!mnt.isValid()
      || mnt.isBefore(minDate, 'day')) {
      return -1;
    }

    return holiday.status;
  }

  showStatus(): boolean {
    return environment.enablePdvStatus;
  }

  holidayNimStatus(detail: WarehouseHolidayDetail): string {
    if (!detail) {
      return '—';
    }
    switch (detail.status) {
      case 0:
        return 'fermé';
      case 1:
        return 'ouvert';
      case 2:
        return 'en attente';
      default:
        return '—';
    }
  }

  ngOnInit() {
    const minDate = moment().add(1, 'day');
    this.minDay = {
      day: minDate.date(),
      month: minDate.month() + 1,
      year: minDate.year()
    };
    this.holidaysSubs = this.holidayService.getHolidaysUpdateListener()
      .subscribe(result => {
        this.holidays = result;
        this.showSpinner = false;
      });
    this.statusSubs = this.holidayService.getStatusUpdateListener()
      .subscribe(result => {
        this.showSpinner = false;
        if (result) {
          this.closeModal();
          this.loadData();
        } else {
          this.sweet.warning('Attention !', 'Nous n’avons pas pu donner suite à votre demande.');
        }
      });
    this.loadData();
  }

  onYearChange(year) {
    this.selectedYear = year;
    this.loadData(year);
  }

  loadData(year: number = null) {
    this.showSpinner = true;
    const selectedYear = year || this.selectedYear;
    this.holidayService.getHolidays(this.authService.currentNim, selectedYear);
  }

  addDate() {
    if (this.showSpinner) {
      return;
    }
    if (!this.holidayName || !(this.holidayName.trim()).length) {
      this.sweet.warning('Attention', 'Vous devez fournir une désignation.');
      return;
    }
    if (!this.holidayDate) {
      this.sweet.warning('Attention', 'Vous devez fournir une date.');
      return;
    }
    this.showSpinner = true;
    const date = `${this.holidayDate.year}-${this.holidayDate.month}-${this.holidayDate.day}T00:00:00Z`;
    this.holidayService.addHolidays(this.authService.currentNim, this.holidayName, date);
  }

  start(item: WarehouseHoliday) {
    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.holidayService.changeStatus(this.authService.currentNim, item.id);
  }

  stop(item: WarehouseHoliday) {
    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.holidayService.changeStatus(this.authService.currentNim, item.id);
  }

  openModal(modal, index: number) {
    if (index < 0) {
      this.ngbModalService.open(modal, { size: 'lg' });
      return;
    }
    this.selectedHoliday = this.holidays[index];
    this.ngbModalService.open(modal, { size: 'lg' });
  }

  getYears(): number[] {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1];
  }

  closeModal() {
    this.selectedHoliday = null;
    this.ngbModalService.dismissAll();
  }

  exportDataToCSV() {
    if (!this.selectedHoliday
      || !this.selectedHoliday.details
      || !this.selectedHoliday.details.length) {
      return;
    }
    const details = this.selectedHoliday.details;
    const date = moment(this.selectedHoliday.date);
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `pdif-jourferie-${date.format('YYYY-MM-DD')}`,
      filename: `pdif-jourferie-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = details.map((v, i) => {
      return { Nim: v.nim,
        nil: v.nil,
        Nature: v.nature || '',
        telephone: v.phone || '',
        statut: this.holidayNimStatus(v), Etat: (v.comment || '') };
    });
    csvExporter.generateCsv(data);
  }

  ngOnDestroy() {
    this.closeModal();
    if (this.holidaysSubs) {
      this.holidaysSubs.unsubscribe();
    }
    if (this.statusSubs) {
      this.statusSubs.unsubscribe();
    }
  }

}
