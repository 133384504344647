import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPopperModule } from 'ngx-popper';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppComponent } from './app.component';
import { HeaderAppComponent } from './header-app/header-app.component';
import { FooterAppComponent } from './footer-app/footer-app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { Page401Component } from './auth/page401/page401.component';
import { SearchComponent } from './search/search.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReorderComponent } from './reorder/reorder.component';
import { ForgottenComponent } from './forgotten/forgotten.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { UnsodComponent } from './unsod/unsod.component';
import { ClaimsComponent } from './claims/claims.component';
import { PlaceholderLoadingComponent } from './controls/placeholder-loading/placeholder-loading.component';
import { ImageLoadingComponent } from './controls/image-loading/image-loading.component';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { FilterMultiPipe } from './shared/pipes/filter.pipe';
import { DatePipe } from './shared/pipes/date-formatter.pipe';
import { DateToNowPipe } from './shared/pipes/date-toNow.pipe';
import { CurrencyFormatter, NumberFormatter } from './shared/pipes/currency-formatter.pipe';
import { AuthorizedLabel } from './shared/pipes/authorized-label-formatter.pipe';
import { ErrorInterceptor } from './error-interceptor';
import { ErrorComponent } from './error/error.component';
import { SearchAutocompleteComponent } from './controls/search-autocomplete/search-autocomplete.component';
import { ChartService } from './services/chart.service';
import { HolidaysComponent } from './holidays/holidays.component';
import { UpdateserviceComponent } from './updateservice/updateservice.component';
import { ArticlesComponent } from './articles/articles.component';
import { NewRequestModalComponent } from './controls/new-request-modal/new-request-modal.component';
import { TitleLoadingComponent } from './controls/title-loading/title-loading.component';
import { ArticleModalComponent } from './controls/article-modal/article-modal.component';
import { HistoryArchivesComponent } from './controls/history-archives/history-archives.component';
import { PerformanceComponent } from './performance/performance.component';
import { TitleComponent } from './title/title.component';
import { BreadcrumbComponent } from './controls/breadcrumb/breadcrumb.component';
import { ChecknimComponent } from './auth/signup/checknim/checknim.component';
import { CreateaccountComponent } from './auth/signup/createaccount/createaccount.component';
import { ArchwizardModule } from 'ng2-archwizard';
import { NotificationsComponent } from './header-app/notifications/notifications.component';
import { AlertsComponent } from './alerts/alerts.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { AlertHistoryComponent } from './alerts/alert-history/alert-history.component';
import { ZoomCoverComponent } from './controls/zoom-cover/zoom-cover.component';
import { ProfilComponent } from './profil/profil.component';
import { PdvComponent } from './pdv/pdv.component';
import { SocialReasonComponent } from './pdv/social-reason/social-reason.component';
import { WarehouseComponent } from './dashboard/warehouse/warehouse.component';
import { DepositComponent } from './pdv/deposit/deposit.component';
import { ContactComponent } from './contact/contact.component';
import { MapComponent } from './controls/map/map.component';
import { IssuesCollectionComponent } from './issues-collection/issues-collection.component';
import { AreaComponent } from './pdv/area/area.component';
import { TimingComponent } from './pdv/timing/timing.component';
import { TimepickerComponent } from './controls/timepicker/timepicker.component';
import { PdvbaseComponent } from './pdv/pdvbase/pdvbase.component';
import { TimingpopupComponent } from './pdv/timing/timingpopup/timingpopup.component';
import { ActivitiesComponent } from './pdv/activities/activities.component';
import { SortablejsModule } from 'angular-sortablejs';
import { HelpdeskComponent } from './admin/helpdesk/helpdesk.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DateUnixPipe } from './shared/pipes/date-unix.pipe';
import { SignupstatsComponent } from './admin/signupstats/signupstats.component';
import { LostpasswordComponent } from './auth/lostpassword/lostpassword.component';
import { ImgZoomComponent } from './controls/img-zoom/img-zoom.component';
import { HtmlSanitizerPipe } from './shared/pipes/html-sanitizer.pipe';
import { EditortitlesComponent } from './admin/editortitles/editortitles.component';
import { OrdersComponent } from './admin/orders/orders.component';

import { CookieService } from 'ngx-cookie-service';
import { StorecomComponent } from './admin/storecom/storecom.component';
import { RenewpasswordComponent } from './auth/renewpassword/renewpassword.component';
import { ArchivesComponent } from './archives/archives.component';
import { SupportComponent } from './support/support.component';
import { AccountstatmentformsComponent } from './archives/account/accountstatmentforms.component';
import { PressStatmentComponent } from './controls/press-statment/press-statment.component';
import { PressstatmentformsComponent } from './archives/press/pressstatmentforms.component';
import { AdCampaignComponent } from './admin/ad-campaign/ad-campaign.component';
import { FactureComponent } from './controls/facture/facture.component';


import { NgSelectModule } from '@ng-select/ng-select';
import { CguComponent } from './auth/cgu/cgu/cgu.component';
import { SortPath } from './shared/pipes/sort-path.pipe';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { StatementsComponent } from './pdv/statements/statements.component';
import { BillComponent } from './archives/bill/bill.component';
import { AvoirComponent } from './archives/avoir/avoir.component';
import { MessagingComponent } from './messaging/messaging.component';
import { ExploreComponent } from './ordering/explore/explore.component';
import { CategoryComponent } from './ordering/category/category.component';
import { CategoryPanelComponent } from './controls/category-panel/category-panel.component';
import { CategoriesNavbarComponent } from './header-app/categories-navbar/categories-navbar.component';
import { UserBasketComponent } from './ordering/user-basket/user-basket.component';
import { IssueCtrlComponent } from './controls/issue-ctrl/issue-ctrl.component';
import { OrderingAddToBasketComponent } from './controls/ordering-add-to-basket/ordering-add-to-basket.component';
import { ProComponent } from './admin/pro/pro.component';
import { OrderTrackingComponent } from './admin/order-tracking/order-tracking.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CustomerCodeComponent } from './admin/customer-code/customer-code.component';
import { RegisterComponent } from './auth/register/register.component';
import { WarehouseAlertsComponent } from './messaging/alerts/warehousealerts.component';
import { TitreexpressComponent } from './admin/titreexpress/titreexpress.component';
import { TecreateComponent } from './admin/titreexpress/tecreate/tecreate.component';
import { DifferedreturnComponent } from './archives/differedreturn/differedreturn.component';
import { BciDetailComponent } from './archives/bci/bci.detail.component';
import { DeliveryDetailComponent } from './archives/delivery/delivery.detail.component';
import { LogisticalertsComponent } from './messaging/alertsLogistic/logisticalerts/logisticalerts.component';
import { TitreexpressDetailComponent } from './controls/titreexpress-detail/titreexpress-detail.component';
import { CompensationComponent } from './archives/compensation/compensation.component';
import { TepdfComponent } from './admin/titreexpress/tepdf/tepdf.component';
import { ActivityReportComponent } from './admin/activity-report/activity-report.component';
import { FiabilityScanComponent } from './archives/fiability-scan/fiability-scan.component';
import { AdminComponent } from './messaging/admin/admin.component';
import { WarehouseHolidaysComponent } from './warehouse-holidays/warehouse-holidays.component';
import { ReopenComponent } from './reopen/reopen.component';
import { ReopendetailComponent } from './archives/reopendetail/reopendetail.component';
import { GmsComponent } from './admin/gms/gms.component';
import { NewBLComponent } from './controls/new-bl/new-bl.component';
import { ContactsComponent } from './admin/contacts/contacts.component';
import { RuptureComponent } from './controls/rupture/rupture.component';
import { SalesChartComponent } from './controls/sales-chart/sales-chart.component';
import { TitreinfoComponent } from './admin/titreinfo/titreinfo.component';
import { StoreFilterPipe } from './shared/pipes/store-filter.pipe';
import { SondagesComponent } from './admin/sondages/sondages.component';
import { ArchivesWarehouseComponent } from './archives-warehouse/archives-warehouse.component';
import { Top10titlesComponent } from './admin/top10titles/top10titles.component';
import { WeeklyformsComponent } from './archives/weeklyforms/weeklyforms.component';
import { PendingrequestComponent } from './updateservice/pendingrequest/pendingrequest.component';
import { AssortimentlistComponent } from './assortimentlist/assortimentlist.component';
import { BaseupdateserviceComponent } from './shared/baseupdateservice/baseupdateservice.component';
import { AssortbulkComponent } from './admin/assortbulk/assortbulk.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RibComponent } from './pdv/rib/rib.component';
import { PlafonnementComponent } from './assortimentlist/plafonnement/plafonnement.component';
import { FullDashboardComponent } from './dashboard/assort/full-dashboard/full-dashboard.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderAppComponent,
    FooterAppComponent,
    LoginComponent,
    SignupComponent,
    Page401Component,
    SearchComponent,
    DashboardComponent,
    ReorderComponent,
    ForgottenComponent,
    SpinnerComponent,
    UnsodComponent,
    ClaimsComponent,
    PlaceholderLoadingComponent,
    ImageLoadingComponent,
    FilterPipe,
    FilterMultiPipe,
    DatePipe,
    DateToNowPipe,
    CurrencyFormatter,
    NumberFormatter,
    ErrorComponent,
    SearchAutocompleteComponent,
    HolidaysComponent,
    BciDetailComponent,
    DeliveryDetailComponent,
    UpdateserviceComponent,
    ArticlesComponent,
    NewRequestModalComponent,
    TitleLoadingComponent,
    ArticleModalComponent,
    HistoryArchivesComponent,
    AuthorizedLabel,
    PerformanceComponent,
    ArticleModalComponent,
    TitleComponent,
    BreadcrumbComponent,
    ChecknimComponent,
    CreateaccountComponent,
    NotificationsComponent,
    AlertsComponent,
    WarehouseAlertsComponent,
    IncidentsComponent,
    AlertHistoryComponent,
    ZoomCoverComponent,
    ProfilComponent,
    PdvComponent,
    SocialReasonComponent,
    PdvbaseComponent,
    WarehouseComponent,
    DepositComponent,
    ContactComponent,
    MapComponent,
    IssuesCollectionComponent,
    AreaComponent,
    TimingComponent,
    TimepickerComponent,
    TimingpopupComponent,
    ActivitiesComponent,
    HelpdeskComponent,
    DateUnixPipe,
    SignupstatsComponent,
    LostpasswordComponent,
    ImgZoomComponent,
    HtmlSanitizerPipe,
    EditortitlesComponent,
    StorecomComponent,
    RenewpasswordComponent,
    ArchivesComponent,
    SupportComponent,
    AccountstatmentformsComponent,
    PressStatmentComponent,
    PressstatmentformsComponent,
    AdCampaignComponent,
    CguComponent,
    SortPath,
    UnauthorizedComponent,
    StatementsComponent,
    BillComponent,
    FactureComponent,
    AvoirComponent,
    MessagingComponent,
    ExploreComponent,
    CategoryComponent,
    CategoryPanelComponent,
    CategoriesNavbarComponent,
    UserBasketComponent,
    OrdersComponent,
    IssueCtrlComponent,
    OrderingAddToBasketComponent,
    ProComponent,
    OrderTrackingComponent,
    CustomerCodeComponent,
    RegisterComponent,
    TitreexpressComponent,
    TecreateComponent,
    DifferedreturnComponent,
    LogisticalertsComponent,
    TitreexpressDetailComponent,
    CompensationComponent,
    TepdfComponent,
    ActivityReportComponent,
    FiabilityScanComponent,
    AdminComponent,
    WarehouseHolidaysComponent,
    ReopenComponent,
    ReopendetailComponent,
    GmsComponent,
    NewBLComponent,
    ContactsComponent,
    RuptureComponent,
    SalesChartComponent,
    TitreinfoComponent,
    StoreFilterPipe,
    SondagesComponent,
    ArchivesWarehouseComponent,
    Top10titlesComponent,
    WeeklyformsComponent,
    PendingrequestComponent,
    AssortimentlistComponent,
    BaseupdateserviceComponent,
    AssortbulkComponent,
    PrivacyPolicyComponent,
    RibComponent,
    PlafonnementComponent,
    FullDashboardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularEditorModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    AngularFontAwesomeModule,
    DataTablesModule,
    NgxPopperModule,
    ArchwizardModule,
    SortablejsModule.forRoot({
      animation: 200,
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      maxOpened: 1,
      timeOut: 1000,
      extendedTimeOut: 0,
      enableHtml: true,
      closeButton: true,
      autoDismiss: true,
      preventDuplicates: true,
      // positionClass: 'toast-bottom-right'
    }),
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
    ChartService,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
