import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { Subject, Subscription } from 'rxjs';
import { TitreexpressService } from 'src/app/services/titreexpress.service';
import { TitreExpress, TitreExpressItem, TitreExpressSendStatus } from 'src/app/Models/admin/titreexpress.model';
import { TitreExpressToPush, Supplement } from 'src/app/Models/admin/titreexpressToPush.model';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { DataTableDirective } from 'angular-datatables';
import { IssuesCollectionComponent } from 'src/app/issues-collection/issues-collection.component';
import { AdminTitle } from 'src/app/Models/admin/title.modal';
import { TitreExpressConfig } from 'src/app/Models/admin/titreexpressConfig.model';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-titreexpress',
  templateUrl: './titreexpress.component.html',
  styleUrls: ['./titreexpress.component.css']
})
export class TitreexpressComponent implements OnInit, OnDestroy, AfterViewInit {

  notificationType = '';
  showSpinner = false;
  savingSpinner = false;
  private titlesSub: Subscription;
  private itemSub: Subscription;
  private itemSendSub: Subscription;

  private openedModal: NgbModalRef;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  showTableSpinner = false;
  selectedItem: TitreExpress;
  items: TitreExpress[] = [];
  sendingItems = [];

  firstSend = { hour: 17, minute: 0 };
  secondSend = { hour: 20, minute: 0 };
  importedTitles: AdminTitle[] = [];

  config: TitreExpressConfig;

  constructor(private ngbModalService: NgbModal,
    private dtlanguages: DtLanguages,
    private titleExpressService: TitreexpressService,
    private router: Router,
    private sweet: Sweetalert) { }

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };

    this.titlesSub = this.titleExpressService.getListRequestsUpdateListner()
      .subscribe(items => {
        this.showTableSpinner = false;
        items.forEach(i => {
          if (i.number === 0) {
            i.number = i.numberInDay;
          }
        });
        this.items = items;
        this.rerender();
      }, err => {
        this.showTableSpinner = false;
        this.items = [];
      });

    this.itemSub = this.titleExpressService.getItemDetailRequestsUpdateListner()
      .subscribe(item => {
        this.showTableSpinner = false;
        this.selectedItem = item;
      }, err => {
        this.showTableSpinner = false;
        this.selectedItem = null;
      });

    this.itemSendSub = this.titleExpressService.sendItemRequestsUpdateListner()
      .subscribe(status => {
        if (!status) {
          return;
        }
        const index = this.sendingItems.indexOf(status.id, 0);
        if (index > -1) {
          this.sendingItems.splice(index, 1);
          const item = this.items.find(i => i.id === status.id);
          if (item) {
            item.sendStatus = { date: moment().locale('fr').format('YYYY-MM-DDTHH:mm:ss'), status: true } as TitreExpressSendStatus;
          }
        }
      }, err => {

      });

    this.getItems();
    this.getConfig();
  }

  getItemSendStatus(id: string): boolean {
    if (this.sendingItems.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  displaySendButton(item: TitreExpress): boolean {
    if (!item) {
      return false;
    }

    const mnt = moment(item.sendDate || item.date);

    if (!mnt.isValid()) {
      return true;
    }

    if (item.sendStatus) {
      return !item.sendStatus.status;
    }

    return mnt.isSame(moment(), 'day');
  }

  getItemDateToString(item: TitreExpress): string {

    if (!item) {
      return '';
    }
    if (item.sendStatus) {
      const mnt = moment(item.sendStatus.date);
      if (!mnt.isValid()) {
        return '';
      }
      if (item.sendStatus.status) {
        return `envoyé le ${mnt.locale('fr').format('DD MMM YYYY')}`;
      }
      return `prévu pour le ${mnt.locale('fr').format('DD MMM YYYY')}`;
    } else {
      const mnt = moment(item.date);
      return `prévu pour le ${mnt.locale('fr').format('DD MMM YYYY')}`;
    }
  }

  getItems() {
    this.showTableSpinner = true;
    this.titleExpressService.getItems();
  }

  getItemDetail(id: string) {
    this.selectedItem = null;
    this.showTableSpinner = true;
    this.titleExpressService.getItemDetail(id);
  }
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  saveItem(titreexpress) {
    if (!titreexpress) {
      return;
    }

    const itemToPush = titreexpress.getItemToSave();
    this.createItem(itemToPush);

  }

  saveConfig() {
    if (!this.isTimeSendChanged() && !this.config.hasChanges) {
      return;
    }

    this.config.sendHours = [this.firstSend.hour, this.secondSend.hour];

    this.savingSpinner = true;
    this.titleExpressService.updateConfig(this.config)
      .subscribe(res => {
        this.savingSpinner = false;
        if (res) {
          this.sweet.success('Succès !', 'Modification envoyée avec succès !');
        } else {
          this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
        }
      },
        err => {
          this.savingSpinner = false;
          this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
        });
  }

  createItem(itemToPush) {
    this.savingSpinner = true;
    this.titleExpressService.saveItem(itemToPush)
      .subscribe(res => {
        this.savingSpinner = false;

        if (res) {
          this.sweet.success('Succès !', 'Notification enregistrée avec succès !');
          this.getItems();
        } else {
          this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
        }
        this.closeModal();
      },
        err => {
          this.savingSpinner = false;
          this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
        });
  }

  closeModal() {
    this.selectedItem = null;
    this.ngbModalService.dismissAll();
  }
  closeEmailsModal() {
    if (!this.openedModal) {
      return;
    }
    this.openedModal.close();
  }
  openModal(content, id: string = null) {
    if (id && id.length) {
      this.getItemDetail(id);
    }
    this.openedModal = this.ngbModalService.open(content, { size: 'lg' });
  }

  openItemDetail(id: string) {
    if (id && id.length) {
      this.getItemDetail(id);
    }
    this.router.navigate([`/titreexpress/${id}`]);
  }

  performSend(id: string) {
    if (!id || !id.length) {
      return;
    }
    this.sendingItems.push(id);
    this.titleExpressService.sendItem(id);
  }

  getType(item: TitreExpressItem) {
    if (!item) {
      return '';
    }

    switch (item.type) {
      case 0:
        return 'Changement de prix';
      case 1:
        return 'Supplément non encarté';
      case 2:
        return 'Modification de durée de mise en vente';
      default:
        return 'Avis important';
    }
  }

  getConfig() {

    this.titleExpressService.getConfig().subscribe(response => {
      if (response.result) {
        this.config = response.result;
        if (this.config && this.config.sendHours && this.config.sendHours.length === 2) {
          this.firstSend = { hour: this.config.sendHours[0], minute: 0 };
          this.secondSend = { hour: this.config.sendHours[1], minute: 0 };
        }
      }
    },
      error => {
        this.config = null;
      });
  }

  onFileSelect(input: HTMLInputElement, type: number, config: TitreExpressConfig) {

    const self = this;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = function (e) {
        const regexNIM = /\d{7}/i;
        // tslint:disable-next-line:max-line-length
        const regexEMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        // import form CSV
        const textFromFileLoaded = fileReader.result as string;
        if (type === 0) {
          config.subscribers = textFromFileLoaded.split(/\r?\n/).map(line => {
            return line.replace('"', '').split(/,|;/ig).map(t => t.trim());
          }).reduce((a, b) => a.concat(b)).filter(s => regexEMAIL.test(s));
          config.hasChanges = config.subscribers.length > 0;
        } else if (type === 2) {
          config.priceEmails = textFromFileLoaded.split(/\r?\n/).map(line => {
            return line.replace('"', '').split(/,|;/ig).map(t => t.trim());
          }).reduce((a, b) => a.concat(b)).filter(s => regexEMAIL.test(s));
          config.hasChanges = config.priceEmails.length > 0;
        }
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  isTimeSendChanged(): boolean {
    if (this.config && this.config.sendHours && this.config.sendHours.length === 2) {
      if (this.firstSend.hour === this.config.sendHours[0]
        && this.secondSend.hour === this.config.sendHours[1]) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  ngOnDestroy() {
    this.closeModal();
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.titlesSub) {
      this.titlesSub.unsubscribe();
    }
    if (this.itemSub) {
      this.itemSub.unsubscribe();
    }
    if (this.itemSendSub) {
      this.itemSendSub.unsubscribe();
    }
  }
}
