import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { StoreAssortimentStatus } from 'src/app/Models/storeAssortiment.model';
import { AuthService } from 'src/app/services/auth-service';
import { PendingrequestService } from 'src/app/services/pendingrequest.service';
import { StoreAssortimentService } from 'src/app/services/store-assortiment.service';

@Component({
  selector: 'app-full-dashboard',
  templateUrl: './full-dashboard.component.html',
  styleUrls: ['./full-dashboard.component.css']
})
export class FullDashboardComponent implements OnInit {

  private assortimentRequestSubs: Subscription;
  assortStatus: StoreAssortimentStatus = null;
  
  constructor(private authService: AuthService,
    private pendingRequestService: PendingrequestService,
    private ngbModalService: NgbModal,
    private assortimentService: StoreAssortimentService
  ) { }

  ngOnInit() {
    this.assortimentRequestSubs = this.assortimentService.getAssortimentDashboardListener()
      .subscribe(result => {
        this.assortStatus = result || null;
        if (result && result.pressConnectAllowed) {
          this.pendingRequestService.getPendingRequestCounter(this.authService.currentNim);
        }
      });

      this.assortimentService.getAssortimentDashboard(this.authService.currentNim, false);
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  get displayLot2c(): boolean {
    return this.assortStatus !== null
    && this.assortStatus.displayDashboard;
  }

  openModal(content) {
    this.ngbModalService.open(content);
  }

  get displayAssortimentDashboard(): number {
    if (this.assortStatus !== null) {
        return this.assortStatus.dashboardType;
      }

    return -1;
  }

  ngOnDestroy(): void {
    if (this.assortimentRequestSubs) {
      this.assortimentRequestSubs.unsubscribe();
    }
  }

}
