import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth-service';
import { AdminTitle } from 'src/app/Models/admin/title.modal';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditortitleService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  private titlesUpdated = new Subject<AdminTitle[]>();
  private titlesSaveUpdated = new Subject<boolean>();

  searchTitles(codif: string) {
    const url = environment.APIURL + '/api/v4/admin/titles/' + codif;
    this.http.get<{ result: AdminTitle[] }>(url)
      .subscribe(response => {
        if (response) {
          this.titlesUpdated.next(response.result);
        } else {
          this.titlesUpdated.next([]);
        }
      }, err => {
        this.titlesUpdated.next([]);
      });
  }

  getTitlesUpdateListener() {
    return this.titlesUpdated.asObservable();
  }

  saveTitles(titles: AdminTitle[]) {
    // tslint:disable-next-line:max-line-length
    const url = environment.APIURL + '/api/v4/admin/titles';
    this.http.post<{ result: boolean }>(url, titles)
      .subscribe(response => {
        if (response) {
          this.titlesSaveUpdated.next(response.result);
        } else {
          this.titlesSaveUpdated.next(false);
        }
      }, err => {
        this.titlesSaveUpdated.next(false);
      });
  }

  getSaveTitlesUpdateListener() {
    return this.titlesSaveUpdated.asObservable();
  }
}
