import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreContact } from 'src/app/Models/store.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StorecomService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  private storesUpdated = new Subject<StoreContact[]>();
  private storesSaveUpdated = new Subject<boolean>();

  searchStores(nim: string) {
    const url = environment.APIURL + '/api/v4/admin/stores/' + nim;
    this.http.get<{ result: StoreContact }>(url)
      .subscribe(response => {
        if (response) {
          this.storesUpdated.next([response.result]);
        } else {
          this.storesUpdated.next([]);
        }
      }, err => {
        this.storesUpdated.next([]);
      });
  }

  getStoresUpdateListener() {
    return this.storesUpdated.asObservable();
  }

  saveStores(stores: StoreContact[]) {
    // tslint:disable-next-line:max-line-length
    const url = environment.APIURL + '/api/v4/admin/stores';
    this.http.post<{ result: boolean }>(url, stores)
      .subscribe(response => {
        if (response) {
          this.storesSaveUpdated.next(response.result);
        } else {
          this.storesSaveUpdated.next(false);
        }
      }, err => {
        this.storesSaveUpdated.next(false);
      });
  }

  getSaveStoresUpdateListener() {
    return this.storesSaveUpdated.asObservable();
  }

  importStoresRequests(requestType: string, stores: any[]) {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.APIURL}/api/v1/admin/importstorerequests?requestType=${requestType || ''}`;
    this.http.post<{ result: boolean }>(url, stores)
      .subscribe(response => {
        if (response) {
          this.storesSaveUpdated.next(response.result);
        } else {
          this.storesSaveUpdated.next(false);
        }
      }, err => {
        this.storesSaveUpdated.next(false);
      });
  }
}
