import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '../Models/store.model';
import { Subject } from 'rxjs';
import { AuthService } from './auth-service';

@Injectable({
  providedIn: 'root'
})
export class FirststepService {

  constructor(private http: HttpClient, private authService: AuthService) { }
  private P2000KEY = 'P2000AUTH';
  private storeLoadedUpdate = new Subject<Store>();
  private storeCreatedUpdate = new Subject<boolean>();

  OnInit() {

  }

  getStoreLoadedListner() {
    return this.storeLoadedUpdate.asObservable();
  }
  getStoreCreatedListner() {
    return this.storeCreatedUpdate.asObservable();
  }

  authCheck() {
    const authValue = localStorage.getItem(this.P2000KEY);

    if (!authValue) {
      return;
    }
    this.performGet(authValue);
  }

  checkNim(nim: string, password: string) {

    if (!nim || !password) {
      this.storeLoadedUpdate.next(null);
      return;
    }

    const authValue = 'PDif ' + AuthService.base64Encode(nim, password);

    this.performGet(authValue);
  }

  private performGet(authValue: string) {

    const url = environment.APIURL + '/api/v2/users/me/firstlogin';
    const headerOptions = {
      headers: new HttpHeaders({
        'Authorization': authValue
      })
    };
    localStorage.removeItem(this.P2000KEY);
    this.http.get<{ result: Store }>(url, headerOptions)
        .subscribe(response => {
            if (response) {
              this.storeLoadedUpdate.next(response.result);
              localStorage.setItem(this.P2000KEY, authValue);
            } else {
              this.storeLoadedUpdate.next(null);
              localStorage.removeItem(this.P2000KEY);
            }
        },
            error => {
              localStorage.removeItem(this.P2000KEY);
              if (error.status === 409) {
                this.storeLoadedUpdate.next({} as Store);
              } else {
                this.storeLoadedUpdate.next(null);
              }
            });
  }

  createAccount(email: string, password: string, pdifCGU: boolean) {

    const authValue = localStorage.getItem(this.P2000KEY);
    this.performPost(authValue, {email: email, password: password, pdifCGU: pdifCGU});
  }

  linkExistingAccount(nim: string, email: string, password: string) {

    const authValue = 'Basic ' + AuthService.base64Encode(email, password);
    this.performPost(authValue, nim);
  }

  private performPost(authValue: string, data: any, nim: string = null) {

    let url = environment.APIURL + '/api/v2/users/me/firstlogin';
    if (nim) {
      url += '/link/?nim=' + nim;
    }
    const headerOptions = {
      headers: new HttpHeaders({
        'Authorization': authValue
      })
    };

    this.http.post(url, data, headerOptions)
        .subscribe(res => {
          localStorage.removeItem(this.P2000KEY);
          this.storeCreatedUpdate.next(true);
          if (nim) {
            localStorage.setItem(AuthService.KEY_AUTH, authValue);
            this.authService.autoAuthUser(true);
          }
        }, error => {
          this.storeCreatedUpdate.next(false);
        });
  }
}
