import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import { SalesTurnoverV2 } from 'src/app/Models/salesTurnover.model';
import { AuthService } from 'src/app/services/auth-service';
import { ChartService } from 'src/app/services/chart.service';
import { StoreService } from 'src/app/services/store.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sales-chart',
  templateUrl: './sales-chart.component.html',
  styleUrls: ['./sales-chart.component.css']
})
export class SalesChartComponent implements OnInit, OnDestroy {

  loading = false;
  salesSubs: Subscription;

  sales: SalesTurnoverV2[] = [];
  chart: Chart; // This will hold our chart info
  salesLabels: string[];
  salesArr: number[];
  turnover: number[];

  constructor(private authService: AuthService, private storeService: StoreService,
    private chartService: ChartService) { }

  ngOnInit() {
    this.loading = true;
    this.salesSubs = this.chartService.getSalesListener()
      .subscribe((salesResult: SalesTurnoverV2[]) => {
        this.sales = salesResult || [];

        this.turnover = $.map(this.sales, function (v, i) {
          return v.amount;
        });

        this.salesLabels = $.map(this.sales, function (v, i) {
          return moment(v.period).locale('fr').format('MMM YYYY');
        });

        this.salesArr = $.map(this.sales, function (v, i) {
          return v.sales;
        });

        // build chart sales
        this.buildChart('Exemplaires vendus', 'canvas', 'line', '#FF7070', '#FF7070', this.salesLabels, this.salesArr, 'ex');

        // build chart Tournover
        // tslint:disable-next-line:max-line-length
        this.buildChart('Chiffre d’affaires', 'canvas2', 'line', '#0F27AA', '#0F27AA', this.salesLabels, this.turnover, '€');

        this.loading = false;
      });

      this.chartService.getSales(this.authService.currentNim);
  }

  ngOnDestroy() {
    if (this.salesSubs) {
      this.salesSubs.unsubscribe();
    }
  }

  // tslint:disable-next-line:max-line-length
  buildChart(label: string, selector: string, chartType: string, backgroundColor: string, borderColor: string, labels: string[], sales: number[], currency: string = '') {
    this.chart = new Chart(selector, {
      type: chartType,
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: sales,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const str = data.datasets[0].data[tooltipItem.index].toLocaleString();
              return `${tooltipItem.xLabel} : ${str} ${currency}`;
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                const ranges = [
                  { divider: 1e6, suffix: currency.length === 0 ? '' : ` M${currency}` },
                  { divider: 1e3, suffix: currency.length === 0 ? '' : ` k${currency}` }
                ];
                function formatNumber(n) {
                  for (let i = 0; i < ranges.length; i++) {
                    if (n >= ranges[i].divider) {
                      return (n / ranges[i].divider).toLocaleString() + ranges[i].suffix;
                    }
                  }
                  return n;
                }
                return formatNumber(value);
              }
            }
          }]
        }
      }
    });
  }

}
