import { Component, OnInit } from '@angular/core';
import { PdvbaseComponent } from '../pdvbase/pdvbase.component';
import { FormGroup, Validators, NgForm } from '@angular/forms';
import { CustomValidator } from '../../shared/customValidator';
import { LineStatement } from 'src/app/Models/newStatment.model';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent extends PdvbaseComponent implements OnInit {
  aFrom: FormGroup;
  dailiesFrom: FormGroup;
  areaFrom: FormGroup;

  LINEAR_SUFFIX = 'specification.linears.';
  DAILIES_SUFFIX = 'specification.dailies.';
  STANDS_SUFFIX = 'specification.stands.';

  // pendingValue = '';
  pendingValue: LineStatement[];
  itemIndex = 0;
  isPendingChanges = true;
  txtModalTitle = '';

  ngOnInit() {
    super.ngOnInit();
    this.ga.createEventScreenView('Pdv > Surfaces');

    this.aFrom = this.fb.group({
      txtAreaInput: ['', [Validators.required, CustomValidator.numberValidator]],
      txtReserveInput: ['', [Validators.required, CustomValidator.numberValidator]]
    });

    this.dailiesFrom = this.fb.group({
      numberInput: ['', [Validators.required, CustomValidator.numberValidator]]
    });

    this.areaFrom = this.fb.group({
      txtMuralInput: ['', [Validators.required, CustomValidator.numberValidator]],
      txtMuralIlots: ['', [Validators.required, CustomValidator.numberValidator]],
      txtMuralAutres: ['', [Validators.required, CustomValidator.numberValidator]]
    });
  }


  pendingRow(specType: string, rowtype: string) {
    return null;
    // if (!this.pendingChanges || !this.pendingChanges[specType]) {
    //   return null;
    // }
    // const found = this.pendingChanges[specType].find(i => i.value.type === rowtype);
    // if (!found) {
    //   return null;
    // }
    // return found;
  }

  resetForm(form: NgForm) {
    form.reset();
  }

  onSaveArea(event: any, form: NgForm) {

    const ctrlSelling = this.getStatementOrDefault('specification.surfaceArea.selling', 'Surface');

    if (ctrlSelling) {
      if (this.store.specification.surfaceArea.selling !== undefined) {
        ctrlSelling.oldValue = '' + this.store.specification.surfaceArea.selling;
      }
      ctrlSelling.newValue = event.target.txtArea.value;
      ctrlSelling.label = 'Surface de vente';
      this.localStatements.push(ctrlSelling);
    }

    const ctrlReserve = this.getStatementOrDefault('specification.surfaceArea.reserve', 'Surface');

    if (ctrlReserve) {
      if (this.store.specification.surfaceArea.reserve !== undefined) {
        ctrlReserve.oldValue = '' + this.store.specification.surfaceArea.reserve;
      }
      ctrlReserve.newValue = event.target.txtReserve.value;
      ctrlReserve.label = 'Réserve';
      this.localStatements.push(ctrlReserve);
    }

    this.save(this.localStatements);
    this.ga.createEvent('click', 'Timing', 'Modification La Surfaces du point de vente');
    this.isPendingChanges = true;
  }

  onSaveDailies(event: any) {
    let obj = null;

    const txtValue = event.target.txtValue.value;
    if (txtValue.length) {
      obj = this.store.specification.dailies.filter(e => e.type === this.txtModalTitle);
      if (obj.length) {
        this.setDailiesStatement(txtValue);
      } else {
        obj = this.store.specification.stands.filter(e => e.type === this.txtModalTitle);
        if (obj.length) {
          this.setStandsStatement(txtValue);
        }
      }

      this.save(this.localStatements);
      this.isPendingChanges = false;
      super.closeModal();
    }
  }

  private setDailiesStatement(value: string) {
    const current = this.store.specification.dailies[this.itemIndex];

    if (current == null || '' + current.count === value) {
      return;
    }

    const id = this.DAILIES_SUFFIX + this.itemIndex;
    const ctrl = this.getStatementOrDefault(id, 'Linéaire de quotidiens');

    ctrl.newValue = value;
    ctrl.oldValue = '' + current.count;
    ctrl.label = current.type;

    this.localStatements = this.localStatements.filter(a => a.id !== id);
    this.localStatements.push(ctrl);
  }

  private setStandsStatement(value: string) {
    const current = this.store.specification.stands[this.itemIndex];

    if (current == null || '' + current.count === value) {
      return;
    }

    const id = this.STANDS_SUFFIX + this.itemIndex;
    const ctrl = this.getStatementOrDefault(id, 'Tournikiosque');

    ctrl.newValue = value;
    ctrl.oldValue = '' + current.count;
    ctrl.label = current.type;

    this.localStatements = this.localStatements.filter(a => a.id !== id);
    this.localStatements.push(ctrl);
  }

  onSaveLinear(event: any, form: NgForm) {
    const txtWall = event.target.txtMural.value;
    const txtIsland = event.target.txtIlots.value;
    const txtOthers = event.target.txtAutres.value;

    this.setLinearStatement(txtWall, 'wall');
    this.setLinearStatement(txtIsland, 'island');
    this.setLinearStatement(txtOthers, 'other');

    // this.save();
    this.save(this.localStatements);
    this.isPendingChanges = false;
    super.closeModal();
  }

  private setLinearStatement(value: string, col: string) {
    const current = this.store.specification.linears[this.itemIndex];

    if (current == null || current[col] === value) {
      return;
    }

    const id = this.LINEAR_SUFFIX + this.itemIndex + '.' + col;
    const ctrl = this.getStatementOrDefault(id, 'Linéaire de publications');

    ctrl.newValue = value;
    ctrl.oldValue = current[col];
    ctrl.label = current.type + '.' + col;

    this.localStatements = this.localStatements.filter(a => a.id !== id);
    this.localStatements.push(ctrl);
  }

  openModal(modalContent, suffix, index, title) {
    this.ngbModalService.open(modalContent, { size: 'sm' });
    this.itemIndex = index;
    this.txtModalTitle = title;

    if (!this.changes
      || !this.changes.length
      || !this.changes[0]
      || !this.changes[0].changes) {
      return;
    }

    this.pendingValue = this.changes[0].changes.filter(i => (i.id || '').indexOf(suffix + '' + index) >= 0);
  }

  openModalLinear(linearModalContent, index, title) {
    this.ngbModalService.open(linearModalContent, { size: 'lg' });
    this.itemIndex = index;
    this.txtModalTitle = title;

    if (!this.changes
      || !this.changes.length
      || !this.changes[0]
      || !this.changes[0].changes) {
      return;
    }

    this.pendingValue = this.changes[0].changes.filter(i => (i.id || '').indexOf(this.LINEAR_SUFFIX) >= 0);
  }

  getPendingValue(col: string): LineStatement {
    if  (this.pendingValue  !== null && this.pendingValue !== undefined)  {
      const items = this.pendingValue.filter(p => p.id === this.LINEAR_SUFFIX + this.itemIndex + '.' + col);
      if (items.length) {
      return items[0];
      }
    }
    return null;
  }
}
