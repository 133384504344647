export const environment = {
  production: false,
  WEBURL: 'http://localhost:4200/',
  APIURL: 'https://pdif-ws-preprod.francemessagerie.fr',
  APIPDF: 'https://pdif-pdf-ws.francemessagerie.fr/AngularHandler.ashx',
  TrackingId: 'G-YHSRX61P8F',//UA-121779171-1
  appVersion: '2.45b87',
  lastRequestTimestamp: 'lastRequestTimeStamp',
  disableStatements: false,
  disableSupport: false,
  disableStock: false,
  disablePlafond: true,
  disableIGP_CPPAP: {
    startDate: '2022-03-09T00:00:00.000'
  },
  isPdif: true,
  title: 'PDIF : le portail des diffuseurs de presse',
  restrictionRefreshMS: 60000,
  lipregoMaxCommandHour: 18,
  disablePerformance: false,
  enablePdvStatus: true,
  historyMonthsAccess: 23,
  cacheRefresh: 10000,
  hideRuptureRecurrente: false,
  reorderMaxQuantity: { item: 30, lot: 5 },
  disabledPFPRequests: false,
  blMessagesDisabled: true,
  disabledAssortimentDashboard: false,
  disabledProposal: false,
  disabledLot2c: false,
  disablePlafonnement: false,
  reaLimitQuantity: 0,
  enablePFPForced: {
    startDate: '2023-11-30T00:00:00.000'
  },
  enableMenuRestriction: {
    startDate: '2024-04-02T00:00:00.000'
  },
  enableAssortBulk: {
    startDate: '2023-11-30T00:00:00.000'
  },
  pfpLoginPage: 'https://presseconnect-recette.sifipresse.fr/login',
  days: [
    { id: '1', name: 'Lundi' },
    { id: '2', name: 'Mardi' },
    { id: '3', name: 'Mercredi' },
    { id: '4', name: 'Jeudi' },
    { id: '5', name: 'Vendredi' },
    { id: '6', name: 'Samedi' },
    { id: '7', name: 'Dimanche' },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
