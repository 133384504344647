import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { LineStatement, NewStatment, PostStatementAction, LineStatementWarehouseAction } from 'src/app/Models/newStatment.model';
import { StoreService } from 'src/app/services/store.service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DatePipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { AuthService } from 'src/app/services/auth-service';
import { PdifAccounts } from 'src/app/Models/user.model';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.css']
})
export class StatementsComponent implements OnInit, OnDestroy, AfterViewInit {
  showSpinner = false;

  private datePipe = new DatePipe();
  loadingTimer: any;
  p2000Accounts: any[] = [];

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  isLoading = false;
  changes: NewStatment[] = [];
  changesFiltred: NewStatment[] = [];
  filterVal = 'all';
  statutFilterVal = '3';
  p2000FilterVal = '';
  natureFilterVal = 'all';

  changesSubs: Subscription;

  listNatures = [];

  actionStatements: PostStatementAction[] = [];
  constructor(private dtlanguages: DtLanguages,
    private sweet: Sweetalert,
    private authService: AuthService,
    protected storeService: StoreService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      paging: false,
      autoWidth: false,
      columnDefs: [{
        'targets': [2],
        'orderable': false
      }],
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };

    this.changesSubs = this.storeService.getStatementsUpdateListner()
      .subscribe(result => {
        this.isLoading = false;
        this.changes = result;
        this.changesFiltred = this.filterData();
      });

    this.getPendingChanges();

    const me = this;
    this.loadingTimer = setInterval(function () {
      if (me.authService.isAuthenticated) {
        me.getPendingChanges(false);
      }
    }, 300000);

    this.p2000Accounts = this.authService.p2000Nims.sort((a, b) => (a.name || '').localeCompare(b.name));
    if (this.p2000Accounts.length === 1) {
      this.p2000FilterVal = this.p2000Accounts[0].nim;
    } else if (this.p2000Accounts.length > 1) {

    }
  }

  exportDataToCSV() {
    if (!this.changesFiltred
      || !this.changesFiltred.length) {
      return;
    }

    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `pdif-jourferie-${date.format('YYYY-MM-DD')}`,
      filename: `pdif-jourferie-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);

    const data = this.changesFiltred.map((v, i) => {
      return v.changes.map((c, j) => {
        let status = 'en attente';
        if (c.warehouseAction && c.warehouseAction.status) {
          status = 'validé';
        } else if (c.warehouseAction && !c.warehouseAction.status) {
          status = 'refusé';
        }
        return {
          Nim: v.nim,
          date: moment(c.changedOn).format('DD-MM-YYYY'),
          nil: v.nil,
          locality: v.locality || '',
          name: v.name || '',
          type: c.label,
          modification: this.getStatementValue(c.id, c.newValue).replace('<br>', ' '),
          statut: status
        };
      });
    }).reduce((a, b) => a.concat(b));
    csvExporter.generateCsv(data);
  }

  deleteStatement() {
    // tslint:disable-next-line:max-line-length
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir supprimer ' + (this.actionStatements.length > 1 ? 'les' : 'la') + ' modification?',
      (error: Error, result: any): void => {
        if (result) {
          this.valideChanges(this.actionStatements, 'refuse');
        }
      });
  }

  validerStatement() {
    // tslint:disable-next-line:max-line-length
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider ' + (this.actionStatements.length > 1 ? 'les' : 'la') + ' modification?',
      (error: Error, result: any): void => {
        if (result) {
          this.valideChanges(this.actionStatements, 'accept');
        }
      });
  }

  getPendingChanges(displayLoading = true) {
    if (displayLoading) {
      this.isLoading = true;
    }

    this.storeService.loadChangesAll();
  }

  valideChanges(changes: PostStatementAction[], actionValide: string) {
    this.isLoading = true;
    this.storeService.valideChanges(changes, actionValide)
      .subscribe(response => {
        this.isLoading = false;
        if (response.result) {
          this.sweet.success('Succès !',
            // tslint:disable-next-line:max-line-length
            'Votre déclaration a bien été enregistrée, vous devez saisir la demande dans votre P2000.');
          this.getPendingChanges();
        } else {
          this.isLoading = false;
          this.sweet.warning('Attention !', 'Vos modifications n’ont pas pu être enregistrer, veuillez réessayer plus tard.');
        }
      },
        err => {
          this.isLoading = false;
          this.sweet.warning('Attention !', 'Vos modifications n’ont pas pu être enregistrer, veuillez réessayer plus tard.');
        });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngOnDestroy() {
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.loadingTimer) {
      clearInterval(this.loadingTimer);
    }
  }

  getPostStatementAction(nim: string, id: string) {
    const ctrl = {
      'nim': nim,
      'id': id
    };
    return ctrl;
  }

  getStatementValue(statementId: string, str: string): string {
    try {
      str = str.split('\n').join('<br>');
      const item = JSON.parse(str);
      if (item) {
        let strResult = '';

        if (item.opening) { // ouverture
          strResult = ('Ouverture : ' + item.opening + '<br>');
          strResult += item.pauseFrom ? ('Pause de : ' + item.pauseFrom + '<br>') : '';
          strResult += item.pauseTo ? ('à: ' + item.pauseTo + '<br>') : '';
          strResult += ('Fermeture : ' + item.closing);
        } else if (item.id) { // activité ou proximité
          if (statementId.indexOf('activités_') === 0) {
            strResult = 'Activité ' + item.name + ' au rang ' + item.rank;
          } else if (statementId.indexOf('proximités_') === 0) {
            strResult = 'Proximité ' + item.name;
          }
        } else if (item.hours.holidays && item.hours.holidays.length) { // congé
          const holiday = item.hours.holidays[0];
          strResult += ('du : ' + this.getTodayDateffFormat(holiday.from) + '<br>');
          strResult += ('au : ' + this.getTodayDateffFormat(holiday.to) + '<br>');
          strResult += holiday.type ? ('raison : ' + holiday.type) : '';
        }

          if (strResult.length > 0) {
            return strResult;
          } else {
            return str;
          }
      }
    } catch (e) {
      if (str === 'checked') {
        return 'ajouté';
      } else if (str === 'unchecked') {
        return 'retiré';
      }
      return str;
    }
    return str;
  }
  getClassStatus(status: LineStatementWarehouseAction) {
    if (!status) {
      return 'text-warning';
    }
    if (status.status) {
      return 'text-success';
    } else {
      return 'text-danger';
    }
  }
  onCheckboxChanged(event, value) {
    const index = this.actionStatements.findIndex(v => v.id === value.id && v.nim === value.nim);
    if (!event.target.checked) {
      if (index >= 0) {
        this.actionStatements.splice(index, 1);
      }
    } else {
      if (index === -1) {
        this.actionStatements.push(value);
      }
    }
  }

  applyFiltersChanges() {
    this.changesFiltred = this.filterData();
    this.rerender();
  }

  filterData(): NewStatment[] {
    this.listNatures = [];
    if (!this.changes || !this.changes.length) {
      return [];
    }
    this.changes.map((v, i) => v.nature).filter((v, i, a) => v && a.indexOf(v) === i)
      .forEach(i => this.listNatures.push(i));

    const changesFiltred = [];
    this.changes.forEach(element => {

      if (this.p2000FilterVal
        && this.p2000FilterVal
        && this.p2000FilterVal.length
        && element.warehouseNim !== this.p2000FilterVal) {
        return;
      }

      const elementFiltred = Object.assign({}, element);

      elementFiltred.changes = element.changes.filter(i => {

        let sFilter = false;
        switch (this.statutFilterVal) {
          case '1':
            sFilter = i.warehouseAction && i.warehouseAction.status;
            break;
          case '2':
            sFilter = i.warehouseAction && !i.warehouseAction.status;
            break;
          case '3':
            sFilter = !i.warehouseAction;
            break;
          default:
            sFilter = true;
            break;
        }

        let nFilter = false;
        switch (this.filterVal) {
          case 'all':
            nFilter = true;
            break;
          case 'dayoff':
            nFilter = i.isDayOff;
            break;
          default:
            nFilter = i.id.startsWith(this.filterVal) && !i.isDayOff;
            break;
        }

        let natFilter = false;
        switch (this.natureFilterVal) {
          case 'all':
            natFilter = true;
            break;
          default:
            natFilter = element.nature === this.natureFilterVal;
            break;
        }

        return sFilter && nFilter && natFilter;
      }
      );

      if (elementFiltred.changes.length) {
        changesFiltred.push(elementFiltred);
      }
    });

    return changesFiltred;
  }

  getTodayDateffFormat(date: string): string {
    return this.datePipe.transform(date, 'DD-MM-YYYY');
  }
}
