import { Component, OnInit, ViewChild } from '@angular/core';
import {NgForm, FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidator } from 'src/app/shared/customValidator';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { CustomerCodeService } from 'src/app/services/customerCode.service';
import { Router, ChildActivationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


  signupFormGroup: FormGroup;
  title = 'Signup';
  showBtnSpinner = false;
  codeId = '';
  email = '';

  constructor(private customerCodeService: CustomerCodeService,
    public fb: FormBuilder,
    private authService: AuthService,
    private sweet: Sweetalert,
    private router: Router) {
      this.router.events.subscribe(event => {
        if (event instanceof ChildActivationEnd) {
          this.codeId = event.snapshot.queryParams['codeId'] || '';
          this.email = event.snapshot.queryParams['email'] || '';
        }
      });
    }

  ngOnInit() {

    this.signupFormGroup = this.fb.group({
      txtCodeInput: ['', [Validators.required]],
      // firstnameInput: ['', Validators.required],
      // lastnameInput: ['', Validators.required],
    });

      if (!this.codeId.length
        || !this.email.length) {
        this.router.navigate(['/login'], { queryParams: { logout: 1 }});
        return;
      }
  }

  isFormValid(form: NgForm) {
    if (!form) {
      return false;
    }

    // const firstname = form.value.firstnameInput || '';
    // const lastname = form.value.lastnameInput || '';
    const code = form.value.txtCodeInput || '';


    return this.codeId.length
      && code.length
      // && lastname.length
      // && firstname.length
      && this.email.length;

  }

  useCode(form: NgForm, event) {
    if (!this.isFormValid(form)) {
      return;
    }

    this.showBtnSpinner = true;
    const newCodeObj = {
      'codeId': this.codeId,
      'code': form.value.txtCodeInput,
      // 'firstName': form.value.firstnameInput,
      // 'lastName': form.value.lastnameInput,
      'email': this.email
    };

    this.customerCodeService.createAccountPublic(newCodeObj)
      .subscribe(response => {
        this.showBtnSpinner = false;
        if (response.result) {
          this.sweet.success('Succès !', 'Votre accès a été activé avec succès, nous vous souhaitons une agréable navigation sur LIPREGO.');
          this.authService.onLogin(this.email, form.value.txtCodeInput, null, null);
         } else {
          this.sweet.warning('Attention !', 'Le code saisi n’est plus valable.');
        }
      },
        error => {
          this.showBtnSpinner = false;
          this.sweet.warning('Attention !', 'Impossible de créer le compte.');
        });
  }
}
