import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BCIBusinessDocument, BCIGroupAmount, BCIItem } from 'src/app/Models/business.documents/bci.business.document.model';
import { DocumentformsService } from 'src/app/services/documentforms.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-bci.detail',
  templateUrl: './bci.detail.component.html',
  styleUrls: ['./bci.detail.component.css']
})
export class BciDetailComponent implements OnInit, OnDestroy {

  constructor(private documentService: DocumentformsService<BCIBusinessDocument>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private ngbModalConfig: NgbModalConfig) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  private documentSubscription: Subscription;
  bci: BCIBusinessDocument;
  fileName = `bci-`;
  print = false;
  bciDate = moment();
  selectedNim: string = null;
  selectedCode = '';

  @ViewChild('content') modalLoading: ElementRef;

  get firstGroup(): BCIItem[] {
    if (!this.bci || !this.bci.groups
      || !this.bci.groups.first
      || !this.bci.groups.first.length) {
      return [];
    }
    return this.bci.groups.first;
  }
  get secondGroup(): BCIItem[] {
    if (!this.bci || !this.bci.groups
      || !this.bci.groups.second
      || !this.bci.groups.second.length) {
      return [];
    }
    return this.bci.groups.second;
  }
  get recall(): BCIItem[] {
    if (!this.bci || !this.bci.recall || !this.bci.recall.length) {
      return [];
    }
    return this.bci.recall;
  }
  get adjustments(): BCIItem[] {
    if (!this.bci || !this.bci.adjustment || !this.bci.adjustment.length) {
      return [];
    }
    return this.bci.adjustment;
  }
  get claims(): BCIItem[] {
    if (!this.bci || !this.bci.claims || !this.bci.claims.length) {
      return [];
    }
    return this.bci.claims;
  }
  get forgotten(): BCIItem[] {
    if (!this.bci || !this.bci.forgotten || !this.bci.forgotten.length) {
      return [];
    }
    return this.bci.forgotten;
  }
  get returned(): BCIItem[] {
    if (!this.bci || !this.bci.returned || !this.bci.returned.length) {
      return [];
    }
    return this.bci.returned;
  }
  get regulation(): BCIGroupAmount[] {
    if (!this.bci || !this.bci.regulation || !this.bci.regulation.length) {
      return [];
    }
    return this.bci.regulation;
  }
  get commission(): BCIGroupAmount[] {
    if (!this.bci || !this.bci.commission || !this.bci.commission.length) {
      return [];
    }
    return this.bci.commission;
  }
  get company(): BCIGroupAmount[] {
    if (!this.bci || !this.bci.company || !this.bci.company.length) {
      return [];
    }
    return this.bci.company;
  }
  get hasTotal() {
    return this.bci && this.bci.total;
  }

  ngOnInit() {

    if (this.route.snapshot.paramMap.get('printOnly') != null) {
      this.print = this.route.snapshot.paramMap.get('printOnly') === 'true';
    }

    this.selectedCode = this.route.snapshot.queryParams['selectedCode'];
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    const nim = this.selectedNim || this.authService.currentNim;

    this.documentSubscription = this.documentService.getBCIDocumentUpdateListener()
      .subscribe(doc => {
        if (doc != null) {
          this.bci = doc;
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF(true);
            }, 1000);
          }
        }
      }, err => {
        this.closeModal();
      });

    let date = '';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.bciDate = moment(date);
      const docType = this.route.snapshot.queryParams['type'] || '';
      this.documentService.getBCIDocumentDetail(date, docType, nim, this.selectedCode);
    }

    this.fileName = `${this.fileName}${date}.pdf`;
  }

  downloadToPDF(closeAfterPrint = false) {
    this.print = true;
    this.documentService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  printBCI() {
    window.print();
  }

  goBack() {
    const date = this.bciDate.format('MM/YYYY');
    const queryParam = { };

    if (this.selectedNim && this.selectedNim.length) {
      queryParam['selectedNim'] = this.selectedNim || '';
    }

    if (this.selectedCode && this.selectedCode.length) {
      queryParam['selectedCode'] = this.selectedCode || '';
    }

    this.router.navigate([`/archives/${date}`], { queryParams: queryParam });
  }

  ngOnDestroy() {
    if (this.documentSubscription) {
      this.documentSubscription.unsubscribe();
    }
  }

}
