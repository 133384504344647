import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MonthPeriode } from '../Models/monthPeriode.model';
import { AuthService } from '../services/auth-service';
import { WarehouseArchivesService } from '../services/warehouse-archives.service';
import { DtLanguages } from '../shared/dtLanguages';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-archives-warehouse',
  templateUrl: './archives-warehouse.component.html',
  styleUrls: ['./archives-warehouse.component.css']
})
export class ArchivesWarehouseComponent implements OnInit, AfterViewInit, OnDestroy {

  archiveSubscription: Subscription;
  selectedDate: '';
  dateArchive: MonthPeriode;
  selectedType = '0';
  periodData = [];
  isLoading = true;

  isLoadingPDF = true;
  noDataToDisplay = false;
  pdfData: SafeResourceUrl;
  pdfSubscription: Subscription;
  currentNim = '';

  // datatable initialisations
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;

  get listMonths(): MonthPeriode[] {
    return this.getMonths();
  }

  constructor(private authService: AuthService,
    private archiveService: WarehouseArchivesService,
    private dtlanguages: DtLanguages,
    private route: ActivatedRoute,
    private ngbModalService: NgbModal,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      paging: false,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: false,
      ordering: false,
      language: this.dtlanguages.frLanguage()
    };

    this.currentNim = this.route.snapshot.queryParams['nim'] || this.authService.currentNim;

    this.selectedType = '0';

    this.archiveSubscription = this.archiveService.getArchiveDatesRequestsUpdateListner().subscribe(docByDate => {

      docByDate = docByDate || {};
      this.isLoading = false;
      this.periodData = [];
      // this.codesData = [];
      // this.wsData = docByCode;

      this.fillTableForDate(docByDate);
    }, err => {
      this.periodData = [];
      this.isLoading = false;
    });

    this.pdfSubscription = this.archiveService.getPdfUpdateListner().subscribe(pdfBase64 => {
      this.isLoadingPDF = false;
      this.noDataToDisplay = !pdfBase64 || !pdfBase64.length;
      if (pdfBase64 != null) {
        const byteCharacters = atob(pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      }
    });

    this.performCall();
  }

  fillTableForDate(docByDate): void {

    if (!docByDate) {
      return;
    }

    this.periodData = [];

    if (this.selectedType === '2') {
      this.fillTableForMonths(docByDate);
      return;
    }

    if (this.selectedType === '1') {
      this.fillTableForWeeks(docByDate);
      return;
    }

    const toDate = moment(this.dateArchive.to);
    const fromDate = moment(this.dateArchive.from);
    const maxDate = moment().add(2, 'd');

    const diffDays = toDate.diff(fromDate, 'day');

    for (let index = 0; index <= diffDays; index++) {

      if (toDate.isAfter(maxDate)) {
        toDate.add(-1, 'day');
        continue;
      }

      const docs = docByDate[toDate.format('YYYYMMDD')];

      if (!docs) {
        this.periodData.push(
          {
            displayDate: toDate.locale('fr').format('dddd DD MMMM YYYY'),
            date: toDate.format('YYYY-MM-DD')
          }
        );
        toDate.add(-1, 'day');
        continue;
      }

      this.fillDataArray(docs, toDate.locale('fr').format('dddd DD MMMM YYYY'), toDate.format('YYYY-MM-DD'));

      toDate.add(-1, 'day');
    }
  }

  getTableHeader(): String {
    if (this.selectedType === '0') {
      return 'JOUR';
    }

    if (this.selectedType === '1') {
      return 'SEMAINE';
    }

    return 'MOIS';
  }

  getColWidth(): string {
    if (this.selectedType === '0') {
      return '40';
    }

    if (this.selectedType === '1') {
      return '30';
    }

    return '40';
  }

  fillTableForWeeks(docByDate): void {

    const toDate = moment(this.dateArchive.to).locale('fr').startOf('isoWeek');
    const year = toDate.year();

    while (toDate.year() === year) {

      if (toDate.isAfter(toDate)) {
        toDate.add(-1, 'week');
        continue;
      }

      const docs = docByDate[toDate.format('YYYYMMDD')];

      if (!docs) {
        this.periodData.push(
          {
            displayDate: `Semaine ${toDate.locale('fr').format('WW')}`,
            date: toDate.format('YYYY-MM-DD')
          }
        );
        toDate.add(-1, 'week');
        continue;
      }

      this.fillDataArray(docs, `Semaine ${toDate.locale('fr').format('WW')}`, toDate.format('YYYY-MM-DD'));

      toDate.add(-1, 'week');
    }
  }

  fillTableForMonths(docByDate): void {

    const toDate = moment(this.dateArchive.to);

    for (let index = 0; index <= 11; index++) {

      if (toDate.isAfter(toDate)) {
        toDate.add(-1, 'month').endOf('month');
        continue;
      }

      const docs = docByDate[toDate.format('YYYYMMDD')];

      if (!docs) {
        this.periodData.push(
          {
            displayDate: toDate.locale('fr').format('MMMM YYYY'),
            date: toDate.format('YYYY-MM-DD')
          }
        );
        toDate.add(-1, 'month').endOf('month');
        continue;
      }

      this.fillDataArray(docs, toDate.locale('fr').format('MMMM YYYY'), toDate.format('YYYY-MM-DD'));

      toDate.add(-1, 'month').endOf('month');
    }
  }

  fillDataArray(docs, displayDate, date) {
    this.periodData.push(
      {
        // daily
        f: docs.filter(t => t.type === 'f').length > 0 ? docs.filter(t => t.type === 'f')[0].id : null,
        i: docs.filter(t => t.type === 'i').length > 0 ? docs.filter(t => t.type === 'i')[0].id : null,
        j: docs.filter(t => t.type === 'j').length > 0 ? docs.filter(t => t.type === 'j')[0].id : null,
        k: docs.filter(t => t.type === 'k').length > 0 ? docs.filter(t => t.type === 'k')[0].id : null,
        l: docs.filter(t => t.type === 'l').length > 0 ? docs.filter(t => t.type === 'l')[0].id : null,

        // weekly
        r: docs.filter(t => t.type === 'r').length > 0 ? docs.filter(t => t.type === 'r')[0].id : null,
        d: docs.filter(t => t.type === 'd').length > 0 ? docs.filter(t => t.type === 'd')[0].id : null,
        e: docs.filter(t => t.type === 'e').length > 0 ? docs.filter(t => t.type === 'e')[0].id : null,
        n: docs.filter(t => t.type === 'n').length > 0 ? docs.filter(t => t.type === 'n')[0].id : null,
        t: docs.filter(t => t.type === 't').length > 0 ? docs.filter(t => t.type === 't')[0].id : null,
        s: docs.filter(t => t.type === 's').length > 0 ? docs.filter(t => t.type === 's')[0].id : null,
        g: docs.filter(t => t.type === 'g').length > 0 ? docs.filter(t => t.type === 'g')[0].id : null,
        h: docs.filter(t => t.type === 'h').length > 0 ? docs.filter(t => t.type === 'h')[0].id : null,

        // monthly
        o: docs.filter(t => t.type === 'o').length > 0 ? docs.filter(t => t.type === 'o')[0].id : null,
        u: docs.filter(t => t.type === 'u').length > 0 ? docs.filter(t => t.type === 'u')[0].id : null,
        v: docs.filter(t => t.type === 'v').length > 0 ? docs.filter(t => t.type === 'v')[0].id : null,
        w: docs.filter(t => t.type === 'w').length > 0 ? docs.filter(t => t.type === 'w')[0].id : null,
        rim: docs.filter(t => t.type === 'rim').length > 0 ? docs.filter(t => t.type === 'rim')[0].id : null,

        displayDate: displayDate,
        date: date
      }
    );
  }

  onDatesChange(e): void {
    this.isLoading = true;
    this.performCall();
  }

  onTypeChange(event): void {
    this.isLoading = true;
    this.selectedDate = '';
    this.performCall();
  }

  displayText(month) {
    return month.displayText(this.selectedType !== '0');
  }

  private performCall() {

    const searchDates = this.listMonths.filter(f => this.displayText(f) === this.selectedDate);

    if (!searchDates.length) {
      this.dateArchive = this.listMonths[0];
    } else {
      this.dateArchive = searchDates[0];
    }

    this.loadWarehouseData();
  }

  private loadWarehouseData() {
    // const nim = this.selectedNim || this.authService.currentNim;
    const nim = this.currentNim;
    this.archiveService.getDocs(nim, this.dateArchive.from, this.dateArchive.to, this.selectedType);
  }

  closeModal() {
    this.isLoadingPDF = false;
    this.noDataToDisplay = false;
    this.pdfData = null;
    this.ngbModalService.dismissAll();
  }

  openModal(content, id) {
    this.isLoadingPDF = true;
    this.noDataToDisplay = false;
    // const nim = this.selectedNim || this.authService.currentNim;
    const nim = this.authService.currentNim;
    this.archiveService.getDocPDF(nim, id);
    this.ngbModalService.open(content, { size: 'xl' as any });
  }

  public getMonths(): MonthPeriode[] {
    const datesResults: MonthPeriode[] = [];

    const currentYear = (new Date()).getFullYear();
    if (this.selectedType !== '0') {
      const minYear = 2020;
      for (let i = 0; i <= (currentYear - minYear); i++) {
        datesResults[i] = new MonthPeriode(
          moment(new Date(currentYear - i, 0, 1)).locale('fr').format('MMMM'),
          '' + (new Date(currentYear - i, 0, 1)).getFullYear(),
          moment(new Date(currentYear - i, 0, 1)).locale('fr').format('YYYY-MM-DD'),
          moment(new Date(currentYear - i, 12, 0)).locale('fr').format('YYYY-MM-DD'));
      }
    } else {
      const currentMonth = (new Date()).getMonth();
      for (let i = 0; i <= environment.historyMonthsAccess; i++) {
        datesResults[i] = new MonthPeriode(
          moment(new Date(currentYear, currentMonth - i, 1)).locale('fr').format('MMMM'),
          '' + (new Date(currentYear, currentMonth - i, 1)).getFullYear(),
          moment(new Date(currentYear, currentMonth - i, 1)).locale('fr').format('YYYY-MM-DD'),
          moment(new Date(currentYear, currentMonth + 1 - i, 0)).locale('fr').format('YYYY-MM-DD'));
      }
    }

    return datesResults;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.archiveSubscription) {
      this.archiveSubscription.unsubscribe();
    }
  }

}
