import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service';
import { OrderService } from 'src/app/services/order.service';
import { ProCustomer } from 'src/app/Models/order.model';
import { Address } from 'src/app/Models/address.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html',
  styleUrls: ['./pro.component.css']
})
export class ProComponent implements OnInit {

  @ViewChild('content') modalNotification: ElementRef;

  constructor(private orderService: OrderService,
    private ngbModalService: NgbModal,
    private authService: AuthService) { }
  customers: ProCustomer[];
  selectedCustomer: ProCustomer;
  isLoading = true;

  ngOnInit() {
    this.getCustomers();
  }

  addressToString(adr: Address) {
    if (!adr) {
      return 'Adresse non renseignée !';
    }

    return `${adr.firstAddress} ${adr.secondAddress || ''} ${adr.postalCode} ${adr.locality}`;
  }

  getCustomers() {
    this.isLoading = true;
    this.customers = [];
    this.orderService.getCustomers()
      .subscribe(res => {
        if (res.result) {
          this.customers = res.result;
          this.isLoading = false;
        }
      },
        err => {
          this.isLoading = false;
        });
  }

  addCustomer(form: NgForm) {

    if (!form.valid) {
      return;
    }
    this.isLoading = true;
    const adr: Address = {
      firstAddress: form.value.accountAddress1,
      secondAddress: form.value.accountAddress2,
      postalCode: form.value.accountAddressCP,
      locality: form.value.accountAddressCity,
      country: form.value.accountAddressCountry
    };
    const customer: ProCustomer = {
      id: null,
      name: form.value.accountName,
      email: form.value.accountEmail,
      password: form.value.accountPassword,
      phone: form.value.accountPhone,
      contact: form.value.accountContact,
      address: adr,
      nim: form.value.accountNim,
      vatNumber: form.value.accountVAT,
      siret: form.value.accountSIRET,
      status: null
    };

    this.orderService.addCustomer(customer)
      .subscribe(res => {
        this.isLoading = false;
        if (res.result) {
          this.closeModal();
          this.getCustomers();
        }
      },
        err => {
          this.isLoading = false;
        });
  }

  updateCustomer() {

    if (!this.selectedCustomer) {
      return;
    }
    this.isLoading = true;

    this.orderService.updateCustomer(this.selectedCustomer)
      .subscribe(res => {
        this.isLoading = false;
        if (res.result) {
          this.selectedCustomer = null;
          this.closeModal();
          this.getCustomers();
        }
      },
        err => {
          this.isLoading = false;
        });
  }

  initCatelog(proId: string) {

    if (!proId || !proId.length) {
      return;
    }
    this.isLoading = true;

    this.orderService.initCatalog(proId)
      .subscribe(res => {
        this.isLoading = false;
        if (res.result) {
          alert('Catalogue initialisé avec succès !');
        } else {
          alert('Veuillez contacter l’administrateur !');
        }
      },
        err => {
          this.isLoading = false;
          alert('Veuillez contacter l’administrateur !');
        });
  }

  openModal(content: NgbModal) {
    this.ngbModalService.open(content);
  }

  openModalDetail(content: NgbModal, index: number) {
    this.selectedCustomer = this.customers[index];
    this.ngbModalService.open(content);
  }

  closeModal() {
    this.selectedCustomer = null;
    this.ngbModalService.dismissAll();
  }
}
