import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'FrenchCurrency' })
export class CurrencyFormatter implements PipeTransform {
    private _currency = 'EUR';
    private _currencyDisplay = '€';
    private numberFormatter = Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  transform(value: number, removeZero: boolean = true): string {
    if (!value && removeZero) {
        return '';
    }
    if (value === undefined) {
      value = 0;
    }
    return this.numberFormatter.format(value);
  }
}

@Pipe({ name: 'FrenchNumberDisplay' })
export class NumberFormatter implements PipeTransform {
    private numberFormatter = Intl.NumberFormat('fr-FR', { style: 'decimal',
      minimumIntegerDigits: 1, minimumFractionDigits: 2,
    maximumFractionDigits: 2 });
  transform(value: number, displayZero: boolean = true): string {
    if (!value && !displayZero) {
        return '';
    }
    if (value === undefined) {
      value = 0;
    }
    return this.numberFormatter.format(value);
  }
}
