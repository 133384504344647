import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorePlafonnement, StorePlafonnementTitre } from 'src/app/Models/storeAssortiment.model';
import { AuthService } from 'src/app/services/auth-service';
import { StoreAssortimentService } from 'src/app/services/store-assortiment.service';
import { UpdateserviceService } from 'src/app/services/updateservice.service';
import { BaseupdateserviceComponent } from 'src/app/shared/baseupdateservice/baseupdateservice.component';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { GA } from 'src/app/shared/gAnalytics';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import * as moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-plafonnement',
  templateUrl: './plafonnement.component.html',
  styleUrls: ['./plafonnement.component.css']
})
export class PlafonnementComponent extends BaseupdateserviceComponent implements OnInit, OnDestroy, AfterViewInit {

  private plafondSubs: Subscription;

  private DONE_SERVICE_KEY_PREFIX = 'plafond-service';
  isLimited = false;
  showSpinner = false;
  filteredData: StorePlafonnementTitre[] = [];

  changeCodif: Subject<string> = new Subject<string>();

  searchMotif = '';
  searchFamily = '';
  searchCategory = '';
  searchStatus = '';

  plafondResult: StorePlafonnement;

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(protected updateService: UpdateserviceService,
    protected sweet: Sweetalert,
    private assortService: StoreAssortimentService,
    protected authService: AuthService,
    private dtlanguages: DtLanguages,
    protected router: Router,
    protected ngbModalService: NgbModal,
    protected ga: GA,
    private route: ActivatedRoute) {
      super(updateService, authService, router, ngbModalService, ga, sweet);
     }

  ngOnInit() {

    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      ordering: true,
      order: [[6, 'desc'], [1, 'asc']],
      language: this.dtlanguages.frLanguage()
    };

    this.plafondSubs = this.assortService.getPlafondListner().subscribe(result => {
      this.showSpinner = false;
      if (result) {
        this.plafondResult = result;
        this.isLimited = result.isLimited;
        this.filteredData = result.titles || [];
        this.rerender();
      }
    });

    this.saveSubs = this.UpdateserviceRequest.getSaveUpdateListener()
          .subscribe(result => {
            this.showModalSpinner.next(false);
            if (result && result.status) {
              localStorage.setItem(this.getCodifLineId(this.titleCodif), this.titleCodif);
              if (this.soldQuantity === 0) {
                this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande d’installation de titre');
              } else {
                this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande de modification de service');
              }
              this.goNext();
              //this.checkServiceProposal(result);
            }  else {
              this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
            }
          });

    this.refreshData();
  }

  private checkCurrentItemWithActiveSorting(position: number) {

    // get active sorting
    const order = this.dtElement['dt'].order()[0];
    const orderDirection = order[1] === 'desc' ? 0 : 1;
    let orderIndex = parseInt(order[0].toString(), 0);

    const keys = ['arrow', 'name', 'releaseDate', 'family', 'category', 'averageSales', 'breakupRate', 'retainedCeiling', 'quantityCeiling', 'maxQuantity', 'motifId'];

    this.filteredData
      .sort((a, b) => {
        return orderDirection ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      });

    if(orderIndex <= 1 || keys.length <= orderIndex)
      return;

    const field = keys[orderIndex];
    const thenOrderByName = field !== "name";

    this.filteredData
      .sort((a, b) => {
        const aVal = a[field] || 0;
        const bVal = b[field] || 0;
        
        let compare = 0;
        if (typeof aVal === 'number') {
          if (orderDirection) {
            if (aVal > bVal) compare = 1;
            else if (aVal < bVal) compare = -1;
            else compare = a.name.localeCompare(b.name);
          } else {
            if (aVal < bVal) compare = 1;
            else if (aVal > bVal) compare = -1;
            else compare = a.name.localeCompare(b.name);
          }
        }
        else if (thenOrderByName){
          compare = orderDirection ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
          if(thenOrderByName) {
            compare = compare === 0 ? a.name.localeCompare(b.name) : compare;
          }
        }
        return compare;



      });

    const itemIndex = this.filteredData.findIndex(e => e.codif === this.titleCodif);
    if (itemIndex >= this.filteredData.length) {
      this.closeModal();
      return;
    }

    const item = this.filteredData[itemIndex + position];

    if (!item) {
      this.closeModal();
      return;
    }

    this.titleCodif = item.codif;
    this.changeCodif.next(this.titleCodif);
  }

  goNext() {
    this.checkCurrentItemWithActiveSorting(1);
  }

  goPrevious() {
    this.checkCurrentItemWithActiveSorting(-1);
  }

  getCodifLineId(codif: string): string {
    return `${this.DONE_SERVICE_KEY_PREFIX}-${codif}-service`;
  }

  filterList() {
    if (!this.plafondResult
      || !this.plafondResult.titles
      || !this.plafondResult.titles.length) {
      return;
    }

    this.filteredData = this.plafondResult.titles;

    if (this.searchCategory.length) {
      this.filteredData = this.filteredData.filter(t => t.category === this.searchCategory);
    }

    if (this.searchFamily.length) {
      this.filteredData = this.filteredData.filter(t => t.family === this.searchFamily);
    }

    this.rerender();
  }

  // rerender the datatable
  rerender() {
    if(this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    }
    this.dtTrigger.next();
  }

  serviceForTitle(item: StorePlafonnementTitre) {
    if (!item) {
      return;
    }

    this.titleCodif = item.codif;
    this.openModal(this.modalService);
  }

  refreshData() {
    this.showSpinner = true;
    this.assortService.getPlafondTitres(this.authService.currentNim);
  }

  ngAfterViewInit() {
    
  }

  showRedBorder(title: StorePlafonnementTitre) {
    if (!title) return false;
    return title.breakupRate > 40 || ((title.maxQuantity || 0) < (title.averageSales || 0));
  }

  exportDataToCSV() {
    if (!this.filteredData.length) {
      return;
    }
    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `plafonnement-quantitemax-${date.format('YYYY-MM-DD')}`,
      filename: `plafonnement-quantitemax-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Codif', 'Libellé', 'Parution', 'Date mev', 'Famille', 'Catégorie', 'La vente moy par parution sur 12 mois',
      'Tx de ruptures sur 12 mois', 'Le type de plafond appliqué', 'Le N° de la parution homologue si le plafond sur parution homologue appliqué',
      'Qté plafond appliquée', 'Quantité max', 'Motif de déplafonnement']
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.filteredData.map((v, i) => {
      const dt = moment(v.releaseDate);
      // tslint:disable-next-line:max-line-length
      return { 
        codif: v.codif, 
        name: v.name, 
        parution: v.issueNumber, 
        date: dt.format('YYYY-MM-DD'), 
        famille: v.family, 
        categorie: v.category,
        avg: v.averageSales,
        pct: v.breakupRate,
        plafonnement: v.retainedCeiling,
        parutionApplied: v.issueNumber,
        qtePlafond: v.quantityCeiling,
        qteMax: v.maxQuantity,
        motif: v.motifId
      };
    });
    csvExporter.generateCsv(data);
  }

  ngOnDestroy(): void {
    if (this.plafondSubs) {
      this.plafondSubs.unsubscribe();
    }
  }
}
