import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WarehouseContacts } from '../Models/employeeWarehouse.model';
import { Subject } from 'rxjs';
import { AdminWarehouseContactBlocs } from '../Models/contactdepot.model';

@Injectable({
  providedIn: 'root'
})
export class WarehouseContactsService {

  constructor(private http: HttpClient) { }

  private itemsUpdated = new Subject<WarehouseContacts[]>();
  private itemActionUpdated = new Subject<boolean>();

  private adminWarehouseContactUpdated = new Subject<AdminWarehouseContactBlocs>();

  getItems(nim: string) {
    const url = `${environment.APIURL}/api/v1/warehouses/${nim}/contacts`;
    this.http.get<{ result: WarehouseContacts[] }>(url)
      .subscribe((response) => {
        if (response) {
          this.itemsUpdated.next(response.result);
        } else {
          this.itemsUpdated.next([]);
        }
      }, err => {
        this.itemsUpdated.next([]);
      });
  }

  getListRequestsUpdateListner() {
    return this.itemsUpdated.asObservable();
  }

  getAdminWarehouseContacts() {
    const url = `${environment.APIURL}/api/v1/admin/warehousecontacts`;
    this.http.get<{ result: AdminWarehouseContactBlocs }>(url)
      .subscribe((response) => {
        if (response) {
          this.adminWarehouseContactUpdated.next(response.result);
        } else {
          this.adminWarehouseContactUpdated.next(null);
        }
      }, err => {
        this.adminWarehouseContactUpdated.next(null);
      });
  }

  setAdminWarehouseContacts(blocs) {
    const url = `${environment.APIURL}/api/v1/admin/warehousecontacts`;
    this.http.post<{ result: boolean }>(url, blocs)
      .subscribe((response) => {
        if (response) {
          this.itemActionUpdated.next(response.result);
        } else {
          this.itemActionUpdated.next(false);
        }
      }, err => {
        this.itemActionUpdated.next(false);
      });
  }

  getAdminWarehouseContactsUpdateListner() {
    return this.adminWarehouseContactUpdated.asObservable();
  }

  getActionRequestsUpdateListner() {
    return this.itemActionUpdated.asObservable();
  }

  addUpdateContact(nim: string, id: string, data: any) {
    const url = `${environment.APIURL}/api/v1/warehouses/${nim}/contacts?id=${id || ''}`;
    this.http.post<{ result: boolean }>(url, data)
    .subscribe((response) => {
      if (response) {
        this.itemActionUpdated.next(response.result);
      } else {
        this.itemActionUpdated.next(false);
      }
    }, err => {
      this.itemActionUpdated.next(false);
    });
  }

  deleteContact(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/warehouses/${nim}/contacts/${id || ''}`;
    this.http.delete<{ result: boolean }>(url)
    .subscribe((response) => {
      if (response) {
        this.itemActionUpdated.next(response.result);
      } else {
        this.itemActionUpdated.next(false);
      }
    }, err => {
      this.itemActionUpdated.next(false);
    });
  }

}
