import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/customValidator';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service';
import { Sweetalert } from 'src/app/shared/sweetAlert';

@Component({
  selector: 'app-renewpassword',
  templateUrl: './renewpassword.component.html',
  styleUrls: ['./renewpassword.component.css']
})
export class RenewpasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public sweet: Sweetalert) { }
  changePasswordForm: FormGroup;
  isProcessing = false;

  ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['/login']);
      return;
    }
    this.changePasswordForm = this.fb.group({
      txtNewPasswordInput: ['', [Validators.required]],
      txtConfirmPasswordInput: ['', Validators.required]
    },
      {
        validator: CustomValidator.matchPasswordProfil
      });
  }

  // change password
  onChangePassword(event) {
    this.isProcessing = true;
    const newPassword = event.target.txtPassword.value;
    // retrive token from url
    const token = this.route.snapshot.paramMap.get('token');
    if (!token || !token.length) {
      this.router.navigate(['/login']);
      return;
    }
    const data = { password: newPassword, passwordToken: token };
    this.authService.renewPassword(data)
      .subscribe(res => {
        this.isProcessing = false;
        // tslint:disable-next-line:max-line-length
        this.sweet.success('Succès !', 'Votre mot de passe a été réinitialisé, vous allez être redirigé vers la page de connexion automatiquement.');
        const routeNav = this.router;
        setTimeout(() => {
          routeNav.navigate(['/login']);
        }, 2000);
      },
        error => {
          this.isProcessing = false;
          if (error.status && error.status === 404) {
            // tslint:disable-next-line:max-line-length
            const msg = 'Votre demande de réinitialisation du mot de passe a expiré, vous devez renouveler votre demande en utilisant la fonction mot de passe oublié';
            this.sweet.warning('Information', msg);
          } else {
            this.sweet.warning('Erreur !', 'Votre demande de réinitialisation du mot de passe a échoué, veuillez réessayer plus tard.');
          }
        });
  }

}
