import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AssortimentStatus } from 'src/app/Models/assortimentStatus.model';
import { AuthService } from 'src/app/services/auth-service';
import { UpdateserviceService } from 'src/app/services/updateservice.service';
import { Sweetalert } from '../sweetAlert';
import { SaveUpdateServiceStatus } from 'src/app/Models/savereorder.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GA } from '../gAnalytics';

@Component({
  selector: 'app-baseupdateservice',
  templateUrl: './baseupdateservice.component.html',
  styleUrls: ['./baseupdateservice.component.css']
})
export class BaseupdateserviceComponent implements OnInit, OnDestroy {

  // assortiment
  protected assortimentSubs: Subscription;
  protected assortimentRequestSubs: Subscription;
  // declarations

  @ViewChild('content') modalService: ElementRef;

  protected saveSubs: Subscription;
  protected typeAction = 'service';
  showTableSpinner = true;
  protected showModalSpinner: Subject<boolean> = new Subject<boolean>();
  protected titleCodif: string;
  protected titleOnly = false;
  protected soldQuantity = 0;
  protected nimAssortimentStatus = false;
  protected assortimentResponse: AssortimentStatus;
  protected isProposalProcess = false;

  protected quantityParam = '';
  protected motifParam = '';

  constructor(protected UpdateserviceRequest: UpdateserviceService,
    protected authService: AuthService,
    protected router: Router,
    protected ngbModalService: NgbModal,
    protected ga: GA,
    protected sweet: Sweetalert) { }

  ngOnInit() {
    this.assortimentSubs = this.authService.getAssortimentListener().subscribe(res => {
      this.nimAssortimentStatus = res;
    });

    this.assortimentRequestSubs = this.UpdateserviceRequest.getRequestUpdatedListener()
      .subscribe(res => {
        this.assortimentResponse = res;

        if (this.assortimentResponse.status) {
          this.sweet.confirmDialog('Attention !', this.assortimentResponse.message, (error: Error, result: any): void => {
            if (result) {
              this.performSave(this.authService.currentNim, this.assortimentResponse.innerData);
            } else {
              this.showModalSpinner.next(false);
            }
          }, 'Valider');
        } else {
          this.showModalSpinner.next(false);
          this.sweet.error('Attention !', this.assortimentResponse.message);
        }
      });
    if (!this.authService.getPdifAccount 
      || this.authService.getPdifAccount.assortStatus === undefined) {
      this.authService.getAssortimentStatus(this.authService.currentNim);
    } else if (this.authService.getPdifAccount) {
      this.nimAssortimentStatus = (this.authService.getPdifAccount.assortStatus || false);
    }
  }

  protected setAverageQuantity($event) {
    this.soldQuantity = $event;
  }

  protected samplesText(count: number) {
    if (!count) {
      return 'aucun exemplaire';
    }
    const n = Number.isInteger(count) ? count.toString() : count.toFixed(2);
    let txt = `${n} exemplaire`;
    if (count > 1) {
      txt += 's';
    }

    return txt;
  }

  // on post new service
  protected onSaveRequest($event) {
    if (!$event) {
      this.showModalSpinner.next(false);
      return;
    }

    const isDaily = $event[0].dayCode;
    // const quantity = $event.map((v, i) => v.quantity || 0).reduce((a, b) => a + b);
    const quantity = $event[0].quantity;
    const canRemoveService = $event[0].canRemoveService;
    const soldFloor = Math.floor(this.soldQuantity);
    const quantitySamples = this.samplesText(quantity);
    const soldSamples = this.samplesText(this.soldQuantity);
    this.titleOnly = $event[0].titleOnly || false;

    const nim = this.authService.currentNim;

    if (this.nimAssortimentStatus) {
      this.UpdateserviceRequest.checkNewServiceRequest(nim, $event);
    } else {

      if (soldFloor === -2 || isDaily) {
        this.performSave(nim, $event);
        return;
      }

      let message = `Vous avez demandé à <b>installer ${quantitySamples}</b>.<br/>Souhaitez-vous valider votre demande ?`;

      if (this.soldQuantity > 0) {
        if (canRemoveService && quantity === 0) {
          // tslint:disable-next-line:max-line-length
          message = `Vous demandez la suppression d’un titre non CPPAP, vous risquez de perdre ${this.soldQuantity.toFixed(0)} € de chiffre d’affaire.<br/>Souhaitez-vous continuer ?`;
        } else {
          // tslint:disable-next-line:max-line-length
          message = `Vous avez demandé <b>${quantitySamples}</b>, la moyenne de vos dernières ventes est de <b>${soldSamples}</b>.<br/>Souhaitez-vous valider votre demande ?`;
        }
      } else if (this.soldQuantity === 0) {
        // tslint:disable-next-line:max-line-length
        message = `Vous avez demandé <b>${quantitySamples}</b>, vous n’avez aucune vente sur les 3 dernières parutions.<br/>Souhaitez-vous valider votre demande ?`;
      }

      this.sweet.confirmDialog('Attention !', message, (error: Error, result: any): void => {
        if (result) {
          this.performSave(nim, $event);
        } else {
          this.showModalSpinner.next(false);
        }
      });
    }
  }

  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
    this.ga.createEvent('view', 'Demandes', 'Création Nouvelle Demande de modification de service');

  }

  gaEvent(){
    if (this.soldQuantity === 0) {
      this.ga.createEvent('cancel', 'Demandes', 'Création Nouvelle Demande d’installation de titre');
    } else {
      this.ga.createEvent('cancel', 'Demandes', 'Création Nouvelle Demande de modification de service');
    }
  }

  closeModal(sendrRacking = true) {
    if (sendrRacking) {
      this.gaEvent();
    }
    this.ngbModalService.dismissAll();
  }


  protected performSave(nim, event) {
    this.UpdateserviceRequest.addNewService(nim, this.titleOnly, event);
  }

  ngOnDestroy() {
    if (this.saveSubs) {
      this.saveSubs.unsubscribe();
    }
    if (this.assortimentSubs) {
      this.assortimentSubs.unsubscribe();
    }
    if (this.assortimentRequestSubs) {
      this.assortimentRequestSubs.unsubscribe();
    }
    if (this.showModalSpinner) {
      this.showModalSpinner.unsubscribe();
    }
  }

  protected checkServiceProposal(proposal: SaveUpdateServiceStatus) {
    if (!proposal || !proposal.status || !proposal.codif || !proposal.codif.length) {
      return;
    }
    this.closeModal();
    this.sweet.confirmDialog('Proposition de titre !',
      proposal.message || '', (s, e) => {
        if (!s && !e) {
          return;
        }
        if (e) {
          // redirect to service
          // const url = `services/titles/${proposal.codif}`;
          // const queryParam = { q: proposal.serviceQuantity, m: proposal.motif};
          // this.router.navigate([url], { queryParams: queryParam });

          this.isProposalProcess = true;
          this.titleCodif = proposal.codif;
          this.quantityParam = proposal.serviceQuantity.toString();
          this.motifParam = proposal.motif;
          this.openModal(this.modalService);
        }
      }, null, 'success');
  }

}
