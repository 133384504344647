import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth-service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DocumentformsService<T> {

    private archiveDates: T[] = [];
    private archiveDatesUpdated = new Subject<T[]>();
    private documentDetailUpdate = new Subject<T>();
    private pdfUpdate = new Subject<ArrayBuffer>();

    constructor(private http: HttpClient) { }

    getArchiveDatesUpdatedRequests(dateFrom: string, dateTo: string, isbls: boolean, nim: string) {
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: T[] }>(environment.APIURL + '/api/v1/stores/' + (isbls ? 'bls' : 'bcis') + '?from=' + dateFrom + '&to=' + dateTo + '&nim=' + nim)
            .subscribe(response => {
                if (response) {
                    this.archiveDates = response.result;
                    this.archiveDatesUpdated.next([...this.archiveDates]);
                } else {
                    this.archiveDatesUpdated.next([]);
                }
            }, err => {
                this.archiveDatesUpdated.next([]);
            });
    }

    getBLDocumentDetail(date: string, nim: string, code: string) {

        const lastTimestamp = localStorage.getItem(environment.lastRequestTimestamp) || new Date().toISOString().substring(0, 10);
        const url = `${environment.APIURL}/api/v1/stores/bls/${date}?nim=${nim}&code=${code || ''}&ts=${lastTimestamp}`;
        this.http.get<{ result: T }>(url)
            .subscribe(response => {
                if (response) {
                    this.documentDetailUpdate.next(response.result);
                } else {
                    this.documentDetailUpdate.next(null);
                }
            }, err => {
                this.documentDetailUpdate.next(null);
            });
    }

    getBCIDocumentDetail(date: string, documentType: string, nim: string, code: string) {

        const lastTimestamp = localStorage.getItem(environment.lastRequestTimestamp) || new Date().toISOString().substring(0, 10);
        // tslint:disable-next-line:max-line-length
        const url = `${environment.APIURL}/api/v1/stores/bcis/${date}?nim=${nim}&code=${code || ''}&type=${documentType || ''}&ts=${lastTimestamp}`;
        this.http.get<{ result: T }>(url)
            .subscribe(response => {
                if (response) {
                    this.documentDetailUpdate.next(response.result);
                } else {
                    this.documentDetailUpdate.next(null);
                }
            }, err => {
                this.documentDetailUpdate.next(null);
            });
    }

    getOtherBLDocumentDetail(date: string, documentType: string, nim: string, code: string) {

        const lastTimestamp = localStorage.getItem(environment.lastRequestTimestamp) || new Date().toISOString().substring(0, 10);
        // tslint:disable-next-line:max-line-length
        const url = `${environment.APIURL}/api/v1/stores/otherbl/${date}?nim=${nim}&code=${code || ''}&version=${documentType || ''}&ts=${lastTimestamp}`;
        this.http.get<{ result: T }>(url)
            .subscribe(response => {
                if (response) {
                    this.documentDetailUpdate.next(response.result);
                } else {
                    this.documentDetailUpdate.next(null);
                }
            }, err => {
                this.documentDetailUpdate.next(null);
            });
    }

    getArchiveDatesRequestsUpdateListner() {
        return this.archiveDatesUpdated.asObservable();
    }

    getBCIDocumentUpdateListener() {
        return this.documentDetailUpdate.asObservable();
    }

    getPdfListener() {
        return this.pdfUpdate.asObservable();
    }

    downloadToPDF() {
        const html = `<html>
        <head>${document.head.innerHTML}</head>
        <body>${document.body.innerHTML}</body>
        </html>`;

        const options = {
            headers: new HttpHeaders({
                'Accept': 'application/pdf'
            }),
            mode: 'cors',
            'responseType': 'blob' as 'json'
         };
        return this.http.post(environment.APIPDF, {
            html: html,
            host: environment.WEBURL
        }, options);
    }
}
