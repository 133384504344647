import { Component, OnInit } from '@angular/core';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.css']
})
export class CguComponent implements OnInit {

  constructor(private sweet: Sweetalert,
    private authService: AuthService) { }

    showBtnSpinner = false;
  ngOnInit() {
  }

  confirmCGU() {
    this.showBtnSpinner = true;
    this.authService.accepteCGU().subscribe(response => {
      this.showBtnSpinner = false;
      this.authService.onLoadUser();
    }, error => {
      this.showBtnSpinner = false;
      this.sweet.error('Erreur', 'Une erreur serveur est servenu, veuillez réessayer plus tard.');
    });
  }
  Logout() {
    this.authService.onLogout();
  }
}
