import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from '../services/store.service';
import { Store } from '../Models/store.model';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Sweetalert } from '../shared/sweetAlert';
import { AuthService } from '../services/auth-service';
import { User } from '../Models/user.model';
import { CustomValidator } from 'src/app/shared/customValidator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit, OnDestroy {

  constructor(private storeService: StoreService, private sweet: Sweetalert,
    public authService: AuthService,
    private ngbModalService: NgbModal,
    public fb: FormBuilder) { }

  changePasswordForm: FormGroup;
  changeEmailFrm: FormGroup;
  store: Store;

  userSubscription: Subscription;
  storeSubscription: Subscription;

  account: User;
  resetingPassword = false;
  changingPassword = false;
  resendEmail = false;
  isLoading = false;

  publicProfileActivated = false;
  passwordProfile = '';

  get storeName() {
    if (!this.store) {
      return '';
    }

    if (this.store.contact) {
      return `${this.store.contact.firstName} ${this.store.contact.lastName}`;
    }

    return this.store.name;
  }
  get pendingEmail() {
    if (!this.account || !this.account.pendingEmail) {
      return null;
    }

    return this.account.pendingEmail;
  }

  get storeAddress() {
    if (!this.store || !this.store.address) {
      return '';
    }

    let address = this.store.address.firstAddress;
    if (this.store.address.secondAddress) {
      address += ' ' + this.store.address.secondAddress;
    }

    return `${address} ${this.store.address.postalCode} ${this.store.address.locality}`;
  }

  ngOnInit() {
    this.storeSubscription = this.storeService.getStoreUpdateListner()
      .subscribe(result => {
        this.store = result;
      });

    this.userSubscription = this.authService.getUserUpdateListener()
      .subscribe(user => {
        this.account = user;
      });
    this.storeService.getStore();
    this.authService.autoAuthUser(false);
    this.account = this.authService.getUser;

    this.changePasswordForm = this.fb.group({
      txtOldPasswordInput: ['', [Validators.required]],
      txtNewPasswordInput: ['', [Validators.required]],
      txtConfirmPasswordInput: ['', Validators.required]
    },
      {
        validator: CustomValidator.matchPasswordProfil
      });

    this.changeEmailFrm = this.fb.group({
      newEmailInput: ['', [Validators.required, CustomValidator.emailValidator]],
      confirmEmailInput: ['', [Validators.required, CustomValidator.emailValidator]]
    },
      {
        validator: CustomValidator.matchEmailProfil
      });

    this.publicProfileActivated = false; //this.authService.isPublicPro() || this.authService.isCustomer();
  }

  onPublicChanged(content, val: boolean) {
    this.publicProfileActivated = val;
    this.ngbModalService.open(content, { size: 'lg' });
  }

  validatePublicProfile() {
    if (!this.passwordProfile || !this.passwordProfile.length) {
      return;
    }

    const email = this.authService.getUser.email.toLowerCase();
    const pwd = this.passwordProfile;

    const authinfo = 'Basic ' + AuthService.base64Encode(email, pwd);
    const currentAuth = AuthService.getAuthInfo();
    if (authinfo !== currentAuth) {
      alert('Mot de passe incorrect');
      return;
    }

    this.passwordProfile = '';
    this.ngbModalService.dismissAll();
    if (this.publicProfileActivated) {
      localStorage.setItem('PROPUBLIC', '1');
    } else {
      localStorage.removeItem('PROPUBLIC');
    }
  }

  resetPassword() {
    this.resetingPassword = true;
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir réinitialiser votre mot de passe ?',
      (err: Error, result: any): void => {
        if (!result && !err) {
          this.resetingPassword = false;
          return;
        }
        if (err) {
          this.resetingPassword = false;
          this.sweet.error('Erreur', 'Votre demande de réinitialisation du mot de passe a échoué, veuillez réessayer plus tard.');
          return;
        }
        this.authService.resetPassword().subscribe(response => {
          this.resetingPassword = false;
          this.sweet.success('Succès', 'Un email de confirmation vous a été envoyé pour renouveler votre mot de passe.');
        }, error => {
          this.resetingPassword = false;
          this.sweet.error('Erreur', 'Votre demande de réinitialisation du mot de passe a échoué, veuillez réessayer plus tard.');
        });
      });
  }

  changePassword(form: NgForm, event) {
    this.changingPassword = true;
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir changer votre mot de passe ?',
      (err: Error, result: any): void => {
        if (!result && !err) {
          this.changingPassword = false;
          return;
        }
        if (err) {
          this.changingPassword = false;
          this.sweet.error('Erreur', 'Votre demande de changement du mot de passe a échoué, veuillez réessayer plus tard.');
          return;
        }
        this.authService.changePassword(event.target.newpassword.value).subscribe(response => {
          this.sweet.success('Succès', 'Votre mot de passe a été changé avec succès.');
          this.changingPassword = false;
          this.authService.onLogout();
        }, error => {
          this.changingPassword = false;
          this.sweet.error('Erreur', 'Votre demande de changement du mot de passe a échoué, veuillez réessayer plus tard.');
        });
      });
  }

  changeEmail(form: NgForm, event) {
    this.performEmailChange(event.target.newemail.value, 'Êtes-vous sûr de vouloir changer votre adresse de courriel ?',
      'Votre demande a bien été prise en compte, vous receverez un courriel pour confirmer votre nouvelle adresse.',
      'Votre demande de changement a échoué, veuillez réessayer plus tard.');
  }

  relaunchEmailChange(email: string) {
    this.performEmailChange(email, 'Êtes-vous sûr de vouloir confirmer votre adresse de courriel ?',
      'Votre demande a bien été prise en compte, vous receverez un courriel pour confirmer votre nouvelle adresse.',
      'Votre demande de confirmation a échoué, veuillez réessayer plus tard.');
  }

  private performEmailChange(email: string, question: string, successMessage: string, errorMessage: string) {
    this.resendEmail = true;
    this.sweet.confirmDialog('Attention !', question, (err: Error, result: any): void => {
      if (!result && !err) {
        this.resendEmail = false;
        return;
      }
      if (err) {
        this.resendEmail = false;
        this.sweet.error('Erreur', errorMessage);
        return;
      }
      this.authService.changeEmail(email).subscribe(response => {
        this.resendEmail = false;
        this.authService.refreshUser();
        this.sweet.success('Succès', successMessage);
      }, error => {
        this.resendEmail = false;
        if (error.status === 409) {
          this.sweet.warning('Attention !', 'L’adresse de courriel "' + email + '" est utilisée !');
        } else {
          this.sweet.error('Erreur', errorMessage);
        }
      });
    });
  }

  closeModal() {
    this.publicProfileActivated = !this.publicProfileActivated;
    this.ngbModalService.dismissAll();
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
