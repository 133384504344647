import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Top10Title, Top10TitleItem } from 'src/app/Models/top10title.model';
import { AuthService } from 'src/app/services/auth-service';
import { IncontournableService } from 'src/app/services/incontournable.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import * as moment from 'moment';

@Component({
  selector: 'app-top10titles',
  templateUrl: './top10titles.component.html',
  styleUrls: ['./top10titles.component.css']
})
export class Top10titlesComponent implements OnInit, OnDestroy {

  listSubs: Subscription;
  itemSubs: Subscription;
  saveSubs: Subscription;
  issuesSubs: Subscription;

  importErrorMessage = '';
  importSuccessMessage = '';

  selectedItem: Top10Title;
  operations: Top10Title[] = [];
  showSpinner = false;
  selectedIndex = -1;

  opStart: NgbDateStruct;
  opEnd: NgbDateStruct;

  today: NgbDateStruct  = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear()};

  constructor(private ngbModalService: NgbModal,
    private sweet: Sweetalert,
    private top10Service: IncontournableService,
    private authService: AuthService) { }

  ngOnInit() {
    this.listSubs = this.top10Service.getTop10ListUpdateListener()
      .subscribe(data => {
        this.showSpinner = false;
        this.operations = data;
      });

    this.itemSubs = this.top10Service.getTop10UpdateListener()
      .subscribe(data => {
        data.items.forEach(d => {
          d.issues = [];
          d.issues.push({id: d.id, titleCodif: d.codif, issueNumber: d.issueNumber, cover: d.coverUrl});
        });
        this.selectedItem = data;
        this.showSpinner = false;
      });

    this.saveSubs = this.top10Service.getTop10CallbackUpdateListner()
      .subscribe(data => {
        this.showSpinner = false;
        if (data) {
          this.ngbModalService.dismissAll();
          this.loadData();
        } else {
          this.sweet.warning('Attention !', 'Une erreur s‘est produite lors du traitement de votre demande.');
        }
      });

    this.issuesSubs = this.top10Service.getIssuesUpdateListner()
      .subscribe(data => {
        this.showSpinner = false;
        this.selectedItem.items[this.selectedIndex].issues = data;
      });

    this.loadData();
  }

  loadData() {
    this.top10Service.top10List();
  }

  addLine() {
    if (this.selectedItem.items.length === 10) {
      return;
    }
    this.selectedItem.items.push({codif: '', issueNumber: '', coverUrl: '', name: '', issues: [], id: ''});
  }

  removeLine(index: number) {
    this.selectedItem.items.splice(index, 1);
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{5}$/i;
        const lines = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.replace('"', '').split(/,|;/ig);
        }).filter(t => t.length >= 2)
        .slice(0, 10); // max 10
        if (lines.length === 0) {
          this.importErrorMessage = 'Le fichier ne contient pas de lignes.';
          input.value = '';
        } else {
          this.selectedItem.items = [];
          lines.forEach(n => {
            if (regexp.test(n[0])) {
              let issueNumber = n[1];
              let itemId = `${n[0]}-${issueNumber}-`;
              if (n.length >= 3) {
                itemId = `${n[0]}-${issueNumber.trim()}-${n[2].trim().toUpperCase()}`;
                issueNumber = `${issueNumber.trim()} ${n[2].trim().toUpperCase()}`;
              } else {
                itemId = `${n[0]}-${issueNumber[0].trim().toUpperCase().replace(' ', '-')}-`;
              }
              const item = {codif: n[0], issueNumber: issueNumber, coverUrl: null, name: null, id: null, issues: []};
              item.issues.push({id: itemId, titleCodif: item.codif, issueNumber: item.issueNumber, cover: null});
              this.selectedItem.items.push(item);
            }
          });
          this.importSuccessMessage = `Nous avons detecté ${this.selectedItem.items.length} lignes dans le fichier`;
        }
        };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  openModal(modal, index: number) {
    if (index < 0) {
      this.selectedItem = {start: null, end: null, items: [], id: ''};
      this.ngbModalService.open(modal, { size: 'lg' });
      return;
    }
    this.selectedItem = this.operations[index];
    this.showSpinner = true;
    const stDate = moment(this.selectedItem.start);
    const enDate = moment(this.selectedItem.end);
    this.opStart = { day: stDate.date(), month: stDate.month() + 1, year: stDate.year()};
    this.opEnd = { day: enDate.date(), month: enDate.month() + 1, year: enDate.year()};
    this.top10Service.top10ById(this.selectedItem.id);
    this.ngbModalService.open(modal, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  deleteOperation(id: string) {
    if (this.showSpinner) {
      return;
    }

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir supprimer cette opération ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showSpinner = true;
          this.top10Service.deleteTop10(id);
        }
      });
  }

  saveOperation() {
    if (this.showSpinner) {
      return;
    }

    this.importErrorMessage = '';
    this.importSuccessMessage = '';

    if (!this.opStart) {
      this.sweet.warning('Attention !', 'Merci de saisir une date de début');
      return;
    }

    if (!this.opEnd) {
      this.sweet.warning('Attention !', 'Merci de saisir une date de fin');
      return;
    }

    const startDate = `${this.opStart.year}-${this.opStart.month}-${this.opStart.day}T00:00:00Z`;
    const endDate = `${this.opEnd.year}-${this.opEnd.month}-${this.opEnd.day}T00:00:00Z`;
    // check if any in the same period
    const operation = this.operations.find(i => i.id !== this.selectedItem.id
        && moment(startDate).isSameOrBefore(moment(i.end))
        && moment(endDate).isSameOrAfter(moment(i.start))
      );
    if (operation) {
        this.sweet.warning('Attention !', 'Vous avez une compagne sur la même période.');
        return;
    }

    this.showSpinner = true;
    this.selectedItem.start = startDate;
    this.selectedItem.end = endDate;

    this.top10Service.addOrUpdateTop10(this.selectedItem);
  }

  doSearch(text: string, index) {
    if (!text || text.length !== 5) {
      this.showSpinner = false;
      return;
    }
    this.selectedIndex = index;
    this.selectedItem.items[this.selectedIndex].issues = [];
    this.showSpinner = true;

    this.top10Service.getIssues(text);
  }

  canEdit() {
    if (!this.selectedItem) {
      return false;
    }
    if (!this.selectedItem.id || !this.selectedItem.id.length) {
      return true;
    }
    return moment().isSameOrBefore(moment(this.selectedItem.end));
  }

  ngOnDestroy() {
    this.closeModal();
    if (this.listSubs) {
      this.listSubs.unsubscribe();
    }
    if (this.itemSubs) {
      this.itemSubs.unsubscribe();
    }
    if (this.saveSubs) {
      this.saveSubs.unsubscribe();
    }
    if (this.issuesSubs) {
      this.issuesSubs.unsubscribe();
    }
  }
}
