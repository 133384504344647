import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
// tslint:disable-next-line:max-line-length
import { AssortimentSimulationResult, AssortimentSimulationValidationResult, StoreAssortimentDashboard, StoreAssortimentResult, StoreAssortimentStatus, StoreAssortimentTitre, StorePlafonnement } from '../Models/storeAssortiment.model';
import { StoreBulkAssortiment } from '../Models/storeBulkAssortiment.model';

@Injectable({
  providedIn: 'root'
})
export class StoreAssortimentService {

  private feedbackTS = 'feedBackTS';
  private assortStatusUpdated = new Subject<StoreAssortimentStatus>();
  private assortTitlesUpdated = new Subject<StoreAssortimentResult>();
  private assortSimulationUpdated = new Subject<AssortimentSimulationResult>();

  private assortValidationUpdated = new Subject<AssortimentSimulationValidationResult>();

  private assortProposalUpdated = new Subject<string>();

  private assortWarehouseUpdated = new Subject<StoreBulkAssortiment[]>();

  private validationWarehouseUpdated = new Subject<boolean>();

  private proposalWarehouseUpdated = new Subject<boolean>();

  private plafondTitlesUpdated = new Subject<StorePlafonnement>();

  constructor(private http: HttpClient) { }

  getAssortimentSimulationListener() {
    return this.assortSimulationUpdated.asObservable();
  }

  getAssortimentSimulation(nim: string, type: number, data: any) {
    const url = `${environment.APIURL}/api/v1/stores/assortiment/${type}/simulate?nim=${nim}`;
    this.http.post<{ result: AssortimentSimulationResult }>(url, data)
      .subscribe(response => {
        if (response) {
          this.assortSimulationUpdated.next(response.result);
        } else {
          this.assortSimulationUpdated.next(null);
        }
      }, err => {
        this.assortSimulationUpdated.next(null);
      });
  }

  getAssortimentValidationListener() {
    return this.assortValidationUpdated.asObservable();
  }

  validateAssortimentSimulation(nim: string, type: number, data: any) {
    const url = `${environment.APIURL}/api/v1/stores/assortiment/${type}/validate?nim=${nim}`;
    this.http.put<{ result: AssortimentSimulationValidationResult }>(url, data)
      .subscribe(response => {
        if (response) {
          this.assortValidationUpdated.next(response.result);
        } else {
          this.assortValidationUpdated.next(null);
        }
      }, err => {
        this.assortValidationUpdated.next(null);
      });
  }

  getAssortimentTitresListener() {
    return this.assortTitlesUpdated.asObservable();
  }

  getAssortimentTitles(nim: string, type: number) {
    let url = `${environment.APIURL}/api/v1/stores/assortiment/titles?nim=${nim}&type=${type}`;
    if (type === 0) {
      url = `${environment.APIURL}/api/v1/stores/assortiment/palmares/titles?nim=${nim}`;
    }
    const ts = localStorage.getItem(this.feedbackTS);
    if (ts) {
      url += `&ts=${ts}`;
    }
    this.http.get<{ result: StoreAssortimentResult }>(url)
      .subscribe(response => {
        if (response) {
          this.assortTitlesUpdated.next(response.result);
        } else {
          this.assortTitlesUpdated.next(null);
        }
      }, err => {
        this.assortTitlesUpdated.next(null);
      });
  }

  getAssortimentDashboardListener() {
    return this.assortStatusUpdated.asObservable();
  }

  getAssortimentDashboard(nim: string, light: boolean) {
    const url = `${environment.APIURL}/api/v1/stores/assortiment/dashboard?nim=${nim}&light=${light}`;

    this.http.get<{ result: StoreAssortimentStatus }>(url)
      .subscribe(response => {
        if (response) {
          this.assortStatusUpdated.next(response.result);
        } else {
          this.assortStatusUpdated.next(null);
        }
      }, err => {
        this.assortStatusUpdated.next(null);
      });
  }


  getserviceFeedbackOnProposalListener() {
    return this.assortProposalUpdated.asObservable();
  }

  serviceFeedbackOnProposal(nim: string, status: boolean, item: StoreAssortimentTitre) {
    const url = `${environment.APIURL}/api/v1/stores/assortiment/propositions/${status}?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, item)
      .subscribe(response => {
        if (response) {
          if (response.result) {
            localStorage.setItem(this.feedbackTS, new Date().getTime().toString());
          }
          this.assortProposalUpdated.next(item.codif);
        } else {
          this.assortProposalUpdated.next(null);
        }
      }, err => {
        this.assortProposalUpdated.next(null);
      });
  }

  getWarehouseAssortimentStoresListener() {
    return this.assortWarehouseUpdated.asObservable();
  }

  getWarehouseAssortimentStores(nim: string) {
    let url = `${environment.APIURL}/api/v1/warehouses/assortiment/stores?nim=${nim}`;
    const ts = localStorage.getItem(this.feedbackTS);
    if (ts) {
      url += `&ts=${ts}`;
    }
    this.http.get<{ result: StoreBulkAssortiment[] }>(url)
      .subscribe(response => {
        if (response) {
          this.assortWarehouseUpdated.next(response.result);
        } else {
          this.assortWarehouseUpdated.next([]);
        }
      }, err => {
        this.assortWarehouseUpdated.next([]);
      });
  }

  getWarehouseAssortimentSimulation(nim: string, data: any) {
    const url = `${environment.APIURL}/api/v1/warehouses/assortiment/simulate?nim=${nim}`;
    this.http.post<{ result: StoreBulkAssortiment[] }>(url, data)
      .subscribe(response => {
        if (response) {
          this.assortWarehouseUpdated.next(response.result);
        } else {
          this.assortWarehouseUpdated.next([]);
        }
      }, err => {
        this.assortWarehouseUpdated.next([]);
      });
  }

  getAssortimentWarehouseValidationListener() {
    return this.validationWarehouseUpdated.asObservable();
  }

  validateWarehouseAssortimentSimulation(nim: string, data: any) {
    const url = `${environment.APIURL}/api/v1/warehouses/assortiment/validate?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, data)
      .subscribe(response => {
        if (response) {
          this.validationWarehouseUpdated.next(response.result);
        } else {
          this.validationWarehouseUpdated.next(false);
        }
      }, err => {
        this.validationWarehouseUpdated.next(false);
      });
  }

  getAssortimentWarehouseProposalListener() {
    return this.proposalWarehouseUpdated.asObservable();
  }

  warehouseProposalSet(nim: string, data: any) {
    const url = `${environment.APIURL}/api/v1/warehouses/assortiment/proposals?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, data)
      .subscribe(response => {
        if (response) {
          this.proposalWarehouseUpdated.next(response.result);
        } else {
          this.proposalWarehouseUpdated.next(false);
        }
      }, err => {
        this.proposalWarehouseUpdated.next(false);
      });
  }

  getPlafondListner() {
    return this.plafondTitlesUpdated.asObservable();
  }
  getPlafondTitres(nim: string) {
    const url = `${environment.APIURL}/api/v1/stores/plafonnement?nim=${nim}`;
    this.http.get<{ result: StorePlafonnement }>(url)
      .subscribe(response => {
        if (response) {
          this.plafondTitlesUpdated.next(response.result);
        } else {
          this.plafondTitlesUpdated.next(null);
        }
      }, err => {
        this.plafondTitlesUpdated.next(null);
      });
  }
}
