import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SearchService } from '../services/search.service';
import { Subscription, Subject } from 'rxjs';
import { Issue } from '../Models/issue.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GA } from '../shared/gAnalytics';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../services/auth-service';
import * as moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  keyUp = new Subject<string>();

  constructor(private searchService: SearchService,
    private ngbModalService: NgbModal,
    private authService: AuthService,
    private ga: GA) { }
  private issuesSubs: Subscription;
  private searchSubs: Subscription;

  onSaleIssues: Issue[];
  oldIssues: Issue[];

  issueSelected: Issue;
  showsearchResultCount = false;
  onSaleResultCount = 0;
  oldResultCount = 0;
  showSpinner: Boolean = true;
  loadingText = 'Recherche en cours...';
  showDetail = [];
  get onSaleCountText(): string {
    if (this.onSaleResultCount === 0) {
      if (!this.onSaleResultCount && !this.oldResultCount) {
        return 'Aucun résultat trouvé';
      }
      return '';
    } else if (this.onSaleResultCount === 1) {
      return 'Une parution en cours de vente';
    }
    return `${this.onSaleResultCount} parutions en cours de vente`;
  }

  get oldCountText(): string {
    if (this.oldResultCount === 0) {
      return 'Aucun résultat trouvé';
    } else if (this.oldResultCount === 1) {
      return 'Une ancienne parution';
    }
    return `${this.oldResultCount} anciennes parutions`;
  }

  ngOnInit() {
    this.showSpinner = false;
    this.searchSubs = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.doSearchFromString(value));

    this.issuesSubs = this.searchService.getIssuesUpdateListner()
      .subscribe((issueResult: Issue[]) => {
        issueResult.forEach(i => {
          if (!i.returnDate || !i.returnDate.length) {
            this.onSaleIssues.push(i);
            return;
          }

          const mnt = moment(i.returnDate);
          if (mnt.isValid() && mnt.isSameOrAfter(moment.now(), 'day')) {
            this.onSaleIssues.push(i);
          } else {
            this.oldIssues.push(i);
          }
        });

        this.onSaleResultCount = this.onSaleIssues.length;
        this.oldResultCount = this.oldIssues.length;
        this.showsearchResultCount = true;
        this.showSpinner = false;
      });
  }

  ngOnDestroy(): void {
    if (this.issuesSubs) {
      this.issuesSubs.unsubscribe();
    }
    if (this.searchSubs) {
      this.searchSubs.unsubscribe();
    }
  }

  onSearchTitle(form: NgForm) {
    const searchInput = form.value.keyword;
    this.keyUp.next(searchInput);
  }

  doSearchFromString(text: string) {
    this.showSpinner = true;
    this.showsearchResultCount = false;

    this.onSaleIssues = [];
    this.oldIssues = [];

    this.searchService.searchTitles(text, this.authService.currentNim, false);
    this.ga.createEventSearch(text, 1);
  }

  // on open modal and logic
  openModal(content, issue) {
    this.issueSelected = issue;
    this.ngbModalService.open(content); // ,  { size: 'lg' }
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }
}
