import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '../../Models/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  @Input() notifications: Notification[];
  @Input() newNotificationCount: Number = 0;

  constructor() { }

  ngOnInit() {

  }
}
