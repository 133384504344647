import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { NgbModalConfig, NgbDate, NgbDateStruct, NgbCalendar, NgbDatepickerConfig, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BL, BLS } from '../Models/bl.model';
import { UnsoldService } from '../services/unsold.service';
import { AuthService } from '../services/auth-service';
import { DataTableDirective } from 'angular-datatables';
import { DtLanguages } from '../shared/dtLanguages';
import { ItemToPush } from '../Models/itemToPush.model';
import { Sweetalert } from '../shared/sweetAlert';
import { Commons } from '../shared/commons';
import { GA } from '../shared/gAnalytics';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ngDevModeResetPerfCounters } from '@angular/core/src/render3/ng_dev_mode';

@Component({
  selector: 'app-unsod',
  templateUrl: './unsod.component.html',
  styleUrls: ['./unsod.component.css']
})

export class UnsodComponent implements OnInit, OnDestroy, AfterViewInit {

  bls: BL[] = [];
  invalidData = [];

  get unsold(): BL[] {
    if (!this.bls || !this.bls.length) {
      return [];
    }

    return this.bls.filter(i => (i.type || 0) === 0);
  }
  get remind(): BL[] {
    if (!this.bls || !this.bls.length) {
      return [];
    }

    return this.bls.filter(i => i.type === 1);
  }
  get forgotten(): BL[] {
    if (!this.bls || !this.bls.length) {
      return [];
    }

    return this.bls.filter(i => i.type === 2);
  }

  private blsSubs: Subscription;
  private datesCount = 5;
  showSpinner = true;
  saveProcessing = false;
  @ViewChildren('myInputUnsold') inputsUnsold;
  @ViewChildren('myInputRemind') inputsRemind;
  @ViewChildren('myInputForgot') inputsForgot;
  isReadOnly = true;
  canEFile = true;
  localData = false;

  public searchString: string;

  private dates: string[] = [];
  private datesSubs: Subscription;
  private unlockSubs: Subscription;
  private sendSubs: Subscription;
  // datatable initialisations
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  // --------
  model: NgbDateStruct;
  disabled = true;
  isDisabled = true;
  bldate: string;


  constructor(private unsoldService: UnsoldService, private authService: AuthService,
    private ngbModalConfig: NgbModalConfig, private dtlanguages: DtLanguages, calendar: NgbCalendar,
    private config: NgbDatepickerConfig, private ngbDateParserFormatter: NgbDateParserFormatter,
    private sweet: Sweetalert, private ngbModalService: NgbModal,
    private commons: Commons,
    private ga: GA
  ) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
    this.model = calendar.getToday();
    // config.markDisabled;
    // this.isDisabled = (date: NgbDate, current: { month: number }) => calendar.date === 13;

  }

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: false,
      columnDefs: [{
        'targets': [0, 6],
        'orderable': false
      }
      ],
      autoWidth: false,
      language: this.dtlanguages.frLanguage()
    };

    // this.onBindBLs();
    this.getAvailableDates();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
       dtInstance.destroy();
       this.dtTrigger.next();
   });
  }

  // bind bls
  onBindBLs(bldate: string = null) {

    this.bldate = bldate || this.bldate;

    if (this.bldate == null) {
      if ((this.dates || []).length === 0) {
        return;
      }
      this.bldate = this.dates[0];
    }

    this.showSpinner = true;
    const nim = this.authService.currentNim;

    this.bls = [];
    this.localData = false;
    const localBls = this.unsoldService.getUnslod(this.bldate);

    this.unsoldService.getBls(nim, this.bldate);

    this.blsSubs = this.unsoldService.getBlsUpdateListener()
      .subscribe((bls: BLS) => {
        this.canEFile = true;
        this.localData = true;
        this.bls = localBls;
        this.isReadOnly = false;
        this.showSpinner = false;
        if (bls) {
          this.isReadOnly = bls.isReadOnly || this.authService.isReadOnly();
          this.bls = bls.bls || [];

          if (this.isReadOnly) {
            this.localData = false;
            this.unsoldService.removeUnslod(this.bldate);
          }
          this.canEFile = bls.canEFile;
          if (!this.bls.length) {
            this.sweet.warning('Le BL/BI n’est pas disponible', '');
          }
        } else {
          this.sweet.warning('Le BL/BI n’est pas disponible', '');
        }
      });

      this.sendSubs = this.unsoldService.getSendReclamationListener()
      .subscribe(response => {
        this.saveProcessing = false;
        if (response) {
          this.onBindBLs(this.bldate);
          this.sweet.success('Succès !', 'Votre déclaration d’invendus a bien été validée.');
          this.ga.createEvent('click', 'BL/BI', 'Valider les invendus du jour');
          this.closeModal();
        } else {
          this.sweet.error('Attention !', 'Votre déclaration d’invendus n’a pas pu être validée, veuillez réessayer plus tard.');
        }
      });

      this.sendSubs = this.unsoldService.getUnlockUpdateListener()
      .subscribe(response => {
        this.saveProcessing = false;
        if (response) {
          this.sweet.success('Succès !', 'La déclaration d’invendus a bien été déverouillé.');
          this.onBindBLs(this.bldate);
        } else {
          this.sweet.error('Attention !', 'Votre déclaration d’invendus n’a pas pu être déverouillé, un BCI existe pour cette date.');
        }
      });
  }

  get isAdmin(): boolean {
    if (!this.authService) {
      return false;
    }

    return this.authService.isAdmin;
  }

  unlockBI() {
    if (!this.isAdmin) {
      return;
    }
    this.saveProcessing = true;
    this.sweet.confirmDialog('Attention !',
      '<b>IMPORTANT :</b> Vous êtes sur le point de déverouiller la déclaration, êtes-vous sûr de vouloir continuer ?',
      (error: Error, result: any): void => {

      if (!result) {
        this.saveProcessing = false;
        return;
      }
      this.unsoldService.unlockBI(this.authService.currentNim, this.bldate);
    });
  }

  refreshData() {
    this.getAvailableDates();
  }

  // Go to next input on keyup Enter
  goNextRemind(e) {
    this.commons.goNext(e, this.inputsRemind);
  }

  goNextUnsold(e) {
    this.commons.goNext(e, this.inputsUnsold);
  }

  goNextForgot(e) {
    this.commons.goNext(e, this.inputsForgot);
  }


  getAvailableDates() {
    const nim = this.authService.currentNim;

    this.unsoldService.getAvailableDates(nim, this.datesCount);
    this.datesSubs = this.unsoldService.getAvailableDatesUpdateListener()
      .subscribe((dates: string[]) => {

        if (!dates || !dates.length) {
          return;
        }
        this.rerender();
        this.dates = dates;
        const mnt = moment(dates[0]);
        this.model = { year: mnt.year(), month: mnt.month() + 1, day: mnt.date()};
        this.disableDates(dates);
        this.onBindBLs(dates[0].split('T')[0]);
      });
  }
  disableDates(dates) {
    this.config.markDisabled = (dateStruct: NgbDateStruct) => {
      return dates.filter(date => {
        const dateArr: string[] = date.split('-');
        // tslint:disable-next-line:radix
        const y: number = parseInt(dateArr[0]);
        // tslint:disable-next-line:radix
        const m: number = parseInt(dateArr[1]);
        // tslint:disable-next-line:radix
        const d: number = parseInt(dateArr[2]);
        return dateStruct.year === y && dateStruct.month === m && dateStruct.day === d;
      }).length === 0;
    };
  }

  onDateSelect(event: NgbDate) {
    this.onBindBLs(this.ngbDateParserFormatter.format(event));
  }

  checkData(element, bl) {// provided, btnId, btnValidatId, issueId) {

    if (!element || !bl) { return; }

    // tslint:disable-next-line:radix
    let unsold = parseInt(element.value, 0);
    if (element.value === '') {
      unsold = 0;
    }

    const rowId = element.parentElement.parentElement.id;
    const elementIndex = this.invalidData.indexOf(rowId);

    const input = $(element);
    if (unsold == null || Number.isNaN(unsold)) {
      if (elementIndex >= 0) {
        this.invalidData.splice(elementIndex, 1);
      }
      this.removeCssFormat(input);
      return;
    }

    bl.provided = bl.provided || unsold;
    const providedNumber = parseInt(bl.provided, 0) || 0;

    const item = this.bls.find(i => i.id === bl.id);
    if (!item) {
      return;
    }
    if (element.value === '') {
      item.unsolt = null;
    } else {
      item.unsolt = element.value;
    }

    // check name for consigne bac
    if (!item.title.match(/consign bac/i)) {
      const allowValue = unsold >= 0 && unsold <= providedNumber;
      if (!allowValue) {
        if (elementIndex < 0) {
          this.invalidData.push(rowId);
        }
        this.warningValueOrFormat(input);
        return;
      }
    }

    if (elementIndex >= 0) {
      this.invalidData.splice(elementIndex, 1);
    }

    this.localData = true;
    this.unsoldService.saveUnslod(this.bldate, this.bls);
    this.okValueOrFormat(input);

    // if (btnId) checkPersistedValueInLocalStorage(objName, btnId, btnValidatId);
  }

  warningValueOrFormat(input) {

    const errorClassCss = ' is-invalid face';
    const successClassCss = 'is-valid';

    const TD = $(input.parent().parent()).children().last()[0].firstElementChild;

    input.addClass('is-invalid face');
    input.removeClass('is-valid');
    $(TD).next().addClass(errorClassCss);
    $(TD).next().removeClass(successClassCss);
  }

  okValueOrFormat(input) {

    const errorClassCss = ' is-invalid face';
    const successClassCss = 'is-valid';
    input.addClass(successClassCss);
    input.removeClass(errorClassCss);
  }

  removeCssFormat(input) {

    const errorClassCss = ' is-invalid face';
    const successClassCss = 'is-valid';
    input.removeClass(successClassCss);
    input.removeClass(errorClassCss);
  }

  gotoLine(id) {
    document.getElementById(id).scrollIntoView();
  }

  getIssueInfoFromId(id) {
    const item = this.bls.find(b => b.id === id.substring(1));
    if (!item) {
      return '';
    }

    return `${item.title} : ${item.codif}, n° ${item.number}`;
  }

  getSumUnsold() {

    if (!this.bls || !this.bls.length) {
      return '';
    }

    return this.bls.map(a => parseInt(a.unsolt, 0) || 0).reduce((a, b) => a + b);
  }

  cannotValidate() {
    return (this.invalidData && this.invalidData.length > 0);
  }

  validateChanges() {

    if (this.cannotValidate()) {
      return;
    }

    const listItemToPush: ItemToPush[] = [];
    const localBls = this.unsoldService.getUnslod(this.bldate);

    for (let i = 0; i < localBls.length; i++) {

      const itemToPush = new ItemToPush();
      itemToPush.code = 'INV';
      itemToPush.codif = localBls[i].codif;
      itemToPush.numeroParution = localBls[i].number;
      itemToPush.nim = this.authService.currentNim;
      itemToPush.date = this.bldate;
      if (localBls[i].unsolt === null) {
        continue;
      }
      if (localBls[i].unsolt.length) {
        itemToPush.quantity = parseInt(localBls[i].unsolt, 0);
      } else {
        itemToPush.quantity = null;
      }
      listItemToPush.push(itemToPush);
    }

    this.saveProcessing = true;
    // tslint:disable-next-line:max-line-length
    this.sweet.confirmDialog('Attention !', 'Votre déclaration d’invendus va être validée.\nÊtes-vous sûr de vouloir valider ?', (error: Error, result: any): void => {

      if (!result) {
        this.saveProcessing = false;
        return;
      }

      this.unsoldService.sendReclamation(listItemToPush, this.bldate, this.authService.currentNim);
    });

  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  openModal(popupModal) {
    this.ngbModalService.open(popupModal);
  }

  checkTotal(total) {
    if (total.value === '') {
      this.sweet.warning('Attention !', 'Vous devez confirmer la quantité des invendus déclarés afin de poursuivre.');
      return;
    }
    const providedTotal = parseInt(total.value, 0) || 0;
    if (providedTotal !== this.getSumUnsold()) {
      this.sweet.warning('Attention !', 'La quantité fournie ne correspond pas à votre saisie.');
      return;
    }
    this.validateChanges();
  }

  ngOnDestroy() {
    if (this.blsSubs) {
      this.blsSubs.unsubscribe();
    }
    if (this.datesSubs) {
      this.datesSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.unlockSubs) {
      this.unlockSubs.unsubscribe();
    }
    if (this.sendSubs) {
      this.sendSubs.unsubscribe();
    }
  }

}
