import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { MonthPeriode } from '../Models/monthPeriode.model';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { ArchivesService } from '../services/archives.service';
import { AuthService } from '../services/auth-service';
import { ActivatedRoute } from '@angular/router';
import { DtLanguages } from '../shared/dtLanguages';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ArchiveDocumentByCode } from '../Models/archiveDocument.model';
import { StoreService } from '../services/store.service';
import { StoreFilterPipe } from '../shared/pipes/store-filter.pipe';
import { Store } from '../Models/store.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.css']
})
export class ArchivesComponent implements OnInit, AfterViewInit, OnDestroy {

  wsData: ArchiveDocumentByCode;
  codesData = [];
  periodData = [];
  stores: Store[] = [];
  filteredStores: Store[] = [];
  isLoading = true;
  listMonths: MonthPeriode[];
  dateArchive: MonthPeriode;
  selectedCode = '';
  selectedNim: string = null;
  displayBF = false;
  displayBCIF = false;
  displayBH = false;

  private searchSub: Subscription;
  keyUp = new Subject<string>();

  private storePipe = new StoreFilterPipe();

  // datatable initialisations
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject();

  archiveSubscription: Subscription;
  storesSubscription: Subscription;

  constructor(private archiveService: ArchivesService,
    private authService: AuthService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private dtlanguages: DtLanguages) { }

  get isProduction(): boolean {
    return environment.disableStatements;
  }

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      paging: false,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };
    this.listMonths = this.getMonths();

    this.searchSub = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      this.selectedNim = value;
      this.onNimChange(null);
    });

    this.dateArchive = this.listMonths[0];

    const month = this.route.snapshot.paramMap.get('month');
    const year = this.route.snapshot.paramMap.get('year');
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    this.selectedCode = this.route.snapshot.queryParams['selectedCode'] || '';
    if (month && year) {
      const findDate = this.listMonths.filter(d => d.from === `${year}-${month}-01`);
      if (findDate.length) {
        this.dateArchive = findDate[0];
      }
    }

    this.storesSubscription = this.storeService.getStoresUpdateListner().subscribe(res => {
      this.stores = [];
      if (res && res.length) {
        this.stores = res.sort((a, b) => {
          if (this.authService.isGmsAdmin) {
            return (a.nim || '').localeCompare(b.nim);
          }

          return (a.nil || '').localeCompare(b.nil);
        });

        if (this.authService.isWarehouse) {
          this.selectedNim = this.selectedNim || this.stores[0].nim;
        }
      }

      this.filteredStores = this.stores;

      this.loadStoreData();
    });

    this.archiveSubscription = this.archiveService.getArchiveDatesRequestsUpdateListner().subscribe(docByCode => {

      docByCode = docByCode || {};
      this.isLoading = false;
      this.periodData = [];
      this.codesData = [];
      this.wsData = docByCode;

      Object.keys(docByCode).forEach((key, index) => {
        this.codesData.push(key);
      });

      const codeExists = this.codesData.filter(a => a === this.selectedCode);

      if (!this.selectedCode.length && this.codesData.length) {
        this.selectedCode = this.codesData[0];
      } else if (!codeExists.length) {
        this.selectedCode = this.codesData[0];
      }

      this.fillTableForDate();
    }, err => {
      this.periodData = [];
      this.isLoading = false;
    });

    this.isLoading = true;
    if (this.authService.isWarehouse) {
      this.storeService.getWarehouseStores();
    } else {
      this.performCall();
    }
  }

  fillTableForDate(): void {

    if (!this.wsData) {
      return;
    }

    this.displayBF = false;
    this.displayBCIF = false;
    this.displayBH = false;

    this.periodData = [];

    const toDate = moment(this.dateArchive.to);
    const fromDate = moment(this.dateArchive.from);
    const maxDate = moment().add(2, 'd');

    const diffDays = toDate.diff(fromDate, 'days');

    const doc = this.wsData[this.selectedCode];

    if (!doc) {
      return;
    }

    for (let index = 0; index <= diffDays; index++) {

      if (toDate.isAfter(maxDate)) {
        toDate.add(-1, 'day');
        continue;
      }

      this.displayBF = this.displayBF || (doc.bfe && doc.bfe.length) ? true : false;
      this.displayBCIF = this.displayBCIF || (doc.bcifs && doc.bcifs.length) ? true : false;
      this.displayBH = this.displayBH || (doc.blhs && doc.blhs.length) ? true : false;

      this.periodData.push(
        {
          bls: doc.bls ? doc.bls.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          bcis: doc.bcis ? doc.bcis.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          bcirs: doc.bcirs ? doc.bcirs.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          bcifs: doc.bcifs ? doc.bcifs.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          blhs: doc.blhs ? doc.blhs.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          account: doc.account ? doc.account.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          press: doc.press ? doc.press.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          bill: doc.bills ? doc.bills.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          credit: doc.credits ? doc.credits.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          bre: doc.bre ? doc.bre.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          bfe: doc.bfe ? doc.bfe.filter(t => moment(t).isSame(toDate, 'day')).length : false,
          displayDate: toDate.locale('fr').format('dddd DD MMMM YYYY'),
          date: toDate.format('YYYY-MM-DD')
        }
      );

      toDate.add(-1, 'day');
    }
    this.rerender();
  }

  isAdmin(): boolean {
    return this.authService.isCommercial || this.authService.isWarehouse || this.authService.isAdmin;
  }

  onDatesChange(e): void {
    this.isLoading = true;
    this.performCall();
  }

  onCodeChange(e): void {
    this.fillTableForDate();
  }

  onNimChange(e): void {
    this.isLoading = true;
    this.selectedCode = '';
    this.performCall();
  }

  onTextChanged(e): void {
    const text = e.currentTarget.value;

    this.filteredStores = this.storePipe.transform(this.stores, text);
    if (this.filteredStores && this.filteredStores.length) {
      this.keyUp.next(this.filteredStores[0].nim);
    }
  }

  public getMonths(): MonthPeriode[] {
    const datesResults: MonthPeriode[] = [];

    for (let i = 0; i <= environment.historyMonthsAccess; i++) {
      datesResults[i] = new MonthPeriode(
        moment(new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1)).locale('fr').format('MMMM'),
        '' + (new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1)).getFullYear(),
        moment(new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1)).locale('fr').format('YYYY-MM-DD'),
        moment(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1 - i, 0)).locale('fr').format('YYYY-MM-DD'));
    }

    return datesResults;
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.archiveSubscription) {
      this.archiveSubscription.unsubscribe();
    }
  }

  private performCall() {
    if (!this.dateArchive) {
      return;
    }

    this.loadStoreData();
  }

  private loadStoreData() {
    const nim = this.selectedNim || this.authService.currentNim;
    this.archiveService.getArchiveDatesUpdatedRequests(this.dateArchive.from, this.dateArchive.to, nim);
  }

}
