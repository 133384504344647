import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArchiveDocumentByCode } from '../Models/archiveDocument.model';
import { PressStatement } from '../Models/statements/press.model';
import { AccountStatement } from '../Models/statements/account.model';
import { Bill } from '../Models/bill.model';
import { PDFDocumentModel } from '../Models/compensation.model';
import { IssueDiffered } from '../Models/issue.model';
import { StoreTicket } from '../Models/store-ticket.model';

@Injectable({
    providedIn: 'root'
})
export class ArchivesService {

    private archiveDatesUpdated = new Subject<ArchiveDocumentByCode>();
    private pressDocumentUpdated = new Subject<PressStatement>();
    private accountDocumentUpdated = new Subject<AccountStatement>();
    private differedDocumentUpdated = new Subject<IssueDiffered>();
    private differedDatesUpdated = new Subject<string[]>();
    private pdfDocumentUrlUpdated = new Subject<string>();
    private pdfDocumentDatesUpdated = new Subject<PDFDocumentModel[]>();
    private storeTicketsUpdated = new Subject<StoreTicket[]>();
    private billUpdated = new Subject<Bill>();

    public warehouseSelectedNim: string = null;

    constructor(private http: HttpClient) { }

    getArchiveDatesUpdatedRequests(dateFrom: string, dateTo: string, nim: string) {
        const url = environment.APIURL + '/api/v1/stores/archives/bycode?from=' + dateFrom + '&to=' + dateTo + '&nim=' + nim;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: ArchiveDocumentByCode }>(url)
            .subscribe(response => {
                if (response) {
                    this.archiveDatesUpdated.next(response.result);
                } else {
                    this.archiveDatesUpdated.next(null);
                }
            }, err => {
                this.archiveDatesUpdated.next(null);
            });
    }

    getArchiveDatesRequestsUpdateListner() {
        return this.archiveDatesUpdated.asObservable();
    }

    getPressDocument(date: string, nim: string, code: string) {
        const url = `${environment.APIURL}/api/v1/stores/press/${date}?nim=${nim}&code=${code || ''}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: PressStatement }>(url)
            .subscribe(response => {
                if (response) {
                    this.pressDocumentUpdated.next(response.result);
                } else {
                    this.pressDocumentUpdated.next(null);
                }
            }, err => {
                this.pressDocumentUpdated.next(null);
            });
    }

    getPressDocumentListner() {
        return this.pressDocumentUpdated.asObservable();
    }

    getAccountDocument(date: string, nim: string, code: string) {
        const url = `${environment.APIURL}/api/v1/stores/account/${date}?nim=${nim}&code=${code || ''}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: AccountStatement }>(url)
            .subscribe(response => {
                if (response) {
                    this.accountDocumentUpdated.next(response.result);
                } else {
                    this.accountDocumentUpdated.next(null);
                }
            }, err => {
                this.accountDocumentUpdated.next(null);
            });
    }

    getAccountDocumentListner() {
        return this.accountDocumentUpdated.asObservable();
    }

    getDifferedDocumentListner() {
        return this.differedDocumentUpdated.asObservable();
    }

    getDifferedDatesListner() {
        return this.differedDatesUpdated.asObservable();
    }

    getPdfDocumentDatesListner() {
        return this.pdfDocumentDatesUpdated.asObservable();
    }

    getstoreTicketsListner() {
        return this.storeTicketsUpdated.asObservable();
    }

    getPdfDocumentUrlListner() {
        return this.pdfDocumentUrlUpdated.asObservable();
    }

    getDifferedDocument(date: string, nim: string) {
        const url = environment.APIURL + '/api/v1/stores/differed/' + date + '?nim=' + nim;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: IssueDiffered }>(url)
            .subscribe(response => {
                if (response) {
                    this.differedDocumentUpdated.next(response.result);
                } else {
                    this.differedDocumentUpdated.next(null);
                }
            }, err => {
                this.differedDocumentUpdated.next(null);
            });
    }

    getDifferedDates(nim: string) {
        const url = environment.APIURL + '/api/v1/stores/differedDates?nim=' + nim;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: string[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.differedDatesUpdated.next(response.result);
                } else {
                    this.differedDatesUpdated.next(null);
                }
            }, err => {
                this.differedDatesUpdated.next(null);
            });
    }

    getPDFDocumentDates(nim: string, docType: string) {
        const url = `${environment.APIURL}/api/v1/stores/${docType}/dates?nim=${nim}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: PDFDocumentModel[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.pdfDocumentDatesUpdated.next(response.result);
                } else {
                    this.pdfDocumentDatesUpdated.next(null);
                }
            }, err => {
                this.pdfDocumentDatesUpdated.next(null);
            });
    }

    getStoreTickets(nim: string) {
        const url = `${environment.APIURL}/api/v1/stores/fiability/tickets?nim=${nim}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: StoreTicket[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.storeTicketsUpdated.next(response.result || []);
                } else {
                    this.storeTicketsUpdated.next([]);
                }
            }, err => {
                this.storeTicketsUpdated.next([]);
            });
    }

    getPDFDocumentUrl(nim: string, id: string, docType: string) {
        const url = `${environment.APIURL}/api/v1/stores/${docType}/dates/${id}?nim=${nim}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: string }>(url)
            .subscribe(response => {
                if (response) {
                    this.pdfDocumentUrlUpdated.next(response.result);
                } else {
                    this.pdfDocumentUrlUpdated.next(null);
                }
            }, err => {
                this.pdfDocumentUrlUpdated.next(null);
            });
    }

    downloadToPDF() {
        const html = `<html>
    <head>${document.head.innerHTML}</head>
    <body>${document.body.innerHTML}</body>
    </html>`;

        const options = {
            headers: new HttpHeaders({
                'Accept': 'application/pdf'
            }),
            mode: 'cors',
            'responseType': 'blob' as 'json'
        };
        return this.http.post(environment.APIPDF, {
            html: html,
            host: environment.WEBURL
        }, options);
    }

    downloadToXML(date: string, nim: string, doctype: string) {
    const url = `${environment.APIURL}/api/v1/stores/${doctype}/${date}/xml/?nim=${nim}`;
        const options = {
            headers: new HttpHeaders({
                'Accept': 'application/xml'
            }),
            mode: 'cors',
            'responseType': 'blob' as 'json'
        };
        return this.http.get(url, options);
    }

    getBillOrCredit(date: string, nim: string, billCredit: string, code: string) {
        const url = `${environment.APIURL}/api/v1/stores/${billCredit}/${date}?nim=${nim}&code=${code || ''}`;
        // tslint:disable-next-line:max-line-length
        this.http.get<{ result: Bill }>(url)
            .subscribe(response => {
                if (response) {
                    this.billUpdated.next(response.result);
                } else {
                    this.billUpdated.next(null);
                }
            }, err => {
                this.billUpdated.next(null);
            });
    }

    getBillListner() {
        return this.billUpdated.asObservable();
    }
}
