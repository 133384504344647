import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TitreExpress } from 'src/app/Models/admin/titreexpress.model';
import { TitreexpressService } from 'src/app/services/titreexpress.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-tepdf',
  templateUrl: './tepdf.component.html',
  styleUrls: ['./tepdf.component.css']
})
export class TepdfComponent implements OnInit, OnDestroy {

  showTableSpinner = false;
  selectedItem: TitreExpress;
  printMode = false;
  private titlesSub: Subscription;
  private itemSub: Subscription;
  items: TitreExpress[] = [];

  @ViewChild('content') modalLoading: ElementRef;

  constructor(private titleExpressService: TitreexpressService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private http: HttpClient) { }

  ngOnInit() {
    this.titlesSub = this.titleExpressService.getListRequestsUpdateListner()
      .subscribe(items => {
        this.showTableSpinner = false;
        this.items = items;
      }, err => {
        this.showTableSpinner = false;
        this.items = [];
      });

    this.itemSub = this.titleExpressService.getItemDetailRequestsUpdateListner()
      .subscribe(item => {
        this.showTableSpinner = false;
        this.selectedItem = item;
      }, err => {
        this.showTableSpinner = false;
        this.selectedItem = null;
      });

      const itemId = this.route.snapshot.paramMap.get('itemId') || '';
      if (itemId.length) {
        this.titleExpressService.getItemDetail(itemId);
      }
  }

  printTE() {
    window.print();
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  downloadToPDF() {
    if (!this.selectedItem) {
      return;
    }

    const fileName = `titreexpress-${this.selectedItem.number}.pdf`;
        const html = `<html>
    <head>${document.head.innerHTML}</head>
    <body>${document.body.innerHTML}</body>
    </html>`;

        const options = {
            headers: new HttpHeaders({
                'Accept': 'application/pdf'
            }),
            mode: 'cors',
            'responseType': 'blob' as 'json'
        };
        this.http.post(environment.APIPDF, {
            html: html,
            host: environment.WEBURL
        }, options).subscribe(result => {
          saveAs(result, fileName);
          this.closeModal();
        }, err => {
          this.closeModal();
        });
        this.openModal();
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  goBack() {
    if (this.authService.isAdmin || this.authService.isTitreExpressAdmin) {
      this.router.navigate(['/admin/titreexpress']);
    } else if (this.authService.isAuthenticated) {
      this.router.navigate(['/messaging']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  getItems() {
    this.showTableSpinner = true;
    this.titleExpressService.getItems();
  }

  ngOnDestroy() {
    if (this.titlesSub) {
      this.titlesSub.unsubscribe();
    }
    if (this.itemSub) {
      this.itemSub.unsubscribe();
    }
  }
}
