import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth-service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  signinFormGroup: FormGroup;

  constructor(public authService: AuthService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private cookieService: CookieService) { }

  @ViewChild('txtPasswordInput') passwordTxt: ElementRef;

  private userSubs: Subscription;

  isError = false;
  isProcessing = false;
  isShowPassword = false;
  isBearerLoading = false;
  showPasswordStr = 'Afficher le mot de passe';
  isPDIF = environment.isPdif;
  requirePFPConnection = false;

  ngOnInit() {

    this.signinFormGroup = this.fb.group({
      txtEmailInput: ['', [Validators.required]],
      txtPasswordInput: ['', [Validators.required]]
    });

    this.userSubs = this.authService.getUserUpdateListener()
      .subscribe(user => {
        this.isProcessing = false;
        if (!user) {
          this.isError = true;
        } else {
          this.isError = false;
          console.log(this.isPFPForced);
          // check if should forcePFP
          if (user.forcePFPConnect
            && this.isPFPForced) {
            this.authService.onLogout(false);
            this.requirePFPConnection = true;
          } else {
            this.authService.redirectUser();
          }
        }
      }, err => {
        this.isProcessing = false;
        this.isError = true;
      });

    const pfpBearer = this.route.snapshot.queryParams.bearer || '';
    if (pfpBearer.length) {
      this.authService.onLogout(false);
      this.isError = false;
      this.isBearerLoading = true;
      this.isProcessing = true;
      this.authService.onLogin(null, null, pfpBearer, null);
      return;
    }

    if (this.authService.isAuthenticated) {
      this.authService.redirectUser();
      return;
    }

    this.requirePFPConnection = (this.route.snapshot.queryParams.pfp || '').length;
  }

  get isPFPForced(): boolean {
    const pfpForcedConfig = environment.enablePFPForced;
    if (!pfpForcedConfig) return false;
    const startDate = new Date(pfpForcedConfig.startDate);
    return (new Date() >= startDate);
  }

  pfpUrl() {
    return environment.pfpLoginPage;
  }

  getCopyRight() {
    const date = new Date();
    return this.isPDIF ? `© 2018 - ${date.getFullYear()} PDIF` : `© 2019 - ${date.getFullYear()} LIPREGO`;
  }

  isFormValid(form: NgForm) {

    if (this.isBearerLoading
      || !form) {
      return false;
    }

    return this.isDataValid(form.value.txtEmailInput, form.value.txtPasswordInput);
  }

  private isDataValid(email: string, password: string) {

    email = email || '';
    password = password || '';

    return email.length
      && password.length;
  }

  togglePassword() {
    this.isShowPassword = !this.isShowPassword;
    if (this.isShowPassword) {
      this.showPasswordStr = 'Masquer le mot de passe';
      this.passwordTxt.nativeElement.setAttribute('type', 'text');
    } else {
      this.showPasswordStr = 'Afficher le mot de passe';
      this.passwordTxt.nativeElement.setAttribute('type', 'password');
    }
  }

  onLogin(event) {

    if (this.isProcessing) {
      return;
    }

    if (!this.isDataValid(event.target.txtEmailInput.value, event.target.txtPasswordInput.value)) {
      return;
    }

    this.isError = false;
    this.isProcessing = true;

    const nim = localStorage.getItem(AuthService.KEY_NIM_FROM_URL);
    this.authService.onLogin(event.target.txtEmailInput.value, event.target.txtPasswordInput.value, null, nim);
  }


  ngOnDestroy() {
    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
  }
}
