import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Issue } from 'src/app/Models/issue.model';

@Component({
  selector: 'app-zoom-cover',
  templateUrl: './zoom-cover.component.html',
  styleUrls: ['./zoom-cover.component.css']
})
export class ZoomCoverComponent implements OnInit, OnDestroy {

  @Input()  publication: Issue;
  @Output() closeModalOutput = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  closeModal() {
    this.closeModalOutput.emit();
  }
  ngOnDestroy() {
    this.closeModal();

  }
}
