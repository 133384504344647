import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { StoreContact } from 'src/app/Models/store.model';
import { Subscription } from 'rxjs';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { StorecomService } from 'src/app/services/admin/storecom.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-storecom',
  templateUrl: './storecom.component.html',
  styleUrls: ['./storecom.component.css']
})
export class StorecomComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};

  stores: StoreContact[] = [];
  importedStores: StoreContact[] = [];
  private completeFileLoad = new EventEmitter();

  searching = false;
  processing = false;
  private nim: string = null;

  private storesSub: Subscription;
  private storesSaveSub: Subscription;

  constructor(private dtlanguages: DtLanguages,
    private ngbModalService: NgbModal,
    private storeService: StorecomService) { }

  ngOnInit() {
    this.dtOptions = {
      searching: false,
      lengthChange: false,
      autoWidth: true,
      ordering: false,
      paging: false,
      processing: false,
      language: this.dtlanguages.frLanguage()
    };

    this.storesSub = this.storeService.getStoresUpdateListener()
      .subscribe(result => {
        this.stores = result || [];
        this.searching = false;
      }, err => {
        this.searching = false;
        this.stores = [];
      });

    this.storesSaveSub = this.storeService.getSaveStoresUpdateListener()
      .subscribe(res => {
        this.processing = false;
        this.searching = false;
        if (res) {
          if (this.importedStores.length) {
            alert('Fichier importé avec succès !');
            this.importedStores = [];
            this.ngbModalService.dismissAll();
          }
          if (this.nim && this.nim.length) {
            this.storeService.searchStores(this.nim);
          } else {
            this.stores = [];
            this.importedStores = [];
          }
        } else {
          if (this.nim && this.nim.length) {
            alert('Nous n’avons pas pu enregistré votre demande, veuillez réessayer ultérieurement.');
          } else if (this.importedStores.length) {
            this.importedStores = [];
            alert('Nous n’avons pas importer votre fichier, veuillez réessayer ultérieurement.');
          }
        }
      }, err => {
        this.processing = false;
        this.searching = false;
        this.stores = [];
        this.importedStores = [];
      });
  }

  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
  }
  closeModal() {
    this.importedStores = [];
    this.ngbModalService.dismissAll();
  }

  onFileSelect(input: HTMLInputElement) {

    const self = this;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = function(e) {
        // import form CSV
        const textFromFileLoaded = fileReader.result as string;
        self.importedStores = textFromFileLoaded.split(/\r?\n/).map(line => {
          const cols = line.replace(/"/ig, '').split(/,|;/ig);
          if (cols.length !== 4) {
            return null;
          }
          if (cols[0].length < 7) {
            cols[0] = cols[0].padStart(7, '0');
          } else if (cols[0].length > 7) {
            const startIndex = cols[0].length - 7;
            cols[0] = cols[0].substring(startIndex, 7 + startIndex);
          }
          const deskEmails = [cols[1].toLowerCase()].filter(s => s.length > 0);
          const comEmails = [cols[2].toLowerCase(), cols[3].toLowerCase()].filter(s => s.length > 0);

          const item: StoreContact = {
            nim: cols[0],
            commercialRecipients: comEmails,
            frontDeskRecipients: deskEmails
          };
          return item;
        }).filter(s => s != null);
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  searchStores(codif: string) {
    this.searching = true;
    this.nim = codif;
    this.storeService.searchStores(this.nim);
  }

  getCommercialEmail(rowIndex: number, colIndex: number): string {
    return this.getEmail(rowIndex, colIndex, 'commercialRecipients');
  }

  getHelpdeskEmail(rowIndex: number, colIndex: number): string {
    return this.getEmail(rowIndex, colIndex, 'frontDeskRecipients');
  }

  private getEmail(rowIndex: number, colIndex: number, collectionName: string) {
    const title = this.stores[rowIndex];
    const collection = title[collectionName];

    if (!collection || !collection.length || collection.length <= colIndex) {
      return '';
    }

    return collection[colIndex];
  }

  addCommercialEmail(rowIndex: number, $event, input: HTMLInputElement = null) {
    this.addEmail(rowIndex, $event, input, 'commercialRecipients');
  }

  addHelpdeskEmail(rowIndex: number, $event, input: HTMLInputElement = null) {
    this.addEmail(rowIndex, $event, input, 'frontDeskRecipients');
  }

  private addEmail(rowIndex: number, $event, input: HTMLInputElement, collectionName: string) {

    let value: string = null;
    if ($event) {
      if ($event.keyCode !== 13) {
        return;
      }
      if ($event.srcElement) {
          value = $event.srcElement.value;
      }
    } else if (input) {
      value = input.value;
    } else {
      return;
    }

    if (!value) {
      return;
    }

    const title = this.stores[rowIndex];
    const email = value.toLowerCase();
    const collection = title[collectionName];

     if (!collection) {
      title[collectionName] = [email];
     } else if (collection.findIndex(i => i.toLowerCase() === email) < 0) {
      collection.push(email);
     }

     if (input) {
      input.value = '';
     } else if ($event) {
      $event.srcElement.value = '';
     }
  }

  changeCommercialEmail(rowIndex: number, colIndex: number, $event) {
    this.changeEmail(rowIndex, colIndex, $event.target.value, 'commercialRecipients');
  }

  changeHelpdeskEmail(rowIndex: number, colIndex: number, $event) {
    this.changeEmail(rowIndex, colIndex, $event.target.value, 'frontDeskRecipients');
  }

  private changeEmail(rowIndex: number, colIndex: number, value: string, collectionName: string) {

    const title = this.stores[rowIndex];

    const collection = title[collectionName];
    if (colIndex < collection.length) {
      if (!value || !value.length) {
        collection.splice(colIndex, 1);
      } else {
        collection[colIndex] = value;
      }
    } else {
      collection.push(value);
    }
  }

  removePublisherEmail(rowIndex: number, value: string) {
    this.removeEmail(rowIndex, value, 'commercialRecipients');
  }

  removeHelpdeskEmail(rowIndex: number, value: string) {
    this.removeEmail(rowIndex, value, 'frontDeskRecipients');
  }

  private removeEmail(rowIndex: number, value: string, collectionName: string) {

    const title = this.stores[rowIndex];

    const collection = title[collectionName];
    if (!collection) {
      return;
    }

    const elementIndex = collection.findIndex(i => i.toLowerCase() === value.toLowerCase());
    if (elementIndex < 0) {
      return;
    }
    collection.splice(elementIndex, 1);
  }

  saveData(stores) {
    if (!stores || !stores.length) {
      return;
    }
    this.processing = true;
    this.storeService.saveStores(stores);
  }

  ngOnDestroy() {
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
    if (this.storesSaveSub) {
      this.storesSaveSub.unsubscribe();
    }
  }
}
