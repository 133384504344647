import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Issue } from '../Models/issue.model';
import { Subject, forkJoin } from 'rxjs';
import { HistorySale } from '../Models/history-sale.model';
import { StockInfo, StockInfoDelivery, StockInfoItem } from '../Models/stockinfo.model';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(private http: HttpClient) { }

    private issuesUpdated = new Subject<Issue[]>();
    private publicationsUpdated = new Subject<Issue[]>();
    private historysUpdated = new Subject<HistorySale[]>();

    private stockUpdated = new Subject<StockInfoItem[]>();
    private deliveryUpdated = new Subject<StockInfoDelivery>();

    // get title by keyword
    searchTitles(keyword: string, nim: string, allowTest: Boolean, actionType: string = '') {
        const url = `${environment.APIURL}/api/v1/content/search/titles?q=${keyword}&nim=${nim}&allowTest=${allowTest}&actionType=${actionType}`;
        this.http.get<{ result: Issue[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.issuesUpdated.next(response.result);
                } else {
                    this.issuesUpdated.next([]);
                }
            }, err => {
                this.issuesUpdated.next([]);
              });
    }

    // update issues object
    getIssuesUpdateListner() {
        return this.issuesUpdated.asObservable();
    }

    // get parutions
    getPublications(codif: string, nim: string, onlyRecent: boolean, includeProvided: boolean, onlySupplied: boolean) {

        let url = environment.APIURL + '/api/v1/content/search/issues?codif=' + codif + '&nim=' + nim;

        if (onlyRecent) {
            url += '&onlyRecent=' + onlyRecent;
        }
        if (includeProvided) { url += '&includeProvided=' + includeProvided; }
        if (onlySupplied) { url += '&onlySupplied=' + onlySupplied; }

        this.http.get<{ result: Issue[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.publicationsUpdated.next(response.result);
                } else {
                    this.publicationsUpdated.next([]);
                }
            }, err => {
                this.publicationsUpdated.next([]);
              });
    }

    getNextPublications(codif: string, nim: string) {
        const url = `${environment.APIURL}/api/v1/content/search/issues/next?codif=${codif}&nim=${nim}`;

        this.http.get<{ result: Issue[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.publicationsUpdated.next(response.result);
                } else {
                    this.publicationsUpdated.next([]);
                }
            }, err => {
                this.publicationsUpdated.next([]);
              });
    }

    getPublicationsWithNext(codif: string, nim: string) {

        const nextPubsurl = `${environment.APIURL}/api/v1/content/search/issues/next?codif=${codif}&nim=${nim}`;
        const pubsUrl = `${environment.APIURL}/api/v1/content/search/issues?codif=${codif}&nim=${nim}&onlySupplied=true`;

        const pubsGet = this.http.get<{ result: Issue[] }>(pubsUrl);
        const nextPubs = this.http.get<{ result: Issue[] }>(nextPubsurl);

        forkJoin([pubsGet, nextPubs]).subscribe(results => {

            const pubsResponse = results[0];
            const nextPubsResponse = results[1];

            const issues = [];

            if (nextPubsResponse.result && nextPubsResponse.result.length) {
                issues.push(nextPubsResponse.result[0]);
            }

            if (pubsResponse.result) {
                pubsResponse.result.slice(0, 2).forEach(i => {
                    issues.push(i);
                });
            }

            this.publicationsUpdated.next(issues);
        },
            error => {
                this.publicationsUpdated.next([]);
            });
    }

    getStockInfo(ids: string[], nim: string) {

        const url = `${environment.APIURL}/api/v1/content/issues/stock?nim=${nim}`;

        this.http.post<{ result: StockInfo }>(url, ids)
            .subscribe(response => {
                if (response) {
                    this.stockUpdated.next(response.result.items);
                    this.deliveryUpdated.next(response.result.expectedDelivery);
                } else {
                    this.stockUpdated.next(null);
                    this.deliveryUpdated.next(null);
                }
            }, err => {
                this.stockUpdated.next(null);
                this.deliveryUpdated.next(null);
              });
    }

    // get parutions observable
    getPublicationsUpdateListener() {
        return this.publicationsUpdated.asObservable();
    }

    getStockUpdateListener() {
        return this.stockUpdated.asObservable();
    }

    getDeliveryDateUpdateListener() {
        return this.deliveryUpdated.asObservable();
    }

    // get history
    getHistorys(titleId: string, nim: string) {

        const url = `${environment.APIURL}/api/v1/stores/${nim}/titles/${titleId}/history`;

        this.http.get<{ result: HistorySale[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.historysUpdated.next(response.result);
                } else {
                    this.historysUpdated.next([]);
                }
            }, err => {
                this.historysUpdated.next([]);
              });
    }

    // get history observable
    getHistoryUpdateListener() {
        return this.historysUpdated.asObservable();
    }
}
