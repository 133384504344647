import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { BL, BLS } from '../Models/bl.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClaimsService {

    constructor(private http: HttpClient) { }
    private bls: BL[] = [];
    private blsUpdated = new Subject<BLS>();

    private dates: string[] = [];
    private datesUpdated = new Subject<string[]>();

    private unlockUpdated = new Subject<boolean>();
    private sendUpdated = new Subject<boolean>();

    // get list of claims by date and nim
    getBls(nim: string, bldate: string) {
        const lastTimestamp = localStorage.getItem(environment.lastRequestTimestamp) || new Date().toISOString().substring(0, 10);
        const url = `${environment.APIURL}/api/v1/stores/bls/delivered?nim=${nim}&blDate=${bldate}&ts=${lastTimestamp}`;
        this.http.get<{ result: BL[], isReadOnly: boolean}>(url)
            .subscribe(response => {
                if (response) {
                    this.bls = response.result;
                    const a: BLS = {
                        bls: this.bls,
                        isReadOnly: response.isReadOnly
                    };
                    this.blsUpdated.next(a);
                } else {
                    this.blsUpdated.next(null);
                }
            }, err => {
                this.blsUpdated.next(null);
              });
    }

    // return as observable
    getBlsUpdateListener() {
        return this.blsUpdated.asObservable();
    }

    // get list of available dates
    getAvailableDates(nim: string, count: number) {
        const url = `${environment.APIURL}/api/v1/stores/bls/availabledates?nim=${nim}&take=${count}`;
        this.http.get<{ result: string[] }>(url)
            .subscribe(response => {
                if (response) {
                    this.dates = response.result;
                    this.datesUpdated.next([...this.dates]);
                } else {
                    this.datesUpdated.next([]);
                }
            }, err => {
                this.datesUpdated.next([]);
              });
    }

    // return as observable
    getAvailableDatesUpdateListener() {
        return this.datesUpdated.asObservable();
    }

    saveClaims(date: string, bls: BL[]) {

        localStorage.setItem('claims_' + date, JSON.stringify(bls));
    }
    removeClaims(date: string) {

        localStorage.removeItem('claims_' + date);
    }
    getClaims(date: string): BL[] {
       return JSON.parse(localStorage.getItem('claims_' + date));
    }

    sendReclamation(data: any, blDate: string, nim: string) {
        // tslint:disable-next-line:max-line-length
        this.http.put<{ result: boolean }>(environment.APIURL + '/api/v1/stores/bls/reclamation/lock?nim=' + nim + '&blDate=' + blDate, data)
        .subscribe(response => {
            if (response.result) {
              localStorage.setItem(environment.lastRequestTimestamp, new Date().getTime().toString());
            }
            this.sendUpdated.next(response.result);
          },
            () => {
                this.sendUpdated.next(false);
            });
    }

    getSendReclamationListener() {
        return this.sendUpdated.asObservable();
    }

    // get list of claims by date and nim
    unlockBL(nim: string, bldate: string) {
        const url = `${environment.APIURL}/api/v1/stores/bl/unlock?nim=${nim}&blDate=${bldate}`;
        this.http.put<{ result: boolean }>(url, {})
            .subscribe(response => {
                localStorage.setItem(environment.lastRequestTimestamp, new Date().getTime().toString());
                this.unlockUpdated.next(response.result);
            },
                () => {
                    this.unlockUpdated.next(false);
                });
    }

    getUnlockUpdateListener() {
        return this.unlockUpdated.asObservable();
    }
}
