import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PendingRequestCounter, Title } from '../Models/title.model';

@Injectable({
  providedIn: 'root'
})
export class PendingrequestService {

  private titleUpdated = new Subject<Title[]>();
  private counterUpdated = new Subject<PendingRequestCounter>();
  private removeUpdated = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  getRemoveListener() {
    return this.removeUpdated.asObservable();
  }
  getPendingRequestListener() {
    return this.titleUpdated.asObservable();
  }
  getCounterPendingRequestListener() {
    return this.counterUpdated.asObservable();
  }

  getPendingRequestCounter(nim: string) {
    const url = `${environment.APIURL}/api/v1/stores/requests/pendingrequests/counters?nim=${nim}`;
    this.http.get<{ result: PendingRequestCounter }>(url)
      .subscribe(response => {
        if (response) {
          this.counterUpdated.next(response.result);
        } else {
          this.counterUpdated.next(null);
        }
      }, err => {
        this.counterUpdated.next(null);
      });
  }

  // get list of services by nim
  getPFPPendingRequests(nim: string, type: number) {
    this.getPendingRequest(nim, 'PFP', type);
  }

  private getPendingRequest(nim: string, source: string, type: number) {
    const url = `${environment.APIURL}/api/v1/stores/requests/pendingrequests?nim=${nim}&source=${source}&type=${type}`;
    this.http.get<{ result: Title[] }>(url)
      .subscribe(response => {
        if (response) {
          this.titleUpdated.next(response.result);
        } else {
          this.titleUpdated.next([]);
        }
      }, err => {
        this.titleUpdated.next([]);
      });
  }

  removePendingRequest(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/stores/requests/pendingrequests/${id}?nim=${nim}`;
    this.http.delete<{ result: boolean}>(url)
      .subscribe(response => {
        if (response) {
          this.removeUpdated.next(response.result);
        } else {
          this.removeUpdated.next(false);
        }
      }, err => {
        this.removeUpdated.next(false);
      });
  }
}
