import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';


@Injectable({
    providedIn: 'root'
})
export class CustomValidator {

    // Validates numbers
    static numberValidator(input): any {
        if (input.pristine) {
            return null;
        }
        const REGEXP = /^[0-9]\d*$/;
        input.markAsTouched();
        if (REGEXP.test(input.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }
    // Validates zeens phone number format
    static phoneNumberValidator(input): any {
        if (input.pristine) {
            return null;
        }
        const REGEXP = /^\d{2}\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/;
        input.markAsTouched();
        if (REGEXP.test(input.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates email format
    static emailValidator(input): any {
        if (input.pristine) {
            return null;
        }

        // tslint:disable-next-line:max-line-length
        const REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        input.markAsTouched();
        if (REGEXP.test(input.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates email format
    static ibanValidator(input): any {
        if (input.pristine) {
            return null;
        }

        // tslint:disable-next-line:max-line-length
        const REGEXP = /^[A-Z]{2}\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{3}$/;
        input.markAsTouched();
        if (REGEXP.test(input.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    static ibanCodeValidator(input): any {
        if (input.pristine) {
            return null;
        }

        // tslint:disable-next-line:max-line-length
        const REGEXP = /^\d{6}$/;
        input.markAsTouched();
        if (REGEXP.test(input.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    static matchPassword(AC: AbstractControl) {
        const password = AC.get('txtPasswordInput').value; // to get value in input tag
        const confirmPassword = AC.get('txtConfirmPasswordInput').value; // to get value in input tag
        if (confirmPassword !== '') {
            if (password !== confirmPassword) {
                AC.get('txtConfirmPasswordInput').setErrors({ matchPassword: true });
            } else {
                return null;
            }
        }
    }


    static matchPasswordProfil(AC: AbstractControl) {
        const password = AC.get('txtNewPasswordInput').value; // to get value in input tag
        const confirmPassword = AC.get('txtConfirmPasswordInput').value; // to get value in input tag
        if (confirmPassword !== '') {
            if (password !== confirmPassword) {
                AC.get('txtConfirmPasswordInput').setErrors({ matchPassword: true });
            } else {
                return null;
            }
        }
    }

    static matchEmailProfil(AC: AbstractControl) {
        const password = AC.get('newEmailInput').value; // to get value in input tag
        const confirmPassword = AC.get('confirmEmailInput').value; // to get value in input tag
        if (confirmPassword !== '') {
            if (password !== confirmPassword) {
                AC.get('confirmEmailInput').setErrors({ matchPassword: true });
            } else {
                return null;
            }
        }
    }

}
