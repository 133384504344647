import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.css']
})
export class TimepickerComponent implements OnInit {

  @Input() time = { hour: 13, minute: 30 };
  @Output() timeOut = new EventEmitter<any>();

  // time = { hour: 13, minute: 30 };
  constructor() { }

  ngOnInit() {

  }
  onchange() {
    this.timeOut.emit(this.time);
  }

}
