import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { NgbModalConfig, NgbDate, NgbDateStruct, NgbDatepickerConfig, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProCustomerOrder } from 'src/app/Models/order.model';
import { OrderService } from 'src/app/services/order.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { AuthService } from 'src/app/services/auth-service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { CurrencyFormatter } from 'src/app/shared/pipes/currency-formatter.pipe';
import * as moment from 'moment';


@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.css']
})
export class OrderTrackingComponent implements OnInit, OnDestroy, AfterViewInit {

  orders: ProCustomerOrder[];
  isLoading = true;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  orderSelected: ProCustomerOrder;
  showBtnSpinner = false;
  private currencyPipe = new CurrencyFormatter();
  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  private customers: string[] = [];
  get customerReferences(): string[] {

    if (!this.customers.length
      && this.orders && this.orders.length) {
        this.customers = this.orders.map((v, i) => v.reference)
                  .filter(this.onlyUnique)
                  .sort((a, b) => 0 - (a < b ? 1 : -1));
    }

    return this.customers;
  }

  constructor(private orderService: OrderService,
    private authService: AuthService,
    private config: NgbDatepickerConfig,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private ngbModalService: NgbModal,
    private sweet: Sweetalert,
    private dtlanguages: DtLanguages) { }

  ngOnInit() {
     // datatable options
     this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      columnDefs: [{
        'targets': [1, 5],
        'orderable': false
      }
      ],
      autoWidth: false,
      order: [0, 'desc'],
      language: this.dtlanguages.frLanguage()
    };
    this.getProOrders('', '', '');
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
       dtInstance.destroy();
       this.dtTrigger.next();
   });
  }
  getProOrdersByOptions() {
    const from = this.fromDate ? this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day : '';
    const to = this.toDate ? this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day : '';
    const reference = (document.getElementById('sltReference') as HTMLInputElement).value;
    this.getProOrders(from, to, reference);
  }

  getStatus(order: ProCustomerOrder): string {
    if (!order) {
      return '';
    }

    if (!order.status) {
      return 'En attente';
    }

    if (order.received) {
      if (order.paid) {
        return 'Soldé';
      }
      return 'À payer';
    }

    const mnt = moment(order.expectedDelivery);
    if (!order.expectedDelivery
      || !mnt.isValid()) {
      return 'En cours de livraison';
    }

    const diffDays = mnt.diff(moment(), 'days');
    if (diffDays === 0) {
      return 'Livraison prévue pour aujourd’hui';
    }

    return `Livraison prévue pour ${mnt.locale('fr').format('DD MMM YYYY')}`;
  }

  total() {
    if (!this.orderSelected || !this.orderSelected.items || !this.orderSelected.items.length) {
      return 0;
    }

    return this.orderSelected.items.map((v, i) => {
      return (v.retrievedQuantity || v.quantity) * v.unitPrice;
    }).reduce((a, v) => a + v);
  }

  totalToPay() {
    return this.total() - this.totalPrepaid();
  }

  totalPrepaid() {
    if (!this.orderSelected || !this.orderSelected.items || !this.orderSelected.items.length) {
      return 0;
    }

    const items = this.orderSelected.items.filter(i => i.paid);

    if (!items.length) {
      return 0;
    }

    return items.filter(i => i.paid).map((v, i) => {
      return (v.retrievedQuantity || v.quantity) * v.unitPrice;
    }).reduce((a, v) => a + v);
  }

  getProOrders(dFrom: string, dTo: string, reference: string) {
    this.isLoading = true;
    this.orderService.getProOrders(dFrom, dTo, reference, this.authService.currentNim)
      .subscribe(res => {
        if (res.result) {
          this.orders = res.result;
          this.isLoading = false;
          this.rerender();
        }
      },
        err => {
          this.isLoading = false;
        });
  }

  onChangeQantity(itemId: string) {
    const item = this.orderSelected.items.find(i => i.id === itemId);

    if (!item) {
      return;
    }

    const availableQtt = parseInt(((document.getElementById('txt-' + item.id) as HTMLInputElement).value), 0);
    if (availableQtt === undefined) {
      return;
    }
    item.retrievedQuantity = availableQtt;
  }

  cancelOrder(index: number) {
    const order = this.orders[index];

    if (!order) {
      return;
    }

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir annuler cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          // to be adapted with API
          // const items = this.itemsToValidate;
          this.showBtnSpinner = true;
          this.orderService.cancelOrder(order.id, order.reference)
            .subscribe(res => {
              this.showBtnSpinner = false;
              if (res && res.result) {
                this.sweet.success('Succès !', 'Commande annulée');
                this.getProOrders('', '', '');
              } else {
                this.sweet.success('Erreur !', 'Une erreur s’est produite au moment de l’annulation de la commande.');
              }
            },
              err => {
                this.showBtnSpinner = false;
              });
        }
      });
  }

  onValidate(paid?: boolean) {
    if (!this.orderSelected) {
      return;
    }

    this.orderSelected.items.forEach(i => {
      if (paid !== undefined) {
        i.paid = paid;
      }
      if (i.retrievedQuantity === undefined) {
        i.retrievedQuantity = i.quantity;
      }
    });

    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          // to be adapted with API
          // const items = this.itemsToValidate;
          this.showBtnSpinner = true;
          this.orderService.deliverOrder(this.orderSelected.id, this.orderSelected)
            .subscribe(res => {
              this.showBtnSpinner = false;
              if (res) {
                this.sweet.success('Succès !', 'Commande accepté');
                this.getProOrders('', '', '');
                this.closeModal();
              }
            },
              err => {
                this.showBtnSpinner = false;
              });
        }
      });
  }

  onPay() {
    if (!this.orderSelected) {
      return;
    }

    this.onValidate(true);
  }

  openModal(content, index: number) {
    this.orderSelected = this.orders[index];
    this.ngbModalService.open(content, { size: 'lg' });
  }
  closeModal() {
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }

  printDocument() {
    if (!this.orderSelected) {
      return;
    }

    this.printAll([this.orderSelected]);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  printAll(orders: ProCustomerOrder[] = null) {

    orders = orders || this.orders;

    if (!orders || !orders.length) {
      return;
    }

    let html = `<html>
    <head>
    ${document.head.innerHTML}
    </head><body><div class="container">`;

    let minDate = moment();
    let maxDate = moment();

    const customers = orders.map((v, i) => v.reference).filter(this.onlyUnique);

    let table = `<table class="table">
    <thead>
    <tr>
      <th>Référence</th>
      <th>Titre</th>
      <th class="text-center">Quantité</th>
      <th class="text-center">Prix</th>
    </tr>
    </thead><tbody>`;

    let totalQty = 0;
    let total = 0;
    let totalPaid = 0;

    orders.forEach(order => {

      const dt = moment(order.date);
      if (minDate.diff(dt, 'days') > 0) {
        minDate = dt;
      }
      if (maxDate.diff(dt, 'days') < 0) {
        maxDate = dt;
      }

      table += `<tr><td colspan="4">`;
      if (customers.length > 1) {
        table += `${order.reference}<br>`;
      }
      table += `Commande : ${order.id} du ${dt.locale('fr').format('DD MMM YYYY')} (${order.items.length} exemplaires)</td></tr>`;
      order.items.forEach(item => {
        const qty = item.retrievedQuantity === undefined ? item.quantity : item.retrievedQuantity;
        const price = qty * item.unitPrice;
        totalQty += qty;
        total += price;
        totalPaid += item.paid ? price : 0;
        table += `<tr>
          <td>${item.id}</td>
          <td class="bold">${item.name}
            <br/>
            <span class="font-weight-lighter">${moment(item.releaseDate).locale('fr').format('DD MMMM YYYY')}<span>
          </td>
          <td class="text-center">${qty}</td>
          <td class="text-center">${this.currencyPipe.transform(price, false)}</td>
        </tr>`;
      });
    });

    table += `<tr class="bold">
        <td colspan="3" class="text-right p-r-10">Total</td>
        <td class="text-center">${this.currencyPipe.transform(total, false)}</td>
      </tr>`;
      table += `<tr class="bold">
        <td colspan="3" class="text-right p-r-10">Total prépayé</td>
        <td class="text-center">${this.currencyPipe.transform(totalPaid, false)}</td>
      </tr>`;
      table += `<tr class="bold">
        <td colspan="3" class="text-right p-r-10">Reste à payer</td>
        <td class="text-center">${this.currencyPipe.transform(total - totalPaid, false)}</td>
      </tr>`;

    table += '</tbody></table>';

    if (customers.length === 1) {
      html += `<h6>Client : ${customers[0]}</h6>`;
    } else if (customers.length > 1) {
      html += `<h6>Synthèse des commandes<h6>`;
    }

    if (minDate.isSame(maxDate)) {
      html += `<div>Date : ${minDate.locale('fr').format('DD MMMM YYYY')}</div>`;
    } else {
      html += `<div>Du : ${minDate.locale('fr').format('DD MMMM YYYY')}</div>`;
      html += `<div>Au : ${maxDate.locale('fr').format('DD MMMM YYYY')}</div>`;
    }

    html += `${table}</div></body></html>`;

    const w = window.open();
    w.document.write(html);
    setTimeout(() => {
      w.print();
      w.close();
    }, 200);
  }
}
