import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TitreInfo } from 'src/app/Models/titreingo.model';
import { ArticlesService } from 'src/app/services/articles.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-titreinfo',
  templateUrl: './titreinfo.component.html',
  styleUrls: ['./titreinfo.component.css']
})
export class TitreinfoComponent implements OnInit, OnDestroy {

  private addSub: Subscription;
  private listSub: Subscription;
  dtOptions: DataTables.Settings = {};

  dataLoading = true;

  items: TitreInfo[] = [];

  dataToUpdate = '';

  constructor(private articleService: ArticlesService,
    private sweet: Sweetalert,
    private ngbModalService: NgbModal) { }

  ngOnInit() {

    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false,
      responsive: true,
      autoWidth: true,
      order: [0, 'desc']
    };

    this.listSub = this.articleService.getAllBlobsListner()
    .subscribe(result => {
      this.dataLoading = false;
      this.items = result;
    });

    this.addSub = this.articleService.getAddBlobListner()
    .subscribe(result => {
      this.dataLoading = false;
      this.dataToUpdate = '';
      if (result) {
        this.articleService.getAllBlobs();
        this.sweet.success('Confirmation', 'Votre article a bien été ajouté');
      } else {
        this.sweet.error('Attention !', 'Nous n’avons pas pu ajouter votre article.');
      }
    });

    this.articleService.getAllBlobs();
  }

  openModal(tiModal) {
    this.ngbModalService.open(tiModal);
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  getItemDateToString(date: string): string {
    const mnt = moment(date);
    if (!mnt.isValid()) {
      return '–';
    }

    return mnt.locale('fr').format('DD MMM YYYY');
  }

  onFileSelect(input: HTMLInputElement) {

    const self = this;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = function (e) {
        self.dataToUpdate = fileReader.result as string;
      };
      fileReader.readAsDataURL(fileToRead);
    }
  }

  addItem(form: NgForm) {

    if (this.dataLoading) {
      return;
    }

    if (!this.dataToUpdate || !this.dataToUpdate.length) {
      return;
    }

    const formDate = form.value.datePickerTiDate;
    const date = moment(`${formDate.year}-${formDate.month}-${formDate.day}`).locale('fr');
    this.dataLoading = true;
    const data: TitreInfo = {
      number : form.value.txtNumber,
      date : date.format('YYYY-MM-DD'),
      base64: this.dataToUpdate,
      url: ''
    };

    this.articleService.getAddBlob(data);
  }

  ngOnDestroy() {
    if (this.addSub) {
      this.addSub.unsubscribe();
    }
    if (this.listSub) {
      this.listSub.unsubscribe();
    }
  }

}
