import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SondageItem, SondageAdminItem } from '../Models/sondage.model';

@Injectable({
  providedIn: 'root'
})
export class SondageService {

  private STORAGE_PREFIX = 'SONDAGE';
  private sondageListStoreUpdated = new Subject<SondageAdminItem[]>();
  private sondageStoreUpdated = new Subject<SondageItem>();
  private sondageStoreListUpdated = new Subject<SondageItem[]>();
  private sondageCallbackUpdated = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  setToStorage(nim: string, date: string) {
    localStorage.setItem(`${this.STORAGE_PREFIX}_${nim}`, date);
  }

  removeFromStorage(nim: string) {
    localStorage.removeItem(`${this.STORAGE_PREFIX}_${nim}`);
  }

  getSondageListUpdateListener() {
    return this.sondageListStoreUpdated.asObservable();
  }

  getStoreSondageUpdateListener() {
    return this.sondageStoreUpdated.asObservable();
  }

  getSondageCallbackUpdateListner() {
    return this.sondageCallbackUpdated.asObservable();
  }

  sondageList(nim: string) {
    const url = `${environment.APIURL}/api/v1/sondages?nim=${nim}`;
    this.http.get<{ result: SondageAdminItem[] }>(url)
      .subscribe(response => {
        if (response) {
          this.sondageListStoreUpdated.next(response.result);
        } else {
          this.sondageListStoreUpdated.next([]);
        }
      }
        ,
        error => {
          this.sondageListStoreUpdated.next([]);
        });
  }

  sondageCallBack(nim: string, id: string, status: boolean) {
    const url = `${environment.APIURL}/api/v1/sondages/${id}/stores/${nim}?answer=${status}`;
    this.http.put<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.sondageCallbackUpdated.next(response.result);
        } else {
          this.sondageCallbackUpdated.next(false);
        }
      }
        ,
        error => {
          this.sondageCallbackUpdated.next(false);
        });
  }

  changeStatus(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/sondages/${id}/status?nim=${nim}`;
    this.http.put<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.sondageCallbackUpdated.next(response.result);
        } else {
          this.sondageCallbackUpdated.next(false);
        }
      }
        ,
        error => {
          this.sondageCallbackUpdated.next(false);
        });
  }

  deleteSondage(nim: string, id: string) {
    const url = `${environment.APIURL}/api/v1/sondages/${id}?nim=${nim}`;
    this.http.delete<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.sondageCallbackUpdated.next(response.result);
        } else {
          this.sondageCallbackUpdated.next(false);
        }
      }
        ,
        error => {
          this.sondageCallbackUpdated.next(false);
        });
  }

  addSondage(nim: string, body: any) {
    const url = `${environment.APIURL}/api/v1/sondages?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, body)
      .subscribe(response => {
        if (response) {
          this.sondageCallbackUpdated.next(response.result);
        } else {
          this.sondageCallbackUpdated.next(false);
        }
      }
        ,
        error => {
          this.sondageCallbackUpdated.next(false);
        });
  }

  getStoreSondageQuestion(nim: string) {
    const url = `${environment.APIURL}/api/v1/sondages/stores/${nim}`;
    this.http.get<{ result: SondageItem }>(url)
      .subscribe(response => {
        if (response) {
          this.sondageStoreUpdated.next(response.result);
        } else {
          this.sondageStoreUpdated.next(null);
        }
      }
        ,
        error => {
          this.sondageStoreUpdated.next(null);
        });
  }

  getStoreSondageListUpdateListener() {
    return this.sondageStoreListUpdated.asObservable();
  }

  getStoreSondageQuestions(nim: string) {
    const url = `${environment.APIURL}/api/v1/sondages/stores/${nim}/list`;
    this.http.get<{ result: SondageItem[] }>(url)
      .subscribe(response => {
        if (response) {
          this.sondageStoreListUpdated.next(response.result);
        } else {
          this.sondageStoreListUpdated.next([]);
        }
      }
        ,
        error => {
          this.sondageStoreListUpdated.next([]);
        });
  }
}
