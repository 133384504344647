import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authInfo = AuthService.getAuthInfo();
        if (!authInfo.length) {
            return next.handle(req);
        }

        const authRequest = req.clone({
            headers: req.headers.set('Authorization', authInfo)
        });
        return next.handle(authRequest);
    }
}
