import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Issue } from '../Models/issue.model';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TitlesService {

  constructor(private http: HttpClient) { }

  private issue: Issue;
  private issuesUpdated = new Subject<Issue>();

  // get title by keyword
  searchIssue(titleCodif: string, issueNumber: string, nim: string) {
    //environment.APIURL + '/api/v1/content/titles/' + titleCodif + ( issueNumber ? ('/issues/' + issueNumber) : '')
    const url = `${environment.APIURL}/api/v1/content/titles/${titleCodif}/${issueNumber ? ('/issues/' + issueNumber) : ''}?nim=${nim}`;
    // tslint:disable-next-line:max-line-length
    this.http.get<{ result: Issue }>(environment.APIURL + '/api/v1/content/titles/' + titleCodif + ( issueNumber ? ('/issues/' + issueNumber) : ''))
        .subscribe(response => {
          if (response) {
            this.issue = response.result;
            this.issuesUpdated.next(this.issue);
          } else {
            this.issuesUpdated.next(null);
          }
        }, err => {
          this.issuesUpdated.next(null);
        });
}

getIssuesUpdateListner() {
    return this.issuesUpdated.asObservable();
  }

}
