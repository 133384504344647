import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Authorization } from '../Models/admin/authorization.model';

@Injectable({
  providedIn: 'root'
})

export class AccessService {

  constructor(private http: HttpClient) { }

  getNimAuthorizationList(nim: string) {
    return this.http.get<{ result: Authorization }>(environment.APIURL + '/api/v2/security/access?nim=' + nim);
  }

  // update access
  updateRestrictions(nim: string, data: any) {
    return this.http.put<{ result: Authorization }>(environment.APIURL + '/api/v2/security/access?nim=' + nim, data);
  }

  // associate user to Nim
  associateUserToNim(data: any) {
    return this.http.put<{ result: Authorization }>(environment.APIURL + '/api/v2/security/authorize', data);
  }

  // dissociate user from Nim
  dissociateUserFromNim(data: any) {
    return this.http.put<{ result: Authorization }>(environment.APIURL + '/api/v2/security/unauthorize', data);
  }

}
