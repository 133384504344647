import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DtLanguages {

    frLanguage() {

        const lang: any = {
            'sProcessing': 'Traitement en cours...',
            'sSearch': 'Recherche',
            'searchPlaceholder': 'Tapez pour rechercher',
            'sInfo': 'Affichage de l’&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments',
            'sInfoEmpty': 'Affichage de l’&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment',
            'sInfoFiltered': '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            'sInfoPostFix': '',
            'sLoadingRecords': 'Chargement en cours…',
            'sZeroRecords': 'Vous n’avez aucune donnée &agrave; ce jour.',
            'sEmptyTable': 'Vous n’avez aucune donnée &agrave; ce jour.',
            // tslint:disable-next-line:max-line-length
            'sLengthMenu': '<div id="fav_show_wrapper"><select name="fav_show">' + '<option value="10">AFFICHER 10 LIGNES</option>' + '<option value="20">AFFICHER 20 LIGNES</option>' + '<option value="30">AFFICHER 30 LIGNES</option>' + '<option value="40">AFFICHER 40 LIGNES</option>' + '<option value="50">AFFICHER 50 LIGNES</option>' + '<option value="-1">AFFICHER TOUTES LES LIGNES</option>' + '</select></div>',
            'oPaginate': {
                'sFirst': '<<',
                'sPrevious': '<',
                'sNext': '>',
                'sLast': '>>'
            },
            'oAria': {
                'sSortAscending': ': activer pour trier la colonne par ordre croissant',
                'sSortDescending': ': activer pour trier la colonne par ordre d&eacute;croissant'
            }

        };
        return lang;
    }
}
