import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ArchivesService } from 'src/app/services/archives.service';
import { AuthService } from 'src/app/services/auth-service';
import { PDFDocumentModel } from 'src/app/Models/compensation.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-compensation',
  templateUrl: './compensation.component.html',
  styleUrls: ['./compensation.component.css']
})
export class CompensationComponent implements OnInit, OnDestroy {

  isLoading = true;
  noDataToDisplay = false;
  private conpensationUrlSubscription: Subscription;
  private conpensationDatesSubscription: Subscription;

  pdfData: SafeResourceUrl;

  dates: PDFDocumentModel[] = [];

  constructor(private archiveService: ArchivesService,
    private authService: AuthService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.conpensationUrlSubscription = this.archiveService.getPdfDocumentUrlListner()
      .subscribe(pdfBase64 => {
        this.isLoading = false;
        if (pdfBase64 != null) {
          const byteCharacters = atob(pdfBase64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], { type: 'application/pdf;base64' });
          const fileURL = URL.createObjectURL(file);
          this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        }
      }, err => {
        this.pdfData = null;
        this.isLoading = false;
      });
    this.conpensationDatesSubscription = this.archiveService.getPdfDocumentDatesListner()
    .subscribe(dates => {
      this.dates = dates || [];
      if (!this.dates.length) {
        this.noDataToDisplay = true;
        this.isLoading = false;
        this.pdfData = null;
        return;
      }

      this.dates = this.dates.sort((a, b) => {
          return a.end > b.end ? -1 : 1; });

      this.performCall(this.dates[0].id);
    });

    this.archiveService.getPDFDocumentDates(this.authService.currentNim, 'compensations');
  }

  onDatesChange(e): void {
    this.isLoading = true;
    this.performCall(e.target.value);
  }

  performCall(id: string) {
    this.pdfData = null;
    this.archiveService.getPDFDocumentUrl(this.authService.currentNim, id, 'compensations');
  }

  getDisplayText(complement: PDFDocumentModel) {
    if (!complement) {
      return '';
    }

    const start = moment(complement.start).locale('fr').format('DD MMMM YYYY');
    const end = moment(complement.end).locale('fr').format('DD MMMM YYYY');
    return `du ${start} au ${end}`;
  }

  ngOnDestroy() {
    if (this.conpensationUrlSubscription) {
      this.conpensationUrlSubscription.unsubscribe();
    }
    if (this.conpensationDatesSubscription) {
      this.conpensationDatesSubscription.unsubscribe();
    }
    this.pdfData = null;
  }

}
