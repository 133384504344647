import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IssueSearch, Top10Title } from '../Models/top10title.model';

@Injectable({
  providedIn: 'root'
})
export class IncontournableService {

  private top10ListStoreUpdated = new Subject<Top10Title[]>();
  private top10Updated = new Subject<Top10Title>();
  private top10CallbackUpdated = new Subject<boolean>();
  private issuesUpdated = new Subject<IssueSearch[]>();

  constructor(private http: HttpClient) { }

  getTop10ListUpdateListener() {
    return this.top10ListStoreUpdated.asObservable();
  }

  getTop10UpdateListener() {
    return this.top10Updated.asObservable();
  }

  getTop10CallbackUpdateListner() {
    return this.top10CallbackUpdated.asObservable();
  }

  getIssuesUpdateListner() {
    return this.issuesUpdated.asObservable();
  }

  top10List() {
    const url = `${environment.APIURL}/api/v1/top10titles`;
    this.http.get<{ result: Top10Title[] }>(url)
      .subscribe(response => {
        if (response) {
          this.top10ListStoreUpdated.next(response.result);
        } else {
          this.top10ListStoreUpdated.next([]);
        }
      }
        ,
        error => {
          this.top10ListStoreUpdated.next([]);
        });
  }

  top10ById(id: string) {
    const url = `${environment.APIURL}/api/v1/top10titles/${id}`;
    this.http.get<{ result: Top10Title }>(url)
      .subscribe(response => {
        if (response) {
          this.top10Updated.next(response.result);
        } else {
          this.top10Updated.next(null);
        }
      }
        ,
        error => {
          this.top10Updated.next(null);
        });
  }

  addOrUpdateTop10(body: any) {
    const url = `${environment.APIURL}/api/v1/top10titles`;
    this.http.post<{ result: boolean }>(url, body)
      .subscribe(response => {
        if (response) {
          this.top10CallbackUpdated.next(response.result);
        } else {
          this.top10CallbackUpdated.next(false);
        }
      }
        ,
        error => {
          this.top10CallbackUpdated.next(false);
        });
  }

  deleteTop10(id: string) {
    const url = `${environment.APIURL}/api/v1/top10titles/${id}`;
    this.http.delete<{ result: boolean }>(url)
      .subscribe(response => {
        if (response) {
          this.top10CallbackUpdated.next(response.result);
        } else {
          this.top10CallbackUpdated.next(false);
        }
      }
        ,
        error => {
          this.top10CallbackUpdated.next(false);
        });
  }

  getIssues(codif: string) {

    const url = environment.APIURL + '/api/v2/titreexpress/issues?codif=' + codif;
    this.http.get<{ result: IssueSearch[] }>(url)
        .subscribe(response => {
            if (response) {
                this.issuesUpdated.next(response.result || []);
            } else {
                this.issuesUpdated.next([]);
            }
        }, err => {
            this.issuesUpdated.next([]);
          });
  }
}
