import { Component, OnInit, Input } from '@angular/core';
import { Bill } from 'src/app/Models/bill.model';

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.css']
})
export class FactureComponent implements OnInit {

  constructor() { }

  @Input() bill: Bill;
  ngOnInit() {
  }

}
