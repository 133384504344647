import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { AdminTitle } from 'src/app/Models/admin/title.modal';
import { EditortitleService } from 'src/app/services/admin/editortitle.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editortitles',
  templateUrl: './editortitles.component.html',
  styleUrls: ['./editortitles.component.css']
})
export class EditortitlesComponent implements OnInit, OnDestroy {

  titles: AdminTitle[] = [];
  importedTitles: AdminTitle[] = [];
  private completeFileLoad = new EventEmitter();
  searching = false;
  processing = false;
  private codif: string = null;

  private titlesSub: Subscription;
  private titlesSaveSub: Subscription;

  dtOptions: DataTables.Settings = {};

  constructor(private dtlanguages: DtLanguages,
    private ngbModalService: NgbModal,
    private adminService: EditortitleService) { }

  ngOnInit() {
    this.dtOptions = {
      searching: false,
      lengthChange: false,
      autoWidth: true,
      ordering: false,
      paging: false,
      processing: false,
      language: this.dtlanguages.frLanguage()
    };

    this.titlesSub = this.adminService.getTitlesUpdateListener()
      .subscribe(result => {
        this.titles = result || [];
        this.searching = false;
      }, err => {
        this.searching = false;
        this.titles = [];
      });

    this.titlesSaveSub = this.adminService.getSaveTitlesUpdateListener()
      .subscribe(res => {
        this.processing = false;
        this.searching = false;
        if (res) {
          if (this.importedTitles.length) {
            alert('Fichier importé avec succès !');
            this.importedTitles = [];
            this.ngbModalService.dismissAll();
          }
          if (this.codif && this.codif.length) {
            this.adminService.searchTitles(this.codif);
          } else {
            this.titles = [];
            this.importedTitles = [];
          }
        } else {
          if (this.codif && this.codif.length) {
            alert('Nous n’avons pas pu enregistré votre demande, veuillez réessayer ultérieurement.');
          } else if (this.importedTitles.length) {
            this.importedTitles = [];
            alert('Nous n’avons pas importer votre fichier, veuillez réessayer ultérieurement.');
          }
        }
      }, err => {
        this.processing = false;
        this.searching = false;
        this.titles = [];
        this.importedTitles = [];
      });
  }

  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
  }
  closeModal() {
    this.importedTitles = [];
    this.ngbModalService.dismissAll();
  }

  onFileSelect(input: HTMLInputElement) {

    const self = this;
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = function(e) {
        // import form CSV
        const textFromFileLoaded = fileReader.result as string;
        self.importedTitles = textFromFileLoaded.split(/\r?\n/).map(line => {
          const cols = line.replace(/"/ig, '').split(/,|;/ig);
          if (cols.length !== 5) {
            return null;
          }
          if (cols[0].length < 5) {
            cols[0] = cols[0].padStart(5, '0');
          } else if (cols[0].length > 5) {
            const startIndex = cols[0].length - 5;
            cols[0] = cols[0].substring(startIndex, 5 + startIndex);
          }

          const pubEmails = [cols[1].toLowerCase(), cols[2].toLowerCase(), cols[3].toLowerCase()].filter(s => s.length > 0);
          const extEmails = [cols[4].toLowerCase()].filter(s => s.length > 0);

          const item: AdminTitle = {
            titleCodif: cols[0],
            publisherRecipients: pubEmails,
            externalRecipients: extEmails,
            id: '',
            name: ''
          };
          return item;
        }).filter(s => s != null);
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  searchTitles(codif: string) {
    this.searching = true;
    this.codif = codif;
    this.adminService.searchTitles(this.codif);
  }

  getPublisherEmail(rowIndex: number, colIndex: number): string {
    return this.getEmail(rowIndex, colIndex, 'publisherRecipients');
  }

  getExternalEmail(rowIndex: number, colIndex: number): string {
    return this.getEmail(rowIndex, colIndex, 'externalRecipients');
  }

  private getEmail(rowIndex: number, colIndex: number, collectionName: string) {
    const title = this.titles[rowIndex];
    const collection = title[collectionName];

    if (!collection || !collection.length || collection.length <= colIndex) {
      return '';
    }

    return collection[colIndex];
  }

  addPublisherEmail(rowIndex: number, $event, input: HTMLInputElement = null) {
    this.addEmail(rowIndex, $event, input, 'publisherRecipients');
  }

  addExternalEmail(rowIndex: number, $event, input: HTMLInputElement = null) {
    this.addEmail(rowIndex, $event, input, 'externalRecipients');
  }

  private addEmail(rowIndex: number, $event, input: HTMLInputElement, collectionName: string) {

    let value: string = null;
    if ($event) {
      if ($event.keyCode !== 13) {
        return;
      }
      if ($event.srcElement) {
          value = $event.srcElement.value;
      }
    } else if (input) {
      value = input.value;
    } else {
      return;
    }

    if (!value) {
      return;
    }

    const title = this.titles[rowIndex];
    const email = value.toLowerCase();
    const collection = title[collectionName];

     if (!collection) {
      title[collectionName] = [email];
     } else if (collection.findIndex(i => i.toLowerCase() === email) < 0) {
      collection.push(email);
     }

     if (input) {
      input.value = '';
     } else if ($event) {
      $event.srcElement.value = '';
     }
  }

  changePublisherEmail(rowIndex: number, colIndex: number, $event) {
    this.changeEmail(rowIndex, colIndex, $event.target.value, 'publisherRecipients');
  }

  changeExternalEmail(rowIndex: number, colIndex: number, $event) {
    this.changeEmail(rowIndex, colIndex, $event.target.value, 'externalRecipients');
  }

  private changeEmail(rowIndex: number, colIndex: number, value: string, collectionName: string) {

    const title = this.titles[rowIndex];

    const collection = title[collectionName];
    if (colIndex < collection.length) {
      if (!value || !value.length) {
        collection.splice(colIndex, 1);
      } else {
        collection[colIndex] = value;
      }
    } else {
      collection.push(value);
    }
  }

  removePublisherEmail(rowIndex: number, value: string) {
    this.removeEmail(rowIndex, value, 'publisherRecipients');
  }

  removeExternalEmail(rowIndex: number, value: string) {
    this.removeEmail(rowIndex, value, 'externalRecipients');
  }

  private removeEmail(rowIndex: number, value: string, collectionName: string) {

    const title = this.titles[rowIndex];

    const collection = title[collectionName];
    if (!collection) {
      return;
    }

    const elementIndex = collection.findIndex(i => i.toLowerCase() === value.toLowerCase());
    if (elementIndex < 0) {
      return;
    }
    collection.splice(elementIndex, 1);
  }

  saveData(titles: AdminTitle[]) {
    if (!titles || !titles.length) {
      return;
    }
    this.processing = true;
      this.adminService.saveTitles(titles);
  }

  ngOnDestroy() {
    if (this.titlesSub) {
      this.titlesSub.unsubscribe();
    }
    if (this.titlesSaveSub) {
      this.titlesSaveSub.unsubscribe();
    }
  }
}
