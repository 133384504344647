import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { SupportService } from '../services/support.service';
import { SupportItem } from '../Models/support.model';
import { AuthService } from '../services/auth-service';
import { Sweetalert } from '../shared/sweetAlert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor(private supportService: SupportService,
    private authService: AuthService,
    private sweet: Sweetalert,
    private router: Router) { }

  primaryOption: SupportItem;
  secondaryOption: SupportItem;

  selectedOptionForHtml: number;
  isDisabled = true;
  data: SupportItem[] = [];
  fileCount: string;
  attachements = [];
  isLoading = true;
  isSending = false;

  get userFullName(): string {
    return this.authService.userFullName;
  }

  get userEmail(): string {
    return this.authService.userEmail;
  }
  get userNIM(): string {
    return this.authService.currentNim;
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  ngOnInit() {
    $('#helpStepOne').show();
    $('#helpStepTow').hide();
    $('#helpStepThree').hide();


    this.isLoading = true;
    this.supportService.getTopics();
    this.supportService.getTopicsUpdateListner().subscribe(topics => {
      this.data = topics;

      if (this.router.url.endsWith('/login')) {
        this.goToLoginSupport();
      }
      this.isLoading = false;
    });

    this.supportService.getSendTopicsUpdateListner().subscribe(status => {
      this.isSending = false;
      if (status) {
        this.sweet.success('Succès !', 'Votre demande a bien été prise en compte !');
      } else {
        this.sweet.warning('Erreur !', 'Votre demande n’a pas été prise en compte !');
      }
    });
  }

  goToLoginSupport() {
    this.goToStepTow(90);
    this.enableNextBtn(1);
    this.goToStepThree();
  }

  // read attachement files
  handleFileInput(input) {
    $('#fileCount').show();
    const files = input.target.files;
    const isFileExist = false;
    const self = this;
    let count = files.length - 1;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const picReader = new FileReader();
      picReader.addEventListener('load', function (event) {
        const picFile = <FileReader>event.target;
        const picnames = files[count].name;
        const fileExtension = picnames.split('.').pop();
        count--;
        const attachToPush = {
          'content': picFile.result,
          'extension': fileExtension,
          'picnames': picnames
        };

        if (self.attachements.length > 0) {
          $.each(self.attachements, function (index, item) {
            if (item.content === picFile.result) {
              this.isFileExist = true;
              return false;
            }
          });
          if (isFileExist === false) {
            self.attachements.push(attachToPush);
          }
        } else {
          self.attachements.push(attachToPush);
        }

        self.calculatNbrFile();

      });
      picReader.readAsDataURL(file);

    }
  }

  calculatNbrFile() {
    const nbrFile = this.attachements.length;
    nbrFile > 1 ? this.fileCount = nbrFile + ' Fichiers sélectionnés' : this.fileCount = nbrFile + ' Fichier sélectionné';
  }

  removeAttachement(index: number) {
    $('#fileCount').show();
    this.attachements.splice(index, 1);
    this.calculatNbrFile();
    if (this.attachements.length === 0) {
      $('#fileCount').hide();
    } else { $('#fileCount').show(); }
  }

  goToStepTow(id: number) {
    this.primaryOption = this.data.find(o => o.id === id);

    $('#helpStepOne').hide();
    $('#helpStepTow').show();
    $('#helpStepThree').hide();
  }

  enableNextBtn(id: number) {
    this.selectedOptionForHtml = 0;
    this.isDisabled = true;
    this.secondaryOption = this.primaryOption.subjects.find(e => e.id === id);
    if (!this.secondaryOption) {
      this.goToStepOne();
      return;
    }

    if (!this.secondaryOption.innerHtml) {
      this.isDisabled = false;
    } else {
      this.selectedOptionForHtml = this.secondaryOption.id;
    }
  }

  goToStepThree() {
    if (!this.primaryOption
      || !this.secondaryOption) {
      return;
    }

    $('#helpStepOne').hide();
    $('#helpStepTow').hide();
    $('#helpStepThree').show();
  }

  goToStepOne() {
    $('#helpStepOne').show();
    $('#helpStepTow').hide();
    $('#helpStepThree').hide();
    $('#txtDescription').val('');
    this.isDisabled = true;
    this.selectedOptionForHtml = 0;
    this.primaryOption = null;
    this.secondaryOption = null;
  }

  checkInput(event) {
    if (!this.isAuthenticated) {
      if (event.target.txtNim.value === ''
        || event.target.txtFullName.value === ''
        || event.target.txtEmail.value === '') {
        this.sweet.error('Erreur !', 'Le NIM, Nom et prénom et votre email sont obligatoire!');
        return;
      } else {
        this.performTopic(event);
      }
    } else {
      this.performTopic(event);
    }

  }

  performTopic(event) {

    if (this.authService.isReadOnly()) {
      return;
    }

    if (this.isSending) {
      return;
    }

    this.isSending = true;

    let email = '';
    let fullName = '';
    let currentNim = '';
    if (!this.primaryOption
      || !this.secondaryOption) {
      this.isSending = false;
      return;
    }

    if (this.isAuthenticated) {
      email = this.userEmail;
      fullName = this.userFullName;
      currentNim = this.userNIM;
    } else {
      fullName = event.target.txtFullName.value;
      email = event.target.txtEmail.value;
      currentNim = event.target.txtNim.value;
    }

    const message = event.target.txtDescription.value;
    const nim = currentNim; // current Nim if connected or the NIM entered by the user
    const body = {
      'body': message, // get description from html
      'email': email, // current email if connected or the email entered by the user
      'name': fullName, // current user firstname and lastname if connected or the firstname and lastname entered by the user
      'attachements': this.attachements,
    };

    this.supportService.sendTopics(this.primaryOption.id, this.secondaryOption.id, nim, body);
  }
}
