import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Tour } from 'src/app/Models/tour.model';
import { Store } from 'src/app/Models/store.model';
import { NgbModal, NgbModalRef, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';
import { Subscription } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';
import { NgForm } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';

@Component({
  selector: 'app-warehousealerts',
  templateUrl: './warehousealerts.component.html',
  styleUrls: ['./warehousealerts.component.css']
})
export class WarehouseAlertsComponent implements OnInit, OnDestroy {

  @Input() meModal: NgbModalRef;

  tours: Tour[] = [];
  _selectedTours: string[] = [];
  selectedTours: string[] = [];
  selectedStores: string[] = [];
  startDate;
  endDate;
  dateFrom;
  reccurentPeriod = '';
  stores: Store[];
  private openedModal: NgbModalRef;
  private storeChangeSubs: Subscription;

  @ViewChild('alertForm') formAlert: NgForm;

  isRecurrent = false;
  isSending = false;
  showBtnSpinner = false;

  constructor(private alertService: AlertService,
    private ngbModalService: NgbModal,
    private sweet: Sweetalert,
    private storeService: StoreService,
    private authService: AuthService) { }

  private bindData(): void {
    if (!this.tours || !this.tours.length) {
      return;
    }
    this.selectedStores = this.tours.map(t => t.stores).reduce((a, b) => a.concat(b)).sort((a, b) => {
      return (a.name || '').localeCompare(b.name);
    }).map(s => s.nim);

    this.stores = this.tours.map(t => {
      return t.stores;
    }).reduce((r, b) => r.concat(b)).sort((a, b) => {
      return (a.name || '').localeCompare(b.name);
    });
  }
  ngOnInit() {
    const date = new Date();
    this.dateFrom = { year: date.getFullYear(), month: date.getUTCMonth() + 1, day: date.getDate() };
    this.storeChangeSubs = this.authService.getStoreChangeListener()
    .subscribe(store => {
      if (store) {
        this.storeService.getWarehouseTours();
      }
    });

    this.alertService.getSendUpdateListener()
      .subscribe(status => {
        this.isSending = false;
        if (status) {
          this.sweet.success('Succès !', 'l’alerte est envoyée avec succès.');
        } else {
          this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
        }
      }, error => {
        this.isSending = false;
        this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
      });

    this.storeService.getToursUpdateListner()
    .subscribe(tours => {
      this.tours = tours;
      this.bindData();
    }, error => {
    });
    this.storeService.getWarehouseTours();
  }

  toggleCheck() {
    this.isRecurrent = !this.isRecurrent;
    return false;
  }

  onSendDateSelect(event: NgbDate) {

    this.startDate = { year: event.year, month: event.month, day: event.day };
  }

  onSendDateEndSelect(event: NgbDate) {
    this.endDate = { year: event.year, month: event.month, day: event.day };
  }

  onNimChecked(event, value: string) {

    if (!event) {
      return;
    }

    const indexOfItem = this.selectedStores.indexOf(value);
    if (event.target.checked) {
      if (indexOfItem < 0) {
        this.selectedStores.push(value);
      }
    } else if (indexOfItem >= 0) {
      this.selectedStores.splice(indexOfItem, 1);
    }
  }

  onTourChecked(event, value: string) {

    if (!event) {
      return;
    }

    const indexOfItem = this.selectedTours.indexOf(value);
    if (event.target.checked) {
      if (indexOfItem < 0) {
        this.selectedTours.push(value);
      }
    } else if (indexOfItem >= 0) {
      this.selectedTours.splice(indexOfItem, 1);
    }
  }

  validateStores(event, value: string) {

    if (!this.selectedStores) {
      this.closeModal();
      return;
    }

    this.closeModal();
  }

  isTourChecked(value: string) {
    return this.selectedTours.indexOf(value) >= 0;
  }

  isStoreChecked(value: string) {
    return this.selectedStores.indexOf(value) >= 0;
  }

  validateTours() {

    if (!this.selectedTours) {
      this.closeModal();
      return;
    }

    this.selectedStores = [];
    this.selectedTours.forEach(tourNumber => {
      const tourIndex = this.tours.findIndex(t => t.tourNumber === tourNumber);
      if (tourIndex < 0) {
        return;
      }

      const tour = this.tours[tourIndex];
      if (!tour.stores) {
        return;
      }

      tour.stores.forEach(store => {
        this.selectedStores.push(store.nim);
      });
    });

    this.closeModal();
  }

  selectAll() {
    this.selectedStores = this.stores.map(s => s.nim);
    return false;
  }
  unselectAll() {
    this.selectedStores = [];
    return false;
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.split(/,|;/ig).map(t => t.trim());
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
        if (nims.length === 0) {
          alert('Le fichier ne contient pas de NIM sur 7 caractères.');
          input.value = '';
        } else {
          this.selectedStores = nims;
        }
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }

  }

  sendAlert() {
    if (!this.formAlert || !this.selectedStores || !this.selectedStores.length) {
      return;
    }

    if (!this.startDate) {
      return;
    }

    if (!this.formAlert.value.alertText.length) {
      return;
    }

    const data = {
      // tslint:disable-next-line:max-line-length
      sendingDate: `${this.startDate.year}-${this.startDate.month}-${this.startDate.day}T00:00:00`,
      diffuserNims: this.selectedStores,
      webLink: this.formAlert.value.alertLink,
      content: this.formAlert.value.alertText,
      period: {
        every: 0,
        until: ''
      }
    };
    if (!this.isRecurrent) {
      data.period = null;
    } else {

      if (this.reccurentPeriod === '') {
        return;
      }
      data.period.every = +this.reccurentPeriod;
      data.period.until = `${this.endDate.year}-${this.endDate.month}-${this.endDate.day}T00:00:00`;
    }

    this.isSending = true;
    this.alertService.sendAlert(data);
  }

  openModal(content) {
    this.openedModal = this.ngbModalService.open(content, { size: 'lg' });
  }

  closeModal() {
    if (!this.openedModal) {
      return;
    }
    this.openedModal.close();
  }

  closeModalRoot() {
    this.ngbModalService.dismissAll();
  }

  ngOnDestroy() {
    if (this.storeChangeSubs) {
      this.storeChangeSubs.unsubscribe();
    }
  }
}
