import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ForgottenService } from '../services/forgotten.service';
import { Title } from '../Models/title.model';
import { DtLanguages } from '../shared/dtLanguages';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth-service';
import { ActivatedRoute, Router } from '@angular/router';
import { GA } from '../shared/gAnalytics';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgotten',
  templateUrl: './forgotten.component.html',
  styleUrls: ['./forgotten.component.css']
})
export class ForgottenComponent implements OnInit, OnDestroy, AfterViewInit {

  // declarations
  private titlesSubs: Subscription;
  private saveSubs: Subscription;
  showModalSpinner: Subject<boolean> = new Subject<boolean>();
  titles: Title[];
  showSpinner = true;
  private loadingText = 'Chargement des demandes d’ouverture de droits aux oubliés...';
  searchString: string;
  typeAction = 'forgotten';
  titleCodif: string;
  @ViewChild('content') contentRef: ElementRef;

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  // constructor
  constructor(private forgottenService: ForgottenService,
    private authService: AuthService,
    private ngbModalService: NgbModal,
    private dtlanguages: DtLanguages,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private ga: GA) { }

  ngOnInit() {

    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      columnDefs: [{
        'targets': [0],
        'orderable': false
      }
      ],
      autoWidth: false,
      order: [1, 'desc'],
      language: this.dtlanguages.frLanguage()
    };
    this.titlesSubs = this.forgottenService.getForgotterUpdateListner()
      .subscribe((result: Title[]) => {
        this.titles = result;
        this.showSpinner = false;
        this.rerender();
        this.dtTrigger.next();
      });

    this.saveSubs = this.forgottenService.getSaveUpdateListner()
      .subscribe(result => {
        this.showModalSpinner.next(false);
        if (result) {
          if (this.titleCodif != null) {
            this.location.back();
          }
          this.onBindServices();
          this.closeModal(false);
          this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande d’ouverture de droits aux oubliés');
        }
      });

    this.onBindServices();

  }
  // bind Services
  onBindServices() {

    this.showSpinner = true;
    this.forgottenService.getForgotten();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    if (this.route.snapshot.paramMap.get('titleCodif') != null) {
      this.titleCodif = this.route.snapshot.paramMap.get('titleCodif');
      setTimeout(() => {
        this.openModal(this.contentRef);
      });
    }
  }
  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }
  // on post new forgotten
  onSaveRequest($event) {
    if (!$event) {
      this.showModalSpinner.next(false);
      return;
    }

    const nim = this.authService.currentNim;
    this.forgottenService.addNewForgotten(nim, $event);
  }

  // on open modal and logic
  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
    this.ga.createEvent('view', 'Demandes', 'Création Nouvelle Demande d’ouverture de droits aux oubliés');
  }

  closeModal(sendrRacking = true) {
    if (sendrRacking) {
      this.ga.createEvent('cancel', 'Demandes', 'Création Nouvelle Demande d’ouverture de droits aux oubliés');
    }
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    this.closeModal(false) ;
    if (this.titlesSubs) {
      this.titlesSubs.unsubscribe();
    }
    if (this.saveSubs) {
      this.saveSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.showModalSpinner) {
      this.showModalSpinner.unsubscribe();
    }
  }
}
