import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'DatePipe' })
export class DatePipe implements PipeTransform {
  transform(value: string, format: string, todayIfEmpty: boolean = false): string {

    let mmt = moment(value);

    if (!value || !mmt.isValid()) {
      if (todayIfEmpty) {
        mmt = moment();
      } else {
        return '–';
      }
    }

    // const mmt = moment(value);
    const newStr = (mmt.locale('fr').format(format)); // dddd d MMMM YYYY
    if (mmt.year() === 9999) {
      return '–';
    }
    return newStr;
  }
}
