import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { Sweetalert } from '../../shared/sweetAlert';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth-service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpDeskAccount } from 'src/app/Models/user.model';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.css']
})
export class HelpdeskComponent implements OnInit {
  constructor(private storeService: StoreService,
    private sweet: Sweetalert,
    private ngbModalService: NgbModal,
    private authService: AuthService) { }
  isSearching = false;
  isProcessing = false;
  creationstatus?: boolean;
  currentNim = '';
  account: HelpDeskAccount;
  mutationDate: NgbDateStruct;
  get isAdmin(): boolean {
    return this.account && this.authService.isAdmin;
  }
  get isWarehouse(): boolean {
    return this.account && this.account.type === 'warehouse';
  }
  get isStore(): boolean {
    return this.account && (this.account.type || 'store') === 'store';
  }
  get accountStatus(): string {
    if (!this.account) {
      return '';
    }
    if (this.account.accountCreated || false) {
      const hasRole = this.account.hasRole || false;
      return hasRole ? 'compte crée avec succès' : 'il faut confirmer le compte.';
    } else if (this.account.pending) {
      const date = moment(this.account.pending.date).locale('fr').format('dddd DD MMMM YYYY');
      return `compte est en attente de validation<br>email envoyé le ${date} à ${this.account.pending.email}`;
    } else {
      return 'compte à créer par le diffuseur';
    }
  }
  get canResetPassword(): boolean {
    if (this.isProcessing) {
      return false;
    }
    if (!this.account) {
      return false;
    }
    return this.account.accountCreated || false;
  }
  get storeName(): string {
    if (!this.account || !this.account.contact) {
      return '';
    }
    return `${this.account.contact.firstName} ${this.account.contact.lastName}`;
  }
  get canSendMail(): boolean {
    if (this.isProcessing) {
      return false;
    }
    if (!this.account) {
      return false;
    }
    const created = this.account.accountCreated || false;
    const pending = this.account.pending != null;
    return created && pending;
  }
  get canConfirm(): boolean {
    if (this.isProcessing) {
      return false;
    }
    if (!this.account || !this.account.pending) {
      return false;
    }
    if (!this.storeService.isHelpdesk) {
      return false;
    }
    const created = this.account.accountCreated || false;
    if (created) {
      return (!this.account.hasRole || false);
    }
    return true;
  }
  get canUnlink(): boolean {
    if (this.isProcessing) {
      return false;
    }
    if (!this.account) {
      return false;
    }
    const status = this.account.hasRole || this.account.accountCreated || false;
    return status;
  }
  ngOnInit() {
    this.storeService.getHelpdeskUpdateListner().subscribe(store => {
      this.account = store;
      this.isSearching = false;
    }, erro => {
      this.isSearching = false;
    });
    this.storeService.getCreateAccountUpdateListner().subscribe(status => {
      this.isProcessing = false;
      this.creationstatus = status;
    }, erro => {
      this.isSearching = false;
      this.creationstatus = false;
    });
    this.storeService.getheldeskActionUpdateListner().subscribe(result => {
      this.isProcessing = false;
      if (result && result.status) {
        this.search(result.nim);
      } else {
        this.sweet.warning('Attention !',
        'Votre demande ne peut être satisfaite pour le moment, veuillez contacter le service technique ZEENS.');
      }
    }, erro => {
      this.isProcessing = false;
      this.sweet.warning('Attention !',
      'Votre demande ne peut être satisfaite pour le moment, veuillez contacter le service technique ZEENS.');
    });
    this.storeService.getChangePasswordUpdateListner().subscribe(result => {
      this.isSearching = false;
      if (result) {
        this.sweet.success('Succès !',
          'Votre demande a été traité avec succès.');
        if (this.account) {
          this.search(this.account.nim);
        }
      } else {
        this.sweet.warning('Attention !',
        'Votre demande ne peut être satisfaite pour le moment, veuillez contacter le service technique ZEENS.');
      }
    }, erro => {
      this.isSearching = false;
      this.sweet.warning('Attention !',
        'Votre demande ne peut être satisfaite pour le moment, veuillez contacter le service technique ZEENS.');
    });
  }
  search(nim: string) {
    if (!nim.length || nim.length !== 7) {
      this.sweet.warning('', 'Veuillez saisir un NIM sur 7 chiffres.');
      return;
    }
    this.isSearching = true;
    this.storeService.searchNim(nim);
  }
  changePassword(nim: string, password: string) {
    if (!password || !password.length) {
      this.sweet.warning('', 'Veuillez saisir un mot de passe valide.');
      return;
    }
    this.isSearching = true;
    this.storeService.changePasswordByAdmin(nim, password);
  }
  changeEmail(nim: string, email: string) {
    if (!email || !email.length) {
      this.sweet.warning('', 'Veuillez saisir une adresse email valide.');
      return;
    }
    this.isSearching = true;
    this.storeService.changeEmailByAdmin(nim, email);
  }
  setAccountingEmail(nim: string) {
    if (!this.account.docAccount || !this.account.docAccount.email.length) {
      this.sweet.warning('', 'Veuillez saisir une adresse email valide.');
      return;
    }

    this.isSearching = true;
    this.storeService.changeAccountingEmailByAdmin(nim, this.account.docAccount);
  }
  unsetAccountingEmail(nim: string) {
    if (!this.account.docAccount || !this.account.docAccount.email.length) {
      return;
    }
    this.isSearching = true;
    this.storeService.unsetAccountingEmailByAdmin(nim);
  }
  resetPassword(nim: string) {
    if (!nim.length || nim.length !== 7) {
      this.sweet.warning('', 'Veuillez saisir un NIM sur 7 chiffres.');
      return;
    }
    this.sweet.confirmDialog('Réinitialisation du mot de passe !',
    'Souhaitez vous réinitialiser le mot de passe pour ce compte ?', (s, e) => {
      if (!s && !e) {
        return;
      }
      if (e) {
        this.isProcessing = true;
        this.storeService.resetPassword(nim);
      }
    });
  }
  sendMail(nim: string) {
    if (!nim.length || nim.length !== 7) {
      this.sweet.warning('', 'Veuillez saisir un NIM sur 7 chiffres.');
      return;
    }
    this.sweet.confirmDialog('Mail de confirmation !',
    'Souhaitez vous envoyer un nouveau mail de confirmation de création de compte ?', (s, e) => {
      if (!s && !e) {
        return;
      }
      if (e) {
        this.isProcessing = true;
        this.storeService.sendMail(nim);
      }
    });
  }
  confirmAccount(nim: string) {
    if (!nim.length || nim.length !== 7) {
      this.sweet.warning('', 'Veuillez saisir un NIM sur 7 chiffres.');
      return;
    }
    this.sweet.confirmDialog('Confirmation du compte !', 'Souhaitez vous confirmer le compte ?', (s, e) => {
      if (!s && !e) {
        this.isProcessing = false;
        return;
      }
      if (e) {
        this.isProcessing = true;
        this.storeService.confirmAccount(nim);
      }
    });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
    if (this.creationstatus === true) {
      this.search(this.currentNim);
    }
    this.creationstatus = null;
  }

  openModal(modal) {
    this.ngbModalService.open(modal, { size: 'sm' });
  }

  createNewAccount(nim: string, email: string, password: string) {
    if (!nim || !nim.length
      || !email || !email.length
      || !password || !password.length) {
      this.sweet.warning('', 'Veuillez remplir tous les champs.');
      return;
    }
    this.creationstatus = null;
    this.currentNim = nim;
    this.isProcessing = true;
    this.storeService.createAccountByAdmin(nim, email, password, null);
  }

  createOldOwner(nim: string, email: string, password: string) {
    if (!nim || !nim.length
      || !email || !email.length
      || !password || !password.length) {
      this.sweet.warning('', 'Veuillez remplir tous les champs.');
      return;
    }

    if (!this.mutationDate) {
      this.sweet.warning('', 'Veuillez renseigner une date de mutation.');
      return;
    }

    const mutationDate = this.mutationDate.year + '-' + this.mutationDate.month + '-' + this.mutationDate.day;

    this.creationstatus = null;
    this.currentNim = nim;
    this.isProcessing = true;
    this.storeService.createAccountByAdmin(nim, email, password, mutationDate);
  }

  breakAccount(nim: string) {
    if (!nim.length || nim.length !== 7) {
      this.sweet.warning('', 'Veuillez saisir un NIM sur 7 chiffres.');
      return;
    }
    this.sweet.confirmDialog('Dissocier un compte !', 'Souhaitez vous dissocier le compte ?', (s, e) => {
      if (!s && !e) {
        this.isProcessing = false;
        return;
      }
      if (e) {
        this.isProcessing = true;
        this.storeService.breakAccount(nim);
      }
    });
  }
}
