import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { Subscription } from 'rxjs';
import { SignupStat } from '../../Models/signupStat.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-signupstats',
  templateUrl: './signupstats.component.html',
  styleUrls: ['./signupstats.component.css']
})
export class SignupstatsComponent implements OnInit, OnDestroy {

  constructor(private storeService: StoreService) { }
  private statsSubscription: Subscription;
  private storageFromKEY = 'SSFROM';
  private storageToKEY = 'SSTO';
  stats: SignupStat[] = [];
  modelFrom: NgbDateStruct;
  modelTo: NgbDateStruct;
  showNoDataPanel = false;

  ngOnInit() {
    this.statsSubscription = this.storeService.getSignupStatsListner().subscribe(s => {
      this.stats = s || [];
      this.showNoDataPanel = false;
    }, err => {
      this.showNoDataPanel = true;
      this.stats = [];
    });

    const fromDate = localStorage.getItem(this.storageFromKEY) || '';
    if (fromDate.length) {
      const d1 = new Date(fromDate);
      this.modelFrom = { day: d1.getDate(), month: d1.getMonth() + 1, year: d1.getFullYear()};
    }
    const toDate = localStorage.getItem(this.storageToKEY) || '';
    if (toDate.length) {
      const d2 = new Date(toDate);
      this.modelTo = { day: d2.getDate(), month: d2.getMonth() + 1, year: d2.getFullYear()};
    }
    // this.performCall(fromDate, toDate);
  }

  getDateCount(date: Date) {
    if (!this.stats.length) {
      return 0;
    }

    return this.stats.filter(s => s.date === date).length;
  }

  ngOnDestroy() {
    if (this.statsSubscription) {
      this.statsSubscription.unsubscribe();
    }
  }

  getStats() {
    const fromDate = this.modelFrom ? this.modelFrom.year + '-' + this.modelFrom.month + '-' + this.modelFrom.day :  '';
    const toDate = this.modelTo ? this.modelTo.year + '-' + this.modelTo.month + '-' + this.modelTo.day : '';

    this.performCall(fromDate, toDate);
  }

  toggleRow(date: Date, nim: string) {
    if (nim) {
      return;
    }
    const rowId = this.rowUnixId(date);
    const array = document.getElementsByClassName(rowId);
    for (let index = 0; index < array.length; index++) {
      array[index].classList.toggle('showRow');
      array[index].classList.toggle('hideRow');
    }
  }

  exportDataToCSV() {
    if (!this.stats.length) {
      return;
    }
    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `signup-metrics-${date.format('YYYY-MM-DD')}`,
      filename: `signup-metrics-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.stats.map((v, i) => {
      return {date: v.date, nim: v.nim || 'tous', count: v.count || 1};
    });
    csvExporter.generateCsv(data);
  }

  rowUnixId(date: Date): string {
    return 'row-' + moment(date).unix();
  }

  private performCall(from: string, to: string) {
    from = from || '';
    to = to || '';
    if (!from.length || !to.length) {
      return;
    }

    localStorage.setItem(this.storageFromKEY, from);
    localStorage.setItem(this.storageToKEY, to);
    this.storeService.getSignupStats(from, to);
  }

}
