import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service';
import { MapComponent } from 'src/app/controls/map/map.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminWarehouseContact, AdminWarehouseContactBloc, AdminWarehouseContactBlocs } from 'src/app/Models/contactdepot.model';
import { NgForm } from '@angular/forms';
import { WarehouseContactsService } from 'src/app/services/warehouse-contacts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit, OnDestroy {

  loading = false;
  isNew = false;
  contactListSubs: Subscription;
  contactUpdateSubs: Subscription;
  blocs: AdminWarehouseContactBlocs;
  contact: AdminWarehouseContact = {
    heading: '',
    name: '',
    email: ''
  };

  blocImages = {
    outgoing: 'fa fa-3x fa-truck text-blue',
    incoming: 'fa fa-3x fa-truck text-blue',
    billing: 'fa fa-3x fa-money text-blue',
    technical: 'fa fa-3x fa-laptop text-blue',
    other: ''
  };

  constructor(private authService: AuthService,
    private ngbModalService: NgbModal,
    private warehouseContactService: WarehouseContactsService) {
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  getBlocName(key: string): string {
    if (!this.blocs || !this.blocs[key]) {
      return '';
    }

    return this.blocs[key].name || '';
  }

  getBlocContacts(key: string): AdminWarehouseContact[] {
    if (!this.blocs || !this.blocs[key]) {
      return [];
    }

    return this.blocs[key].contacts || [];
  }

  ngOnInit() {

    this.initBlocs(this.blocs);

    this.contactListSubs = this.warehouseContactService.getAdminWarehouseContactsUpdateListner()
      .subscribe(result => {
        this.initBlocs(result);
        this.loading = false;
        this.isNew = false;
      });
    this.contactUpdateSubs = this.warehouseContactService.getActionRequestsUpdateListner()
      .subscribe(result => {
        this.loading = false;
        this.ngbModalService.dismissAll();
        this.warehouseContactService.getAdminWarehouseContacts();
      });

    this.loading = true;
    this.warehouseContactService.getAdminWarehouseContacts();
  }

  get blocsFromDicoExceptOther(): string[] {
    return Object.keys(this.blocs).filter(k => k !== 'other');
  }

  get otherBlocKey(): string {
    const keys = Object.keys(this.blocs).filter(k => k === 'other');

    if (!keys.length) {
      return '';
    }

    return keys[0];
  }

  initBlocs(blocs) {
    if (blocs) {
      this.blocs = blocs;
      return;
    }
    this.blocs = {
      outgoing: {
        name: 'Logistique flux aller',
        contacts: []
      },
      incoming: {
        name: 'Logistique flux retour',
        contacts: []
      },
      billing: {
        name: 'Facturation & rémunération',
        contacts: []
      },
      technical: {
        name: 'Informatique',
        contacts: []
      },
      other: {
        name: 'Autres demandes, remarques ou suggestions',
        contacts: []
      }
    };
  }

  get otherEmail(): string {
    if (!this.blocs
      || !this.blocs.other
      || !this.blocs.other.contacts
      || !this.blocs.other.contacts.length) {
        return 'bientôt disponible';
    }

    const email = this.blocs.other.contacts[0].email;
    return `<a href="mailto:${email}">${email}</a>`;
  }

  manageContact(form: NgForm) {
    if (!form.valid || !this.contact) {
      return;
    }

    if (!this.contact.email || !this.contact.email.length) {
      return;
    }

    if (!this.contact.name || !this.contact.name.length) {
      return;
    }

    if (!this.contact.heading || !this.contact.heading.length) {
      return;
    }

    this.contact.email = this.contact.email.toLowerCase();
    this.contact.name = this.contact.name.toLowerCase();
    this.contact.heading = this.contact.heading.toLowerCase();

    this.blocs = this.blocs || {} as AdminWarehouseContactBlocs;

    if (this.isNew) {
      // add to blocs
      const bloc = this.blocs[this.contact.heading] as AdminWarehouseContactBloc;
      if (!bloc) {
        return;
      }

      if (this.contact.heading === 'other') {
        bloc.contacts = [];
        bloc.contacts.push(this.contact);
      } else {
        const findBloc = bloc.contacts.filter(c => c.email.toLowerCase() === this.contact.email.toLowerCase());
        if (findBloc.length > 0) {
          findBloc[0].email = this.contact.email.toLowerCase();
          findBloc[0].name = this.contact.name.toLowerCase();
        } else {
          bloc.contacts.push(this.contact);
        }
      }
    }

    this.loading = true;

    this.warehouseContactService.setAdminWarehouseContacts(this.blocs);
  }

  openModal(modal) {
    if (!this.isAdmin) {
      return;
    }
    this.contact = {
      name: '',
      email: '',
      heading: ''
    };
    this.isNew = true;
    this.ngbModalService.open(modal);
  }

  editContact(key, index, modal) {
    if (!this.blocs || !this.isAdmin) {
      return;
    }

    const bloc = this.blocs[key];
    if (!bloc || !bloc.contacts || !bloc.contacts.length || !bloc.contacts[index]) {
      return;
    }

    this.contact = bloc.contacts[index];
    this.contact.heading = key;

    this.ngbModalService.open(modal);
  }

  removeContact(key, index, modal) {
    if (!this.blocs || !this.isAdmin) {
      return;
    }

    const bloc = this.blocs[key];
    if (!bloc || !bloc.contacts || !bloc.contacts.length || !bloc.contacts[index]) {
      return;
    }

    bloc.contacts.splice(index, 1);

    this.warehouseContactService.setAdminWarehouseContacts(this.blocs);
  }

  closeModal() {
    this.ngbModalService.dismissAll();

  }

  ngOnDestroy() {
    if (this.contactListSubs) {
      this.contactListSubs.unsubscribe();
    }
    if (this.contactUpdateSubs) {
      this.contactUpdateSubs.unsubscribe();
    }
  }
}
