import { Component, OnInit, OnDestroy } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service';
import { ArchivesService } from 'src/app/services/archives.service';
import { PDFDocumentModel } from 'src/app/Models/compensation.model';
import { StoreTicket } from 'src/app/Models/store-ticket.model';

@Component({
  selector: 'app-fiability-scan',
  templateUrl: './fiability-scan.component.html',
  styleUrls: ['./fiability-scan.component.css']
})
export class FiabilityScanComponent implements OnInit, OnDestroy {

  // isLoading = true;

  isLoadingPDF = true;
  isLoadingTickets = true;

  noDataToDisplay = false;
  private fiabilityUrlSubscription: Subscription;
  private fiabilityDatesSubscription: Subscription;
  private storeTicketsSubscription: Subscription;

  pdfData: SafeResourceUrl;

  dates: PDFDocumentModel[] = [];
  tickets: StoreTicket[] = [];

  constructor(private archiveService: ArchivesService,
    private authService: AuthService,
    private sanitizer: DomSanitizer) { }

  get DaysCount(): number {
    if (!this.tickets || !this.tickets.length) {
      return 0;
    }

    const maxDate = this.tickets[0].date;
    const minDate = this.tickets[this.tickets.length - 1].date;

    return moment(maxDate).diff(minDate, 'day') + 1;
  }

  ngOnInit() {
    this.fiabilityUrlSubscription = this.archiveService.getPdfDocumentUrlListner()
      .subscribe(pdfBase64 => {
        this.isLoadingPDF = false;
        if (pdfBase64 != null) {
          const byteCharacters = atob(pdfBase64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], { type: 'application/pdf;base64' });
          const fileURL = URL.createObjectURL(file);
          this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        }
      }, err => {
        this.pdfData = null;
        this.isLoadingPDF = false;
      });
    this.fiabilityDatesSubscription = this.archiveService.getPdfDocumentDatesListner()
    .subscribe(dates => {
      this.dates = dates || [];
      if (!this.dates.length) {
        this.noDataToDisplay = true;
        this.isLoadingPDF = false;
        this.pdfData = null;
        return;
      }

      this.dates = this.dates.sort((a, b) => {
          return a.end > b.end ? -1 : 1; });

      this.performCall(this.dates[0].id);
    });

    this.storeTicketsSubscription = this.archiveService.getstoreTicketsListner()
    .subscribe(tickets => {
      this.isLoadingTickets = false;
      this.tickets = tickets;
    });

    this.archiveService.getPDFDocumentDates(this.authService.currentNim, 'fiability');
    this.isLoadingTickets = true;
    this.archiveService.getStoreTickets(this.authService.currentNim);
  }

  onDatesChange(e): void {
    this.isLoadingPDF = true;
    this.performCall(e.target.value);
  }

  performCall(id: string) {
    this.pdfData = null;
    this.archiveService.getPDFDocumentUrl(this.authService.currentNim, id, 'fiability');
  }

  getDisplayText(complement: PDFDocumentModel) {
    if (!complement) {
      return '';
    }

    return moment(complement.start).locale('fr').format('MMMM YYYY');
  }

  getTicketStatusText(ticket: StoreTicket) {
    let text = '';
    if (!ticket) {
      return text;
    }

    if (!ticket.receiveDate) {
      text = ticket.status;
      if (ticket.comment && ticket.comment.length) {
        text = `${text}<br/><span class="small">${ticket.comment || ''}</span>`;
      }
      return text;
    }

    const rDate = moment(ticket.receiveDate);
    const date = moment(ticket.date);
    if (!rDate.isValid()) {
      return text;
    }
    if (rDate.isSame(date, 'day')) {
      text = `Réceptionné à ${moment(ticket.receiveDate).locale('fr').format('HH:mm')}`;
    } else {
      text = `Réceptionné le ${moment(ticket.receiveDate).locale('fr').format('DD MMM YYYY à HH:mm')}`;
    }

    return text;
  }

  ngOnDestroy() {
    if (this.fiabilityUrlSubscription) {
      this.fiabilityUrlSubscription.unsubscribe();
    }
    if (this.fiabilityDatesSubscription) {
      this.fiabilityDatesSubscription.unsubscribe();
    }
    if (this.storeTicketsSubscription) {
      this.storeTicketsSubscription.unsubscribe();
    }
    this.pdfData = null;
  }

}
