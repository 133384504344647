import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgbDateStruct, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

import { DataTableDirective } from 'angular-datatables';
import { UpdateserviceService } from '../services/updateservice.service';
import { AuthService } from '../services/auth-service';
import { DtLanguages } from '../shared/dtLanguages';
import { Sweetalert } from '../shared/sweetAlert';
import { Title } from '../Models/title.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GA } from '../shared/gAnalytics';
import { DatePipe } from '../shared/pipes/date-formatter.pipe';
import { environment } from 'src/environments/environment';
import { BaseupdateserviceComponent } from '../shared/baseupdateservice/baseupdateservice.component';

@Component({
  selector: 'app-updateservice',
  templateUrl: './updateservice.component.html',
  styleUrls: ['./updateservice.component.css']
})
export class UpdateserviceComponent extends BaseupdateserviceComponent implements OnInit, OnDestroy, AfterViewInit {

  // declarations
  protected titlesSubs: Subscription;
  private datePipe = new DatePipe();
  titles: Title[];
  @ViewChild('content') contentRef: ElementRef;

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  days = [
    { id: '1', name: 'Lundi' },
    { id: '2', name: 'Mardi' },
    { id: '3', name: 'Mercredi' },
    { id: '4', name: 'Jeudi' },
    { id: '5', name: 'Vendredi' },
    { id: '6', name: 'Samedi' },
    { id: '7', name: 'Dimanche' },
  ];

  // constructor
  constructor(protected UpdateserviceRequest: UpdateserviceService,
    protected authService: AuthService,
    protected ngbModalService: NgbModal,
    protected ngbModalConfig: NgbModalConfig,
    private dtlanguages: DtLanguages,
    protected sweet: Sweetalert,
    private route: ActivatedRoute,
    protected router: Router,
    protected ga: GA,
    private location: Location
  ) {
    super(UpdateserviceRequest, authService, router, ngbModalService, ga, sweet);
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  requestTypeText(title: Title): string {

    if (!title
      || !title.type) {
      return 'Autres';
    }
    switch (title.type.toString()) {
      case '21':
        return 'Commande ferme';
      case '22':
        return 'Évènement local';
      case '23':
        return 'Invendus trop élevés';
      case '24':
        return 'Fournis trop faible';
      case '25':
        return 'Installation du titre';
      case '26':
        return 'Analyse PDIF';
      case '27':
          return 'Suppression';
      case '29':
          return 'Quantité max';
      default:
        return 'Autres';
    }
  }

  showPlafond(): boolean {
    return !environment.disablePlafond;
  }

  issueText(title: Title): string {

    if (!title) {
      return '';
    }

    let textCodif = title.codif;

    if (title.releaseDate && title.releaseDate.length) {
      textCodif += `<br> ${this.datePipe.transform(title.releaseDate, 'dddd DD MMM YYYY')}`;
    } else if (title.dayCode) {
      const day = this.days.filter(d => d.id === title.dayCode);
      if (day.length) {
        textCodif += `<br> ${day[0].name}`;
      }
    }

    return `${title.title}<br><span class="text-muted">Codif : ${textCodif}</span>`;
  }

  ngOnInit() {

    super.ngOnInit();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      columnDefs: [{
        'targets': [0],
        'orderable': false
      }
      ],
      autoWidth: false,
      order: [1, 'desc'],
      language: this.dtlanguages.frLanguage()
    };

    this.titlesSubs = this.UpdateserviceRequest.getServicesUpdateListener()
      .subscribe((servicesResults: Title[]) => {
        this.titles = servicesResults;
        this.showTableSpinner = false;

        this.rerender();
        this.dtTrigger.next();

      });

    this.saveSubs = this.UpdateserviceRequest.getSaveUpdateListener()
          .subscribe(result => {
            this.showModalSpinner.next(false);
            if (result && result.status) {
              if (this.titleCodif != null) {
                this.location.back();
              } this.onBindServices();
              this.closeModal(false);
              if (this.soldQuantity === 0) {
                this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande d’installation de titre');
              } else {
                this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande de modification de service');
              }
              this.checkServiceProposal(result);
            }   else {
              this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
            }
          });

    this.onBindServices();
  }

  // bind Services
  onBindServices() {

    this.showTableSpinner = true;
    const nim = this.authService.currentNim;

    this.UpdateserviceRequest.getServices(nim);

  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    if (this.route.snapshot.paramMap.get('titleCodif') != null) {
      this.titleCodif = this.route.snapshot.paramMap.get('titleCodif');
      setTimeout(() => {
        this.openModal(this.contentRef);
      });
    }
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngOnDestroy() {
    this.closeModal(false);
    super.ngOnDestroy();
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.titlesSubs) {
      this.titlesSubs.unsubscribe();
    }
  }
}
