import { Component, OnInit, Input } from '@angular/core';
import { DataItem, ActivityType } from '../../Models/store.model';
import { PdvbaseComponent } from '../pdvbase/pdvbase.component';
import { LineStatement } from 'src/app/Models/newStatment.model';



@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent extends PdvbaseComponent implements OnInit {

  ITEM_SUFFIX = 'ACTPRO';
  isDataFailed: Boolean = false;
  forceUpdate: Boolean = false;
  dataItemList: [DataItem];
  localStatements: LineStatement[] = [];

  private currentDataList: DataItem[] = [];
  private _changeItems: LineStatement[] = [];
  get items(): LineStatement[] {

    this._changeItems = [];
    if (this.changes
      && this.changes.length
      && this.changes[0].changes
      && this.changes[0].changes.length) {
       // tslint:disable-next-line:max-line-length
        this._changeItems = this.changes[0].changes.filter(i => i.id.indexOf(this.activityType === 1 ? 'activités_' : 'proximités_')  === 0 && i.action === 'add');
     }
     if (this.localStatements && this.localStatements.length) {
       // tslint:disable-next-line:max-line-length
       this._changeItems =  this._changeItems.concat( this.localStatements.filter(i => i.id.indexOf(this.activityType === 1 ? 'activités_' : 'proximités_')  === 0 && i.action === 'add'));
     }
    return this._changeItems;
  }

  get name(): string {
    return this.activityType === 1 ? 'activités' : 'proximités';
  }

  get pendingDataUpdate(): Boolean {
    return this.items.length > 0;
  }

  private _items: DataItem[] = [];
  get selectedList(): DataItem[] {
    if (!this._items.length || this.forceUpdate) {
      this._items =  [];
      if ( this.currentDataList ) {
        this._items =  this._items.concat(this.currentDataList);
      }
      this.items.forEach(p => {
        const newId = p.id;
        if (p.action === 'add') {
          this._items.push({id: newId, name: p.label, rank: this.items.indexOf(p)});
        } else {
          const index = this._items.findIndex(i => i.id === newId);
          if (index >= 0) {
            if (p.action === 'delete' || p.action === 'remove') {
              this._items.splice(index, 1);
            } else if (p.action === 'update') {
              this._items[index] = {id: newId, name: p.label, rank: this.items.indexOf(p)};
            }
          }
        }
      });
      this._items = this._items.sort((a, b) => {
        return  (a.rank || 0) <= (b.rank || 0) ? -1 : 1;
    });
   }
   this.forceUpdate = false;
    return this._items;
  }

  @Input() activityType: ActivityType;
  @Input() limit: number;

  loadingText = 'Chargement en cours...';

  ngOnInit() {
    super.ngOnInit();
    this.loadAllDataItems();
  }

  isPending(item: DataItem) {
    return this.items.findIndex(i => this.synitizeId(i.id) === item.id) >= 0;
  }

  private synitizeId(id: string) {
    return (this.activityType === 1 ? 'activités_' : 'proximités_') + (id || '').replace(this.ITEM_SUFFIX, '');
  }

  loadAllDataItems() {
    this.isLoading = true;
    this.activityService.getActivitiesUpdateListner()
      .subscribe((entries: [DataItem]) => {
        this.dataItemList = entries;
        this.isLoading = false;
        this.isDataFailed = this.dataItemList == null;
        this.updateCurrentDataList();
      });
    this.activityService.getAllActivities(this.activityType);
  }

  get limitReached(): boolean {
    return (this.selectedList.length) >= this.limit;
  }

  itemIsSelected(itemID: string) {
    if (this._items.findIndex(i => i.id === itemID) >= 0) {
      return true;
    } else {
      const newId = this.synitizeId(itemID);
      if (this.changes != null && this.changes.length && this.changes[0].changes) {
       return this.changes[0].changes.findIndex(i => i.id === newId) >= 0;
        } else {
          return false;
        }
    }
  }
  itemIsSelectedEnLocal(itemID: string) {
    if (this.itemIsSelected(itemID)) {
      return true;
    }
    const newId = this.synitizeId(itemID);
    if (this.localStatements.findIndex(i => i.id === newId) >= 0) {
      if (this.localStatements[this.localStatements.findIndex(i => i.id === newId)].action === 'add') {
        return true;
      } else {
        return false;
      }
    } else {
     return false;
    }
  }
  localIndexItem(itemID: string) {
      return this.localStatements.findIndex(i => i.id === itemID);
  }
  itemIsChanged(itemID: string) {
    const newId = this.synitizeId(itemID);
    return this.localStatements.findIndex(i => i.id === newId) >= 0;
  }
  onChange(itemId: string) {

    const newId = this.synitizeId(itemId);
    const dataItem = this.dataItemList.find(i => i.id === itemId);

    const st = this.getStatementOrDefault(newId, this.name);
    if (this.localIndexItem(newId) >= 0) {
      this.localStatements.splice(this.localIndexItem(newId), 1);
    } else if (this.itemIsSelected(itemId)) {
      st.label = dataItem.name;
      st.action = 'remove';
        this.localStatements.push(st);
    } else {
      st.label = dataItem.name;
      st.action = 'add';
      this.localStatements.push(st);
    }
    this.forceUpdate = true;
  }

  updateCurrentDataList() {
    switch (this.activityType) {
      case ActivityType.Activity: {
        this.currentDataList = this.store.activities || [];
        break;
      }
      case ActivityType.Proximity:
      default: {
        this.currentDataList = this.store.proximities || [];
        break;
      }
    }
  }

  reorderItems(index, down) {
    if (index === 0 && !down) {
      return;
    }
    if (index === (this._items.length - 1) && down) {
      return;
    }

    const b = down ? (index + 1) : (index - 1);
    const temp = this._items[index];
    this._items[index] = this._items[b];
    this._items[b] = temp;

    this.reorderTrackChange(index);
    this.reorderTrackChange(b);
  }

  private reorderTrackChange(index) {
    const itemId = this._items[index].id;
    const dataItem = this.dataItemList.find(i => this.synitizeId(i.id) === itemId);
    const itemChange = this.localStatements.find(i => i.id === itemId);

    if (!itemChange) {
      const st = this.getStatementOrDefault(itemId, this.name);
      st.label = dataItem.name;
      this.localStatements.push (st);
    }
  }

  onSave() {
    if (!this.localStatements.length) {
      return;
    }

    this.localStatements.forEach(s => {
      const val = this.dataItemList.find(i => this.synitizeId(i.id) === s.id);
      const index = this._items.findIndex(i => i.id === s.id) + 1;
      if (s.action === 'add') {
        val.rank = index;
        s.oldValue = '';
      } else if (s.action === 'update') {
        val.rank = index;
        const current = this.currentDataList.find(i => i.id === s.id);
        s.oldValue = JSON.stringify(current);
      }
      s.newValue = JSON.stringify(val);
    });

    this.save(this.localStatements);
  }

  isDataDisabled() {
    if (this.isReadOnly()) {
      return true;
    }
    if (this.changes != null && this.changes.length && this.changes[0].changes) {
      // tslint:disable-next-line:max-line-length
      return this.changes[0].changes.findIndex(i => i.id.startsWith((this.activityType === 1 ? 'activités_' : 'proximités_')) && i.warehouseAction == null) >= 0;
   } else {
     return false;
   }
 }
}
