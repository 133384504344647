import { Component, OnInit, ViewChild } from '@angular/core';
import { PdvbaseComponent } from '../pdvbase/pdvbase.component';
import { MapComponent } from 'src/app/controls/map/map.component';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css']
})
export class DepositComponent extends PdvbaseComponent implements OnInit {


  @ViewChild(MapComponent) map: MapComponent;

  get contactName() {
    if (!this.store
      || !this.store.depot
      || !this.store.depot.contact) {
      return '';
    }

    return `${this.store.depot.contact.civility} ${this.store.depot.contact.firstName} ${this.store.depot.contact.lastName}`;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.store) {
      this.map.setLocationWarehouse(this.store.depot);
    }
    this.ga.createEventScreenView( 'Pdv > Votre dépôt');
  }
}
