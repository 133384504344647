import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { NgForm } from '@angular/forms';
import { NgbDate, NgbModal, NgbCheckBox } from '@ng-bootstrap/ng-bootstrap';
import { Sweetalert } from '../shared/sweetAlert';
import { StoreService } from '../services/store.service';
import { Tour } from '../Models/tour.model';
import { Store } from '../Models/store.model';
import { AuthService } from '../services/auth-service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private sweet: Sweetalert,
    private ngbModalService: NgbModal,
    private storeService: StoreService,
    private authService: AuthService) { }

  private storeChangeSubs: Subscription;
  isRecurrent = false;
  isSending = false;
  showBtnSpinner = false;
  dateFrom;
  minDateRecurrent;
  tours: Tour[] = [];
  selectedTours: string[] = [];
  selectedStores: string[] = [];
  stores: Store[];

  toggleCheck() {
    this.isRecurrent = !this.isRecurrent;
  }

  ngOnInit() {
    const date = new Date();
    this.dateFrom = { year: date.getFullYear(), month: date.getUTCMonth() + 1, day: date.getDate() };

    this.storeChangeSubs = this.authService.getStoreChangeListener()
    .subscribe(store => {
      if (store) {
        this.storeService.getWarehouseTours();
      }
    });

    this.alertService.getSendUpdateListener()
      .subscribe(status => {
        this.isSending = false;
        if (status) {
          this.sweet.success('Succès !', 'l’alerte est envoyée avec succès.');
        } else {
          this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
        }
      }, error => {
        this.isSending = false;
        this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
      });
    this.storeService.getToursUpdateListner()
      .subscribe(tours => {
        this.showBtnSpinner = false;
        this.tours = tours;
        if (tours) {
          this.selectedStores = tours.map(t => t.stores).reduce((a, b) => a.concat(b)).sort((a, b) => {
            return (a.name || '').localeCompare(b.name);
          }).map(s => s.nim);

          this.stores = this.tours.map(t => {
            return t.stores;
          }).reduce((r, b) => r.concat(b)).sort((a, b) => {
            return (a.name || '').localeCompare(b.name);
          });
        }
      }, error => {
        this.showBtnSpinner = false;
      });

    this.storeService.getWarehouseTours();
  }

  onSendDateSelect(event: NgbDate) {

    this.minDateRecurrent = { year: event.year, month: event.month, day: event.day };
  }

  onNimChecked(event, value: string) {

    if (!event) {
      return;
    }

    const indexOfItem = this.selectedStores.indexOf(value);
    if (event.target.checked) {
      if (indexOfItem < 0) {
        this.selectedStores.push(value);
      }
    } else if (indexOfItem >= 0) {
      this.selectedStores.splice(indexOfItem, 1);
    }
  }

  onTourChecked(event, value: string) {

    if (!event) {
      return;
    }

    const indexOfItem = this.selectedTours.indexOf(value);
    if (event.target.checked) {
      if (indexOfItem < 0) {
        this.selectedTours.push(value);
      }
    } else if (indexOfItem >= 0) {
      this.selectedTours.splice(indexOfItem, 1);
    }
  }

  validateStores(event, value: string) {

    if (!this.selectedStores) {
      this.closeModal();
      return;
    }

    this.closeModal();
  }

  isTourChecked(value: string) {
    return this.selectedTours.indexOf(value) >= 0;
  }

  isStoreChecked(value: string) {
    return this.selectedStores.indexOf(value) >= 0;
  }

  validateTours() {

    if (!this.selectedTours) {
      this.closeModal();
      return;
    }

    this.selectedStores = [];
    this.selectedTours.forEach(tourNumber => {
      const tourIndex = this.tours.findIndex(t => t.tourNumber === tourNumber);
      if (tourIndex < 0) {
        return;
      }

      const tour = this.tours[tourIndex];
      if (!tour.stores) {
        return;
      }

      tour.stores.forEach(store => {
        this.selectedStores.push(store.nim);
      });
    });

    this.closeModal();
  }

  selectAll() {
    this.selectedStores = this.stores.map(s => s.nim);
    return false;
  }

  unselectAll() {
    this.selectedStores = [];
    return false;
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.split(/,|;/ig).map(t => t.trim());
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
        if (nims.length === 0) {
          alert('Le fichier ne contient pas de NIM sur 7 caractères.');
          input.value = '';
        } else {
          this.selectedStores = nims;
        }
        };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  sendAlert(form: NgForm) {
    if (!form || !this.selectedStores || !this.selectedStores.length) {
      return;
    }

    const data = {
      // tslint:disable-next-line:max-line-length
      sendingDate: `${form.value.sendingEffectDate.year}-${form.value.sendingEffectDate.month}-${form.value.sendingEffectDate.day}T00:00:00`,
      diffuserNims: this.selectedStores,
      webLink: form.value.alertLink,
      content: form.value.alertText,
      period: {
        every: 0,
        until: ''
      }
    };
    if (!this.isRecurrent) {
      data.period = null;
    } else {

      if (form.value.reccurentPeriod === '') {
        return;
      }
      data.period.every = form.value.reccurentPeriod;
      data.period.until = `${form.value.sendingEndDate.year}-${form.value.sendingEndDate.month}-${form.value.sendingEndDate.day}T00:00:00`;
    }

    this.isSending = true;
    this.alertService.sendAlert(data);
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
  }

  ngOnDestroy() {
    this.closeModal();
    if (this.storeChangeSubs) {
      this.storeChangeSubs.unsubscribe();
    }
  }
}
