import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';

import { BreadCrumb } from '../../Models/breadCrumb.model';
import { AuthService } from 'src/app/services/auth-service';
import { CategoryService } from 'src/app/services/category.service';
import { Explore } from 'src/app/Models/explore.model';
import { Category } from 'src/app/Models/issue.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: BreadCrumb[];
  catId = '';
  ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  constructor(private categoryService: CategoryService, private activatedRoute: ActivatedRoute, private authService: AuthService,
    private router: Router) { }

  get isUserAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }
  ngOnInit() {
    const breadcrumb: BreadCrumb = {
      label: 'Accueil',
      url: '',
      isActive: false
    };

    // for default page
    const root: ActivatedRoute = this.activatedRoute.root;
    this.breadcrumbs = this.getBreadcrumbs(root);
    this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // set breadcrumbs
      // tslint:disable-next-line:no-shadowed-variable
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];

    });
  }
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length === 0) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: BreadCrumb = {
        label: this.getTitle(child),
        url: url,
        isActive: true
      };
      breadcrumbs.push(breadcrumb);
      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

  private getTitle(activatedRoute: ActivatedRoute): string {
    if (activatedRoute.snapshot.data[this.ROUTE_DATA_BREADCRUMB] === 'Catégorie') {
      this.catId = activatedRoute.snapshot.params['id'];
      localStorage.getItem('PROPUBLIC');
      const categories: Category[] = JSON.parse(localStorage.getItem('categories'));
      if (categories ) {
       const  cat = categories.filter(c => c.id === this.catId );
       if (categories ) {
         return cat[0].name;
       }
      }
    }
    return activatedRoute.snapshot.data[this.ROUTE_DATA_BREADCRUMB];
  }

}
