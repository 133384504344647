import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Issue } from 'src/app/Models/issue.model';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderService } from 'src/app/services/order.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { AuthService } from 'src/app/services/auth-service';
import { environment } from 'src/environments/environment.liprego';

@Component({
  selector: 'app-ordering-add-to-basket',
  templateUrl: './ordering-add-to-basket.component.html',
  styleUrls: ['./ordering-add-to-basket.component.css']
})
export class OrderingAddToBasketComponent implements OnInit {

  @Input() issue: Issue;
  @Output() closeModalOutput = new EventEmitter<any>();
  @Output() basketOutput = new EventEmitter<any>();

  isPaid = false;
  coverFailed = false;
  showBtnSpinner = false;
  constructor(private orderService: OrderService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private sweet: Sweetalert) { }

  ngOnInit() {
  }

  get maxHour(): number {
    if (!this.issue) {
      return environment.lipregoMaxCommandHour;
    }

    return this.issue.maxHour || environment.lipregoMaxCommandHour;
  }
  get isAfterMaxHour(): boolean {
    if (!this.issue) {
      return false;
    }

    return moment().hour() >= (this.issue.maxHour || this.maxHour);
  }
  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }
  getCoverStyle(issue: Issue) {

    if (!issue || moment(issue.releaseDate).isAfter(moment())) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustStyle(`url(${issue.cover}),url(https://zeensassets.blob.core.windows.net/web/notfound.png)`);
  }
  OnInCreaseQuantity(id) {
    let qtt = parseInt(((document.getElementById(id) as HTMLInputElement).value), 0);
    if (qtt >= 1) {
      qtt--;
      (document.getElementById(id) as HTMLInputElement).value = qtt.toString();
    }
  }

  OnUpCreaseQuantity(id) {
    let qtt = parseInt(((document.getElementById(id) as HTMLInputElement).value), 0);
    qtt++;
    (document.getElementById(id) as HTMLInputElement).value = qtt.toString();
  }

  getTotal(price: number, inputId: string) {
    const qtt = parseInt(((document.getElementById(inputId) as HTMLInputElement).value), 0);
    if (qtt === 0) {
      return 0;
    }
    return qtt * price;
  }

  displayName() {
    this.coverFailed = true;
  }

  onAddIssueToUserBasket() {
    const qtt = parseInt(((document.getElementById(this.issue.id) as HTMLInputElement).value), 0);
    if (qtt === 0) {
      this.closeModal() ;
      return 0;
    }

    let ref = null;
    const refInput = (document.getElementById('refClient') as HTMLInputElement);
    if (refInput) {
      ref = refInput.value;
    }

    this.basketOutput.emit({issue: this.issue, quantity: qtt, reference: ref, paid: this.isPaid});
    this.closeModal() ;
  }

  onOrder() {
    const qtt = parseInt(((document.getElementById(this.issue.id) as HTMLInputElement).value), 0);
    if (qtt === 0) {
      this.closeModal() ;
      return 0;
    }
    const ref = (document.getElementById('refClient') as HTMLInputElement).value;

    if (!ref || !ref.length) {
      this.sweet.warning('Référence client', 'Merci de saisir la référence du client SVP !');
      return;
    }

    const items = [{id: this.issue.id, quantity: qtt, reference: ref, paid: this.isPaid}];
    this.onValidatOrder(items);
  }
  getIssueQuantity(issue) {
    const isExist = this.orderService.getBasketByIssue(issue);
    if (isExist.length && isExist.length > 0) {
      return isExist[0].quantity;
    } else {
      return 1;
    }
  }
  closeModal() {
    this.closeModalOutput.emit();
  }
  toggleCheck() {
    this.isPaid = !this.isPaid;
  }
  onValidatOrder(items: any[]) {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir valider cette commande ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showBtnSpinner = true;
          this.orderService.createOrder(items, this.authService.currentNim)
              .subscribe(res => {
                this.showBtnSpinner = false;
                if (res.result) {
                  this.sweet.success('Succès !', 'Votre commande a été envoyée au diffuseur.');
                  // clear user basket if order created
                  this.closeModal() ;
                }
              },
                err => {
                  this.showBtnSpinner = false;
                  this.closeModal() ;
                });
        }
      });
  }
  get isPublicPro(): boolean {
    return this.authService.isPublicPro();
  }

  get isCustomer(): boolean {
    return this.authService.isCustomer();
  }
  get isPro(): boolean {
    return this.authService.isPro();
  }
}
