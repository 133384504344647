import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Issue } from 'src/app/Models/issue.model';
import { TitreExpressToPush, Supplement } from 'src/app/Models/admin/titreexpressToPush.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TitreExpressItem } from 'src/app/Models/admin/titreexpress.model';
import { TitreexpressService } from 'src/app/services/titreexpress.service';
import { StoreService } from 'src/app/services/store.service';
import { Warehouse } from 'src/app/Models/warehouse.model';

@Component({
  selector: 'app-tecreate',
  templateUrl: './tecreate.component.html',
  styleUrls: ['./tecreate.component.css']
})
export class TecreateComponent implements OnInit, OnDestroy {
  _titreExpressItem: TitreExpressItem;
  warehouses: Warehouse[] = [];

  @Input()
  set titreExpressItem(val: any) {
    if (val) {
      this._titreExpressItem = val;
      this.notificationType = val.type.toString();
      this.recipient = val.recipient;
      this.selectedNims = val.nims;
      if (val.type === 0) {
        this.newPrice = val.newPrice;
      } else if (val.type === 1) {
        this.grammes = val.supplement.grammes;
        this.exemplaires = val.supplement.samples;
        this.paquets = val.supplement.paquets;
        this.message = val.message;
      } else if (val.type === 2) {
        const d1 = new Date(val.newReturnDate);
        this.newDate = { day: d1.getDate(), month: d1.getMonth() + 1, year: d1.getFullYear()};
        this.message = val.message;
      } else if (val.type === 3) {
        this.message = val.message;
      }
    }
  }
  @Input() updateMode =  false;
  notificationType = '';
  searchingCodif = false;
  issueNumber = '';
  titleCodif = '';
  recipient = '';
  newPrice = '';
  grammes = '';
  exemplaires = '';
  paquets = '';
  newDate: NgbDateStruct;
  message = '';
  private issuesSub: Subscription;
  isLoading: Boolean = false;
  noDataFound = false;
  issues: Issue[] = [];
  selectedNims: string[] = null;

  @ViewChild('sendDateCheckbox') differedSend: ElementRef;
  sendDate: NgbDateStruct;

  selectedIssue: Issue = null;

  constructor(private titleExpressService: TitreexpressService,
    private storeService: StoreService) { }

  ngOnInit() {
    this.issuesSub = this.titleExpressService.getIssuesRequestsUpdateListner()
      .subscribe(issuesResult => {
        this.isLoading = false;
        this.issues = issuesResult;
        if (this.issues.length) {
          this.selectedIssue = this.issues[0];
        }
        this.noDataFound = !this.issues.length;
      });

      this.storeService.getWarehousesUpdateListner()
      .subscribe(warehouses => {
        this.warehouses = (warehouses || [])
        .sort((a, b) => {
          const aVal = (a.name || a.nim || '');
          const bVal = (b.name || b.nim || '');
          return (aVal).localeCompare(bVal);
        });
      }, error => {
        this.warehouses = [];
      });
      this.storeService.getWarehouses();
  }

  onWarehouseChecked(event, nim: string) {
    if (!this.selectedNims
      || !event) {
        return;
    }

    const indexOfItem = this.selectedNims.indexOf(nim);
    if (event.target.checked) {
      if (indexOfItem < 0) {
        this.selectedNims.push(nim);
      }
    } else if (indexOfItem >= 0) {
      this.selectedNims.splice(indexOfItem, 1);
    }
  }

  isWarehouseChecked(value: string) {
    if (!this.selectedNims) {
      return true;
    }
    return this.selectedNims.indexOf(value) >= 0;
  }

  manageCheckBoxes(select: boolean) {
    if (select) {
      return this.selectedNims = this.warehouses.map(m => m.nim);
    } else {
      return this.selectedNims = [];
    }
  }

  getItemToSave(): TitreExpressToPush {
    if (!this.selectedIssue && !this._titreExpressItem) {
      return null;
    }

    const itemToPush = new TitreExpressToPush();
    if (this._titreExpressItem) {
      itemToPush.id = this._titreExpressItem.id;
    } else {
      itemToPush.id = this.selectedIssue.id;
    }
    itemToPush.type = parseInt(this.notificationType, 0);
    itemToPush.recipient = parseInt(this.recipient, 0);

    if (this.differedSend
      && this.differedSend.nativeElement.checked) {
      const fromDate = this.sendDate ? `${this.sendDate.year}-${this.sendDate.month}-${this.sendDate.day}` : '';
      itemToPush.sendDate = fromDate;
    }

    const nims = this.selectedNims;
    if (this.recipient === '0' || this.recipient === '2') {
      itemToPush.nims = nims || [];
    } else {
      itemToPush.nims = null;
    }
    if (itemToPush.type === 0) {
      itemToPush.newPrice = parseFloat(this.newPrice);
    } else if (itemToPush.type === 1) {
      itemToPush.supplement = new Supplement();
      itemToPush.supplement.grammes = parseInt(this.grammes, 0);
      itemToPush.supplement.samples = parseInt(this.exemplaires, 0);
      itemToPush.supplement.paquets = parseInt(this.paquets, 0);
      itemToPush.message = this.message;
    } else if (itemToPush.type === 2) {
      itemToPush.newReturnDate = `${this.newDate.year}-${this.newDate.month}-${this.newDate.day}T00:00:00`;
      itemToPush.message = this.message;
    } else if (itemToPush.type === 3) {
      itemToPush.message = this.message;
    } else {
      return null;
    }

    return itemToPush;
  }

  displayPanel(code: number): boolean {
    return this.notificationType === code.toString();
  }

  selectedIssueChanged(event) {

    if (!event || !event.target) {
      this.selectedIssue = null;
      return;
    }

    this.selectedIssue = this.issues.find(i => i.id === event.target.value);
  }

  doSearch(text: string ) {
    this.titleCodif = text;
    if (!this.titleCodif || !this.titleCodif.length || this.titleCodif.length === 1) {
      this.isLoading = false;
      return;
    }
    this.issues = [];
    this.isLoading = true;

    if (this.titleCodif === '') {
      this.isLoading = false;
      return;
    }

    this.titleExpressService.getIssues(this.titleCodif);
  }

  ngOnDestroy() {
    if (this.issuesSub) {
      this.issuesSub.unsubscribe();
    }
  }
}
