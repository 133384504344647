import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth-service';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart, ChildActivationStart } from '@angular/router';
import { GA } from './shared/gAnalytics';
import { environment } from '../environments/environment';
import { Campaign } from './Models/campaign.model';
import { AdCampaignService } from './services/ad-campaign.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { VersionCheckService } from './services/version-check.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  gtag;
  constructor(private authService: AuthService, public router: Router, private activatedRoute: ActivatedRoute,
    private ga: GA, private adCampaignService: AdCampaignService, config: NgbCarouselConfig,
    private versionCheckService: VersionCheckService,
    private titleService: Title) {
    this.appendGaTrackingCode();
    this.router.events.subscribe(event => {

      if (event instanceof ChildActivationStart) {
        const logout = event.snapshot.queryParams['logout'] || '0';
        if (logout === '1') {
          this.onLogout();
        }
        const nim = event.snapshot.queryParams['nim'] || '';
        if (nim.length) {
          if (this.isUserAuthenticated) {
            this.authService.setCurrentNim(nim);
          } else {
            const auth = event.snapshot.queryParams['auth'] || '';
            if (auth.length) {
              localStorage.setItem(AuthService.KEY_AUTH, 'Basic ' + auth);
              this.authService.setCurrentNim(nim);
            } else {
              this.authService.setUrlNim(nim);
            }
          }
        }
        return;
      }
      if (event instanceof NavigationEnd) {

        const userId = this.authService.isAdmin ? this.authService.userEmail : this.authService.currentNim;
        this.ga.setUserId(userId);

        let title = 'PDIF : le portail des diffuseurs de presse';
        // tslint:disable-next-line:max-line-length
        if (activatedRoute.firstChild && activatedRoute.firstChild.data && activatedRoute.firstChild.snapshot.data.hasOwnProperty('breadcrumb')) {
          title = activatedRoute.firstChild.snapshot.data['breadcrumb'];
        }
        this.ga.sendPageView(event.urlAfterRedirects, title);

        this.currentCampaign = null;
        this.activeCampaigns = [];

        this.activeCampain();
      }
    });
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 1000;
  }
  activeCampaigns: Campaign[];
  currentCampaign: Campaign;

  get isUserAuthenticated(): boolean {
    if (this.authService.isAuthenticated && this.router.isActive('/cgu', true)) {
      return false;
    }
    return this.authService.isAuthenticated && !this.router.isActive('/login', true);
  }
  get isCGU(): boolean {
    if (this.authService.isAuthenticated && this.router.isActive('/cgu', true)) {
      return true;
    } else if (!this.authService.isAuthenticated) {
      return true;
    }
    return false;
  }
  get isMessaging(): boolean {
    return this.router.isActive('/messaging', true);
  }
  get isExplore(): boolean {
    return this.router.isActive('/explore', true) || this.router.isActive('/category', false);
  }
  get isPro(): boolean {
    return this.authService.isPro();
  }
  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  activeCampain() {

    if (!this.authService.isStore) {
      return;
    }

    this.adCampaignService.getACTIVECampaigns(this.authService.currentNim)
      .subscribe(res => {
        if (res && res.result) {
          let campaigns: Campaign[];
          campaigns = res.result;
          campaigns.forEach(element => {

            element.routePaths.forEach(elementPath => {

              if (this.activatedRoute.firstChild
                && this.activatedRoute.firstChild.data
                && this.activatedRoute.firstChild.snapshot.data.hasOwnProperty('breadcrumb')
                && elementPath === this.activatedRoute.firstChild.snapshot.data['breadcrumb']) {
                this.activeCampaigns.push(element);
                return;
              }
            });
          });
        }
      },
        err => {
        });
  }

  ngOnInit() {
    // check if there is any updates every 30 min
    const time = environment.cacheRefresh;
    this.versionCheckService.initVersionCheck('/version.json', time);

    this.titleService.setTitle(environment.title);

    this.authService.autoAuthUser();
  }

  ngOnDestroy() {
    
  }

  changeNim(nim: string) {
    if (!nim) {
      return;
    }

    this.authService.changeNim(nim);
  }

  onLogout() {
    this.authService.onLogout();
  }
  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.TrackingId;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  trackCampaignClick(campaign: Campaign) {
    if (!campaign || !campaign.deepLink) {
      return;
    }

    if (campaign.deepLink.url) {
      this.ga.createEvent('click', 'ads', `Pub : ${campaign.name} url : ${campaign.deepLink.url}`);
      const url = campaign.deepLink.url.includes('//') ? campaign.deepLink.url : `//${campaign.deepLink.url}`;
      window.open(url, '_blank');
    } else if (campaign.deepLink.codif) {
      this.ga.createEvent('click', 'ads', `Pub : ${campaign.name} codif : ${campaign.deepLink.codif}`);
      const url = '/titles/' + campaign.deepLink.codif;
      window.open(url);
    }
  }
}
