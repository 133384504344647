import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { AuthGuard } from './auth/auth-guard';
import { Page401Component } from './auth/page401/page401.component';
import { SearchComponent } from './search/search.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReorderComponent } from './reorder/reorder.component';
import { ForgottenComponent } from './forgotten/forgotten.component';
import { UnsodComponent } from './unsod/unsod.component';
import { ClaimsComponent } from './claims/claims.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { UpdateserviceComponent } from './updateservice/updateservice.component';
import { ArticlesComponent } from './articles/articles.component';
import { DeliveryDetailComponent } from './archives/delivery/delivery.detail.component';
import { BciDetailComponent } from './archives/bci/bci.detail.component';
import { PerformanceComponent } from './performance/performance.component';
import { TitleComponent } from './title/title.component';
import { AlertsComponent } from './alerts/alerts.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { AlertHistoryComponent } from './alerts/alert-history/alert-history.component';
import { ProfilComponent } from './profil/profil.component';
import { PdvComponent } from './pdv/pdv.component';
import { HelpdeskComponent } from './admin/helpdesk/helpdesk.component';
import { SignupstatsComponent } from './admin/signupstats/signupstats.component';
import { LostpasswordComponent } from './auth/lostpassword/lostpassword.component';
import { EditortitlesComponent } from './admin/editortitles/editortitles.component';
import { StorecomComponent } from './admin/storecom/storecom.component';
import { RenewpasswordComponent } from './auth/renewpassword/renewpassword.component';
import { ArchivesComponent } from './archives/archives.component';
import { SupportComponent } from './support/support.component';
import { AccountstatmentformsComponent } from './archives/account/accountstatmentforms.component';
import { PressstatmentformsComponent } from './archives/press/pressstatmentforms.component';
import { AdCampaignComponent } from './admin/ad-campaign/ad-campaign.component';
import { CguComponent } from './auth/cgu/cgu/cgu.component';
import { ContactComponent } from './contact/contact.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import { StatementsComponent } from './pdv/statements/statements.component';
import { BillComponent } from './archives/bill/bill.component';
import { AvoirComponent } from './archives/avoir/avoir.component';
import { MessagingComponent } from './messaging/messaging.component';
import { ExploreComponent } from './ordering/explore/explore.component';
import { CategoryComponent } from './ordering/category/category.component';
import { UserBasketComponent } from './ordering/user-basket/user-basket.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { ProComponent } from './admin/pro/pro.component';
import { OrderTrackingComponent } from './admin/order-tracking/order-tracking.component';
import { CustomerCodeComponent } from './admin/customer-code/customer-code.component';
import { RegisterComponent } from './auth/register/register.component';
import { TitreexpressComponent } from './admin/titreexpress/titreexpress.component';
import { DifferedreturnComponent } from './archives/differedreturn/differedreturn.component';
import { CompensationComponent } from './archives/compensation/compensation.component';
import { TepdfComponent } from './admin/titreexpress/tepdf/tepdf.component';
import { ActivityReportComponent } from './admin/activity-report/activity-report.component';
import { FiabilityScanComponent } from './archives/fiability-scan/fiability-scan.component';
import { WarehouseHolidaysComponent } from './warehouse-holidays/warehouse-holidays.component';
import { ReopenComponent } from './reopen/reopen.component';
import { ReopendetailComponent } from './archives/reopendetail/reopendetail.component';
import { GmsComponent } from './admin/gms/gms.component';
import { ContactsComponent } from './admin/contacts/contacts.component';
import { TitreinfoComponent } from './admin/titreinfo/titreinfo.component';
import { SondagesComponent } from './admin/sondages/sondages.component';
import { ArchivesWarehouseComponent } from './archives-warehouse/archives-warehouse.component';
import { Top10titlesComponent } from './admin/top10titles/top10titles.component';
import { WeeklyformsComponent } from './archives/weeklyforms/weeklyforms.component';
import { PendingrequestComponent } from './updateservice/pendingrequest/pendingrequest.component';
import { AssortimentlistComponent } from './assortimentlist/assortimentlist.component';
import { AssortbulkComponent } from './admin/assortbulk/assortbulk.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PlafonnementComponent } from './assortimentlist/plafonnement/plafonnement.component';
import { FullDashboardComponent } from './dashboard/assort/full-dashboard/full-dashboard.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: 'welcome',
        component: LoginComponent
    },
    {
        path: 'home',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Dashboard', roles: ['pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'plafonnement',
        component: PlafonnementComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Plafonnement & quantités max', roles: ['pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'financialreport',
        component: DashboardComponent,
        data: {
            breadcrumb: 'Courrier de rémunération', roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'reorder',
        component: ReorderComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'commande de réassort', roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'reorder/:titleCodif',
        component: ReorderComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'commande de réassort', roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'forgotten',
        component: ForgottenComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'demandes d’ouverture de droits aux oubliés',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'archives',
        component: ArchivesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Mes archives',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        },
    },
    {
        path: 'warehouse-archives-docs',
        component: ArchivesWarehouseComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Mes archives',
            roles: ['pdifgms'],
            accessToOldOwner: false,
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        },
    },
    {
        path: 'warehouse-archives',
        component: ArchivesWarehouseComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Mes archives',
            roles: ['pdifwarehouse', 'pdifcommercial'],
            accessToOldOwner: true,
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        },
    },
    {
        path: 'archives/:month/:year',
        component: ArchivesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Mes archives',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        },
    },
    {
        path: 'bill/:date',
        component: BillComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Facture hors presse',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        },
    },
    {
        path: 'credit/:date',
        component: AvoirComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Avoir hors presse',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        },
    },
    {
        path: 'forgotten/:titleCodif',
        component: ForgottenComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'demandes d’ouverture de droits aux oubliés',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        },
    },
    {
        path: 'unsold',
        component: UnsodComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'invendus du jour',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'reopen',
        component: ReopenComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'bordereaux',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'claims',
        component: ClaimsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'réclamation sur BL du jour',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'deliveryforms/:date',
        component: DeliveryDetailComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bordereaux de livraison',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        },
    },
    {
        path: 'reopen/:date',
        component: ReopendetailComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bordereaux de réouverture',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        },
    },
    {
        path: 'weeklyforms/:date',
        component: WeeklyformsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bordereaux d’invendus hebdomadaire',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        },
    },
    {
        path: 'deliveryforms/:date/:printOnly',
        component: DeliveryDetailComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bordereaux de livraison',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'differed',
        component: DifferedreturnComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Parutions en différés',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'compensation',
        component: CompensationComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Historiques des compléments de rémunération',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'fiability-scan',
        component: FiabilityScanComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Historiques des remontées de ventes',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'bciforms/:date',
        component: BciDetailComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bordereaux d’invendus',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'bciforms/:date/:printOnly',
        component: BciDetailComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bordereaux d’invendus',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'accountforms/:date',
        component: AccountstatmentformsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Relevé de compte',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
            allowUrlAuth: true,
        },
    },
    {
        path: 'accountforms/:date/:printOnly',
        component: AccountstatmentformsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Relevé de compte',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        },
    },
    {
        path: 'pressforms/:date',
        component: PressstatmentformsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Relevé de presse',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
            allowUrlAuth: true,
        },
    },
    {
        path: 'pressforms/:date/:printOnly',
        component: PressstatmentformsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Relevé de presse',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        },
    },
    {
        path: 'performances',
        component: PerformanceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Performance du point de vente',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'services',
        component: UpdateserviceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'demande de service',
            roles: ['pdifdiffuser', 'pdifcommercial', 'pdifgms', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'assortimenttitles',
        component: AssortimentlistComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Votre offre titres',
            roles: ['pdifdiffuser', 'pdifcommercial', 'pdifgms', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'pendingrequests',
        component: PendingrequestComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'demande en attente',
            roles: ['pdifdiffuser', 'pdifcommercial', 'pdifgms', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'alerts',
        component: AlertsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'envoyer une alerte',
            roles: ['pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'admin/titles',
        component: EditortitlesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'base d’adressage',
            roles: ['pdifeditoradressing'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'admin/stores',
        component: StorecomComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'base d’adressage diffuseurs',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'alerts-history',
        component: AlertHistoryComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'historique des alertes',
            roles: ['pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'admin/top10',
        component: Top10titlesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Les incontournables',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'services/titles/:titleCodif',
        component: UpdateserviceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'modification de service',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'titles/:titleCodif',
        component: TitleComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'information titre',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdifeditoradressing', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'titles/:titleCodif/issues/:issueNumber',
        component: TitleComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'information titre',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdifeditoradressing', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'incidents',
        component: IncidentsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Incident de remontée des ventes',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'contact',
        redirectTo: '/support',
        pathMatch: 'full'
    },
    {
        path: 'support',
        component: environment.disableSupport ? ContactComponent : SupportComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Contacter le support',
            allowAnonymous: true,
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        }
    },
    {
        path: 'support/login',
        component: environment.disableSupport ? ContactComponent : SupportComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Contacter le support',
            allowAnonymous: true,
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        }
    },
    {
        path: 'admin/contacts',
        component:  ContactsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Contacts',
            roles: ['pdifwarehouse', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false
        }
    },
    {
        path: '401',
        component: Page401Component
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        data: {
            breadcrumb: 'Accès refusé',
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'helpdesk',
        component: HelpdeskComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Helpdesk',
            roles: ['pdifhelpdesk', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'lostpassword',
        component: LostpasswordComponent
    },
    {
        path: 'renewpassword',
        component: RenewpasswordComponent
    },
    {
        path: 'renewpassword/:token',
        component: RenewpasswordComponent
    },
    {
        path: 'activity',
        component: SignupstatsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Activité du portail',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'activity-report',
        component: ActivityReportComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Activité du portail',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'cgu',
        component: CguComponent,
    },
    {
        path: 'firststeps',
        component: SignupComponent
    },
    {
        path: 'pdv',
        component: PdvComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Votre point de vente',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: true,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'explore',
        component: ExploreComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Explorer',
            roles: ['pdifpro', 'pdifprocustomer'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'presse/:shortCode',
        component: ExploreComponent,
        data: {
            breadcrumb: 'Explorer',
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'category/:id',
        component: CategoryComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Catégorie',
            roles: ['pdifdiffuser', 'pdifpro'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'category/:id/:shortCode',
        component: CategoryComponent,
        data: {
            breadcrumb: 'Catégorie',
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Commandes PRO',
            roles: ['pdifdiffuser', 'pdifpro'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    }
    ,
    {
        path: 'order-tracking',
        component: OrderTrackingComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Suivi des commandes clients',
            roles: ['pdifpro'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'statements',
        component: StatementsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Déclarations diffuseurs',
            roles: ['pdifwarehouse', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'warehouse-holidays',
        component: WarehouseHolidaysComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Jours fériés',
            roles: ['pdifwarehouse', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'sondages',
        component: SondagesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Sondages',
            roles: ['pdifwarehouse', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'proaccounts',
        component: ProComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Gestion des comptes pro',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'proaccounts',
        component: ProComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Gestion des comptes pro',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Recherche d’un titre',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifcommercial', 'pdifwarehouse'],
            isOnRestriction: false,
            isAccessForAds: true,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        }
    },
    {
        path: 'adcampaign',
        component: AdCampaignComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Manage Pubs',
            roles: ['pdifads'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'manage-dashboard',
        component: FullDashboardComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Tableau de bord assortiment',
            roles: ['pdifdiffuser', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'account',
        component: ProfilComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Mon compte',
            roles: ['pdifdiffuser', 'pdifwarehouse', 'pdifeditoradressing', 'pdifhelpdesk', 'pdifgms', 'pdifpro', 'pdifprocustomer'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'articles',
        component: ArticlesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'articles',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdifeditoradressing', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: true,
        }
    },
    {
        path: 'admin/titreexpress',
        component: TitreexpressComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Titre express',
            roles: ['pdifadmin', 'pdiftitreexpress'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'admin/titresinfos',
        component: TitreinfoComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Titres Infos',
            roles: ['pdifadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'titreexpress/:itemId',
        component: TepdfComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Détail du titre express',
            roles: ['pdifadmin', 'pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdiftitreexpress', 'pdifcommercial'],
            allowAnonymous: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'admin/gms',
        component: GmsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Gestion des GMS',
            roles: ['pdifadmin', 'pdifgmsadmin'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'messaging',
        component: MessagingComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Centre de messagerie',
            roles: ['pdifdiffuser', 'pdifgms', 'pdifwarehouse', 'pdifeditoradressing', 'pdifhelpdesk', 'pdifcommercial'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'basket',
        component: UserBasketComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Mon Panier',
            roles: ['pdifpropublic', 'pdifpro', 'pdifprocustomer'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'customer-code',
        component: CustomerCodeComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'code client',
            roles: ['pdifpro'],
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: false,
            isMediumPack: false,
            isFullPack: false,
        }
    },
    {
        path: 'warehouse-assortment',
        component: AssortbulkComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Gestion des préférences en masse',
            roles: ['pdifwarehouse', 'pdifcommercial'],
            accessToOldOwner: true,
            isOnRestriction: false,
            isAccessForAds: false,
            isBasicPack: true,
            isMediumPack: true,
            isFullPack: true,
        },
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
