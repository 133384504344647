import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Title } from '../Models/title.model';
import { AssortimentStatus } from '../Models/assortimentStatus.model';
import { SaveUpdateServiceStatus } from '../Models/savereorder.model';

@Injectable({
    providedIn: 'root'
})
export class UpdateserviceService {

    constructor(private http: HttpClient) { }

    private titleUpdated = new Subject<Title[]>();

    private saveUpdated = new Subject<SaveUpdateServiceStatus>();
    private requestUpdated = new Subject<AssortimentStatus>();

    // get list of services by nim
    getServices(nim: string) {
        this.http.get<{ result: Title[] }>(environment.APIURL + '/api/v1/stores/requests/updateservice?nim=' + nim)
            .subscribe(response => {
                if (response) {
                    this.titleUpdated.next(response.result);
                } else {
                    this.titleUpdated.next([]);
                }
            }, err => {
                this.titleUpdated.next([]);
              });
    }

    // return as observable
    getServicesUpdateListener() {
        return this.titleUpdated.asObservable();
    }

    getSaveUpdateListener() {
        return this.saveUpdated.asObservable();
    }

    getRequestUpdatedListener() {
        return this.requestUpdated.asObservable();
    }

    addNewService(nim: string, titleOnly: boolean, data: any) {
        const url = `${environment.APIURL}/api/v1/stores/requests/updateservice?nim=${nim}&titleOnly=${titleOnly}`;
        this.http.put<{ result: SaveUpdateServiceStatus }>(url, data)
        .subscribe(response => {
            if (response.result) {
                localStorage.setItem('LR_TS', (new Date()).getTime().toString());
            }
            this.saveUpdated.next(response.result);
          },
            () => {
                this.saveUpdated.next(null);
            });
    }

    checkNewServiceRequest(nim: string, data: any) {
        // tslint:disable-next-line:max-line-length
        this.http.put<{ result: AssortimentStatus }>(environment.APIURL + '/api/v1/stores/requests/updateservice/check?nim=' + nim, data)
        .subscribe(response => {
            if (response.result) {
                response.result.innerData = data;
            }
            this.requestUpdated.next(response.result);
          },
            () => {
                this.requestUpdated.next(null);
            });
    }
}
