import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Issue } from 'src/app/Models/issue.model';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-category-panel',
  templateUrl: './category-panel.component.html',
  styleUrls: ['./category-panel.component.css']
})
export class CategoryPanelComponent implements OnInit {
  @Input() issues: Issue[] = [];
  @Input() catName = '';
  @Input() shortCode = '';
  @Input() isSearchResult = false;
  @Input() catId = '';

  get isNextId(): boolean {
    return this.catId.indexOf('next') >= 0;
  }

  get isCategoryUrl(): string {

    if (this.shortCode.length) {
      return `/category/${this.catId}/${this.shortCode}`;
    }
    return `/category/${this.catId}`;
  }

  basketIssues: Issue[] = [];

  constructor(private orderService: OrderService, private toastrService: ToastrService) { }

  ngOnInit() {

  }
  scrollRight(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }
    const newLeft = container.scrollLeft + 450;
    container.scrollTo({ left: newLeft, behavior: 'smooth' });
  }

  scrollLeft(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }
    const newLeft = container.scrollLeft - 450;
    container.scrollTo({ left: newLeft, behavior: 'smooth' });
  }

  getParentContainer(element) {
    if (!element
      || !element.currentTarget
      || !element.currentTarget.parentElement
      || !element.currentTarget.parentElement.parentElement
      || element.currentTarget.parentElement.parentElement.className !== 'category-items') {
        return null;
      }
    return element.currentTarget.parentElement.parentElement;
  }
}
