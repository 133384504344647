import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service';
import { Subject } from 'rxjs';
import { GmsUser } from '../Models/gms.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GmsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  private gmsAccountsUpdated = new Subject<GmsUser[]>();
  private statusUpdated = new Subject<boolean>();

  getGmsUsers() {
    const url = `${environment.APIURL}/api/v2/gms/accounts`;
    this.http.get<{ result: GmsUser[] }>(url)
      .subscribe(response => {
        if (response && response.result) {
          this.gmsAccountsUpdated.next(response.result);
        } else {
          this.gmsAccountsUpdated.next([]);
        }
      }, err => {
        this.gmsAccountsUpdated.next([]);
      });
  }

  getGmsUsersUpdateListner() {
    return this.gmsAccountsUpdated.asObservable();
  }

  addOrUpdateGmsAccount(data: GmsUser) {
    const url = `${environment.APIURL}/api/v2/gms/accounts`;
    // tslint:disable-next-line:max-line-length
    this.http.post<{ result: boolean }>(url, data)
    .subscribe(response => {
      if (response) {
        this.statusUpdated.next(response.result);
      } else {
        this.statusUpdated.next(false);
      }
    }, err => {
      this.statusUpdated.next(false);
    });
  }

  deleteGms(data: GmsUser) {
    const url = `${environment.APIURL}/api/v2/gms/accounts/${data.id}`;
    // tslint:disable-next-line:max-line-length
    this.http.delete<{ result: boolean }>(url)
    .subscribe(response => {
      if (response) {
        this.statusUpdated.next(response.result);
      } else {
        this.statusUpdated.next(false);
      }
    }, err => {
      this.statusUpdated.next(false);
    });
  }

  addOrUpdateGmsAccountUpdateListner() {
    return this.statusUpdated.asObservable();
  }
}
