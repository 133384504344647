import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from '../services/notifications.service';
import { NotificationMessage, MessageGroup } from '../Models/message.model';
import { AuthService } from '../services/auth-service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '../shared/pipes/date-formatter.pipe';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Tour } from '../Models/tour.model';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css']
})
export class MessagingComponent implements OnInit, OnDestroy {

  groups: MessageGroup[] = [];
  notifications: NotificationMessage[] = [];
  selectedNotif: NotificationMessage;
  private datePipe = new DatePipe();

  showSpinner = false;
  messageListIndex = 0;
  warehouseAlertsModal: NgbModalRef;

  constructor(private notificationService: NotificationsService,
    private authService: AuthService,
    private ngbModalService: NgbModal,
    private toastrService: ToastrService) { }

  htmlContent = '';
  isArchiving = false;
  selectedIndex = '0-0';

  get canSend(): boolean {
    return this.isAdmin || this.isWarehouse;
  }

  get isWarehouse(): boolean {
    if (!this.authService) {
      return false;
    }
    return this.authService.isWarehouse;
  }

  get isAdmin(): boolean {
    if (!this.authService) {
      return false;
    }
    return this.authService.isAdmin;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Répondre...',
    translate: 'no'
  };
  get isStore(): boolean {
    return this.authService.isStore;
  }

  ngOnInit() {
    this.loadNotifications();
  }

  getSnapShot(body: string) {
    if (!body || !body.length) {
      return null;
    }
    return body
      .replace(/<[^>]*>/ig, '')
      .replace(/\r?\n/ig, ' ')
      .replace('&nbsp;', ' ')
      .substring(0, 200) + '...';
  }

  getSentMessages() {

    this.showSpinner = true;
    this.selectedNotif = null;
    this.messageListIndex = 1;
    this.groups = [];
    this.notificationService.getSentMessages(this.authService.userId, this.authService.currentNim).subscribe(res => {
      this.showSpinner = false;
     if (res) {
        this.notifications = res.result || [];
        const format = 'DD MMMM YYYY';
        if (this.notifications.length) { this.selectedNotif = this.notifications[0]; }
        this.notifications.forEach(n => {
          const key = this.datePipe.transform(n.date, format);
          let item = this.groups.find(i => i.date === key);
          if (item == null) {
            item = { date: key, messages: [], timestamp: 0};
            this.groups.push(item);
          }

          item.messages.push(n);
          item.timestamp = parseInt(this.datePipe.transform(n.date, 'X'), 0);
        });
        this.groups = this.groups.sort((a, b) => {
          return a.timestamp <= b.timestamp ? 1 : -1;
        });
      }
    },
      err => {
        this.showSpinner = false;
        this.notifications = [];
      });
  }

  reply() {

  }

  loadNotifications() {
    this.showSpinner = true;
    this.selectedNotif = null;
    this.messageListIndex = 0;
    this.groups = [];
    this.notificationService.getMessages(this.authService.userId, this.authService.currentNim).subscribe(res => {
      this.showSpinner = false;
      if (res) {
        this.groups = [];
        this.notifications = res.result || [];
        const format = 'DD MMMM YYYY';
        if (this.notifications.length) { this.selectedNotif = this.notifications[0]; }
        this.notifications.forEach(n => {
          const key = this.datePipe.transform(n.date, format);

          let item = this.groups.find(i => i.date === key);
          if (item == null) {
            item = { date: key, messages: [], timestamp: 0 };
            this.groups.push(item);
          }
          item.messages.push(n);
          item.timestamp = parseInt(this.datePipe.transform(n.date, 'X'), 0);
        });
        this.groups = this.groups.sort((a, b) => {
          return a.timestamp <= b.timestamp ? 1 : -1;
        });
      }
    },
      err => {
        this.showSpinner = false;
        this.notifications = [];
      });
  }

  displayAtIndex(notif: NotificationMessage) {
    this.selectedNotif = notif;
  }
  setIndex(i: number, j: number) {
    this.selectedIndex = i + '-' + j;
  }
  // mark a message as read
  markMessageAsRead(messageId: string) {
    if (messageId) {
      this.notificationService.markAsRead(messageId)
        .subscribe(res => {
          if (res) {
            // flag selected message as read
            this.selectedNotif.isRead = true;
          }
        },
          err => {
          });
    }
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  openModal(content) {
    this.warehouseAlertsModal = this.ngbModalService.open(content, { size: 'lg' });
  }

  ngOnDestroy() {
    this.closeModal();
  }
}
