import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth-service';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd, ChildActivationStart } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NotificationsService } from '../services/notifications.service';
import { Notification } from '../Models/notification.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthGuard } from '../auth/auth-guard';
import { Commons } from '../shared/commons';
import { Basket } from '../Models/basket.model';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { Observable, Subscription } from 'rxjs';
import { WarehouseHolidaysService } from '../services/warehouse-holidays.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WarehouseStoreHoliday, WarehouseStoreHolidayLatestAnswer } from '../Models/warehouse-holiday.model';
import { environment } from 'src/environments/environment';
import { StorecomService } from '../services/admin/storecom.service';
import { SondageService } from '../services/sondage.service';
import { SondageItem } from '../Models/sondage.model';

@Component({
  selector: 'app-header-app',
  templateUrl: './header-app.component.html',
  styleUrls: ['./header-app.component.css']
})
export class HeaderAppComponent implements OnInit, OnDestroy {
  @ViewChild('btnCollapseToggle') btnCollapseToggle: ElementRef;
  @ViewChild('askDateModal') askDateModal: ElementRef;
  @ViewChild('askSondageModal') askSondageModal: ElementRef;
  _newNotif = 0;

  NOTIF_LASTSYNC_KEY = 'NOTIF_LASTSYNC';
  authSubs: Subscription;
  compensationSubs: Subscription;
  holidaySubs: Subscription;
  holidayStatusSubs: Subscription;
  holidayLatestAnswerSub: Subscription;

  sondagesSubs: Subscription;
  sondageCallbackSubs: Subscription;
  adminImportSub: Subscription;
  loadNotificationSub: Subscription;
  isCollapsed = false;
  shortcode = '';
  currentModal: NgbModalRef;

  private questionTimer: any;
  private notifTimer: any;
  private sondageTimer: any;

  recapImport = '';
  linesToImport = [];
  importType = '';
  deleteImport = true;

  storeLatestAnswer: WarehouseStoreHolidayLatestAnswer[] = [];

  showHolidaySpinner = false;
  showSondageSpinner = false;

  showImportSpinner = false;

  holidayQuestion: WarehouseStoreHoliday;

  sondageItems: SondageItem[] = [];

  currentItem: SondageItem;

  goToRIBScreen = false;

  constructor(private authService: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private route: ActivatedRoute,
    private commons: Commons,
    private storeComService: StorecomService,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationsService,
    private toastr: ToastrService,
    private wsService: WarehouseHolidaysService,
    private sondageService: SondageService,
    private adminGuard: AuthGuard) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let doCollapse = (this.deviceService.isMobile() || this.deviceService.isTablet());
        if (!doCollapse) {
          doCollapse = window.innerWidth <= 768;
        }
        if (this.isCollapsed && doCollapse) {
          this.btnCollapseToggle.nativeElement.click();
        }
        this.isCollapsed = true;
      } else if (event instanceof ChildActivationStart) {
        // retrive shortcode from url
        this.shortcode = event.snapshot.firstChild.params['shortCode'] || '';
      }
    });
  }

  element;
  isFullScreen = false;

  get isExplore(): boolean {
    return this.router.isActive('/explore', true)
      || this.router.isActive('/category', false)
      || this.router.isActive('/presse', false);
  }
  get isPro(): boolean {
    return this.authService.isPro();
  }
  get isMenuRestricted(): boolean {

    if (!this.authService.menuRestricted) {
      return false;
    }
    const enableMenuRestriction = environment.enableMenuRestriction;
    if (!enableMenuRestriction) return false;
    const startDate = new Date(enableMenuRestriction.startDate);
    return (new Date() >= startDate);
  }
  get isProOnly(): boolean {
    return this.authService.isPro(true);
  }
  get isPublicPro(): boolean {
    return this.authService.isPublicPro();
  }
  get isCustomer(): boolean {
    return this.authService.isCustomer();
  }

  get displayAssortBulk(): boolean {
    if (!this.isWarehouse) {
      return false;
    }
    const assortBulkConfig = environment.enableAssortBulk;
    if (!assortBulkConfig) return false;
    const startDate = new Date(assortBulkConfig.startDate);
    return (new Date() >= startDate);
  }
  get isUserAuthenticated(): boolean {
    if (this.router.isActive('/cgu', true)) {
      return false;
    }
    return this.authService.isAuthenticated && !this.router.isActive('/login', true);
  }
  get displayAnonymousHeader(): boolean {
    if (this.router.isActive('/login', false)) {
      return false;
    }
    if (this.router.isActive('/register', false)
      && !this.shortcode.length) {
      return false;
    }
    return true;
  }
  get allowWarehouseDocs(): boolean {
    return this.authService.isWarehouseAccounting;
  }
  get isPdif(): boolean {
    return environment.isPdif;
  }
  get isStore(): boolean {
    if (this.isCommercial && this.authService.isStore) {
      return true;
    }
    if (this.isSecurityAdmin && this.authService.isStore) {
      return true;
    }
    return this.authService.isStore && this.authService.isActualOwner;
  }
  get isReadOnly(): boolean {
    return this.authService.isReadOnly();
  }
  get isStoreOldOwner(): boolean {
    if (this.isSecurityAdmin && this.authService.isStore) {
      return false;
    }
    return this.authService.isStore && !this.authService.isActualOwner;
  }
  get isStorePro(): boolean {
    return this.authService.isStoreForPro;
  }
  get isAdsManager(): boolean {
    return this.authService.isAdsManager;
  }
  get isSecurityAdmin(): boolean {
    return this.authService.isSecurityAdmin;
  }
  get isHelpdesk(): boolean {
    return this.authService.isHelpdesk || this.isCommercial;
  }
  get isWarehouse(): boolean {
    return this.authService.isWarehouse;
  }
  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }
  get isCommercial(): boolean {
    return this.authService.isCommercial;
  }
  get isGmsAdmin(): boolean {
    return this.authService.isGmsAdmin;
  }
  get additionalNim(): string {
    if (!this.authService.getPdifAccount) {
      return '';
    }
    return this.authService.getPdifAccount.additionalNim || '';
  }
  get isEditorAdressing(): boolean {
    return this.authService.isEditorAdressing;
  }
  get nims(): any[] {
    if (this.isAdmin) {
      return [];
    }
    return this.authService.accessibleNims;
  }
  get isRelay(): boolean {
    return this.authService.isRelay;
  }
  get plafondDisabled(): boolean {
    return environment.disablePlafonnement;
  }
  get currentNim(): string {
    return this.authService.currentNim;
  }
  get displayName(): string {
    return this.authService.userFirstName || '';
  }
  get basket(): Basket[] {
    const items = this.commons.getUserBasket();
    if (!items || !items.length) {
      return [];
    }
    return items;
  }
  get basketCount(): number {
    let qty = 0;
    this.basket.forEach(item => {
      qty += item.quantity;
    });
    return qty;
  }
  get faqUrl(): string {
    let url = 'https://zeensuploads.blob.core.windows.net/static/faq/store.v2.22.pdf';
    if (this.isWarehouse) {
      url = 'https://zeensuploads.blob.core.windows.net/static/faq/warehouse.v2.21.pdf';
    }
    return url;
  }
  hasCompensations = false;

  storeHolidayAnswer(item: WarehouseStoreHolidayLatestAnswer): string {
    if (!item) {
      return '';
    }

    const dateStr = moment(item.date).locale('fr').format('DD MMM YYYY');
    if (item.status) {
      return `Ouvert le ${dateStr}`;
    }

    return `Fermé le ${dateStr}`;
  }

  ngOnInit() {

    this.compensationSubs = this.authService.getCompensationUrlListner().subscribe(res => {
      this.hasCompensations = (res || '').length > 0;
    });

    this.authService.getCompensationUrl();
    this.loadNotifications();
    this.autoReloadNotification();

    this.holidayQuestion = null;

    this.loadNotificationSub = this.notificationService.getNotificationsCountListner()
      .subscribe(result => {
        this._newNotif = result || 0;
        localStorage.setItem(this.NOTIF_LASTSYNC_KEY, moment().locale('fr').format('YYYY-MM-DD'));
      });

    this.adminImportSub = this.storeComService.getSaveStoresUpdateListener()
      .subscribe(result => {
        this.showImportSpinner = false;
        if (result) {
          this.recapImport = 'Votre demande a été prise en compte';
        } else {
          this.recapImport = 'Une erreur est survenue lors de l‘import !';
        }
      });
    this.holidaySubs = this.wsService.getStoreHolidayUpdateListener()
      .subscribe(result => {
        this.holidayQuestion = result;
        if (this.holidayQuestion) {
          this.currentModal = this.ngbModalService.open(this.askDateModal);
        }
      });
    this.holidayStatusSubs = this.wsService.getStatusUpdateListener()
      .subscribe(result => {
        if (result) {
          this.wsService.removeFromStorage(this.currentNim);
          this.toastr.success('Votre demande a bien été prise en compte.', 'Confirmation');
          this.closeModal(false);
        } else {
          // message
          this.toastr.warning('Votre demande n’a pas été prise en compte, elle vous sera posé ultérieurement.', 'Information');
          this.closeModal(true);
        }
      });
    this.sondagesSubs = this.sondageService.getStoreSondageListUpdateListener()
      .subscribe(result => {
        this.sondageItems = result || [];
        if (this.sondageItems.length) {
          this.currentItem = this.sondageItems.splice(0, 1)[0];
          this.currentModal = this.ngbModalService.open(this.askSondageModal);
        }
      });
    this.sondageCallbackSubs = this.sondageService.getSondageCallbackUpdateListner()
      .subscribe(result => {
        this.showSondageSpinner = false;
        if (result) {
          this.sondageService.removeFromStorage(this.currentNim);
          this.closeModalSondage(false);
          if (this.goToRIBScreen) {
            this.goToRIBScreen = false;
            this.router.navigate(['/pdv'], { queryParams: { 'screen': 'tabRIB' } });
          }
        } else {
          this.closeModalSondage(true);
        }
      });
    this.holidayLatestAnswerSub = this.wsService.getLatestAnswerUpdateListener().subscribe(result => {
      this.storeLatestAnswer = result;
    });

    this.authSubs = this.authService.getStoreChangeListener().subscribe(user => {
      if (!this.authService.isStore) {
        return;
      }

      this.storeSondageTimeout(10000);
      if (this.authService.isAuthenticated) {
        this.wsService.getStoreLatestAnswer(this.authService.currentNim);
      }
    });

    if (this.authService.isAuthenticated
      && this.authService.isStore
    ) {

      this.storeSondageTimeout(10000);
      this.wsService.getStoreLatestAnswer(this.authService.currentNim);
    }
  }

  private storeSondageTimeout(milliseconds: number = 1000) {
    if (!this.authService.isAuthenticated
      || !this.authService.isStore
    ) {
      return;
    }
    if (this.sondageTimer) {
      clearTimeout(this.sondageTimer);
    }
    const me = this;
    this.sondageTimer = setTimeout(function () {
      if (me.authService.isAuthenticated) {
        me.sondageService.getStoreSondageQuestions(me.currentNim);
      }
    }, milliseconds);
  }

  // auto reload notification
  autoReloadNotification() {
    if (!this.authService.isStore) return;
    const me = this;
    this.notifTimer = setInterval(function () {
      me.loadNotifications();
    }, 300000);
  }

  loadNotifications(component: HeaderAppComponent = this) {
    if (!component.authService.isAuthenticated) {
      return;
    }

    const lastSync = localStorage.getItem(component.NOTIF_LASTSYNC_KEY) || '';
    component.notificationService.getNotificationsCount(this.authService.userId, this.authService.currentNim, lastSync);
  }

  getAdminInfo() {

    if (!this.isAdmin && !this.isCommercial && this.nims.length <= 1) {
      return;
    }
    if (this.isStore) {
      return `MODE : Diffuseur (${this.authService.currentNim})`;
    } else if (this.isWarehouse) {
      return `MODE : Depôt (${this.authService.currentNim})`;
    }
  }

  changeNim(nim: string, route = false) {
    this.authService.changeNim(nim);
    this.storeLatestAnswer = [];
    this.authService.getCompensationUrl(nim);
  }

  onLogout() {
    this.authService.onLogout();
  }

  toggleFullScreen() {
    this.element = document.documentElement;

    if (!this.isFullScreen) {
      const methodToBeInvoked = this.element.requestFullscreen ||
        this.element.webkitRequestFullScreen || this.element['mozRequestFullscreen'] ||
        this.element['msRequestFullscreen'];
      if (methodToBeInvoked) {
        methodToBeInvoked.call(this.element);
        this.isFullScreen = true;
      }
    } else {
      document.exitFullscreen();
      this.isFullScreen = false;

    }

  }

  ngOnDestroy() {
    this.storeLatestAnswer = [];
    if (this.compensationSubs) {
      this.compensationSubs.unsubscribe();
    }
    if (this.authSubs) {
      this.authSubs.unsubscribe();
    }
    if (this.holidaySubs) {
      this.holidaySubs.unsubscribe();
    }
    if (this.loadNotificationSub) {
      this.loadNotificationSub.unsubscribe();
    }
    if (this.adminImportSub) {
      this.adminImportSub.unsubscribe();
    }
    if (this.holidayStatusSubs) {
      this.holidayStatusSubs.unsubscribe();
    }
    if (this.sondagesSubs) {
      this.sondagesSubs.unsubscribe();
    }
    if (this.holidayLatestAnswerSub) {
      this.holidayLatestAnswerSub.unsubscribe();
    }
    if (this.notifTimer) {
      clearTimeout(this.notifTimer);
    }

    this.closeModal(false);
  }

  closeModal(setNextRun = true) {
    clearTimeout(this.questionTimer);

    this.holidayQuestion = null;
    if (this.currentModal) {
      this.currentModal.close();
      this.currentModal = null;
    } else {
      this.ngbModalService.dismissAll();
    }
  }

  closeModalSondage(setNextRun = true) {

    this.showSondageSpinner = false;

    if (setNextRun || this.currentItem.type === 2) {
      const nextRunMS = moment.duration(moment().add(1, 'day').startOf('day').diff(moment())).asMilliseconds();
      this.storeSondageTimeout(nextRunMS);
    }

    // if any sondages
    if (this.sondageItems.length) {
      // next question
      this.currentItem = this.sondageItems.splice(0, 1)[0];
      return;
    }

    this.sondageItems = [];
    if (this.currentModal) {
      this.currentModal.close();
      this.currentModal = null;
    } else {
      this.ngbModalService.dismissAll();
    }

    clearTimeout(this.sondageTimer);
  }

  setHoliday(open: boolean) {
    if (!this.holidayQuestion) {
      return;
    }
    this.showHolidaySpinner = true;
    if (open) {
      this.wsService.setOpen(this.currentNim, this.holidayQuestion.id);
    } else {
      this.wsService.setClosed(this.currentNim, this.holidayQuestion.id);
    }
  }

  sondageCallback(status: boolean) {
    if (!this.currentItem) {
      return;
    }
    this.showSondageSpinner = true;
    this.goToRIBScreen = this.currentItem.type === 3 && status;
    this.sondageService.sondageCallBack(this.currentNim, this.currentItem.id, status);
  }

  onFileSelect(input: HTMLInputElement) {
    const self = this;
    const columnsCount = this.importType === 'PRO' ? 3 : 4;
    const files = input.files;
    this.recapImport = '';
    this.linesToImport = [];
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const lines = textFromFileLoaded.split(/\r?\n/).filter(l => (l || '').length > 0);
        lines.forEach(line => {
          const splitted = line.replace('"', '').split(/,|;/ig);
          if (splitted.length < columnsCount) {
            return;
          }

          const nim = splitted[0];
          const codif = splitted[1];
          let issueNumber = '';
          let quantity = 0;
          let argument = '';
          if (columnsCount === 3) {
            quantity = 2;
            argument = splitted[2].trim();
          } else {
            issueNumber = splitted[2].trim();
            quantity = parseInt(splitted[3], 0);
          }

          if (!regexp.test(nim)) {
            return;
          }
          if (codif.length !== 5) {
            return;
          }
          if (isNaN(quantity)) {
            return;
          }
          this.linesToImport.push({
            nim: nim,
            code: this.importType,
            codif: codif,
            issueNumber: issueNumber,
            quantity: quantity,
            argument: argument
          });
        });

        if (!this.linesToImport.length) {
          const fileFormat = columnsCount === 3 ? '(NIM;CODIF sur 5;Argument)' : '(NIM;CODIF sur 5;Numéro Parution;Quantité)';
          this.recapImport = `Le fichier ne respecte pas la structure suivante ${fileFormat}`;
          input.value = '';
        } else {
          this.recapImport = `Nous avons detecté ${lines.length} lignes dans le fichier`;
          if (lines.length !== this.linesToImport.length) {
            const diff = lines.length - this.linesToImport.length;
            this.recapImport += `, dont ${this.linesToImport.length} valides`;
            this.recapImport += ` et ${diff} lignes ont été ignoré`;
          } else {

          }
        }
      };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
    return false;
  }

  openModalImport(modal) {
    this.importType = '';
    this.recapImport = '';
    this.linesToImport = [];
    this.currentModal = this.ngbModalService.open(modal);
  }

  saveBulkImport() {
    if (!this.linesToImport.length) {
      return;
    }

    var type = this.importType;
    this.showImportSpinner = true;
    if (!this.deleteImport && type === 'PRO') {
      type = "PRO_NOCLEAN";
    }
    this.storeComService.importStoresRequests(type, this.linesToImport);
  }
}
