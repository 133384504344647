import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-img-zoom',
  templateUrl: './img-zoom.component.html',
  styleUrls: ['./img-zoom.component.css']
})
export class ImgZoomComponent implements OnInit {

  @Input() imgSrc = '';
  @Input() modalTitle = '';
  constructor(private ngbModalService: NgbModal) { }

  ngOnInit() {
  }
  openModal(content) {
    this.ngbModalService.open(content);
  }
  closeModal() {
    this.ngbModalService.dismissAll();
  }
}
