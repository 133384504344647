import { Component, OnInit, ViewChild } from '@angular/core';
import {NgForm} from '@angular/forms';
import { WizardComponent } from 'ng2-archwizard';
import { CreateaccountComponent } from './createaccount/createaccount.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  // At the top of my component class that implements the <wizard>
  @ViewChild(WizardComponent)
  private wizard: WizardComponent;
  @ViewChild(CreateaccountComponent)
  private step2Component: CreateaccountComponent;

  title = 'Signup';
  constructor(private router: Router) { }

  ngOnInit() {
  }

  // signup function
  onSignup(form: NgForm) {
     if (form.invalid) {
      return;
     }

     // this.authService.createUser(form.value.email, form.value.password);
  }

  goHome() {
    const keys = Object.keys(localStorage);
    keys.forEach(key => localStorage.removeItem(key));
    this.router.navigate(['/login']);
  }

  goSupport() {
    const keys = Object.keys(localStorage);
    keys.forEach(key => localStorage.removeItem(key));
    this.router.navigate(['/support']);
  }

  onStoreLoaded($event) {
    if (!$event) {
      return;
    }

    this.step2Component.store = $event;
    this.wizard.model.navigationMode.goToStep(1);
  }

  onStoreCreated($event) {
    if (!$event) {
      return;
    }

    this.wizard.model.navigationMode.goToStep(2);
  }
}
