import { Component, OnInit } from '@angular/core';
import { Address } from 'src/app/Models/address.model';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import { Store } from 'src/app/Models/store.model';
import { Warehouse } from 'src/app/Models/warehouse.model';
import { Location } from 'src/app/Models/location.model';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  private leafmap;
  smallIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-icon-2x.png',
    iconSize:    [25, 41],
    iconAnchor:  [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize:  [41, 41],
    draggableWaypoints: true,
    addWaypoints: true,
  });
  constructor(private http: HttpClient) {
  }

  mapLoading = true;
  mapError = false;

  location: Location;
  address: Address;

  ngOnInit() {

  }

  private initMap(): void {

    if (this.leafmap) {
      this.leafmap.remove();
    }

    this.leafmap = L.map('leafmap', {
      center: [ 39.8282, -98.5795 ],
      zoom: 7
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.leafmap);
  }

  private loadMap() {
    this.initMap();
    if (!this.location && !this.address) {
      return;
    }
    this.mapLoading = true;
    if (!this.setLocationLocation(this.location)) {
      this.setLocationFromAddress(this.address);
    } else {
      this.mapLoading = false;
    }
  }

  setLocationStore(store: Store) {

    if (!store) {
      return;
    }

    this.location = store.location;
    this.address = store.address;
    this.loadMap();
  }

  setLocationWarehouse(warehouse: Warehouse) {

    if (!warehouse) {
      return;
    }

    this.location = warehouse.location;
    this.address = warehouse.address;
    this.loadMap();
  }

  private setLocationLocation(location: Location): boolean {

    if (!location || (location.latitude === 0 && location.longitude === 0)) {
      return false;
    }

    const coords = [location.latitude, location.longitude];
    this.leafmap.setView(coords, 17);
    return true;
  }

  private setLocationFromAddress(address: Address) {

    if (!address) {
      return;
    }

    let query = this.sanitizeText(address.firstAddress);
    if (query && query.length) {
      const s2 = this.sanitizeText(address.secondAddress);
      if (s2 && s2.length) {
        query += ` ${s2}`;
      }
    }

    if (address.postalCode && address.postalCode.length) {
      query += ` ${address.postalCode}`;
    }

    if (address.locality && address.locality.length) {
      query += ` ${address.locality}`;
    }

    query += ` ${address.country || 'FRANCE'}`;

    const osmAPI = `https://nominatim.openstreetmap.org/search?format=geojson&limit=1&q=${query}`;

    // https://nominatim.openstreetmap.org/search?street=place+de+l+eglise&format=geojson&limit=1
    this.http.get<any>(osmAPI)
      .subscribe(data => {
        this.mapLoading = false;
        if (!data || !data.features || !data.features.length) {
          this.mapError = true;
          return;
        }

        this.mapError = false;
        const geojson = L.geoJSON(data, {
          pointToLayer: function(feature, latlng) {
              return L.marker(latlng, {
                icon: this.smallIcon
              });
            },
            onEachFeature: function (feature, layer) {
                layer.bindPopup(feature.properties.display_name);
            }
        });
        geojson.addTo(this.leafmap);
        const coord = data.features[0].geometry.coordinates;
        this.leafmap.setView(L.GeoJSON.coordsToLatLng(coord), 17);
      }, err => {
        this.mapLoading = false;
        this.mapError = true;
      });
  }

  private sanitizeText(text: String) {
    if (!text || !text.length) {
      return null;
    }

    return text.replace(' l ', ' l’')
              .replace(' L ', ' L’');
  }
}
