import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, PdifAccounts, UserRestrictions, PFPBearerInfo, PdifAuthResponse } from '../Models/user.model';
import { Subject, forkJoin, Observable } from 'rxjs';
import { Router, ActivatedRoute, UrlHandlingStrategy } from '@angular/router';
import { environment } from '../../environments/environment';
import { Commons } from 'src/app/shared/commons';
import { AccessService } from './access.service';
import { Base64 } from 'js-base64';
import { GA } from '../shared/gAnalytics';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient,
        private ga: GA,
        private router: Router,
        private commons: Commons,
        private accessService: AccessService,
        private activatedRoute: ActivatedRoute) {
        this.parseConnectedUser();
        this.parsePdifAccount();
        this.autoAuthUser(false);
    }
    private static ADMINROLES = ['pdifadmin', 'admin'];
    private static AllowedROLES = ['pdifdiffuser', 'pdifwarehouse', 'pdifeditoradressing', 'pdifhelpdesk'];
    private static KEY_NIM = 'CURRENT_NIM';
    static KEY_NIM_FROM_URL = 'URL_NIM';
    private static KEY_USER = 'USERINFO';
    static KEY_AUTH = 'USERAUTH';
    private static KEY_AUTH_TEMP = 'USERAUTHTEMP';
    private static KEY_PFP_EXPIRE = 'USERAUTHPFPEXPIRE';
    private static KEY_AUTH_EXPIRE = 'USERAUTHBASICEXPIRE';
    private static KEY_AUTH_EXPIRE_IN_SECONDS = 12 * 60 * 60;

    private user: User;
    get getUser(): User {
        return this.user;
    }
    private loginLoading = false;
    private statusCode;
    
    private userUpdateListener = new Subject<User>();
    private storeChangeListner = new Subject<PdifAccounts>();
    private assortimentListener = new Subject<boolean>();
    private compensationUrlChangeListner = new Subject<string>();
    private pdifAccount: PdifAccounts;

    static base64Encode(username: string, password: string): string {
        const str = username + ':' + password;
        return Base64.encode(str);
    }

    static getAuthInfo() {
        let authInfo = localStorage.getItem(AuthService.KEY_AUTH)
            || localStorage.getItem(AuthService.KEY_AUTH_TEMP)
            || '';

        authInfo = AuthService.validAuthenticatedScheme(authInfo);
        return authInfo;
    }

    static validAuthenticatedScheme(authinfo: string): string {
        if (authinfo && !authinfo.startsWith('PFP')) {
            if (AuthService.sessionExpired(AuthService.KEY_AUTH_EXPIRE, true)) {
                return '';
            }
            return authinfo;
        }

        if (AuthService.sessionExpired(AuthService.KEY_PFP_EXPIRE)) {
            return '';
        }

        return authinfo;
    }

    static sessionExpired(key: string, ignoreZero = false) {
        // check time
        const date = new Date();
        const expirationValue = parseInt(localStorage.getItem(key), 0) || 0;

        if (ignoreZero && !expirationValue) {
            AuthService.setBasicExpiration();
            return false;
        }

        return new Date(expirationValue * 1000).getTime() <= date.getTime();
    }

    static setBasicExpiration() {
        const dt = new Date();
        dt.setSeconds(dt.getSeconds() + AuthService.KEY_AUTH_EXPIRE_IN_SECONDS);
        localStorage.setItem(AuthService.KEY_AUTH_EXPIRE, (dt.getTime() / 1000).toString());
    }

    setRestrictionsToUser(restriction: UserRestrictions) {
        if (!restriction || !this.user) {
            return;
        }

        this.user.restrictions = restriction;
    }

    private parseConnectedUser(): User {
        if (this.user != null) {
            return this.user;
        }
        const userJson = (localStorage.getItem(AuthService.KEY_USER) || '');
        if (!userJson.length) {
            return;
        }
        try {
            this.user = JSON.parse(userJson);
        } catch (error) {

        }
    }
    private parsePdifAccount(nim: string = null) {

        if (!this.user) {
            return;
        }

        this.pdifAccount = this.user.pdifAccount;
        if (!this.pdifAccount) {
            return;
        }

        nim = nim || this.currentNim || this.pdifAccount.nim;
        localStorage.setItem(AuthService.KEY_NIM, nim);
    }

    checkUserRole(roles: string[]): boolean {

        if (!roles || !roles.length) {
            return false;
        }

        if (!this.user
            || !this.user.roles
            || !this.user.roles.length) {
            return false;
        }

        let canuse = this.user.roles.filter(i => (AuthService.ADMINROLES.indexOf(i.toLowerCase()) >= 0)).length > 0;
        if (canuse) {
            return true;
        }

        canuse = this.user.roles.filter(i => roles.indexOf(i.toLowerCase()) >= 0).length > 0;
        return canuse;
    }

    hasAccessToPath(path: string): boolean {

        if (!this.user || !this.user.restrictions || !path) {
            return false;
        }

        if (this.isWarehouse) {
            return true;
        }

        if (!this.user.roles.length) {
            return false;
        }

        const restrictions = this.user.restrictions;
        const ownerCheck = (!restrictions.isOwner && restrictions.owner.length && restrictions.owner.indexOf(path) >= 0);
        if (ownerCheck) {
            return false;
        }
        const warehouseCheck = (restrictions.warehouse.length && restrictions.warehouse.indexOf(path) >= 0);
        if (warehouseCheck) {
            return false;
        }
        const gmsCheck = (restrictions.gms.length && restrictions.gms.indexOf(path) >= 0);
        return !gmsCheck;
    }

    getCompensationUrlListner() {
        return this.compensationUrlChangeListner.asObservable();
    }

    getCompensationUrl(nim: string = null, date: number = null) {
        if (!this.isAuthenticated
            || !this.isStore
            || this.isRelay) {
            this.compensationUrlChangeListner.next(null);
            return;
        }
        nim = nim || this.currentNim;
        let url = `${environment.APIURL}/api/v1/stores/compensations?nim=${nim}`;
        if (date) {
            url = `${url}&dt=${date}`;
        }
        this.http.get<{ result: string }>(url)
            .subscribe(res => {
                if (res && res.result && res.result.length) {
                    this.compensationUrlChangeListner.next(res.result[0]);
                } else {
                    this.compensationUrlChangeListner.next(null);
                }
            }, err => {
                this.compensationUrlChangeListner.next(null);
            });
    }
    resetPassword(email: string = null) {
        if (!email && this.user) {
            email = this.user.email;
        }
        return this.http.post(environment.APIURL + '/api/v2/users/me/lostpassword', { email: email });
    }
    resetPassword2(nim: string, name: string): Observable<{ result: string }> {
        const url = `${environment.APIURL}/api/v2/users/me/lostpassword2?name=${name}&nim=${nim}`;
        return this.http.post<{ result: string }>(url, {});
    }
    changePassword(password: string) {
        return this.http.put(environment.APIURL + '/api/v2/users/me', { id: this.user.id, password: password, info: {} });
    }
    changeEmail(email: string) {
        const logoutUrl = `${environment.WEBURL}login?logout=1`;
        const url = `${environment.APIURL}/api/v2/users/me/email?nim=${this.currentNim}&email=${email}&destinationUrl=${logoutUrl}`;
        return this.http.put(url, {});
    }
    accepteCGU() {

        return this.http.put(environment.APIURL + '/api/v2/users/me', { id: this.user.id, email: this.user.email, pdifCGU: true });
    }
    get currentNim(): string {
        return localStorage.getItem(AuthService.KEY_NIM) || '';
    }
    setCurrentNim(nim: string) {
        if (!nim || !nim.length) {
            return;
        }
        localStorage.setItem(AuthService.KEY_NIM, nim);
    }
    setUrlNim(nim: string) {
        if (!nim || !nim.length) {
            return;
        }
        localStorage.setItem(AuthService.KEY_NIM_FROM_URL, nim);
    }
    get userId(): string {
        if (this.user == null
            || this.user.info == null) {
            return '';
        }
        return this.user.info.id;
    }
    get menuRestricted(): boolean {
        if (this.user == null
            || this.user.pdifAccount == null) {
            return false;
        }
        return this.user.restrictedMenu;
    }
    get accessibleNims(): any[] {

        const nims = [];
        if (this.user == null) {
            return nims;
        }

        if (this.user.warehouseNims) {
            Object.keys(this.user.warehouseNims).forEach(key => {
                nims.push({nim: key, name: this.user.warehouseNims[key]});
            });
        }

        if (this.user.storeNims) {
            Object.keys(this.user.storeNims).forEach(key => {
                nims.push({nim: key, name: this.user.storeNims[key]});
            });
        }

        return nims;
    }

    get accessibleStores(): any[] {

        const nims = [];
        if (this.user == null) {
            return nims;
        }

        if (this.user.storeNims) {
            Object.keys(this.user.storeNims).forEach(key => {
                nims.push({nim: key, name: this.user.storeNims[key]});
            });
        }

        return nims;
    }

    get getPdifAccount(): PdifAccounts {

        if (!this.user) {
            return null;
        }

        return this.user.pdifAccount;
    }

    get p2000Nims(): any[] {

        const nims = [];
        if (this.user == null
            || this.isAdmin) {
            return nims;
        }

        if (this.user.warehouseNims) {
            Object.keys(this.user.warehouseNims).forEach(key => {
                nims.push({nim: key, name: this.user.warehouseNims[key]});
            });
        }

        return nims;
    }
    get isAdmin(): boolean {
        return this.user && this.user.roles
            && (this.user.roles.indexOf('ADMIN') >= 0 || this.user.roles.indexOf('PDIFADMIN') >= 0);
    }
    get isCommercial(): boolean {
        return this.user && this.user.roles
            && this.user.roles.indexOf('PDIFCOMMERCIAL') >= 0;
    }
    get isGmsAdmin(): boolean {
        return this.user && this.user.roles
            && this.user.roles.indexOf('PDIFGMS') >= 0;
    }
    get isTitreExpressAdmin(): boolean {
        return this.user && this.user.roles
            && this.user.roles.indexOf('PDIFTITREEXPRESS') >= 0;
    }
    get isStore(): boolean {
        if (this.pdifAccount == null) {
            return false;
        }
        return this.pdifAccount.type === 'STORE';
    }
    get isActualOwner(): boolean {
        if (this.isAdmin
            || this.isCommercial
            || !this.isStore) {
            return true;
        }
        return this.pdifAccount.actualOwner;
    }
    get isStoreForPro(): boolean {
        if (this.pdifAccount == null) {
            return false;
        }
        return this.pdifAccount.isPro;
    }
    get isAdsManager(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.user && this.user.roles
            && (this.user.roles.indexOf('PDIFADS') >= 0);
    }
    isPro(only: boolean = false): boolean {
        if (only) {
            return !this.isAdmin && this.user && this.user.roles
                && (this.user.roles.indexOf('PDIFPRO') >= 0);
        }
        if (this.isAdmin) {
            return true;
        }
        return this.user && this.user.roles
            && (this.user.roles.indexOf('PDIFPRO') >= 0);
    }
    isPublicPro(): boolean {
        return localStorage.getItem('PROPUBLIC') === '1';
    }
    isReadOnly(): boolean {
        return this.user && this.user.roles
            && (this.user.roles.indexOf('PDIFREADONLY') >= 0);
    }
    isCustomer(): boolean {
        return this.user && this.user.roles
            && this.isPro(true)
            && this.user.roles.indexOf('PDIFPROCUSTOMER') >= 0;
    }

    get isSecurityAdmin(): boolean {
        if (this.isAdmin
            || this.isWarehouse) {
            return true;
        }

        if (!this.user || !this.user.roles) {
            return false;
        }

        if (this.user.restrictions
            && this.user.restrictions.isOwner) {
            return true;
        }

        const addNim = this.pdifAccount ? (this.pdifAccount.additionalNim || '') : '';
        return this.user.roles.indexOf('PDIFGMS') >= 0
            && (addNim.length === 0 || this.user.roles.indexOf(addNim) < 0);
    }
    get userFirstName(): string {
        let name = '';
        if (this.user == null) {
            return name;
        }
        if (this.user.info != null) {
            name = this.user.info.firstName || '';
        }
        return name;
    }
    get userFullName(): string {
        let name = '';
        if (this.user == null) {
            return name;
        }
        if (this.user.pdifAccount != null) {
            name = this.user.pdifAccount.contactName || this.user.pdifAccount.name || '';
        } else if (this.user.info != null) {
            name = this.user.info.firstName || '';
        }
        return name;
    }
    get userEmail(): string {
        if (this.user == null
            || this.user.email == null) {
            return '';
        }
        return this.user.email;
    }
    get isWarehouse(): boolean {
        if (this.pdifAccount == null) {
            return false;
        }
        return this.pdifAccount.type === 'WAREHOUSE' || this.isWarehouseAccounting;
    }
    get isWarehouseAccounting(): boolean {
        if (this.pdifAccount == null) {
            return false;
        }
        return this.pdifAccount.type === 'WAREHOUSEACCOUNTING';
    }
    get isEditorAdressing(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.user && this.user.roles
            && (this.user.roles.indexOf('PDIFEDITORADRESSING') >= 0);
    }
    get isHelpdesk(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.user && this.user.roles
            && (this.user.roles.indexOf('PDIFHELPDESK') >= 0);
    }
    get isRelay(): boolean {
        if (this.pdifAccount == null) {
            return false;
        }
        return this.pdifAccount.isRelay;
    }

    get isAuthenticated(): boolean {
        const authInfo = AuthService.getAuthInfo();
        const isAuthenticated = authInfo.length > 0 && this.user != null;

        if (!isAuthenticated) {
            this.clearAuthData();
        }
        return isAuthenticated;
    }
    get isPdifCGU(): boolean {

        if (this.user == null
            || this.user.pdifCGU == null) {
            return false;
        }
        return this.user.pdifCGU;
    }
    getStoreChangeListener() {
        return this.storeChangeListner.asObservable();
    }

    getCompensationChangeListener() {
        return this.compensationUrlChangeListner.asObservable();
    }

    // log user automatically if has a valide token and expiration date
    autoAuthUser(route: boolean = false) {
        // if actualOwner is not set refresh connction
        const authInfo = AuthService.getAuthInfo();

        if (!authInfo.length) {
            this.clearAuthData();
            return;
        }

        // get ellapsed time
        if (this.user != null
            && this.user.restrictions != null
            && this.pdifAccount != null
            && this.isActualOwner !== undefined) {
            this.userUpdateListener.next(this.user);
            this.storeChangeListner.next(this.pdifAccount);
            return;
        }

        this.onLoginWithAuth(authInfo, null, route, false);
    }

    loadCurrent() {
        if (!this.currentNim) {
            return;
        }

        this.storeChangeListner.next(this.pdifAccount);
    }

    changeNim(nim: string) {
        if (!nim || nim === this.currentNim) {
            return;
        }

        this.onLoginWithAuth(AuthService.getAuthInfo(), nim);
    }

    // Login ZEENS user's
    onLogin(email: string, password: string, token: string, nim: string) {
        this.onLoginWithAuthPost(email, password, token, nim, false, false);
    }

    onLoadUser() {
        this.onLoginWithAuth(AuthService.getAuthInfo(), null);
    }

    refreshUser() {

        this.getUserInfoHttp(this.currentNim, data => {
            if (data && data.result) {
                this.userUpdateListener.next(data.result);
            }
        }, error => { });
    }

    getAssortimentStatus(nim: string = null) {
        const ts = new Date().getTime();
        nim = nim || this.currentNim;
        const url = environment.APIURL + `/api/v1/stores/statutassortiment?nim=${nim}`;

        this.http.get<{ result: boolean }>(url)
            .subscribe(res => this.assortimentListener.next(res.result));
    }

    getAssortimentListener() {
        return this.assortimentListener.asObservable();
    }

    private authUser(login, pwd, token, nim, successCompletion, errorCompletion) {
        let urlUser = `${environment.APIURL}/api/v2/users/me`;

        this.http.post<{ result: PdifAuthResponse }>(urlUser, {userName: login, password: pwd, pfpToken: token, nim: nim}).subscribe(successCompletion, errorCompletion);
    }

    private getUserInfoHttp(nim, successCompletion, errorCompletion) {
        const ts = new Date().getTime();
        let urlUser = `${environment.APIURL}/api/v2/users/me?v=${ts}`;
        if (nim) {
            urlUser += '&nim=' + nim;
        }

        this.http.get<{ result: User }>(urlUser).subscribe(successCompletion, errorCompletion);
    }

    private onLoginWithAuth(authinfo: string, nim: string = null, route: boolean = true, routeOnFailer = true) {

        if (this.loginLoading) {
            return;
        }
        this.loginLoading = true;
        nim = nim || this.currentNim;

        this.getUserInfoHttp(nim, response => {

            this.loginLoading = false;
            if (response) {
                localStorage.removeItem(AuthService.KEY_NIM_FROM_URL);
                this.user = response.result;
                this.saveUserToLocalStorage(this.user, authinfo, nim);
                if (route) {
                    this.redirectUser();
                }
            } else {
                this.clearAuthData();
                this.userUpdateListener.next(null);
            }
        },
            error => {
                if (error.status === 409) {
                    // message
                    this.goToPFPLogin();
                    return;
                }
                this.loginLoading = false;
                this.clearAuthData();
                if (routeOnFailer) {
                    this.onLogout();
                } else {
                    this.userUpdateListener.next(null);
                }
            });
    }
    private onLoginWithAuthPost(login: string, pwd: string, token: string, nim: string = null, route: boolean = true, routeOnFailer = true) {

        if (this.loginLoading) {
            return;
        }
        this.loginLoading = true;
        nim = nim || this.currentNim;

        this.authUser(login, pwd, token, nim, response => {

            this.loginLoading = false;
            if (response) {
                localStorage.removeItem(AuthService.KEY_NIM_FROM_URL);
                this.user = response.result.user;
                const authinfo = 'Bearer ' + response.result.bearer;
                if (authinfo) {
                    localStorage.setItem(AuthService.KEY_AUTH_TEMP, authinfo);
                }
                this.saveUserToLocalStorage(this.user, authinfo, nim);
                if (route) {
                    this.redirectUser();
                }
            } else {
                this.clearAuthData();
                this.userUpdateListener.next(null);
            }
        },
            error => {
                if (error.status === 409) {
                    // message
                    this.goToPFPLogin();
                    return;
                }
                this.loginLoading = false;
                this.clearAuthData();
                if (routeOnFailer) {
                    this.onLogout();
                } else {
                    this.userUpdateListener.next(null);
                }
            });
    }

    redirectUser() {
        let rootUrl = '/dashboard';
        if (!this.isAdmin) {
            if (this.user.roles && this.user.roles.length) {
                rootUrl = this.commons.getDefaultRouteByRole(this.user.roles[0]);
            }

            const userId = this.isAdmin ? this.user.email : this.currentNim;
            this.ga.setUserId(userId);
        }

        this.router.navigate([rootUrl]);
    }

    getUserUpdateListener() {
        return this.userUpdateListener.asObservable();
    }

    private saveUserToLocalStorage(user: User, authinfo: string, nim: string = null) {

        localStorage.setItem(AuthService.KEY_USER, JSON.stringify(user));
        if (authinfo) {
            localStorage.setItem(AuthService.KEY_AUTH, authinfo);
            localStorage.removeItem(AuthService.KEY_AUTH_TEMP);
        }

        this.parseConnectedUser();
        this.parsePdifAccount(nim);

        AuthService.setBasicExpiration();

        this.userUpdateListener.next(this.user);
        this.storeChangeListner.next(this.pdifAccount);
    }

    public renewPassword(data: any) {
        return this.http.post(environment.APIURL + '/api/v2/users/resetpassword', data);
    }

    public updateUserObj() {
        const authInfo = AuthService.getAuthInfo();

        if (!authInfo.length) {
            this.clearAuthData();
            return;
        }
        this.onLoginWithAuth(authInfo, this.currentNim, false, false);
    }

    goToPFPLogin() {
        this.clearAuthData();
        this.router.navigate(['/login'], { queryParams: { 'pfp': new Date().getTime() } });
    }

    // logout user
    onLogout(goToLogin = true) {
        this.userUpdateListener.next(null);
        this.clearAuthData();
        if (goToLogin) {
            this.router.navigate(['/login']);
        }
    }

    // clear localstorage
    private clearAuthData() {
        if (this.loginLoading) {
            return;
        }
        const keys = Object.keys(localStorage).filter(r => r !== environment.lastRequestTimestamp && r !== 'P2000AUTH');
        keys.forEach(key => localStorage.removeItem(key));
        this.user = null;
        this.pdifAccount = null;
    }
}
