import { Component, OnInit, Input } from '@angular/core';
import { PressStatementProvider } from 'src/app/Models/statements/press.model';

@Component({
  selector: 'app-press-statment',
  templateUrl: './press-statment.component.html',
  styleUrls: ['./press-statment.component.css']
})
export class PressStatmentComponent implements OnInit {

  constructor() { }
  @Input() pressStatementProvider: PressStatementProvider;
  @Input() statement;

  get warehouse() {
    if (!this.statement) {
      return null;
    }

    return this.statement.warehouse;
  }

  get pdv() {
    if (!this.statement) {
      return null;
    }

    return this.statement.pdv;
  }

  ngOnInit() {
  }

}
