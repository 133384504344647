import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service';
import { Store } from 'src/app/Models/store.model';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { ActivityService } from 'src/app/services/activity.service';
import { GA } from 'src/app/shared/gAnalytics';
import { NewStatment, LineStatement } from 'src/app/Models/newStatment.model';
import { DatePipe } from 'src/app/shared/pipes/date-formatter.pipe';
import * as moment from 'moment';
import { StoreService } from 'src/app/services/store.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';

@Component({
  template: ''
})
export class PdvbaseComponent implements OnInit {

  private datePipe = new DatePipe();
  constructor(protected authService: AuthService,
    public dtlanguages: DtLanguages,
    protected ngbModalService: NgbModal,
    protected storeService: StoreService,
    protected activityService: ActivityService,
    protected sweet: Sweetalert,
    public fb: FormBuilder,
    public ga: GA) { }

  @Input() store: Store;
  @Input() isLoading = true;

  @Output() processSave = new EventEmitter<LineStatement[]>();

  @Input() changes: NewStatment[] = [];
  localStatements: LineStatement[] = [];
  ngOnInit() {

  }

  isReadOnly() {
    if (!this.store) {
      return true;
    }
    return this.store.isReadOnly;
  }

  generatePendingHtml(idStatement: string, index: number = null): string {
    idStatement = this.bindId(idStatement, index);
    const ctrl = this.findStatementById(idStatement);
    if (!ctrl) {
      return '';
    }

    let str = `au ${this.datePipe.transform(ctrl.changedOn, 'DD MMM YYYY')} : ${ctrl.newValue}`;

    if (ctrl.warehouseAction != null) {
      if (ctrl.warehouseAction.status) {
        str = `demande acceptée par le dépôt le ${this.datePipe.transform(ctrl.warehouseAction.actionOn, 'DD MMM YYYY')}`;
      } else {
        str = `demande déclinée par le dépôt le ${this.datePipe.transform(ctrl.warehouseAction.actionOn, 'DD MMM YYYY')}`;
      }
    }

    return str;
  }

  generatePendingHtmlDate(idStatement: string, index: number): string {
    idStatement = this.bindId(idStatement, index);
    const ctrl = this.findStatementById(idStatement);
    if (!ctrl) {
      return '';
    }

    let str = `au ${this.datePipe.transform(ctrl.changedOn, 'DD MMM YYYY')}`;

    if (ctrl.warehouseAction != null) {
      if (ctrl.warehouseAction.status) {
        str = `demande acceptée par le dépôt le ${this.datePipe.transform(ctrl.warehouseAction.actionOn, 'DD MMM YYYY')}`;
      } else {
        str = `demande déclinée par le dépôt le ${this.datePipe.transform(ctrl.warehouseAction.actionOn, 'DD MMM YYYY')}`;
      }
    }

    return str;
  }

  generatePendingHtmlValue(idStatement: string, index: number = null): string {
    idStatement = this.bindId(idStatement, index);
    const ctrl = this.findStatementById(idStatement);
    if (!ctrl) {
      return '';
    }

    return ctrl.newValue;
  }

  isDisabled(idStatement: string, index: number = null): boolean {
    if (this.isLoading
      || this.isReadOnly()) {
      return true;
    }
    idStatement = this.bindId(idStatement, index);
    const ctrl = this.findStatementById(idStatement);
    if (!ctrl) {
      return false;
    }
    const ctrlMoment = moment(ctrl.changedOn);
    const diffMoreThan30Days = ctrlMoment.isValid() ? moment().diff(moment(ctrl.changedOn), 'day') <= 30 : false;
    return ctrl.warehouseAction == null && diffMoreThan30Days;
  }

  private bindId(id: string, index: number = null): string {
    if (index != null) {
      id = `${id}${index}`;
    }

    return id;
  }

  isSectionPending(idStatement: string): boolean {
    if (this.isLoading) {
      return true;
    }
    return this.findStatementById(idStatement, true) != null;
  }

  findStatementById(idStatement: string, startWith: boolean = false): LineStatement {

     if (this.changes != null && this.changes.length && this.changes[0].changes) {
       if (!startWith) {
        return this.changes[0].changes.find(i => i.id === idStatement);
       }
       return this.changes[0].changes.find(i => (i.id || '').indexOf(idStatement) === 0);
    } else {
      return null;
    }
  }

  getStatementOrDefault(id: string, group: string, action: string = 'update') {
    const ctrl = this.findStatementById(id) || {
      'id': id,
      'isDayOff': false,
      'newValue': '',
      'action': action,
      'group': group,
      'oldValue': '',
      'label': '',
      'changedOn': this.getTodayDateFormat(),
      'warehouseAction': null
    };

    ctrl.changedOn = this.getTodayDateFormat();

    return ctrl;
  }

  addToChanges(change: LineStatement) {

    if (change != null) {
      this.localStatements = this.localStatements.filter(i => i.id !== change.id);
      this.localStatements.push(change);
    }
  }

  cancel() {
    this.processSave.emit(null);
  }
  save(statements: LineStatement[]) {
    this.processSave.emit(statements);
   }
  /*saveLocalStatments(statements: LineStatement[]) {
   localStorage.setItem('statements', JSON.stringify(statements));
  }
  getLocalStatments(): LineStatement[] {
    return JSON.parse(localStorage.getItem('statements'));
  }*/
  closeModal() {
    this.ngbModalService.dismissAll();
  }

  getTodayDateFormat(): string {
    return this.datePipe.transform(null, 'YYYY-MM-DDTHH:mm:ss', true);
  }
}
