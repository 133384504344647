import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateUnix'
})
export class DateUnixPipe implements PipeTransform {

  transform(value: string): number {
    if (!value) {
      return 0;
    }
    return moment(value).locale('fr').unix();
  }

}
