import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-app',
  templateUrl: './footer-app.component.html',
  styleUrls: ['./footer-app.component.css']
})
export class FooterAppComponent implements OnInit {
  appVersion = '';
  currentYear: number;
  isPDIF = environment.isPdif;
  constructor(private ngbModalService: NgbModal, private ngbModalConfig: NgbModalConfig) {
    this.appVersion = environment.appVersion;
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  ngOnInit() {
    this.currentYear = (new Date()).getFullYear();
  }


  closeModal() {
    this.ngbModalService.dismissAll();
  }

  openModal(qrCodeModal) {
    this.ngbModalService.open(qrCodeModal);
  }
}
