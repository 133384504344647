import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BIItem, BLHDocument, BLObject } from 'src/app/Models/business.documents/bl.business.document';
import { AuthService } from 'src/app/services/auth-service';
import { DocumentformsService } from 'src/app/services/documentforms.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-weeklyforms',
  templateUrl: './weeklyforms.component.html',
  styleUrls: ['./weeklyforms.component.css']
})
export class WeeklyformsComponent implements OnInit, OnDestroy {

  WEEKDAYS = {
    '': '',
    '0': '',
    '1': 'LU',
    '2': 'MA',
    '3': 'ME',
    '4': 'JE',
    '5': 'VE',
    '6': 'SA',
    '7': 'DI',
  };

  fileName = `blh-`;
  print = false;
  blDate = moment();
  selectedNim: string = null;
  selectedCode = '';
  docName = 'BORDERAU D’INVENDUS HEBDOMADAIRE';

  blbi: BLHDocument;
  private documentSubscription: Subscription;

  @ViewChild('content') modalLoading: ElementRef;

  constructor(private documentService: DocumentformsService<BLHDocument>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ngbModalService: NgbModal) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('printOnly') != null) {
      this.print = this.route.snapshot.paramMap.get('printOnly') === 'true';
    }

    this.selectedCode = this.route.snapshot.queryParams['selectedCode'];
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    const nim = this.selectedNim || this.authService.currentNim;

    this.documentSubscription = this.documentService.getBCIDocumentUpdateListener()
      .subscribe(doc => {
        if (doc != null) {
          this.blbi = doc;
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF();
            }, 1000);
          }
        }
      }, err => {
        this.closeModal();
      });

    let date = '';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.blDate = moment(date);
      const docType = this.route.snapshot.queryParams['type'] || '';
      this.docName = docType === 'f' ? 'BORDERAU DE FERMETURE' : this.docName;
      this.documentService.getOtherBLDocumentDetail(date, docType, nim, this.selectedCode);
    }

    this.fileName = `${this.fileName}${date}.pdf`;
  }

  get storeName(): string {
    if (!this.blbi || !this.blbi.customer) {
      return '';
    }

    return `${this.blbi.customer.name} (${this.blbi.customer.nim})`;
  }

  get dailiesSum(): number {
    if (!this.blbi
      || !this.blbi.items
      || !this.blbi.items.length) {
      return 0 + this.dailiesForgottenSum;
    }

    const dailies = this.blbi.items.filter(i => i.obj && (i.obj.dayCode || '').length);
    if (!dailies.length) {
      return this.dailiesForgottenSum;
    }
    return dailies.map(i => {
      const val = parseInt(i.obj.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b) + this.dailiesForgottenSum;
  }

  get pubsSum(): number {
    if (!this.blbi
      || !this.blbi.items
      || !this.blbi.items.length) {
      return 0 + this.dailiesForgottenSum;
    }

    const dailies = this.blbi.items.filter(i => !i.obj || !(i.obj.dayCode || '').length);
    if (!dailies.length) {
      return this.pubsForgottenSum;
    }
    return dailies.map(i => {
      const val = parseInt(i.obj.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b) + this.pubsForgottenSum;
  }

  get dailiesForgottenSum(): number {
    if (!this.blbi
      || !this.blbi.forgotten
      || !this.blbi.forgotten.length) {
      return 0;
    }

    const dailies = this.blbi.forgotten.filter(i => (i.dayCode || '').length);
    if (!dailies.length) {
      return 0;
    }
    return dailies.map(i => {
      const val = parseInt(i.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b);
  }

  get pubsForgottenSum(): number {
    if (!this.blbi
      || !this.blbi.forgotten
      || !this.blbi.forgotten.length) {
      return 0;
    }

    const dailies = this.blbi.forgotten.filter(i => !(i.dayCode || '').length);
    if (!dailies.length) {
      return 0;
    }
    return dailies.map(i => {
      const val = parseInt(i.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b);
  }

  getHtmlForItem(line: BLObject<BIItem>) {
    let html = '<td colspan="10">&nbsp;</td>';
    if (line == null) {
      return html;
    }

    if (line) {
      if (line.message) {
        html = `<td class="text-center" colspan="11">${line.message || '&nbsp;'}</td>`;
      } else if (line.obj) {
        html = `<td class="text-center">${line.obj.codif}</td>
        <td class="text-left">${line.obj.title}</td>
        <td class="text-left">${this.WEEKDAYS[line.obj.dayCode || '']}</td>
        <td class="text-right">${line.obj.number}</td>
          <td class="text-left">${line.obj.issueType}</td>
          <td class="text-center">${line.obj.dayCode || ''}</td>
          <td class="text-center">${line.obj.cm || ''}</td>
          <td class="text-center">${line.obj.price}</td>
          <td class="text-center">${line.obj.provided}</td>
          <td class="text-center">${line.obj.unsolt || ''}</td>`;
      }
    }

    return html;
  }

  downloadToPDF() {
    this.print = true;
    this.documentService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  printBL() {
    window.print();
  }

  goBack() {
    const date = this.blDate.format('MM/YYYY');
    const queryParam = { };

    if (this.selectedNim && this.selectedNim.length) {
      queryParam['selectedNim'] = this.selectedNim || '';
    }

    if (this.selectedCode && this.selectedCode.length) {
      queryParam['selectedCode'] = this.selectedCode || '';
    }

    this.router.navigate([`/archives/${date}`], { queryParams: queryParam });
  }

  ngOnDestroy() {
    if (this.documentSubscription) {
      this.documentSubscription.unsubscribe();
    }
  }

}
