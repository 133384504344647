import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


import { Article } from '../Models/article.model';
import { ArticlesService } from '../services/articles.service';
import { AuthService } from '../services/auth-service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  @Input() count = 0;
  @Input() isDashbord = false;

  private itemCount = 10;

  articles: Article[] = [];
  totalCount: number;
  tags: string[] = [];
  articleToRead: Article;
  modelFrom: NgbDateStruct;
  modelTo: NgbDateStruct;
  get showNoDataPanel(): boolean {
    return (this.articles || []).length === 0;
  }
  selectedValue = '';
  isLoading = true;
  pageIndex = 0;

  private nim = '';
  get isStore(): boolean {
    return this.authService.isStore;
  }


  constructor(private articlesService: ArticlesService,
    private authService: AuthService,
    private ngbModalService: NgbModal,
    private ngbModalConfig: NgbModalConfig) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  ngOnInit() {
    this.nim = this.authService.currentNim;
  }

  getMore() {
    this.pageIndex += this.itemCount;
    this.getArticlesByOptions(false);
  }

  private setArticles(result: Article[]) {
    if (this.pageIndex === 0) {
      this.articles = result || [];
    } else {
      this.articles = this.articles.concat(result || []);
    }
  }

  // get articles by date range
  getArticlesByOptions(resetCounter = true) {

    const fromDate = this.modelFrom ? this.modelFrom.year + '-' + this.modelFrom.month + '-' + this.modelFrom.day : '';
    const date = this.modelTo ? this.modelTo.year + '-' + this.modelTo.month + '-' + this.modelTo.day : '';

    if (resetCounter) {
      this.pageIndex = 0;
    }
    // this.getArticles(fromDate, date, this.selectedValue.toLowerCase());
  }

  // open article modal
  openArticleModal(content, article) {
    this.ngbModalService.open(content, { size: 'lg' });
    this.articleToRead = article;
  }

}
