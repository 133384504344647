import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { Alert } from 'src/app/Models/alert.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.css']
})
export class AlertHistoryComponent implements OnInit, OnDestroy {

  private alertsSubscription: Subscription;
  
  constructor(private alertService: AlertService) { }
  showSpinner = false;
  alerts: Alert[];
  htmlWebLink(link) {
    if (!link) {
        return '';
    }
    return `<a href="${link}">${link}</a>`;
  }
  htmlStoreCount(count: number) {
    if (!count) {
        return '';
    }
    return `${count} diffuseur${count > 1 ? 's' : ''}`;
  }

  ngOnInit() {

    this.showSpinner = true;
    this.alertsSubscription = this.alertService.getAlertsUpdateListener().subscribe(result => {
      this.showSpinner = false;
      this.alerts = result;
    });
    this.alertService.getAlerts();
  }

  ngOnDestroy(): void {
    if (this.alertsSubscription) {
      this.alertsSubscription.unsubscribe();
    }
  }
}
