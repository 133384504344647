import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { ToastrService } from 'ngx-toastr';
import { Explore } from 'src/app/Models/explore.model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Issue } from 'src/app/Models/issue.model';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/services/auth-service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  shortcode = '';
  constructor(private categoryService: CategoryService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private toastrService: ToastrService) {
    // force route reload whenever params change;
     // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  explore: Explore;
  issues: Issue[];
  isLoading = true;
  isSearch = false;
  isSearching = false;
  searchText = '';
  basketIssues: Issue[] = [];

  catId = '';

  ngOnInit() {
    this.catId = this.route.snapshot.paramMap.get('id');
    // retrive shortcode from url
    this.shortcode = this.route.snapshot.paramMap.get('shortCode') || '';
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
     this.catId = this.route.snapshot.paramMap.get('id');

     this.getCategoryById(0);
    });
    this.getCategoryById(0);
  }

  reloadCategory() {
    this.getCategoryById(0);
  }

  resetResult() {

    this.isSearch = false;
    this.isSearching = false;
    this.getCategoryById(0);
  }

  searchInCategory($event) {

    if ($event.keyCode !== 13
      || !$event.srcElement) {
      return;
    }

    const query = $event.srcElement.value;
    this.isSearching = true;
    this.isSearch = true;
    this.getCategoryById(0, query);
  }

  getCategoryById(count: number, q: string = '') {
    this.isLoading = true;
    this.issues = [];
    this.categoryService.getCategoryById(this.catId, count, this.authService.currentNim, q, this.shortcode)
      .subscribe(res => {
        if (res.result) {
          this.issues = [];
          this.explore = res.result;
          const subCats = res.result;
          this.isSearching = false;
          this.isLoading = false;
          if (res.result.issues) {
            this.issues = res.result.issues;
          }
          if (subCats.subCategories) {
            subCats.subCategories.forEach(elem => {
              elem.issues.forEach(issue => {
                this.issues.push(issue);
              });
            });
          }
        }
      },
        err => {
          this.isLoading = false;
        });
  }
}
