import { Component, OnInit, Input, } from '@angular/core';

import { IssueSnap } from '../Models/issue.model';

@Component({
  selector: 'app-issues-collection',
  templateUrl: './issues-collection.component.html',
  styleUrls: ['./issues-collection.component.css']
})
export class IssuesCollectionComponent implements OnInit {

  constructor() { }
  @Input() sectionTitle = '';
  @Input() isSectionLoading: Boolean = true;
  @Input() issues: [IssueSnap];
  collapse: Boolean = false;

  ngOnInit() {
  }

  scrollRight(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }
    const newLeft = container.scrollLeft + 450;
    container.scrollTo({ left: newLeft, behavior: 'smooth' });
  }

  scrollLeft(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }
    const newLeft = container.scrollLeft - 450;
    container.scrollTo({ left: newLeft, behavior: 'smooth' });
  }

  getParentContainer(element) {
    if (!element
      || !element.currentTarget
      || !element.currentTarget.parentElement
      || !element.currentTarget.parentElement.parentElement
      || element.currentTarget.parentElement.parentElement.className !== 'category-items') {
        return null;
      }
    return element.currentTarget.parentElement.parentElement;
  }
}
