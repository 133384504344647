import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from './error/error.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private ngbModal: NgbModal) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
               /* if (error.status == 401) {
                    alert("Vous n'avez pas l'autorisation d'accéder au portail diffuseurs.");
                }
                if(error.status == 500){
                    alert("Une erreur serveur est servenu.");
                }  */
                return throwError(error);
            })
        );
    }
}
