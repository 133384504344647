import { Pipe, PipeTransform } from '@angular/core';
import { Store } from 'src/app/Models/store.model';

@Pipe({
  name: 'storeFilter'
})
export class StoreFilterPipe implements PipeTransform {

  transform(items: Store[], value: string): Store[] {
    if (!items) {
      return [];
  }

  if (!value || !value.length) {
    return items;
  }

  const valueLower = value.toLowerCase();
  return items.filter(store =>
      store.nil.startsWith(valueLower) || store.nim.startsWith(valueLower) || ((store.name || '').toLowerCase().indexOf(valueLower) >= 0)
  );
  }

}
