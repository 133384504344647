import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DocumentformsService } from '../../services/documentforms.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { MonthPeriode } from '../../Models/monthPeriode.model';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-history-archives',
  templateUrl: './history-archives.component.html',
  styleUrls: ['./history-archives.component.css']
})
export class HistoryArchivesComponent implements OnInit, OnDestroy {

  constructor(private deliveryformsRequest: DocumentformsService<string>,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  @Input() documentType = '';

  // constructor
  documentName: string;
  blOfToday: string;
  archiveDates: string[];
  listMonths: MonthPeriode[];
  private archiveDatesSubs: Subscription;
  dateArchive: MonthPeriode;
  isDetailDeliveryforms: Boolean = false;
  showTableSpinner: Boolean = true;
  documentDetailPath: string;

  ngOnInit() {

    switch (this.documentType) {
      case 'bci':
        this.documentName = 'BCI';
        this.documentDetailPath = 'bciforms';
        break;
      default:
        this.documentName = 'BL/BI';
        this.documentDetailPath = 'deliveryforms';
        break;
    }

    this.listMonths = this.getMonths();

    this.archiveDatesSubs = this.deliveryformsRequest.getArchiveDatesRequestsUpdateListner()
      .subscribe((datesResult: string[]) => {
        if (datesResult.length > 0 && (moment(new Date()).locale('fr').format('YYYY-MM-DDT00:00:00')) === datesResult[0]) {
          this.blOfToday = datesResult[0];
          datesResult = datesResult.slice(1);
        }

        this.showTableSpinner = false;
        this.archiveDates = datesResult;
      });

    this.dateArchive = this.listMonths[0];
    this.getArchiveDates(this.listMonths[0].from, this.listMonths[0].to);

    this.showTableSpinner = true;
  }

  private getArchiveDates(dateFrom: string, dateTo: string) {
    this.showTableSpinner = true;
    this.archiveDates = [];
    this.deliveryformsRequest.getArchiveDatesUpdatedRequests(dateFrom, dateTo, this.documentType === 'bl', this.authService.currentNim);
  }

  onDatesChange(e): void {
    this.getArchiveDates(this.dateArchive.from, this.dateArchive.to);
  }

  public getMonths(): MonthPeriode[] {
    const datesResults: MonthPeriode[] = [];

    for (let i = 0; i < 12; i++) {
      datesResults[i] = new MonthPeriode(
      moment(new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1)).locale('fr').format('MMMM'),
      '' + (new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1)).getFullYear(),
      moment(new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1)).locale('fr').format('YYYY-MM-DD'),
      moment(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1 - i, 0)).locale('fr').format('YYYY-MM-DD'));
    }

    return datesResults;
  }

  ngOnDestroy(): void {
    if (this.archiveDatesSubs) {
      this.archiveDatesSubs.unsubscribe();
    }
  }
}
