import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { StoreBulkAssortiment } from 'src/app/Models/storeBulkAssortiment.model';
import { AuthService } from 'src/app/services/auth-service';
import { StoreAssortimentService } from 'src/app/services/store-assortiment.service';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { CurrencyFormatter } from 'src/app/shared/pipes/currency-formatter.pipe';

@Component({
  selector: 'app-assortbulk',
  templateUrl: './assortbulk.component.html',
  styleUrls: ['./assortbulk.component.css']
})
export class AssortbulkComponent implements OnInit, OnDestroy {

  private currencyPipe = new CurrencyFormatter();
  private assotListSubs: Subscription;
  private assotValidationSubs: Subscription;
  private assotProposalSubs: Subscription;

  data: StoreBulkAssortiment[] = [];
  showSpinner = true;
  simulationLaunched = false;
  validationLaunched = false;
  simulationResultDisplay = false;

  proposalLaunched = false;

  shouldSimulate = false;

  get disableRightButtons(): boolean {
    return this.proposalLaunched || (this.selectedStore && this.selectedStore.proposition >= 0);
  }

  get disableLeftButtons(): boolean {
    return this.validationLaunched || this.simulationLaunched;
  }

  selectedStore: StoreBulkAssortiment = null;
  
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private assortService: StoreAssortimentService,
    private dtlanguages: DtLanguages,
    private ngbModalService: NgbModal,
    private authService: AuthService) { }

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      language: this.dtlanguages.frLanguage()
    };

    this.assotListSubs = this.assortService.getWarehouseAssortimentStoresListener()
    .subscribe(result => {
      this.showSpinner = false;
      const list = (result || []);
      if (this.simulationLaunched) {
        this.simulationLaunched = false;
        this.validationLaunched = false;
        this.simulationResultDisplay = true;
        if(list.length) {
          const stores = list.filter(s => s.nim == this.selectedStore.nim);
          if(stores.length)
            this.selectedStore = stores[0];
        }
      } else {
        if (this.validationLaunched) {
          this.simulationResultDisplay = false;
          this.validationLaunched = false;
          this.simulationLaunched = false;
          this.closeModal();
        }
        this.data = list;
        this.rerender();
      }
      
    });

    this.assotValidationSubs = this.assortService.getAssortimentWarehouseValidationListener()
    .subscribe(result => {
      this.showSpinner = false;
      this.simulationLaunched = false;
      if (result) {
        this.assortService.getWarehouseAssortimentStores(this.authService.currentNim);
      }
    });

    this.assotProposalSubs = this.assortService.getAssortimentWarehouseProposalListener()
    .subscribe(result => {
      this.proposalLaunched = false;
    });

    this.assortService.getWarehouseAssortimentStores(this.authService.currentNim);
  }

  isSimulationMandatory(item: StoreBulkAssortiment): boolean {
    return ((item.cppapThresholdCA >= 0 && item.cppapThresholdCA !== null)
        || (item.cppapThresholdEfficacity >= 0 && item.cppapThresholdEfficacity !== null)
        || (item.firstPresentationCA >= 0 && item.firstPresentationCA !== null))
      && item.simulation === null;
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  simulate() {
    this.simulationLaunched = false;
    this.simulationResultDisplay = false;

    if(!this.selectedStore
      || this.simulationLaunched) {
      return;
    }

    this.shouldSimulate = false;
    this.showSpinner = true;
    this.simulationLaunched = true;

    this.assortService.getWarehouseAssortimentSimulation(this.authService.currentNim, [this.selectedStore]);
  }

  removeProposal() {
    if(!this.selectedStore
      || this.proposalLaunched) {
      return;
    }

    this.proposalLaunched = true;
    this.selectedStore.proposition = null;

    this.assortService.warehouseProposalSet(this.authService.currentNim, this.selectedStore);
  }

  validateProposal() {
    if(!this.selectedStore
      || this.proposalLaunched) {
      return;
    }

    this.proposalLaunched = true;

    this.assortService.warehouseProposalSet(this.authService.currentNim, this.selectedStore);
  }

  validate() {
    if(!this.selectedStore
      || this.validationLaunched) {
      return;
    }

    this.validationLaunched = true;

    this.assortService.validateWarehouseAssortimentSimulation(this.authService.currentNim, [this.selectedStore]);
  }

  openModalForStore(item, content) {
    this.selectedStore = null;
    this.selectedStore = item;
    this.selectedStore.simulation = null;
    
    this.openModal(content);
  }

  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
  }

  closeModal() {
    this.annulerSimulation();
    this.ngbModalService.dismissAll();
  }
  
  allowOnlyZero(event) {

    if (!this.selectedStore) {
      return;
    }

    this.selectedStore.simulation = null;

    if (event.key !== '0') {
      event.preventDefault();
      event.target.value = '';
      var val = parseInt(event.target.value);
      if (val !== 0) {
        event.target.value = '';
      }
    }
  }

  annulerSimulation() {
    this.simulationResultDisplay = false;
  }

  changeValue(event) {
    if (!this.selectedStore) {
      return;
    }

    this.selectedStore.simulation = null;
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    if (this.assotListSubs) {
      this.assotListSubs.unsubscribe();
    }
    if (this.assotValidationSubs) {
      this.assotValidationSubs.unsubscribe();
    }
    if (this.assotProposalSubs) {
      this.assotProposalSubs.unsubscribe();
    }
  }
}
