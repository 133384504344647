export const environment = {
    production: true,
    WEBURL: 'https://www.liprego.fr/',
    APIURL: 'https://pdif-ws.francemessagerie.fr',
    APIPDF: 'https://pdif-pdf-ws.francemessagerie.fr/AngularHandler.ashx',
    TrackingId: 'UA-121779171-4',
    appVersion: '2.45b87',
    lastRequestTimestamp: 'lastRequestTimeStamp',
    disableStatements: true,
    disableSupport: true,
    disableStock: true,
    disablePlafond: true,
    disableIGP_CPPAP: {
        startDate: '2022-03-09T00:00:00.000'
    },
    isPdif: false,
    title: 'LIPREGO, livraison de presse',
    restrictionRefreshMS: 600000,
    lipregoMaxCommandHour: 18,
    disablePerformance: true,
    enablePdvStatus: false,
    historyMonthsAccess: 23,
    cacheRefresh: 900000,
    hideRuptureRecurrente: false,
    reorderMaxQuantity: { item: 30, lot: 5 },
    disabledPFPRequests: true,
    blMessagesDisabled: true,
    disabledAssortimentDashboard: true,
    disabledProposal: true,
    disabledLot2c: true,
    enablePFPForced: null,
    disablePlafonnement: true,
    reaLimitQuantity: 0,
    enableMenuRestriction: {
      startDate: '2024-04-02T00:00:00.000'
    },
    enableAssortBulk: null,
    pfpLoginPage: 'https://presseconnect-recette.sifipresse.fr/login?sso_url=',
    days: [
      { id: '1', name: 'Lundi' },
      { id: '2', name: 'Mardi' },
      { id: '3', name: 'Mercredi' },
      { id: '4', name: 'Jeudi' },
      { id: '5', name: 'Vendredi' },
      { id: '6', name: 'Samedi' },
      { id: '7', name: 'Dimanche' },
    ]
};
