import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { Title } from '../Models/title.model';
import { AuthService } from '../services/auth-service';
import { SaveReorderStatus } from '../Models/savereorder.model';


@Injectable({
    providedIn: 'root'
})
export class ReorderService {

    constructor(private http: HttpClient, private authService: AuthService) { }

    private titleUpdated = new Subject<Title[]>();

    private saveUpdated = new Subject<SaveReorderStatus>();

    getReorderRequests() {
        this.http.get<{ result: Title[] }>(environment.APIURL + '/api/v1/stores/bls/current/reorder?nim=' + this.authService.currentNim)
            .subscribe(response => {
                if (response) {
                    this.titleUpdated.next(response.result);
                } else {
                    this.titleUpdated.next([]);
                }
            }, err => {
                this.titleUpdated.next([]);
            });
    }

    getReorderRequestsUpdateListner() {
        return this.titleUpdated.asObservable();
    }

    saveReorderRequestsUpdateListner() {
        return this.saveUpdated.asObservable();
    }


    addNewReorder(data: any) {
        const url = environment.APIURL + '/api/v1/stores/bls/current/reorder?nim=' + this.authService.currentNim;
        this.http.put<{ result: SaveReorderStatus }>(url, data)
            .subscribe(response => {
                if (response.result) {
                    localStorage.setItem('LR_TS', (new Date()).getTime().toString());
                }
                this.saveUpdated.next(response.result);
            }, err => {
                this.saveUpdated.next(null);
            });
    }

}
