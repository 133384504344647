

export class TitreExpressToPush {
    id: string;
    issueNumber: string;
    titleCodif: string;
    type: number;
    recipient: number;
    newPrice: number;
    newReturnDate: string;
    message: string;
    supplement: Supplement;
    sendDate: string;
    nims: string[];
}
export class Supplement {
    grammes: number;
    samples: number;
    paquets: number;
}
