import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { BLBusinessDocument, BLGroupAmount, BLItem, BLBIObject, BLMouvement, BIItem, BLObject } from 'src/app/Models/business.documents/bl.business.document';
import { DocumentformsService } from 'src/app/services/documentforms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery.detail',
  templateUrl: './delivery.detail.component.html',
  styleUrls: ['./delivery.detail.component.css']
})
export class DeliveryDetailComponent implements OnInit, OnDestroy {

  constructor(private documentService: DocumentformsService<BLBusinessDocument>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private ngbModalConfig: NgbModalConfig) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  fileName = `bl-`;
  print = false;
  blDate = moment();
  selectedNim: string = null;
  selectedCode = '';

  @ViewChild('content') modalLoading: ElementRef;

  get firstGroup(): BLBIObject[] {
    if (!this.blbi || !this.blbi.groups
      || !this.blbi.groups.first
      || !this.blbi.groups.first.length) {
      return [];
    }
    return this.blbi.groups.first;
  }
  get secondGroup(): BLBIObject[] {
    if (!this.blbi || !this.blbi.groups
      || !this.blbi.groups.second
      || !this.blbi.groups.second.length) {
      return [];
    }
    return this.blbi.groups.second;
  }
  get messages(): string[] {
    if (environment.blMessagesDisabled) {
      return [];
    }
    if (!this.blbi
      || !this.blbi.groups
      || !this.blbi.groups.messages
      || !this.blbi.groups.messages.length) {
      return [];
    }
    return this.blbi.groups.messages;
  }
  get remind(): BLObject<BIItem>[] {
    if (!this.blbi || !this.blbi.remind || !this.blbi.remind.length) {
      return [];
    }
    return this.blbi.remind;
  }
  get userAdded(): BLItem[] {
    if (!this.blbi || !this.blbi.userAdded || !this.blbi.userAdded.length) {
      return [];
    }
    return this.blbi.userAdded;
  }
  get storeName(): string {
    if (!this.blbi || !this.blbi.customer) {
      return '';
    }

    return `${this.blbi.customer.name} (${this.blbi.customer.nim})`;
  }

  get forgotten(): BIItem[] {
    if (!this.blbi || !this.blbi.forgotten || !this.blbi.forgotten.length) {
      return [];
    }
    return this.blbi.forgotten;
  }
  get mouvements(): BLMouvement[] {
    if (!this.blbi || !this.blbi.mouvements || !this.blbi.mouvements.length) {
      return [];
    }
    return this.blbi.mouvements;
  }
  get dailyPickup(): BIItem[] {
    if (!this.blbi || !this.blbi.dailyPickup || !this.blbi.dailyPickup.items || !this.blbi.dailyPickup.items.length) {
      return [];
    }
    return this.blbi.dailyPickup.items;
  }
  get regulation(): BLGroupAmount[] {
    if (!this.blbi || !this.blbi.regulation || !this.blbi.regulation.length) {
      return [];
    }
    return this.blbi.regulation;
  }
  get commission(): BLGroupAmount[] {
    if (!this.blbi || !this.blbi.commission || !this.blbi.commission.length) {
      return [];
    }
    return this.blbi.commission;
  }
  get company(): BLGroupAmount[] {
    if (!this.blbi || !this.blbi.company || !this.blbi.company.length) {
      return [];
    }
    return this.blbi.company;
  }
  get total(): BLGroupAmount {
    if (!this.blbi || !this.blbi.total) {
      return null;
    }
    return this.blbi.total;
  }

  get dailiesSum(): number {
    if (!this.blbi || !this.blbi.groups || !this.blbi.groups.first || !this.blbi.groups.first.length) {
      return 0 + this.dailiesRemindSum + this.dailiesForgottenSum;
    }
    const bis = this.blbi.groups.first
                .filter(i => i.bi !== undefined && i.bi.obj !== undefined);

    if (!bis.length) {
      return 0 + this.dailiesRemindSum + this.dailiesForgottenSum;
    }
    return bis.map(i => {
      const val = parseInt(i.bi.obj.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b) + this.dailiesRemindSum + this.dailiesForgottenSum;
  }

  get pubsSum(): number {
    if (!this.blbi || !this.blbi.groups || !this.blbi.groups.second || !this.blbi.groups.second.length) {
      return 0 + this.pubsRemindSum + this.pubsForgottenSum;
    }
    const bis = this.blbi.groups.second
                .filter(i => i.bi !== undefined && i.bi.obj !== undefined);

    if (!bis.length) {
      return 0 + this.pubsRemindSum + this.pubsForgottenSum;
    }
    return bis.map(i => {
      const val = parseInt(i.bi.obj.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b) + this.pubsRemindSum + this.pubsForgottenSum;
  }

  get dailiesRemindSum(): number {
    if (!this.blbi || !this.blbi.remind || !this.blbi.remind.length) {
      return 0;
    }
    const bis = this.blbi.remind
                .filter(i => i.obj !== undefined && (i.obj.dayCode || '').length);

    if (!bis.length) {
      return 0;
    }
    return bis.map(i => {
      const val = parseInt(i.obj.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b);
  }

  get pubsRemindSum(): number {
    if (!this.blbi || !this.blbi.remind || !this.blbi.remind.length) {
      return 0;
    }
    const bis = this.blbi.remind
                .filter(i => i.obj !== undefined && !(i.obj.dayCode || '').length);

    if (!bis.length) {
      return 0;
    }
    return bis.map(i => {
      const val = parseInt(i.obj.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b);
  }

  get dailiesForgottenSum(): number {
    if (!this.blbi || !this.blbi.forgotten || !this.blbi.forgotten.length) {
      return 0;
    }
    const bis = this.blbi.forgotten
                .filter(i => (i.dayCode || '').length);

    if (!bis.length) {
      return 0;
    }
    return bis.map(i => {
      const val = parseInt(i.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b);
  }

  get pubsForgottenSum(): number {
    if (!this.blbi || !this.blbi.forgotten || !this.blbi.forgotten.length) {
      return 0;
    }
    const bis = this.blbi.forgotten
                .filter(i => !(i.dayCode || '').length);

    if (!bis.length) {
      return 0;
    }
    return bis.map(i => {
      const val = parseInt(i.unsolt, 0);
      return val || 0;
    }).reduce((a, b) => a + b);
  }

  blbi: BLBusinessDocument;
  private documentSubscription: Subscription;

  getHtmlForBLItem(line: BLBIObject) {
    let html = '<td colspan="12">&nbsp;</td>';
    if (line == null) {
      return html;
    }

    if (line.bl) {
      if (line.bl.message) {
        html = `<td class="text-center" colspan="12">${line.bl.message || '&nbsp;'}</td>`;
      } else if (line.bl.obj) {
        html = `<td class="text-center">${line.bl.obj.price}</td>
          <td class="text-center">${line.bl.obj.codif}</td>
          <td class="text-center">${line.bl.obj.family}</td>
          <td class="text-right">${line.bl.obj.number}</td>
          <td class="text-left">${line.bl.obj.issueType}</td>
          <td class="text-left">${line.bl.obj.title}</td>
          <td class="text-center">${line.bl.obj.quantity}</td>
          <td class="text-center">${line.bl.obj.received || ''}</td>
          <td class="text-center">${line.bl.obj.amount}</td>
          <td class="text-center">${line.bl.obj.gc}</td>
          <td class="text-center">${line.bl.obj.cr}</td>
          <td class="text-center">${line.bl.obj.cs}</td>`;
      }
    }

    return html;
  }

  getHtmlForBIItem(line: BLBIObject) {
    let html = '<td colspan="10">&nbsp;</td>';
    if (line == null) {
      return html;
    }

    if (line.bi) {
      if (line.bi.message) {
        html = `<td class="text-center" colspan="10">${line.bi.message || '&nbsp;'}</td>`;
      } else if (line.bi.obj) {
        html = `<td class="text-center">${line.bi.obj.sb}</td>
          <td class="text-center">${line.bi.obj.modi}</td>
          <td class="text-center">${line.bi.obj.price}</td>
          <td class="text-center">${line.bi.obj.codif}</td>
          <td class="text-center">${line.bi.obj.family}</td>
          <td class="text-right">${line.bi.obj.number}</td>
          <td class="text-left">${line.bi.obj.issueType}</td>
          <td class="text-left">${line.bi.obj.title}</td>
          <td class="text-center">${this.getTDHtmlForPriority(line.bi.obj.priority, line.bi.obj.provided)}</td>
          <td class="text-center">${line.bi.obj.unsolt === undefined ? '' : line.bi.obj.unsolt}</td>`;
      }
    }

    return html;
  }

  getTDHtmlForPriority(priority: boolean, quantity: string): string {
    if (priority) {
      return `<span class="bg-dark text-white p-lr-priority">${quantity}</span>`;
    }

    return quantity;
  }

  getHtmlForRemindItem(line: BLObject<BIItem>) {
    let html = '<td colspan="10">&nbsp;</td>';
    if (line == null) {
      return html;
    }

    if (line.message) {
      html = `<td class="text-center" colspan="10">${line.message || '&nbsp;'}</td>`;
    } else if (line.obj) {
      html = `<td class="text-center">${line.obj.sb}</td>
        <td class="text-center">${line.obj.modi}</td>
        <td class="text-center">${line.obj.price}</td>
        <td class="text-center">${line.obj.codif}</td>
        <td class="text-center">${line.obj.family}</td>
        <td class="text-right">${line.obj.number}</td>
        <td class="text-left">${line.obj.issueType}</td>
        <td class="text-left">${line.obj.title}</td>
        <td class="text-center">${this.getTDHtmlForPriority(line.obj.priority, line.obj.provided)}</td>
        <td class="text-center">${line.obj.unsolt === undefined ? '' : line.obj.unsolt}</td>`;
    }

    return html;
  }

  ngOnInit() {

    if (this.route.snapshot.paramMap.get('printOnly') != null) {
      this.print = this.route.snapshot.paramMap.get('printOnly') === 'true';
    }

    this.selectedCode = this.route.snapshot.queryParams['selectedCode'];
    this.selectedNim = this.route.snapshot.queryParams['selectedNim'];
    const nim = this.selectedNim || this.authService.currentNim;

    this.documentSubscription = this.documentService.getBCIDocumentUpdateListener()
      .subscribe(doc => {
        if (doc != null) {
          this.blbi = doc;
          if (this.print) {
            setTimeout(() => {
              this.downloadToPDF();
            }, 1000);
          }
        }
      }, err => {
        this.closeModal();
      });

    let date = '';
    if (this.route.snapshot.paramMap.get('date') != null) {
      date = this.route.snapshot.paramMap.get('date');
      this.blDate = moment(date);
      this.documentService.getBLDocumentDetail(date, nim, this.selectedCode);
    }

    this.fileName = `${this.fileName}${date}.pdf`;
  }

  downloadToPDF() {
    this.print = true;
    this.documentService.downloadToPDF()
      .subscribe(result => {
        saveAs(result, this.fileName);
        this.print = false;
        this.closeModal();
      }, err => {
        this.closeModal();
      });
    this.openModal();
  }

  openModal() {
    this.ngbModalService.open(this.modalLoading, { size: 'lg' });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  printBL() {
    window.print();
  }

  goBack() {
    const date = this.blDate.format('MM/YYYY');
    const queryParam = { };

    if (this.selectedNim && this.selectedNim.length) {
      queryParam['selectedNim'] = this.selectedNim || '';
    }

    if (this.selectedCode && this.selectedCode.length) {
      queryParam['selectedCode'] = this.selectedCode || '';
    }

    this.router.navigate([`/archives/${date}`], { queryParams: queryParam });
  }

  ngOnDestroy() {
    if (this.documentSubscription) {
      this.documentSubscription.unsubscribe();
    }
  }
}

