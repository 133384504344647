import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { AuthService } from '../services/auth-service';
import { Title } from '../Models/title.model';


@Injectable({
  providedIn: 'root'
})
export class ForgottenService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  private titles: Title[] = [];
  private titlesUpdated = new Subject<Title[]>();
  private saveUpdated = new Subject<boolean>();
  private currentNIM = this.authService.currentNim;

  getForgotten() {
    this.http.get<{ result: Title[] }>(environment.APIURL + '/api/v1/stores/bls/current/forgotten?nim=' + this.currentNIM)
      .subscribe(response => {
        if (response) {
          this.titles = response.result;
          this.titlesUpdated.next([...this.titles]);
        } else {
          this.titlesUpdated.next([]);
        }
      }, err => {
        this.titlesUpdated.next([]);
      });
  }

  getForgotterUpdateListner() {
    return this.titlesUpdated.asObservable();
  }

  getSaveUpdateListner() {
    return this.saveUpdated.asObservable();
  }

  addNewForgotten(nim: string, data: any) {
    // tslint:disable-next-line:max-line-length
    this.http.put<{ result: boolean }>(environment.APIURL + '/api/v1/stores/bls/current/forgotten?nim=' + nim, data)
    .subscribe(response => {
      this.saveUpdated.next(response.result);
    },
      error => {
        this.saveUpdated.next(false);
      });
  }
}
