import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { NgbModalConfig, NgbDate, NgbDateStruct, NgbCalendar, NgbDatepickerConfig, NgbDateParserFormatter, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BL, BLS } from '../Models/bl.model';
import { ClaimsService } from '../services/claims.service';
import { DataTableDirective } from 'angular-datatables';
import { DtLanguages } from '../shared/dtLanguages';
import { ItemToPush } from '../Models/itemToPush.model';
import { Sweetalert } from '../shared/sweetAlert';
import { Commons } from '../shared/commons';
import { AuthService } from '../services/auth-service';
import { GA } from '../shared/gAnalytics';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit, OnDestroy, AfterViewInit {

  private blsSubs: Subscription;
  private datesCount = 2;
  private performing = false;
  showSpinner: Boolean = true;
  @ViewChildren('myInput') inputs;

  isReadOnly: Boolean = true;
  localData: Boolean = false;
  public searchString: string;

  private dates: string[] = [];
  private datesSubs: Subscription;
  private unlockSubs: Subscription;
  private sendSubs: Subscription;
  // datatable initialisations
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  model: NgbDateStruct;
  disabled = true;
  isDisabled = true;
  bldate: string;
  bls: BL[] = [];
  selectedValue = '';
  invalidData = [];

  savingSpinner = false;
  typeAction = 'reorder';
  titleCodif = '';

  constructor(private claimsService: ClaimsService, private authService: AuthService,
    private ngbModalConfig: NgbModalConfig, private dtlanguages: DtLanguages, calendar: NgbCalendar,
    private config: NgbDatepickerConfig, private ngbDateParserFormatter: NgbDateParserFormatter,
    private sweet: Sweetalert,
    private element: ElementRef,
    private commons: Commons,
    private ga: GA,
    private ngbModalService: NgbModal
  ) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
    this.model = calendar.getToday();
    // config.markDisabled
    // this.isDisabled = (date: NgbDate, current: { month: number }) => calendar.date === 13;

  }

  ngOnInit() {
    this.dtOptions = {
      processing: true,
      searching: false,
      responsive: true,
      lengthChange: true,
      paging: false,
      columnDefs: [{
        'targets': [0, 6],
        'orderable': false
      }
      ],
      autoWidth: false,
      language: this.dtlanguages.frLanguage()
    };

    this.datesSubs = this.claimsService.getAvailableDatesUpdateListener()
      .subscribe((dates: string[]) => {

        if (!dates || !dates.length) {
          return;
        }
        this.rerender();
        this.dates = dates;
        const mnt = moment(dates[0]);
        this.model = { year: mnt.year(), month: mnt.month() + 1, day: mnt.date() };

        this.disableDates(dates);
        this.onBindBLs(dates[0].split('T')[0]);
      });

    const localBls = this.claimsService.getClaims(this.bldate);
    this.blsSubs = this.claimsService.getBlsUpdateListener()
      .subscribe((bls: BLS) => {
        if (localBls == null || localBls === undefined || bls.isReadOnly === true) {
          if (bls.bls != null) {
            this.isReadOnly = bls.isReadOnly || this.authService.isReadOnly();
            if (this.isReadOnly) {

              this.localData = false;
              this.claimsService.removeClaims(this.bldate);
            }

            this.bls = bls.bls;
            this.showSpinner = false;
          } else {

            this.bls = bls.bls;
            this.showSpinner = false;
            this.sweet.warning('Le BL/BI n’est pas disponible', '');
          }
        } else {

          this.localData = true;
          this.bls = localBls;
          this.isReadOnly = false;
          this.showSpinner = false;
        }
        this.performing = false;
      });

      this.sendSubs = this.claimsService.getSendReclamationListener()
      .subscribe(response => {
        if (response) {
          this.onBindBLs(this.bldate);
          this.sweet.success('Succès !', 'Vos réclamations ont bien été transmises à votre dépositaire.');
          this.ga.createEvent('click', 'BL/BI', 'Valider les réclamations sur BL du jour');

        } else {
          this.sweet.warning('Attention !', 'Impossible de valider vos réclamations.');
        }
      });

      this.unlockSubs = this.claimsService.getUnlockUpdateListener()
      .subscribe(response => {
        this.savingSpinner = false;
        if (response) {
          this.sweet.success('Succès !', 'La réclamation a bien été déverouillé.');
          this.onBindBLs(this.bldate);
        } else {
          this.sweet.error('Attention !', 'Votre réclamation n’a pas pu être déverouillé, veuillez réessayer plus tard.');
        }
      });

    // this.onBindBLs();
    this.refreshData();
  }

  gotoLine(id) {
    document.getElementById(id).scrollIntoView();
  }

  getIssueInfoFromId(id) {
    const item = this.bls.find(b => b.id === id.substring(1));
    if (!item) {
      return '';
    }

    return `${item.title} : ${item.codif}, n° ${item.number}`;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  refreshData(nim: string = null) {
    nim = nim || this.authService.currentNim;
    this.getAvailableDates(nim);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  // bind bls
  onBindBLs(bldate: string = null) {

    if (this.performing) {
      return;
    }
    this.performing = true;
    const nim = this.authService.currentNim;
    this.bldate = bldate || this.bldate;

    if (this.bldate == null) {
      if ((this.dates || []).length === 0) {
        return;
      }
      this.bldate = this.dates[0].split('T')[0];
    }

    this.showSpinner = true;

    this.bls = [];
    this.localData = false;

    this.claimsService.getBls(nim, this.bldate);
  }

  getAvailableDates(nim: string) {

    this.claimsService.getAvailableDates(nim, this.datesCount);
  }
  disableDates(dates) {
    this.config.markDisabled = (dateStruct: NgbDateStruct) => {
      return dates.filter(date => {
        const dateArr: string[] = date.split('-');
        // tslint:disable-next-line:radix
        const y: number = parseInt(dateArr[0]);
        // tslint:disable-next-line:radix
        const m: number = parseInt(dateArr[1]);
        // tslint:disable-next-line:radix
        const d: number = parseInt(dateArr[2]);
        return dateStruct.year === y && dateStruct.month === m && dateStruct.day === d;
      }).length === 0;
    };
  }

  onDateSelect(event: NgbDate) {
    this.onBindBLs(this.ngbDateParserFormatter.format(event));
  }

  checkValueAndFormat(element, baseQty) {

    if (element == null) { return; }

    const rowId = element.parentElement.parentElement.id;
    const elementIndex = this.invalidData.indexOf(rowId);

    // tslint:disable-next-line:radix
    const quantity = parseInt(element.value);
    const input = $(element);
    if (quantity == null || Number.isNaN(quantity)) {
      if (elementIndex >= 0) {
        this.invalidData.splice(elementIndex, 1);
      }
      this.removeCssFormat(input);
      return;
    }

    baseQty = baseQty || quantity;
    const baseQquantity = parseInt(baseQty, 0) || 0;

    const receivedVal = parseInt(element.value, 0);
    this.bls[element.id].received = receivedVal;

    const allowValue = quantity >= 0 && quantity !== baseQquantity;

    if (!allowValue) {
      if (elementIndex < 0) {
        this.invalidData.push(rowId);
      }
      this.warningValueOrFormat(input);
      return;
    }

    if (elementIndex >= 0) {
      this.invalidData.splice(elementIndex, 1);
    }

    this.localData = true;
    this.claimsService.saveClaims(this.bldate, this.bls);
    this.okValueOrFormat(input);

    // if (btnId) checkPersistedValueInLocalStorage(objName, btnId, btnValidatId);
  }

  // Go to next input on keyup Enter
  goNext(e) {
    this.commons.goNext(e, this.inputs);
  }

  warningValueOrFormat(input) {

    const errorClassCss = ' is-invalid face';
    const successClassCss = 'is-valid';

    const TD = $(input.parent().parent()).children().last()[0].firstElementChild;

    input.addClass('is-invalid face').removeClass('is-valid');
    $(TD).next().addClass(errorClassCss).removeClass(successClassCss);
  }

  okValueOrFormat(input) {

    const errorClassCss = ' is-invalid face';
    const successClassCss = 'is-valid';
    input.addClass(successClassCss).removeClass(errorClassCss);
  }

  removeCssFormat(input) {

    const errorClassCss = ' is-invalid face';
    const successClassCss = 'is-valid';
    input.removeClass(successClassCss);
    input.removeClass(errorClassCss);
  }

  cannotValidate() {
    return (this.invalidData && this.invalidData.length > 0);
  }

  get isAdmin(): boolean {
    if (!this.authService) {
      return false;
    }

    return this.authService.isAdmin;
  }

  unlockBL() {
    if (!this.isAdmin) {
      return;
    }
    this.savingSpinner = true;
    this.sweet.confirmDialog('Attention !',
      '<b>IMPORTANT :</b> Vous êtes sur le point de déverouiller la déclaration, êtes-vous sûr de vouloir continuer ?',
      (error: Error, result: any): void => {

      if (!result) {
        this.savingSpinner = false;
        return;
      }
      this.claimsService.unlockBL(this.authService.currentNim, this.bldate);
    });
  }

  validateChanges() {

    if (this.cannotValidate()) {
      return;
    }

    const listItemToPush: ItemToPush[] = [];
    const localBls = this.claimsService.getClaims(this.bldate);

    const nim = this.authService.currentNim;

    for (let i = 0; i < localBls.length; i++) {

      const itemToPush = new ItemToPush();
      itemToPush.code = 'RCL';
      itemToPush.codif = localBls[i].codif;
      itemToPush.numeroParution = localBls[i].number;
      itemToPush.nim = nim;
      itemToPush.date = this.bldate;
      itemToPush.supplied = localBls[i].quantity;
      if (localBls[i].received !== null) {
        itemToPush.quantity = localBls[i].received;
      }
      itemToPush.isNew = localBls[i].isNew || false;
      itemToPush.dayCode = localBls[i].dayCode;
      listItemToPush.push(itemToPush);

    }

    this.sweet.confirmDialog('Attention !', 'Vos réclamations vont être envoyées à votre dépositaire,' +
      ' vous ne pourrez plus les modifier depuis cet outil. \nÊtes-vous sûr de vouloir valider ?', (error: Error, result: any): void => {

        if (!result) {
          return;
        }

        this.claimsService.sendReclamation(listItemToPush, this.bldate, nim);
      });

  }
  onBLSeleceted($event) {
    if (!$event) {
      return;
    }
    $event.forEach(e => {
      this.bls.push(e);
    });

    this.claimsService.saveClaims(this.bldate, this.bls);
    this.closeModal();
  }
  removeItem(bl) {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir supprimer l’item ?',
      (err: Error, result: any): void => {
        if (result) {
          const index: number = this.bls.indexOf(bl);
          if (index !== -1) {
            this.bls.splice(index, 1);
            this.claimsService.saveClaims(this.bldate, this.bls);
          }
        }
      });
  }
  // do filter
  onFilter() {

  }
  openModal(content) {
    this.ngbModalService.open(content);

  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    if (this.blsSubs) {
      this.blsSubs.unsubscribe();
    }
    if (this.datesSubs) {
      this.datesSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.unlockSubs) {
      this.unlockSubs.unsubscribe();
    }
    if (this.sendSubs) {
      this.sendSubs.unsubscribe();
    }
  }

}
