import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class GA {

    userId = '';

    // create event UA
    // eventAction ==> click,play,pause,....
    // eventCategory ==> Demandes, BL/BI,Recherche,...
    // eventLabel ==> Nouvelle commande réassort,...
    createEvent(eventAction, eventCategory, eventLabel) {

        (<any>window).gtag('event', eventAction, {
            'event_category': eventCategory,
            'event_label': eventLabel,
            'user_id': this.userId
        });
    }

    createEventSearch(eventLabel, value) {

        (<any>window).gtag('event', 'search', {
            'event_category': 'Validation',
            'search_term': eventLabel,
            'value': value,
            'user_id': this.userId
        });
    }
    createEventScreenView(eventLabel) {

        (<any>window).gtag('event', 'view',
            {
                'event_category': 'screen view',
                'event_label': eventLabel,
                'user_id': this.userId
            });
    }
    sendPageView($url, $title) {

        (<any>window).gtag('config', environment.TrackingId, {
            'page_title': $title,
            'page_path': $url,
            'user_id': this.userId
        });
    }
    setUserId(userId: string) {
        if (!userId || !userId.length) {
            return;
        }
        // Définir l'ID utilisateur à partir du paramètre user_id de l'utilisateur connecté.
        this.userId = userId;
    }
}
