import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Article } from 'src/app/Models/article.model';

@Component({
  selector: 'app-article-modal',
  templateUrl: './article-modal.component.html',
  styleUrls: ['./article-modal.component.css']
})
export class ArticleModalComponent implements OnInit, OnDestroy {
  @Input() currentArticle: Article;

  constructor(private ngbModalService: NgbModal,
    private ngbModalConfig: NgbModalConfig) { }

  ngOnInit() {

  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    this.closeModal();

  }
}
