import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { DtLanguages } from '../../shared/dtLanguages';
import { Sweetalert } from '../../shared/sweetAlert';
import * as moment from 'moment';
import { WarehouseContacts, WarehouseContactDetail } from '../../Models/employeeWarehouse.model';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth-service';
import { WarehouseContactsService } from '../../services/warehouse-contacts.service';
import { HttpClient } from '@angular/common/http';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit, OnDestroy, AfterViewInit {

  filterVal = 'all';

  showSpinner = false;
  savingSpinner = false;
  contactsSub: Subscription;
  contactActionSub: Subscription;
  openedModal: NgbModalRef;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  showTableSpinner = false;
  selectedItem: WarehouseContactDetail = {
    id: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    enableCommunicationLogistic: false,
    enableMails: false,
    enableTitreExpress: false,
    enableTitreInfos: false,
    enableStoreRequest: false,
  };
  emailConfirm = '';
  currentNim = null;
  items: WarehouseContacts[] = [];
  itemsFiltred: WarehouseContacts[] = [];

  @ViewChild('content') modalLoading: ElementRef;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  get isWarehouse(): boolean {
    return this.authService.isWarehouse;
  }

  constructor(private ngbModalService: NgbModal,
    private http: HttpClient,
    private dtlanguages: DtLanguages,
    private authService: AuthService,
    private warehouseContactsService: WarehouseContactsService,
    private sweet: Sweetalert) { }

  ngOnInit() {

    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
      lengthChange: true,
      language: this.dtlanguages.frLanguage()
    };
    this.contactsSub = this.warehouseContactsService.getListRequestsUpdateListner()
      .subscribe(items => {
        this.showTableSpinner = false;
        this.items = items;
        this.applyFilters();
        this.rerender();
      });

    this.contactActionSub = this.warehouseContactsService.getActionRequestsUpdateListner()
      .subscribe(status => {
        if (status) {
          this.sweet.success('Succès !', 'Votre demande a été traité avec succès.');
          this.getItems();
          this.closeModal();
        } else {
          if (this.selectedItem && !this.selectedItem.id.length && this.selectedItem.role === 'Adresse générique') {
            this.sweet.warning('Attention !', 'Vous ne pouvez pas définir plusieurs coordonnées génériques pour ce dépôt');
          } else {
            this.sweet.warning('Attention !', 'Une erreur s‘est produite, nous vous invitons à essayer plus tard.');
          }
        }
        this.showSpinner = false;
        this.savingSpinner = false;
      });

    this.currentNim = this.authService.currentNim;
    this.getItems();
  }

  getItems() {
    this.showTableSpinner = true;
    this.warehouseContactsService.getItems(this.authService.currentNim);
  }

  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  // on post new contact
  onSaveRequest(form: NgForm) {
    this.savingSpinner = false;
    if (!form.valid || !this.selectedItem) {
      return;
    }

    const warehouseData = this.items.find(i => i.nim === this.currentNim);

    if (warehouseData && !(this.selectedItem.id || '').length) {
      const currentEmail = (this.selectedItem.email || '').trim().toLowerCase();
      const currentRole = (this.selectedItem.role || '').trim().toLowerCase();
      if (!currentEmail.length || !currentRole.length) {
        return;
      }

      if (currentEmail !== (this.emailConfirm || '').trim().toLowerCase()) {
        this.sweet.warning('Confirmation email', 'Vérifiez l’adresse de courriel saisie SVP.');
        return;
      }

      const itemExists = warehouseData.contacts.findIndex(
        c => c.email.toLowerCase() === currentEmail
          && c.role.toLowerCase() === currentRole) >= 0;
      // if any item
      if (itemExists) {
        this.sweet.warning('Contact existant', 'Attention le contact que vous avez saisie existe dans votre base.');
        return;
      }
    }

    this.savingSpinner = true;
    this.warehouseContactsService.addUpdateContact(this.currentNim, this.selectedItem.id, this.selectedItem);
  }

  deleteContact() {
    if (!this.selectedItem) {
      return;
    }
    this.sweet.confirmDialog('Attention !', 'Vous êtes sur le point de supprimer le contact, souhaitez vous continuer ?', (s, e) => {
      if (!s && !e) {
        this.savingSpinner = false;
        return;
      }
      if (e) {
        this.savingSpinner = true;
        this.warehouseContactsService.deleteContact(this.currentNim, this.selectedItem.id);
      }
    });
  }


  closeModal() {
    this.selectedItem = {
      id: '',
      role: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      enableCommunicationLogistic: false,
      enableMails: false,
      enableTitreExpress: false,
      enableTitreInfos: false,
      enableStoreRequest: false
    };
    this.emailConfirm = '';
    this.savingSpinner = false;
    this.currentNim = this.authService.currentNim;
    this.ngbModalService.dismissAll();
  }

  openModal(content, id: string = null) {
    this.openedModal = this.ngbModalService.open(content, { size: 'lg' });
  }

  exportToCSV() {

    if (!this.itemsFiltred || !this.itemsFiltred.length) {
      return;
    }

    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `base-contact-dépot-${date.format('YYYY-MM-DD')}`,
      filename: `base-contact-dépot-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.itemsFiltred.map((v, i) => {
      return v.contacts.map((c, j) => {
        return {
          'NIM': v.nim,
          'Dépôt': v.name,
          'Fonction': c.role,
          'Prénom': (c.firstName || '').toLowerCase(),
          'Nom': (c.lastName || '').toUpperCase(),
          'Email': (c.email || '').toLowerCase(),
          'Téléphone': `${c.phone}`,
          'Titre Express': c.enableTitreExpress ? 'OUI' : 'NON',
          'Titre Infos': c.enableTitreInfos ? 'OUI' : 'NON',
          'DLOG relation réseau': c.enableCommunicationLogistic ? 'OUI' : 'NON',
          'IPMV': c.enableMails ? 'OUI' : 'NON',
          'Demandes diffuseurs': c.enableStoreRequest ? 'OUI' : 'NON',
        };
      });
    }).reduce((a, b) => a.concat(b));
    csvExporter.generateCsv(data);
  }

  openItemDetail(nim: string, item: WarehouseContactDetail, content) {
    this.selectedItem = item;
    this.emailConfirm = item.email;
    this.currentNim = nim || this.authService.currentNim;
    this.openedModal = this.ngbModalService.open(content, { size: 'lg' });
  }

  applyFilters() {
    this.itemsFiltred = [];
    this.items.forEach(element => {

      const elementFiltred = Object.assign({}, element);

      elementFiltred.contacts = element.contacts.filter(i => {

        let nFilter = false;
        switch (this.filterVal) {
          case 'all':
            nFilter = true;
            break;
          case 'Titres express':
            nFilter = i.enableTitreExpress === true;
            break;
          case 'Titres infos':
            nFilter = i.enableTitreInfos === true;
            break;
          case 'Communication logistique':
            nFilter = i.enableCommunicationLogistic === true;
            break;
          case 'Mails':
            nFilter = i.enableMails === true;
            break;
          default:
            nFilter = i.id.startsWith(this.filterVal);
            break;
        }

        return nFilter;
      }
      );

      if (elementFiltred.contacts.length) {
        this.itemsFiltred.push(elementFiltred);
      }
    });
  }

  get phonePattern(): string {
    if (this.currentNim === '0062000') {
      return '^00377[0-9]{8,9}$';
    }

    return '^0[0-9]{9}$';
  }

  ngOnDestroy() {
    this.closeModal();
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.contactsSub) {
      this.contactsSub.unsubscribe();
    }
    if (this.contactActionSub) {
      this.contactActionSub.unsubscribe();
    }
  }
}
