import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BL } from '../Models/bl.model';
import { Article } from '../Models/article.model';

import { AuthService } from '../services/auth-service';
import { DashboardService } from '../services/dashboard.service';
import { TitreInfo } from '../Models/titreingo.model';
import { ArticlesService } from '../services/articles.service';
import { environment } from 'src/environments/environment';
import { PendingrequestService } from '../services/pendingrequest.service';
import { StoreAssortimentService } from '../services/store-assortiment.service';
import { StoreAssortimentResult, StoreAssortimentStatus } from '../Models/storeAssortiment.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Sweetalert } from '../shared/sweetAlert';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  blsSubs: Subscription;
  isUnsoldLoading = true;
  loadingText = 'Chargement en cours...';
  collapse = true;
  showSpinner = true;
  nim = '';
  codifQuery = '';
  proposalType = 9;

  rangeCA = 0;
  validationLoading = false;

  loadingSadp = false;

  pendingRequestLoading = false;
  serviceCount = 0;
  reorderCount = 0;

  bls: BL[] = [];

  private storeChangeSubs: Subscription;
  private storeTopSalesSubs: Subscription;
  private simulationValidationSubs: Subscription;

  private pendingCounterRequestSubs: Subscription;
  private assortimentRequestSubs: Subscription;

  private proposalsSubs: Subscription;

  private titreInfoSubs: Subscription;

  articles: Article[];
  articleToRead: Article;
  assortStatus: StoreAssortimentStatus = null;

  proposalResult: StoreAssortimentResult = null;

  titreInfo: TitreInfo;

  constructor(private authService: AuthService,
    private dashboradService: DashboardService,
    private ngbModalService: NgbModal,
    protected sweet: Sweetalert,
    private pendingRequestService: PendingrequestService,
    private assortimentService: StoreAssortimentService,
    private articleService: ArticlesService,
    private http: HttpClient) {
  }

  get pfpRequestEnabled(): boolean {
    return !environment.disabledPFPRequests;
  }
  get assortimentDashboardEnabled(): boolean {
    return !environment.disabledAssortimentDashboard;
  }
  get proposalEnabled(): boolean {
    return !environment.disabledProposal;
  }
  get displayAssortimentDashboard(): number {
    if (this.assortimentDashboardEnabled
      && this.assortStatus !== null) {
        return this.assortStatus.dashboardType;
      }

    return -1;
  }
  get displayLot2c(): boolean {
    return !environment.disabledLot2c
    && this.assortStatus !== null
    && this.assortStatus.displayDashboard;
  }
  get isStore(): boolean {
    return this.authService.isStore;
  }
  get isRelay(): boolean {
    return this.authService.isRelay;
  }
  get isWarehouse(): boolean {
    return this.authService.isWarehouse;
  }
  get hideRuptureRecurrente(): boolean {
    return environment.hideRuptureRecurrente;
  }

  ngOnInit() {
    this.nim = this.authService.currentNim;

    this.proposalsSubs = this.assortimentService.getAssortimentTitresListener()
      .subscribe(data => {
        this.proposalResult = data;
        if(this.proposalResult
          && this.proposalResult.settings
          && this.proposalResult.settings.ca
        ) {
          this.rangeCA = data.settings.ca.currentValue || 0;
        }
      });

    this.storeChangeSubs = this.authService.getStoreChangeListener()
      .subscribe(store => {
        if (store) {
          this.refreshData(store.nim);
        }
      });

    this.blsSubs = this.dashboradService.getUnsoldsListener()
      .subscribe((res: BL[]) => {
        this.bls = res || [];
        this.isUnsoldLoading = false;
      },
        err => {
          this.bls = [];
          this.isUnsoldLoading = false;
        });

    this.titreInfoSubs = this.articleService.getLatestBlobListner()
      .subscribe(result => {
        this.titreInfo = result;
      });

    this.pendingCounterRequestSubs = this.pendingRequestService.getCounterPendingRequestListener()
      .subscribe(result => {
        this.pendingRequestLoading = false;
        if (result) {
          this.serviceCount = result.service || 0;
          this.reorderCount = result.reorder || 0;
        }
      });

    this.assortimentRequestSubs = this.assortimentService.getAssortimentDashboardListener()
      .subscribe(result => {
        this.assortStatus = result || null;
        if (result && result.pressConnectAllowed) {
          this.pendingRequestService.getPendingRequestCounter(this.authService.currentNim);
        }
      });

    this.simulationValidationSubs = this.assortimentService.getAssortimentValidationListener()
      .subscribe(result => {
        this.validationLoading = false;
        this.showSpinner = false;
        if (result) {
          // if (result.codifs && result.codifs.length) {
          //   result.codifs.forEach(r => localStorage.setItem(this.getCodifLineId(r), r));
          // }
          if (result.status) {
            this.sweet.success('Félicitation !', 'Votre demande a été prise en compte avec succès.');
          }   else {
            this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
          }
          this.closeModal();
        } else {
          this.sweet.warning('Attention !', 'Impossible de valider votre demande actuellement, veuillez réessayer ultérieurement SVP.');
        }
      });

    this.refreshData(this.authService.currentNim);
    if (this.isStore) {
      this.articleService.getLatestBlob();
    }
  }

  onChangeFamily(event) {
    if (!event || !event.currentTarget) {
      return;
    }

    this.proposalResult.families.forEach(p => p.selected = event.currentTarget.checked);
  }

  isAllSelected(): boolean {
    return this.proposalResult.families.findIndex(e => !e.selected) < 0;
  }

  downloadSADPFile() {
    if (this.loadingSadp) {
      return;
    }
    this.loadingSadp = true;
    const url = `${environment.APIURL}/api/v1/stores/palmares/sadp?nim=${this.authService.currentNim}`;
    const options = {
      headers: new HttpHeaders({
        // 'Accept': 'application/octet-stream',
        'responseType': 'blob',
        observe: 'response'
      })
    };
    const filename = `palmares-sadp-${this.authService.currentNim}.xlsx`;
    this.http.get<{result: string}>(url, options)
    .subscribe(response => {
      if (response
        && response.result
        && response.result.length) {
        const byteCharacters = atob(response.result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/octect;base64' });
        this.loadingSadp = false;
        saveAs(file, filename);
      }
    });
  }

  scrollRight(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }
    container.scrollTo({ left: (container.scrollLeft + 450), behavior: 'smooth' });
  }

  scrollLeft(element) {
    
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }

    container.scrollTo({ left: (container.scrollLeft - 450), behavior: 'smooth' });
  }

  getParentContainer(element) {
    if (!element
      || !element.currentTarget
      || !element.currentTarget.parentElement
      || !element.currentTarget.parentElement.parentElement
      || element.currentTarget.parentElement.parentElement.className !== 'category-items') {
        return null;
      }
    return element.currentTarget.parentElement.parentElement;
  }

  refreshData(nim: string) {

    this.getUnsolds(nim);
    this.assortStatus = null;
    if (this.isStore) {
      this.pendingRequestLoading = true;

      if (this.pfpRequestEnabled
        && this.assortStatus
        && this.assortStatus.pressConnectAllowed) {
        this.pendingRequestService.getPendingRequestCounter(this.authService.currentNim);
      }

      if (this.assortimentDashboardEnabled) {
        this.assortimentService.getAssortimentDashboard(this.authService.currentNim, true);
        this.assortimentService.getAssortimentTitles(this.authService.currentNim, this.proposalType);
      }
    }
  }

  // get unsolds
  getUnsolds(nim: string) {

    if (!this.authService.isStore) {
      return;
    }

    this.isUnsoldLoading = true;
    this.dashboradService.getUnsoldsService(nim);
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
  }

  removeSettings() {
    this.proposalResult.families.forEach(s => s.selected = false);
    this.rangeCA = 0;
    const message9 = `Êtes vous sûre de vouloir retirer vos préférences ?`;
    this.saveInstallation(message9);
  }

  saveProposalSettings() {
    this.saveInstallation(`Êtes vous sûre de vouloir continuer ?`);
  }

  private saveInstallation(message: string) {
    if (this.validationLoading) {
      return;
    }

    this.sweet.confirmDialog('Attention !',
    // tslint:disable-next-line:max-line-length
    message, (s, e) => {
      if (!s && !e) {
        this.validationLoading = false;
        this.showSpinner = false;
        return;
      }
      this.validationLoading = true;
      this.showSpinner = true;
      if (e) {
        this.assortimentService.validateAssortimentSimulation(this.authService.currentNim, this.proposalType,
          {
            families: this.getFamiliesToSimulate(),
            caMin: this.rangeCA,
            efficacityMin: 0,
            list: []
          });
      }
    });
  }

  getFamiliesToSimulate(): String[] {
    const families = this.proposalResult.families.filter(s => s.selected);
    return families.map(s => s.name);
  }

  ngOnDestroy() {
    if (this.blsSubs) {
      this.blsSubs.unsubscribe();
    }
    if (this.storeChangeSubs) {
      this.storeChangeSubs.unsubscribe();
    }
    if (this.storeTopSalesSubs) {
      this.storeTopSalesSubs.unsubscribe();
    }
    if (this.titreInfoSubs) {
      this.titreInfoSubs.unsubscribe();
    }
    if (this.pendingCounterRequestSubs) {
      this.pendingCounterRequestSubs.unsubscribe();
    }
    if (this.assortimentRequestSubs) {
      this.assortimentRequestSubs.unsubscribe();
    }
    if(this.proposalsSubs) {
      this.proposalsSubs.unsubscribe();
    }
    if(this.simulationValidationSubs) {
      this.simulationValidationSubs.unsubscribe();
    }
  }
}
