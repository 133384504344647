import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Issue } from 'src/app/Models/issue.model';

@Pipe({name: 'AuthorizedLabel'})
export class AuthorizedLabel implements PipeTransform {
  transform(value: Issue): string {

    let text = 'n° ' + (value.issueNumber || value.referenceId);

        if (value.supplied === false) {
            text += ' non facturé';
        } else if (value.pdifInfo) {

            if (value.pdifInfo.returnAuthorized) {

                if (value.pdifInfo.isPremature) {
                    text += ' prématuré autorisé';
                } else if (value.pdifInfo.neverTooOld) {
                    text += ' autorisé';
                } else if (value.pdifInfo.tooOldDate) {
                    text += ' autorisé jusqu’au ' + moment(value.pdifInfo.tooOldDate).locale('fr').format('DD/MM/YYYY');
                } else {
                    text += ' autorisé';
                }
            } else if (moment().diff(moment(value.pdifInfo.returnDate), 'days') <= 0) {
                    text += ' prématuré non autorisé';
            } else if (value.pdifInfo.tooOldDate) {
                text += ' trop vieux depuis ' + moment(value.pdifInfo.tooOldDate).locale('fr').format('DD/MM/YYYY');
            }
        }
    return text;
  }
}
