import { Component, OnInit, ViewChild } from '@angular/core';
import { PdvbaseComponent } from '../pdvbase/pdvbase.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { OpeningItem } from 'src/app/Models/hours/opening.model';
import { NgForm } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { LineStatement } from 'src/app/Models/newStatment.model';
import { Hour } from 'src/app/Models/hours/hours.model';

@Component({
  selector: 'app-timing',
  templateUrl: './timing.component.html',
  styleUrls: ['./timing.component.css']
})
export class TimingComponent extends PdvbaseComponent implements OnInit {

  SUFFIX_OPENING = 'hours.opening.';
  inItem: OpeningItem;
  private hasChanged = false;

  // pour gérer les actions (edit,delete,cancel)
  private AllDays = [
    { id: 1, name: 'Lundi', etat: 'show' },
    { id: 2, name: 'Mardi', etat: 'show' },
    { id: 3, name: 'Mercredi', etat: 'show' },
    { id: 4, name: 'Jeudi', etat: 'show' },
    { id: 5, name: 'Vendredi', etat: 'show' },
    { id: 6, name: 'Samedi', etat: 'show' },
    { id: 7, name: 'Dimanche', etat: 'show' },
    { id: 8, name: 'Jours fériés', etat: 'show' },
  ];

  get mainChanges(): LineStatement[] {
    if (!this.changes || !this.changes.length || !this.changes[0] || !this.changes[0].changes) {
      return [];
    }

    return this.changes[0].changes;
  }

  private _items: OpeningItem[];
  get StoreDay(): OpeningItem[] {

    if (this._items && this._items.length) {
      return this._items;
    }
    this.reloadFromLocal();
    return this._items;
  }

  private reloadFromLocal() {
    this._items = this.AllDays.map((v, index) => {
      let item: OpeningItem = null;
      if (this.store && this.store.hours && this.store.hours.opening) {
        item = this.store.hours.opening.find(i => i.weekDay === v.id);
      }

      item = {
        weekDay: v.id,
        day: v.name,
        opening: item == null ? '' : item.opening,
        closing: item == null ? '' : item.closing,
        pauseFrom: item == null ? '' : item.pauseFrom,
        pauseTo: item == null ? '' : item.pauseTo,
        status: item == null ? 'closed' : item.status
      };
      return item;
    }).sort(i => i.weekDay);
  }

  ngOnInit() {
    super.ngOnInit();

    this.ga.createEventScreenView('Pdv > Horaires');
  }

  getDayStatus(dayCode: number) {

    const day = this._items[dayCode];
    let pending: LineStatement = null;
    if (this.changes && this.changes.length && this.changes[0].changes) {
      pending = this.changes[0].changes.find(i => i.id === this.synitizeId(day.weekDay));
    }

    const lineHasPendingChanges = this.hasPendingChanges(day, pending);

    let str = day.day;
    if (pending && lineHasPendingChanges) {
      if (pending.action === 'delete') {
        return `${str} <br> demande de fermeture au ${moment(pending.changedOn).locale('fr').format('DD/MM')}`;
      } else if (pending.action === 'update') {
        return str = `${str} <br> demande de modification au ${moment(pending.changedOn).locale('fr').format('DD/MM')}`;
      } else {
        return `${str} <br> demande d’ouverture au ${moment(pending.changedOn).locale('fr').format('DD/MM')}`;
      }
    } else {
      if (day.status === 'edit') {
        return `${str} <br> modification en cours`;
      } else if (day.status === 'deleting') {
        return `${str} <br> suppression en cours`;
      } else if (day.status === 'closed') {
        return `${str} <br> fermé`;
      }
    }
    return str;
  }

  private synitizeId(dayCode: number) {
    return `${this.SUFFIX_OPENING}${dayCode}`;
  }

  isPending(dayCode: number) {

    // const day = this._items[dayCode];
    // return (day.status || 'closed') !== 'closed';

    const day = this._items[dayCode];
    return this.hasPendingChanges(day);
  }

  private hasPendingChanges(current: OpeningItem, pending: LineStatement = null) {

    let pendingValue: OpeningItem = null;
    let lineHasPendingChanges = false;
    if (!pending && this.changes && this.changes.length && this.changes[0].changes) {
      pending = this.changes[0].changes.find(i => i.id === this.synitizeId(current.weekDay));
    }

    if (pending && pending.newValue && pending.newValue.length) {
      pendingValue = this.tryParseJSON(pending.newValue) as OpeningItem;
      lineHasPendingChanges = pendingValue && (pendingValue.opening !== current.opening
        || pendingValue.pauseFrom !== current.pauseFrom
        || pendingValue.pauseTo !== current.pauseTo
        || pendingValue.closing !== current.closing);
    }

    return lineHasPendingChanges;
  }

  private tryParseJSON(str: string) {
    try {
      return JSON.parse(str);
    } catch (error) {
      return null;
    }
  }

  getDate(time: string, dayCode: number, col: string) {

    const day = this._items[dayCode];
    if (this.changes && this.changes.length && this.changes[0].changes) {
      const change = this.changes[0].changes.find(i => i.id === `${this.SUFFIX_OPENING}${day.weekDay}`);
      if (change && change.action !== 'deleting') {
        switch (col) {
          case 'open':
            const openingItemOpen: OpeningItem = this.tryParseJSON(change.newValue);
            if (openingItemOpen && openingItemOpen.opening) {
              time = openingItemOpen.opening;
            }
            break;
          case 'from':
            const openingItemFrom: OpeningItem = this.tryParseJSON(change.newValue);
            if (openingItemFrom && openingItemFrom.pauseFrom) {
              time = openingItemFrom.pauseFrom;
            }
            break;
          case 'to':
            const openingItemTo: OpeningItem = this.tryParseJSON(change.newValue);
            if (openingItemTo && openingItemTo.pauseTo) {
              time = openingItemTo.pauseTo;
            }
            break;
          case 'close':
            const openingItemClose: OpeningItem = this.tryParseJSON(change.newValue);
            if (openingItemClose && openingItemClose.closing) {
              time = openingItemClose.closing;
            }
            break;
        }
      }
    }

    if (!time || !time.includes(':')) {
      return '';
    }

    const today = moment(new Date()).locale('fr').format('YYYY-MM-DD');

    const timeResult = moment(today + ' ' + time).locale('fr').format('HH:mm');
    return timeResult;
  }

  /**
   * on click sur button supprimer  horaire
   */
  closeTheDay(index: number) {
    this._items[index].status = 'deleting';
    this._items[index].closing = '';
    this._items[index].opening = '';
    this._items[index].pauseFrom = '';
    this._items[index].pauseTo = '';
  }
  openModal(content, index) {
    this.inItem = this._items[index];
    this.ngbModalService.open(content, { size: 'lg' });
  }

  itemModified($event) {
    const weekDay = $event.weekDay;
    this.inItem = null;
    this._items[weekDay - 1] = $event;
    this.hasChanged = true;
  }
  /**
   * on click sur button cancel  horaire
   */
  cancelTheDay(index: number) {

    if (!this.store.hours) {
      return;
    }

    const store = this.store.hours.opening.find(i => i.weekDay === this._items[index].weekDay);

    if (!store) {
      this._items[index].status = 'closed';
      this._items[index].closing = '';
      this._items[index].opening = '';
      this._items[index].pauseFrom = '';
      this._items[index].pauseTo = '';
    } else if (store) {
      this._items[index].status = undefined;
      this._items[index].closing = store.closing;
      this._items[index].opening = store.opening;
      this._items[index].pauseFrom = store.pauseFrom;
      this._items[index].pauseTo = store.pauseTo;
    }
  }

  cancelForm() {
    this.AllDays.map((v, i) => {
      this.cancelTheDay(i);
    });
  }

  saveTiming() {
    this._items.forEach(element => {
      if (!this.store.hours) {
        this.store.hours = {
          opening: []
        } as Hour;
      }
      const current = this.store.hours.opening.find(i => i.weekDay === element.weekDay);

      const hasChanges = this.hasChanges(element, current);

      if (!hasChanges) {
        return;
      }

      const ctrlopen = this.getStatementOrDefault(`${this.SUFFIX_OPENING}${element.weekDay}`, 'Horaires d’ouvertures', element.status);
      ctrlopen.label = `Horaires du ${element.day}`;
      ctrlopen.oldValue = JSON.stringify(current);
      ctrlopen.newValue = JSON.stringify(element);

      if (!current) {
        ctrlopen.action = 'add';
        ctrlopen.oldValue = '';
        this.localStatements.push(ctrlopen);
      } else {
        if (element.status === 'delete' || element.status === 'deleting' || element.status === 'closed') {
          ctrlopen.action = 'delete';
          ctrlopen.newValue = '';
        } else {
          ctrlopen.action = 'update';
        }

        if (hasChanges) {
          this.localStatements.push(ctrlopen);
        }
      }
    });

    this.save(this.localStatements);
    this.ga.createEvent('click', 'Timing', 'Modification sur les Horaires d’ouverture');
  }

  private hasChanges(element: OpeningItem, current: OpeningItem) {
    if (!element) {
      return false;
    }

    if (!current) {
      return (element.opening || '').length &&
          (element.closing || '').length;
    }

    return current.opening !== element.opening ||
      current.pauseFrom !== element.pauseFrom ||
      current.pauseTo !== element.pauseTo ||
      current.closing !== element.closing;
  }
}

