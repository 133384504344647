import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'sortPath'
})
export class SortPath implements PipeTransform {

    transform(paths: any): any {
        const newPath = paths.sort((a, b) => (a.name || '').localeCompare(b.name));
        return newPath;
    }

}
