import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-loading',
  templateUrl: './title-loading.component.html',
  styleUrls: ['./title-loading.component.css']
})
export class TitleLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
