import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service';
import { TitreExpress } from '../Models/admin/titreexpress.model';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TitreExpressConfig } from '../Models/admin/titreexpressConfig.model';
import { Issue } from '../Models/issue.model';

@Injectable({
  providedIn: 'root'
})
export class TitreexpressService {

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  private itemsUpdated = new Subject<TitreExpress[]>();
  private itemUpdated = new Subject<TitreExpress>();
  private itemSendUpdated = new Subject<any>();
  private issuesUpdated = new Subject<Issue[]>();

  getItems() {
    const url = `${environment.APIURL}/api/v2/titreexpress`;
    this.http.get<{ result: TitreExpress[] }>(url)
      .subscribe((response: any) => {
        if (response) {
          this.itemsUpdated.next(response.result);
        } else {
          this.itemsUpdated.next([]);
        }
      }, err => {
        this.itemsUpdated.next([]);
      });
  }

  getItemDetail(id: string) {
    const url = `${environment.APIURL}/api/v2/titreexpress/${id}`;
    this.http.get<{ result: TitreExpress }>(url)
      .subscribe((response: any) => {
        if (response) {
          this.itemUpdated.next(response.result);
        } else {
          this.itemUpdated.next(null);
        }
      }, err => {
        this.itemUpdated.next(null);
      });
  }

  sendItem(id: string) {
    const url = `${environment.APIURL}/api/v2/titreexpress/${id}/send`;
    this.http.post<{ result: boolean }>(url, {})
      .subscribe((response: any) => {
        if (response) {
          this.itemSendUpdated.next({id: id, status: response.result});
        } else {
          this.itemSendUpdated.next({id: id, status: false});
        }
      }, err => {
        this.itemSendUpdated.next({id: id, status: false});
      });
  }

  sendItemRequestsUpdateListner() {
    return this.itemSendUpdated.asObservable();
  }

  getItemDetailRequestsUpdateListner() {
    return this.itemUpdated.asObservable();
  }

  getIssuesRequestsUpdateListner() {
    return this.issuesUpdated.asObservable();
  }

  getListRequestsUpdateListner() {
    return this.itemsUpdated.asObservable();
  }

  saveItem(data: any) {
    const url = `${environment.APIURL}/api/v2/titreexpress`;
    return this.http.post<{ result: boolean }>(url, data);
  }

  removeItem(id: string, itemId: string, typeId: string) {
    const url = `${environment.APIURL}/api/v2/titreexpress/${id}/items/${itemId}/type/${typeId}`;
    return this.http.delete<{ result: boolean }>(url);
  }

  updateItem(id: string, itemId: string, data: any) {
    const url = `${environment.APIURL}/api/v2/titreexpress/${id}/items/${itemId}`;
    return this.http.put<{ result: boolean }>(url, data);
  }
  getConfig() {
    const url = `${environment.APIURL}/api/v2/titreexpress/config`;
    return this.http.get<{ result: TitreExpressConfig }>(url);
  }

  // get parutions
  getIssues(codif: string) {

    const url = environment.APIURL + '/api/v2/titreexpress/issues?codif=' + codif;
    this.http.get<{ result: Issue[] }>(url)
        .subscribe(response => {
            if (response) {
                this.issuesUpdated.next(response.result || []);
            } else {
                this.issuesUpdated.next([]);
            }
        }, err => {
            this.issuesUpdated.next([]);
          });
}

  updateConfig(data: any) {
    const url = `${environment.APIURL}/api/v2/titreexpress/config`;
    return this.http.post<{ result: boolean }>(url, data);
  }
}
