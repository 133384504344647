import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SupportItem } from '../Models/support.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private topicsUpdated = new Subject<SupportItem[]>();
  private topicsSendUpdated = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  // get title by keyword
  getTopics() {
    this.http.get<{ result: SupportItem[] }>(environment.APIURL + '/api/v1/support/topics')
        .subscribe(response => {
          if (response) {
            this.topicsUpdated.next(response.result);
          } else {
            this.topicsUpdated.next([]);
          }
        }, err => {
          this.topicsUpdated.next([]);
        });
  }

  getTopicsUpdateListner() {
    return this.topicsUpdated.asObservable();
  }

  // get title by keyword
  sendTopics(topicId: number, subTopicId: number, nim: string, data: any) {
    const url = `${environment.APIURL}/api/v1/support/${topicId}/${subTopicId}?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, data)
        .subscribe(response => {
          if (response) {
            this.topicsSendUpdated.next(response.result);
          } else {
            this.topicsSendUpdated.next(false);
          }
        }, err => {
          this.topicsSendUpdated.next(false);
        });
  }

  getSendTopicsUpdateListner() {
    return this.topicsSendUpdated.asObservable();
  }
}
