import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TitreExpress, TitreExpressItem } from 'src/app/Models/admin/titreexpress.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TitreexpressService } from 'src/app/services/titreexpress.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-titreexpress-detail',
  templateUrl: './titreexpress-detail.component.html',
  styleUrls: ['./titreexpress-detail.component.css']
})
export class TitreexpressDetailComponent implements OnInit {


  @Input() selectedItem: TitreExpress;
  @Output() reloadItemsOutput = new EventEmitter<any>();
  private itemSub: Subscription;
  private openedModal: NgbModalRef;
  selectedTitreExpressItem: TitreExpressItem;
  savingSpinner = false;
  showSpinner = false;

  constructor(private ngbModalService: NgbModal,
    private titleExpressService: TitreexpressService,
    private sweet: Sweetalert) { }

  ngOnInit() {
    this.itemSub = this.titleExpressService.getItemDetailRequestsUpdateListner()
      .subscribe(item => {
        this.showSpinner = false;
        this.selectedItem = item;
        if (item == null) {
          this.reloadItemsOutput.emit();
          this.closeModal();
        }
      }, err => {
        this.showSpinner = false;
        this.selectedItem = null;
        this.closeModal();
      });
  }

  getItemDetail(id: string) {
    this.showSpinner = true;
    this.titleExpressService.getItemDetail(id);
  }
  saveItem(titreexpress) {
    if (!titreexpress) {
      return;
    }

    const itemToPush = titreexpress.getItemToSave();

    if (this.selectedTitreExpressItem) {
      this.updatetem(itemToPush);
    }
  }

  updatetem(itemToPush) {
    this.savingSpinner = true;
    this.titleExpressService.updateItem(this.selectedItem.id, this.selectedTitreExpressItem.id, itemToPush)
      .subscribe(res => {
        this.savingSpinner = false;
        if (res) {
          this.sweet.success('Succès !', 'Notification enregistrée avec succès !');
           this.reloadItemsOutput.emit();
        } else {
          this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
        }
        this.getItemDetail(this.selectedItem.id);
        this.closeSecondeModal();
      },
        err => {
          this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
          this.savingSpinner = false;
        });
  }
  editItem(id: string, itemId: string, type: number, content) {

    if (id && id.length && itemId && itemId.length) {
      this.selectedTitreExpressItem = this.selectedItem.items.find(item => item.id === itemId && item.type === type);
      // this.getItemDetail(id);
    }
    this.openedModal = this.ngbModalService.open(content, { size: 'lg' });

  }

  getListUpdatePrice(titreExpress: TitreExpress) {
    return titreExpress.items.filter(s => s.type === 0);
  }
  getListSuplement(titreExpress: TitreExpress) {
    return titreExpress.items.filter(s => s.type === 1);
  }
  getListUpdateMEV(titreExpress: TitreExpress) {
    return titreExpress.items.filter(s => s.type === 2);
  }
  getListAvis(titreExpress: TitreExpress) {
    return titreExpress.items.filter(s => s.type === 3);
  }
  removeItem(id: string, itemId: string, typeId: string) {
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir supprimer l’item ?',
    (err: Error, result: any): void => {
      if (result) {
        this.titleExpressService.removeItem(id, itemId, typeId).subscribe(response => {
          this.showSpinner = false;
          if (response.result) {
            const msg = 'L’item a été supprimé avec succès.';
            this.sweet.success('Succès !', msg);
            this.titleExpressService.getItemDetail(id);
          } else {
            this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
          }
        },
          error => {
            this.showSpinner = false;
            this.sweet.error('Attention !', 'Nous n’avons pas pu traiter votre demande, veuillez réessayer ultérieurement !');
          });
      }
    });
  }

  closeModal() {
    if (this.selectedTitreExpressItem) {
      this.closeSecondeModal();
      this.selectedTitreExpressItem = null;
    } else {
      this.selectedTitreExpressItem = null;
      this.ngbModalService.dismissAll();
      this.selectedItem = null;
    }
  }
  closeSecondeModal() {
    if (!this.openedModal) {
      return;
    }
    this.openedModal.close();
  }
}
