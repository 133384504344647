import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  recipient = '';
  htmlSubject = '';
  htmlContent = '';
  isSending = false;

  selectedNims: string[] = [];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Message...',
    translate: 'no'
  };

  constructor(private alertService: AlertService,
    private sweet: Sweetalert) { }

  ngOnInit() {
    this.alertService.getSendUpdateListener()
      .subscribe(status => {
        this.isSending = false;
        if (status) {
          this.sweet.success('Succès !', 'l’alerte est envoyée avec succès.');
        } else {
          this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
        }
      }, error => {
        this.isSending = false;
        this.sweet.warning('Attention !', 'Une erreur serveur s’est produite. Veuillez réessayer SVP.');
      });
  }

  onFileSelect(input: HTMLInputElement) {

    this.selectedNims = [];
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.split(/,|;/ig).map(t => t.trim());
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
        if (nims.length === 0) {
          alert('Le fichier ne contient pas de NIM sur 7 caractères.');
          input.value = '';
        } else {
          this.selectedNims = nims;
        }
        };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  sendAlert() {
    if (this.htmlContent === ''
      || this.recipient === ''
      || this.htmlSubject === '') {
      this.sweet.warning('Attention !', 'Vous devez choisir un destinataire et renseigner le contenu du message');
      return;
    }

  if (this.recipient !== '3') {
    this.selectedNims = null;
  } else if (!this.selectedNims || !this.selectedNims.length) {
    this.sweet.warning('Attention !', 'Vous devez renseigner une liste de NIMS');
    return;
  }

   const data = {
      recipient: this.recipient,
      content: this.htmlContent,
      subject: this.htmlSubject,
      selectedNims: this.selectedNims
    };

    this.isSending = true;

    this.alertService.sendAdminAlert(data);
  }
}
