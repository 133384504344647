import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AuthService } from 'src/app/services/auth-service';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { CustomerCode } from 'src/app/Models/customerCode.model';
import { CustomerCodeService } from 'src/app/services/customerCode.service';

@Component({
  selector: 'app-customer-code',
  templateUrl: './customer-code.component.html',
  styleUrls: ['./customer-code.component.css']
})
export class CustomerCodeComponent implements  OnInit, OnDestroy, AfterViewInit {

 // declarations
  private customerCodesSubs: Subscription;
  customerCodes: CustomerCode[];
  showSpinner = false;
  blLastThreeDays: string[] = [];
  dateCode: string;
  codeText = '';
  minDate: NgbDateStruct;
  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  // constructor
  constructor(private customerCodeService: CustomerCodeService,
    private authService: AuthService,
    private ngbModalService: NgbModal,
    private dtlanguages: DtLanguages,
    private sweet: Sweetalert) { }

    ngOnInit() {

      // datatable options
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        searching: false,
        responsive: true,
        lengthChange: true,
        autoWidth: false,
        columnDefs: [{
          'targets': [2],
          'type': 'date-DD-MMM-YYYY'
        }
        ],
        order: [3, 'desc'],
        language: this.dtlanguages.frLanguage()
      };
      const mnt = moment().add(1, 'day');
      this.minDate = {year: mnt.year(), month: mnt.month(), day: mnt.day()};
      this.customerCodesSubs = this.customerCodeService.getCustomerCodesUpdateListner()
      .subscribe((result: CustomerCode[]) => {
        this.customerCodes = result;
        this.showSpinner = false;
        this.rerender();
      }, erro => {
        this.showSpinner = false;
      });
      this.onBindCustomerCodes();
    }

    // bind customerCodes
  onBindCustomerCodes() {

    this.showSpinner = true;
    this.customerCodeService.getCustomerCodes();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  isFormValid(form: NgForm) {
    if (!form) {
      return false;
    }

    const refClient = form.value.refClient || '';
    const emailClient = form.value.emailClient || '';
    const endDate = form.value.datePickerFrom;

    return refClient.length && emailClient.length && endDate ? true : false;
  }

  onSaveRequest(form: NgForm) {
    if (!this.isFormValid(form)) {
      return;
    }

    const refClient = form.value.refClient;
    const endDate = form.value.datePickerFrom;
    const email = form.value.emailClient;

    if (!endDate
      || !refClient || !refClient.length
      || !email || !email.length) {
      return;
    }
    const dt = new Date(`${endDate.year}-${endDate.month}-${endDate.day}`);
    const mnt = moment(dt);

    this.showSpinner = true;
    const newCodeObj = {
      'endDate': mnt.format('YYYY-MM-DD'),
      'refClient': refClient,
      'email': email
    };

    this.customerCodeService.addNewCustomerCode(newCodeObj)
      .subscribe(response => {
        this.showSpinner = false;
        if (response.result) {
          const msg = 'Votre demande de création d’accès a bien été prise en compte, un mail a été envoyé à votre client.';
          this.sweet.success('Succès !', msg);
          this.onBindCustomerCodes();
          this.closeModal();
        } else {
          this.sweet.warning('Attention !', 'Impossible de générer le code en ce moment.');
        }
      },
        error => {
          this.showSpinner = false;
          this.sweet.warning('Attention !', 'Impossible de générer le code en ce moment.');
        });
  }

  // on open modal and logic
  openModal(content) {
    this.ngbModalService.open(content);

  }

  cancelCode(code: CustomerCode) {

    if (!code || !code.id || !code.id.length) {
      return;
    }
    this.showSpinner = true;

    this.customerCodeService.cancelCustomerCode(code.id)
      .subscribe(response => {
        this.showSpinner = false;
        if (response.result) {
          const msg = 'Le code a été annulé avec succès.';
          this.sweet.success('Succès !', msg);
          this.onBindCustomerCodes();
        } else {
          this.sweet.warning('Attention !', 'Impossible d’annuler le code en ce moment.');
        }
      },
        error => {
          this.showSpinner = false;
          this.sweet.warning('Attention !', 'Impossible de générer le code en ce moment.');
        });
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }
  ngOnDestroy() {
    this.closeModal();
    if (this.customerCodesSubs) {
      this.customerCodesSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }
}

